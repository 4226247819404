// import React, { useState } from "react";
// import {
//   Modal,
//   Button,
//   Input,
//   Form,
//   Upload,
//   Row,
//   Col,
//   Select,
//   message,
// } from "antd";

// import { UploadOutlined } from "@ant-design/icons";
// import { AddTruck, MergeTruckDriver } from "../API/API";
// import Loader from "../Loaders/Loader";
// import useDrivers from "../Hooks/useDrivers";
// import useTrucks from "../Hooks/useTrucks";
// import { useTranslation } from "react-i18next";
// import { CREATE_DRIVER_TRUCK } from "../../graphql/Mutation/owner/addDriverTruckMutation";
// import { useMutation, useQuery } from "@apollo/client";
// import { GET_TRUCKS, UPDATE_TRUCKS } from "../../graphql/Mutation/owner/addTruckMutation";
// import { GET_DRIVERS, UPDATE_DRIVERS } from "../../graphql/Mutation/owner/addDriverMutation";
// export default function AddTruckDriverModal({
//   isModalVisible,
//   setIsModalVisible,
// }) {
//   const { Option } = Select;
//   const { drivers } = useDrivers();
//   const { trucks } = useTrucks();
//   const [loading, setLoading] = useState(false);
//   const { t } = useTranslation();
//   const [form] = Form.useForm();
//   const handleOk = () => {
//     setIsModalVisible(!isModalVisible);
//   };
//   const handleCancel = () => {
//     setIsModalVisible(!isModalVisible);
//   };
//   const [formData, setFormData] = useState({
//     truck: "",
//     driver: "",
//   });
//   const { data: driversData } = useQuery(GET_TRUCKS);
//     const { data: trucksData } = useQuery(GET_DRIVERS);
//     const [updateDriver] = useMutation(UPDATE_DRIVERS);
//     const [updateTruck] = useMutation(UPDATE_TRUCKS);
//     const Drivers = driversData?.Drivers || [];
//     const Trucks = trucksData?.Trucks || [];

//   // const onFinish = async (values) => {
//   //   setLoading(true);
//   //   let data = {
//   //     driver: { uid: formData.driver.uid },
//   //     truck: { uid: formData.truck.uid },
//   //   };
//   //   if (formData.truck !== "" && formData.driver !== "") {
//   //     await MergeTruckDriver(data)
//   //       .then(() => {
//   //         setLoading(false);
//   //         // form.resetFields();
//   //         handleCancel();
//   //         setFormData({
//   //           truck: "",
//   //           driver: "",
//   //         });
//   //         message.success("Add Successull!");
//   //       })
//   //       .catch((e) => {
//   //         setLoading(false);
//   //         message.error(JSON.stringify(e));
//   //       });
//   //   } else {
//   //     alert("choose trcuk & driver");
//   //   }
//   // };
//   const [refetch,createDriverTruck] = useMutation(CREATE_DRIVER_TRUCK);
//   const ownerId = localStorage.getItem('id');
//   const [success, setSuccess] = useState(false);
//   // const onFinish = async (values) => {
//   //   // setLoading(true);
//   //   console.log(values);
//   //   const ownerId = localStorage.getItem('id');
  
//   //   try {
//   //     const { data } = await createDriverTruck({
//   //       variables: {
//   //         createDriverTruckInput: {
//   //           truckNo: values.truckNo, 
//   //           truck: values.truck?.id, 
//   //           owner: ownerId,
//   //           driverName: values.driverName, 
//   //           driver: values.driver?.id,
//   //         },
//   //       },
//   //     });
  
//   //     // Handle success
//   //     if (data && data.createDriverTruck.success) {
//   //       setLoading(false);
//   //       message.success("Add Successful!");
//   //     } else {
//   //       // Handle failure with an error message
//   //       setLoading(false);
//   //       message.error(data.createDriverTruck.message);
//   //     }
//   //   } catch (error) {
//   //     setLoading(false);
//   //     console.error("Error creating DriverTruck:", error);
//   //     message.error("An error occurred while creating the DriverTruck.");
//   //   }
//   // };
//     const onFinish = async (values) => {
//     setLoading(true);
//     let data = {
//       driver: { id: formData.driver._id },
//       truck: { id: formData.truck._id },
//     };
  
//     let datat = {
//       driver: data.driver.id,
//       driverName: formData.driver?.name,
//       truck: data.truck.id,
//       owner: ownerId,
//       truckNo: formData.truck.noPlate,
//     };
  
//     try {
//       const res = await createDriverTruck({
//         variables: {
//           createDriverTruckInput: datat,
//         },
//       });
  
//       setLoading(false);
  
//       if (res.data.createDriverTruck.success) {
//         await updateDriver({
//           variables: {
//             id: data.driver.id,
//             updateDriverInput: {
//               isTruck: true,
//             },
//           },
//         });
    
//         await updateTruck({
//           variables: {
//             id: data.truck.id,
//             updateTruckInput: {
//               isDriver: true,
//             },
//           },
//         });
//         setSuccess(true);
//         handleCancel();
//         setFormData({
//           truck: "",
//           driver: "",
//         });
//         message.success(res.data.createDriverTruck.message);
//       } else {
//         message.error(res.data.createDriverTruck.message);
//       }
//       await refetch();
//     } catch (error) {
//       setLoading(false);
//       message.error(error.message);
//     }
//   };
  
  
  
  
  
//   return (
//     <div>
//       <Modal
//        title={${t("Modal.merge")} ${t("tableTabs.truck")} & ${t("tableTabs.driver")}}
//         visible={isModalVisible}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         destroyOnClose
//         footer={null}
//       >
//         {loading ? <Loader /> : ""}
//         <div>
//           <Form form={form} name="add_trcuk_form" onFinish={onFinish}>
//             <Input.Group>
//               <Row gutter={24}>
//                 <Col span={12}>
//                   <Form.Item>
//                     <Select
//                       style={{ border: "2px solid #2d808b" }}
//                       className="inp-emai"
//                       name="driverName"
//                       placeholder={t("placeholders.chooseDriver")}
//                       // value={formData.driver?.driverName}
//                       onChange={(value) => {
//                         formData.driver = drivers[value];
//                         setFormData({
//                           ...formData,
//                         });
//                       }}
//                     >
//                       {drivers.length > 0 ? (
//                         drivers
//                           // .filter(
//                           //   (val) => val.isTruck === undefined && !val.isTruck
//                           // )
//                           .map((v, i) => {
//                             return (
//                               <Option
//                                 value={i}
//                                 disabled={
//                                   v.isTruck !== undefined && v.isTruck === true
//                                 }
//                               >
//                                 {v.driverName}
//                               </Option>
//                             );
//                           })
//                       ) : (
//                         <Option disabled>No Driver Avaiable</Option>
//                       )}
//                     </Select>
//                   </Form.Item>
//                 </Col>
//                 <Col span={12}>
//                   <Form.Item>
//                     <Select
//                       style={{ border: "2px solid #2d808b" }}
//                       className="sign-ema"
//                       name="truckNo"
//                       placeholder={t("placeholders.chooseTruck")}
//                       value={formData.truck?.truckName}
//                       onChange={(value) => {
//                         formData.truck = trucks[value];
//                         // formData.truck["uid"] = formData.truck["truckId"];
//                         setFormData({
//                           ...formData,
//                         });
//                       }}
//                     >
//                       {trucks.length > 0 ? (
//                         trucks
//                           // .filter(
//                           //   (val) => val.isDriver === undefined && !val.isDriver
//                           // )
//                           .map((v, i) => {
//                             return (
//                               <Option disabled={v.isDriver === true} value={i}>
//                                 {v.truckPlate}
//                               </Option>
//                             );
//                           })
//                       ) : (
//                         <Option disabled>No Truck Avaiable</Option>
//                       )}
//                     </Select>
//                   </Form.Item>
//                 </Col>
//               </Row>
//             </Input.Group>
//             <Button
//               style={{ border: "2px solid #2d808b" }}
//               htmlType="submit"
//               className="login-form-button lang-btn"
//             >
//               {t("Modal.merge")}
//             </Button>
//           </Form>
//         </div>
//       </Modal>
//     </div>
//   );
// }

import React, { useState, 
  // useEffect 
} from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  // Upload,
  Row,
  Col,
  Select,
  message,
} from "antd";

// import { UploadOutlined } from "@ant-design/icons";
// import { AddTruck, MergeTruckDriver } from "../API/API";
import Loader from "../Loaders/Loader";
import useDrivers from "../Hooks/useDrivers";
import useTrucks from "../Hooks/useTrucks";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
// import { GET_All_DRIVERS } from "../../Graphql/queries/driverQueries";
// import { GET_ALL_TRUCKS } from "../../Graphql/queries/truckQueries";
// import { UPDATE_DRIVER } from "../../Graphql/mutation/driverMutation";
// import { DRIVER_TRUCK_CREATE } from "../../Graphql/mutation/driverTruckMutation";
// import { UPDATE_TRUCK } from "../../Graphql/mutation/truckMutation";
// import { GET_MY_DRIVER_TRUCK } from "../../Graphql/queries/driverTrucksQueries";
import {  UPDATE_TRUCKS } from "../../graphql/Mutation/owner/addTruckMutation";
import { GET_MY_DRIVER_TRUCK, CREATE_DRIVER_TRUCK, GET_TRUCKS_QUERY, GET_DRIVERS_QUERY } from "../../graphql/Mutation/owner/addDriverTruckMutation";
import {  UPDATE_DRIVERS} from "../../graphql/Mutation/owner/addDriverMutation";
export default function AddTruckDriverModal({ isModalVisible, setIsModalVisible, }) {
  // const ownerId = localStorage.getItem('id');
  const ownerId = JSON.parse(localStorage.getItem('id'));

  // console.log("id", ownerId)
  const { Option } = Select;
  const { refetch, driverTrucks } = useQuery(GET_MY_DRIVER_TRUCK, {
    variables: { page: 1, ownerId },
    skip: true,
  });
  const [driverTrucksData, setDriverTrucksData] = useState(null);
  // useEffect(() => {
  //   const fetchDriverTrucksData = async () => {
  //     try {
  //       const result = await refetch(); 
  //       setDriverTrucksData(result.data); 
  //       console.log("DriverTrucks data:", result.data);
  //     } catch (error) {
  //       console.error("Error fetching driverTrucks data:", error);
  //     }
  //   };

  //   fetchDriverTrucksData();
  // }, []);
  const { drivers } = useDrivers();
  const { trucks } = useTrucks();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  // Fetch all drivers and trucks using Apollo Client
  const { data: driversData } = useQuery(GET_DRIVERS_QUERY);
  const { data: trucksData } = useQuery(GET_TRUCKS_QUERY);
  const [updateDriver] = useMutation(UPDATE_DRIVERS);
  const [updateTruck] = useMutation(UPDATE_TRUCKS);
  const [createDriverTruck] = useMutation(CREATE_DRIVER_TRUCK);
  const Drivers = driversData?.Drivers || [];
  const Trucks = trucksData?.Trucks || [];
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };
  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  const [formData, setFormData] = useState({
    truck: "",
    driver: "",
  });
  // const ownerId = localStorage.getItem('id');
  const onFinish = async (values) => {
    setLoading(true);
    // debugger
    console.log(values,"data")
    let data = {
      driver: { id: formData.driver._id },
      truck: { id: formData.truck._id },
    };
    let datat = {
      driver: data.driver.id,
      driverName: formData.driver?.name,
      truck: data.truck.id,
      owner: ownerId,
      truckNo: formData.truck.noPlate,
    };
    console.log(datat)
  
    try {
      const res = await createDriverTruck({
        variables: {
          createDriverTruckInput: datat,
        },
      });
  
      setLoading(false);
  
      if (res.data.createDriverTruck.success) {
        await updateDriver({
          variables: {
            id: data.driver.id,
            updateDriverInput: {
              isTruck: true,
            },
          },
        });
    
        await updateTruck({
          variables: {
            id: data.truck.id,
            updateTruckInput: {
              isDriver: true,
            },
          },
        });
        setSuccess(true);
        handleCancel();
        setFormData({
          truck: "",
          driver: "",
        });
        message.success("Added successfully");
      } else {
        message.error(res.data.createDriverTruck.message);
      }
      await refetch();
    } catch (error) {
      setLoading(false);
      message.error(error.message);
      console.log(error)
      console.log(values,"createDriverTruck")
    }
  };
  
  return (
    <div>
      <Modal
       title={`${t("Modal.merge")} ${t("tableTabs.truck")} & ${t("tableTabs.driver")}`}
        // title={t("Modal.mergeTruckDriver")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form form={form} name="add_trcuk_form" onFinish={onFinish}>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item>
                    <Select
                      placeholder={t("placeholders.chooseDriver")}
                      value={formData.driver?.name}
                      onChange={(value) => {
                        formData.driver = Drivers[value];
                        setFormData({
                          ...formData,
                        });
                      }}
                    >
                      {Drivers.length > 0 ? (
                        Drivers
                          // .filter(
                          //   (val) => val.isTruck === undefined && !val.isTruck
                          // )
                          .map((v, i) => {
                      
                            return (
                              <Option
                              value={i}
                                disabled={v.isTruck !== undefined && v.isTruck===true}
                              >
                                {v.name}
                              </Option>
                            );
                          })
                      ) : (
                        <Option disabled>No Driver Avaiable</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item>
                    <Select
                      placeholder={t("placeholders.chooseTruck")}
                      value={formData.truck?.idNo}
                      onChange={(value) => {
                        formData.truck = Trucks[value];
                        // formData.truck["uid"] = formData.truck["truckId"];
                        setFormData({
                          ...formData,
                        });
                      }}
                    >
                      {Trucks.length > 0 ? (
                        Trucks
                          // .filter(
                          //   (val) => val.isDriver === undefined && !val.isDriver
                          // )
                          .map((v, i) => {
                            return <Option disabled={v.isDriver===true} value={i}>{v.noPlate}</Option>;
                          })
                      ) : (
                        <Option disabled>No Truck Avaiable</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {t("Modal.merge")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}