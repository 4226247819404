import React from "react";
import "./style.css";
import seven from "../../../assets/home/bigrig.jpg";
// import "../../../utils/i18n";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import { useTranslation } from "react-i18next";

export const SwaggerWork = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div className="swaggerWork ">
      <div className="main-div ">
        <div className="cornar-div"
          data-aos="fade-right"
          data-aos-duration="3000">
          <div className="under-cornar">
            <div className="sayapa">
              <h3>{t("OUR SERVICES")}</h3>
            </div>
            <div className="pkg">
              <h5>{t("PACKAGES SERVICES")}</h5>
            </div>
            <div className="img-div">
              <img src={seven} alt="" className="img-point" />
            </div>
            <div className="pkg">
              <h5>{t("Moving SERVICES")}</h5>
            </div>
          </div>
        </div>
      </div>
      <div className="right-div">
        <div className="rightInner"
          data-aos="zoom-in"
          data-aos-duration="2000">
          <h2 style={{ paddingTop: "8px" }} className="white_color">
            {t("Welcome To OAC")}
          </h2>
          <div className="rightinnerpadding">
            <p>
              {t(
                "Track your journey, details of the clients, & be ready for offloa or onload. Manage all your work with just a few clicks. Online transport booking is just a click away! No matter what type and size of your load. Committed to safety, Online Transport maintains one of the best safety records among all truckload carriers."
              )}
            </p>
            <p>
              {t(
                "Trucking aims to build ways of transportation fast, flexible and reliable for every customer. Book with us anytime. Use our website or give us a call and make a booking for the current and future delivery as per your needs and requirements."
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
