import { gql } from "@apollo/client";

export const GET_ALL_CITIES = gql`
  query Query($page: Int!) {
    cities(page: $page) {
      currentPage
      totalDocs
      totalPages
      cities {
        _id
        cityName
        createdAt
      }
    }
  }
`;
