import { gql } from "@apollo/client";

const CREATE_DRIVER_TRUCK = gql`
mutation Mutation($createDriverTruckInput: CreateDriverTruckInput) {
  createDriverTruck(createDriverTruckInput: $createDriverTruckInput) {
    success
    message
    code
    data {
      truckNo
      truck {
        type
        status
        serviseType
        owner {
          type
          token
          rRA
          profileImage
          phoneNumber
          ownerName
          name
          isDeleted
          isApproved
          isAccept
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          bankInfo {
            iBanNumber
            beneficiaryName
            bankIdCode
          }
          _id
        }
        noPlate
        model
        isDriver
        imageUrl
        height
        createdAt
        capacity
        _id
      }
      owner {
        type
        token
        rRA
        profileImage
        phoneNumber
        ownerName
        name
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
      driverName
      driver {
        token
        status
        owner {
          profileImage
          rRA
          token
          type
          phoneNumber
          ownerName
          name
          isDeleted
          isApproved
          isAccept
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          bankInfo {
            iBanNumber
            beneficiaryName
            bankIdCode
          }
          _id
        }
        nationality
        name
        isTruck
        imageUrl
        idNo
        createdAt
        age
        address
        _id
      }
      createdAt
      _id
    }
  }
}
`;

const GET_DRIVERS = gql`
query Drivers($page: Int!) {
  allDrivers(page: $page) {
    drivers {
      token
      status
      password
      owner {
        type
        token
        rRA
        profileImage
        phoneNumber
        ownerName
        name
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
      _id
      age
      createdAt
      idNo
      imageUrl
      name
      nationality
    }
    totalPages
    totalDocs
    currentPage
  }
}
`;

const UPDATE_DRIVERS = gql`
mutation Mutation($id: ID!, $updateDriverInput: UpdateDriverInput) {
  updateDriver(ID: $id, updateDriverInput: $updateDriverInput) {
    success
    message
    code
    data {
      token
      status
      password
      owner {
        phoneNumber
        profileImage
        rRA
        token
        type
        _id
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        crNumber
        crNumberUpload
        createdAt
        email
        emailVerified
        idNumber
        idNumberUpload
        isAccept
        isApproved
        isDeleted
        name
        ownerName
      }
      _id
      age
      createdAt
      idNo
      imageUrl
      name
      nationality
    }
  }
}
`;
const DELETE_DRIVERS = gql`
mutation Mutation($id: ID!, $isArchive: Boolean) {
  deleteDriver(ID: $id, isArchive: $isArchive) {
    success
    message
    code
    data {
      token
      status
      password
      nationality
      name
      imageUrl
      idNo
      createdAt
      age
      _id
      owner {
        type
        token
        rRA
        profileImage
        phoneNumber
        ownerName
        name
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
    }
  }
}
`;
const GET_TRUCKS_QUERY = gql`
query Query {
  Trucks {
    type
    status
    serviseType
    owner {
      type
      token
      rRA
      profileImage
      phoneNumber
      ownerName
      name
      isDeleted
      isApproved
      isAccept
      idNumberUpload
      idNumber
      emailVerified
      email
      createdAt
      crNumberUpload
      crNumber
      bankInfo {
        iBanNumber
        beneficiaryName
        bankIdCode
      }
      _id
    }
    noPlate
    model
    isDriver
    imageUrl
    createdAt
    height
    capacity
    _id
  }
}
`;

const GET_DRIVERS_QUERY = gql`
query Query {
  Drivers {
    token
    status
    owner {
      type
      token
      rRA
      profileImage
      phoneNumber
      ownerName
      name
      isDeleted
      isApproved
      isAccept
      idNumberUpload
      idNumber
      emailVerified
      email
      createdAt
      crNumberUpload
      crNumber
      bankInfo {
        iBanNumber
        beneficiaryName
        bankIdCode
      }
      _id
    }
    nationality
    name
    isTruck
    imageUrl
    idNo
    createdAt
    age
    address
    _id
  }
}
`;
const GET_MY_DRIVER_TRUCK = gql`
query Query($ownerId: ID!, $page: Int!) {
  myDriverTrucks(ownerId: $ownerId, page: $page) {
    totalPages
    totalDocs
    currentPage
    driverTrucks {
      truckNo
      truck {
        type
        status
        serviseType
        owner {
          type
          token
          rRA
          profileImage
          phoneNumber
          ownerName
          name
          isDeleted
          isApproved
          isAccept
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          bankInfo {
            iBanNumber
            beneficiaryName
            bankIdCode
          }
          _id
        }
        noPlate
        model
        isDriver
        imageUrl
        height
        createdAt
        capacity
        _id
      }
      owner {
        type
        token
        rRA
        profileImage
        phoneNumber
        ownerName
        name
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
      driverName
      driver {
        token
        status
        owner {
          type
          token
          rRA
          profileImage
          phoneNumber
          ownerName
          name
          isDeleted
          isApproved
          isAccept
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          bankInfo {
            iBanNumber
            beneficiaryName
            bankIdCode
          }
          _id
        }
        nationality
        name
        isTruck
        imageUrl
        idNo
        createdAt
        age
        address
        _id
      }
      createdAt
      _id
    }
  }
}
`;
const DELETE_DRIVERS_TRUCKS = gql`
mutation DeleteDriverTruck($id: ID!) {
  deleteDriverTruck(ID: $id) {
    success
    message
    code
    data {
      truckNo
      truck {
        type
        status
        serviseType
        owner {
          type
          token
          rRA
          profileImage
          phoneNumber
          ownerName
          name
          isDeleted
          isApproved
          isAccept
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          bankInfo {
            iBanNumber
            beneficiaryName
            bankIdCode
          }
          _id
        }
        noPlate
        model
        isDriver
        imageUrl
        height
        createdAt
        capacity
        _id
      }
      owner {
        type
        token
        rRA
        profileImage
        phoneNumber
        ownerName
        name
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
      driverName
      driver {
        token
        status
        owner {
          profileImage
          type
          token
          rRA
          phoneNumber
          ownerName
          name
          isDeleted
          isApproved
          isAccept
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          bankInfo {
            iBanNumber
            beneficiaryName
            bankIdCode
          }
          _id
        }
        nationality
        name
        isTruck
        imageUrl
        idNo
        createdAt
        age
        address
        _id
      }
      createdAt
      _id
    }
  }
}
`;


export { CREATE_DRIVER_TRUCK, GET_MY_DRIVER_TRUCK, GET_DRIVERS,
  GET_TRUCKS_QUERY, UPDATE_DRIVERS,DELETE_DRIVERS, GET_DRIVERS_QUERY, DELETE_DRIVERS_TRUCKS };