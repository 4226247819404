import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import FinanceTable from "../../Tables/FinanceTable";
import { Tabs } from "antd";
export default function Finance() {
  const [pmtMode, setPmtMode] = useState("offline");
  const { t } = useTranslation();
  const items = [
    {
      key: "offline",
      label: `Offline Payment`,
      children: <FinanceTable pmtMode={pmtMode} />,
    },
    {
      key: "online",
      label: `Online Payment`,
      children: <FinanceTable pmtMode={pmtMode} />,
    },
  ];
  const onChange = (key) => {
    setPmtMode(key);
  };
  return (
    <div className="inp-emai">
      <div>
        <h3>{t("routes.finance")}</h3>
      </div>
      <div>
        <Tabs defaultActiveKey="offline" items={items} onChange={onChange} />;
      </div>
    </div>
  );
}
