import { gql } from "@apollo/client";

const ALL_SERVICES = gql`
  query AllServices {
    allServices {
      _id
      admin {
        _id
      }
      capacity
      createdAt
      price
    }
  }
`;

export { ALL_SERVICES };
