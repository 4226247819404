import React, { Children, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Table, Input, Popconfirm, Space, Tag } from "antd";
import { useTranslation } from "react-i18next";

import { SearchOutlined } from "@ant-design/icons";

import useFinance from "../Hooks/useFinance";

export default function FinanceTable({ pmtMode }) {
  const type = JSON.parse(localStorage.getItem("type"));
  const { finance } = useFinance();
  const [search, setSearch] = useState("");

  const { t } = useTranslation();

  const columns = [
    // {
    //   title: "Customer Name",
    //   render: (recored) => <span>{recored.customer?.name}</span>,
    // },
    // {
    //   title: "Phone No",
    //   render: (recored) => <span>{recored.customer?.phoneNumber}</span>,
    // },
    // {
    //   title: "Owner Name",
    //   render: (recored) => <span>{recored.owner?.name}</span>,
    // },
    // {
    //   title: "Phone No",
    //   render: (recored) => <span>{recored.owner?.phoneNumber}</span>,
    // },
    {
      title: t("tableTabs.id"),
      render: (record) => (
        <span title={record?.requestId}>{record?.requestId}</span>
      ),
    },
    {
      title: t("tableTabs.from"),
      render: (recored) => <span>{recored.from}</span>,
    },
    {
      title: t("tableTabs.to"),
      render: (recored) => <span>{recored.to}</span>,
    },
    {
      title: t("tableTabs.fare"),
      render: (recored) => <span>{recored.fare}</span>,
    },
    // {
    //   title: "Commission",
    //   render: (record) => <span>{`${record.commission}%`}</span>,
    // },
    {
      title: t("tableTabs.createdAt"),
      render: (record) => (
        <span>
          {new Date(record?.createdAt?.seconds * 1000).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      ),
    },
    {
      title: t("tableTabs.payment"),
      render: (record) => (
        <Tag
          key={record.uid}
          color={
            type === "customers"
              ? record?.receivedFromCustomer !== undefined &&
                record?.receivedFromCustomer
                ? "green"
                : "pink"
              : record?.sendToOwner !== undefined && record?.sendToOwner
              ? "green"
              : "pink"
          }
        >
          {type === "customers"
            ? record?.receivedFromCustomer !== undefined &&
              record?.receivedFromCustomer
              ? "Paid"
              : "Underprocess"
            : record?.sendToOwner !== undefined && record?.sendToOwner
            ? "Paid"
            : "Underprocess"}
        </Tag>
      ),
    },
    // {
    //   title: "Owner Paid",
    //   render: (record) => (
    //     <Tag
    //       key={record.uid}
    //       color={
    //         record?.sendToOwner !== undefined && record?.sendToOwner
    //           ? "green"
    //           : "pink"
    //       }
    //     >
    //       {record?.sendToOwner !== undefined && record?.sendToOwner
    //         ? "Yes"
    //         : "No"}
    //     </Tag>
    //   ),
    // },

    // {
    //   title: "Action",
    //   dataIndex: "",
    //   key: "x",
    //   align: "center",
    //   render: (record) => (
    //     <Space>
    //       {record.sendToOwner ? (
    //         "Paid"
    //       ) : (
    //         <span
    //           className="ant-btn  ant-btn-warn"
    //           title="send Request"
    //           onClick={() => {
    //             let data = { sendToOwner: true };
    //             updateFinance(record.uid, data);
    //             //   navigate("/send_request/" + record.uid);
    //           }}
    //         >
    //           <SendOutlined />
    //         </span>
    //       )}

    //       <Popconfirm
    //         title={"Are you sure?"}
    //         okText="Ok"
    //         cancelText="Cancel"
    //         onConfirm={() => {
    //           DeleteRequest(record?.uid, record);
    //         }}
    //       >
    //         <span className="ant-btn ant-btn-danger">
    //           <DeleteOutlined />
    //         </span>
    //       </Popconfirm>
    //     </Space>
    //   ),
    // },
  ];
  return (
    <div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        rowKey="uid"
        columns={columns}
        dataSource={
          finance?.length > 0 &&
          finance
            .filter((v) => (pmtMode ? v.paymentMode == pmtMode : true))
            // .sort((a, b) => b.createdAt - a.createdAt)
            .filter((val) => {
              if (search == "") {
                return val;
              } else if (
                val &&
                Object.keys(val).some((v) =>
                  val[v]
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase())
                )
              ) {
                return val;
              }
            })
        }
      />
    </div>
  );
}
