const ChatData = [
  {
    id: 1,
    Question1: "What is OAC?",
    Answer: "Oac Means That To Join Any Vehicles ",
  },
  {
    id: 2,
    Question: "Why choose Oac? ",
    Answer: "Because Oac Best Platform To Choose Any Vehicles",
  },

  {
    id: 3,
    Question: "How To Provide Oac?",
    Answer: "Oac Always Provide you To best Vehicles ",
  },
  {
    id: 4,
    Question: "is Our Privacy Save in Oac?",
    Answer: " We use Save Privacy to provide and improve the Services",
  },
  {
    id: 5,
    Question: "How to be a Part Of Oac?",
    Answer: " Oac Manage all your work with just a few clicks.",
  },

  {
    id: 6,
    Question: "Is Oac Charge For Join?",
    Answer: "Yes",
  },
  {
    id: 7,
    Question: "Contact with Admin?",
    Answer: "Sending your mail to admin.",
  },
  // {
  //   id: 8,
  //   Question: "Contact with Admin?",
  //   Answer: "Sending your mail to admin",
  // },
];

export default ChatData;
