import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const FooterComponent = ({ title, items }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div
      className="col-sm-12 col-md-3 col-lg-3"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration="2000"
    >
      <ul>
        <li>
          <h3 className="fw-bold text-white title-footer">{title}</h3>
        </li>
        {items?.map((v, i) => {
          return (
            <div>
              <li key={i} className="footer-li">
                {title === "Social Links" ? (
                  <a href={v.link} target={"_blank"} className="footer-li">
                    {v.item}
                  </a>
                ) : (
                  <Link to={v.link} className="footer-li">
                    {v.item}
                  </Link>
                )}
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );
};
