import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/home/OAC LOGO2.png";
import "./style.css";
import { Language } from "../../../../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
export const Navber = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { lng } = useSelector((state) => state.userReducer);
  return (
    <div>
      <div className="nav">
        <div className="mini-nav">
          <div className="logo">
            <img src={logo} alt="Logo Image" onClick={()=>{navigate("/")}} style={{cursor:'pointer'}} />
          </div>

          <div className="btn-div">
            <button
              onClick={() => navigate("/price")}
              className="Price_btn"
              style={{ height: "35px", borderRadius: "5px",width:'24%' }}
              >
                
              {t("Show Prices")}
            </button>
            <button
              className="signup-btn"
              style={{ height: "35px", borderRadius: "5px" }}
            >
              <span className="signin" onClick={() => navigate("/home")}>
                {t("SignIn")}/
              </span>
              <span className="signin" onClick={() => navigate("/home")}>
                {t("SignUp")}
                
              </span>
            </button>

            {/* <button
              className="logi"
              onClick={() => {
                let lng = "en";
                dispatch(Language(lng));
                i18n.changeLanguage(lng);
              }}
            >
              English
            </button>
            <button
              className="logi"
              onClick={() => {
                let lng = "ar";
                dispatch(Language(lng));
                i18n.changeLanguage(lng);
              }}
            >
              عربي
            </button> */}
            <div className="change-locale" style={{height:"35px"}}>
              <button
                className={` ar ${lng == "ar" ? "hello-arabic" : ""}`}
                style={{height:'34px'}}
                onClick={() => {
                  dispatch(Language("ar"));
                  i18n.changeLanguage("ar");
                }}
              >
                العربية
              </button>
              <button
                className={` ar ${lng == "en" ? "hello-english" : ""}`}
                style={{height:'34px'}}
                onClick={() => {
                  dispatch(Language("en"));
                  i18n.changeLanguage("en");
                }}
              >
                English
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
