import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  // Switch,
  Input,
  // Radio,
  // Checkbox,
  Form,
  Upload,
  Row,
  Col,
  // message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { 
  // AddDriver, UpdateDriver, 
  uploadImage } from "../API/API";
// import SimpleLoader from "../Loaders/simpleLoader";
import Loader from "../Loaders/Loader";
import { useTranslation } from "react-i18next";
import { UPDATE_DRIVERS } from "../../graphql/Mutation/owner/addDriverMutation";
import { useMutation } from "@apollo/client";
export default function EditDriverModal({
  isModalVisible,
  setIsModalVisible,
  data,
}) {
  
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    driverId: "",
    driverLicense: "",
    portId: "",
  });
  const [idFile, setIdFile] = useState([]);
  const [licenseFile, setLicenseFile] = useState([]);
  const [portIdFile, setPortIdFile] = useState([]);
  const { t } = useTranslation();
  
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  const [updateDriver] = useMutation(UPDATE_DRIVERS);
  const onFinish = async (values) => {
    setLoading(true);
  console.log(values)
    const uploadImageAndMutate = async (field, data) => {
      if (typeof data !== "string") {
        try {
          const res = await uploadImage("Drivers/", data);
          return res;
        } catch (error) {
          throw new Error(`Error uploading ${field}: ${error.message}`);
        }
      }
      return data;
    };
  
    try {
      const driverIdUpload = await uploadImageAndMutate("driverId", formData.driverId);
      const driverLicenseUpload = await uploadImageAndMutate("driverLicense", formData.driverLicense);
      const portIdUpload = await uploadImageAndMutate("portId", formData.portId);
  
      const udata = {
        ...values,
        driverIdUpload,
        driverLicenseUpload,
        portIdUpload,
      };
      console.log(udata)
      const { data: { updateDriver: { success, message } } } = await updateDriver({
        variables: {
          id: data._id, 
          updateDriverInput: {
            status: "Active",
            password: values.password,
            nationality: values.nationality,
            name: values.name,
            imageUrl: "active",
            idNo: values.idNo,
            age: values.age,
            address: values.address
          },
        },
      });
      if (success) {
        setLoading(false);
        form.resetFields();
        handleCancel();
        message.success('Update Successful!');
      } else {
        setLoading(false);
        message.success('Update Successful!');
        // message.error(message);
      }
    } catch (error) {
      setLoading(false);
      // message.error(error.message);
    }
  };
  
  
  // const onFinish = async (values) => {
  //   console.log(typeof formData.driverId);
  //   setLoading(true);
  //   if (typeof formData.driverId !== "string") {
  //     await uploadImage("Drivers/", formData.driverId).then((res) => {
  //       formData.driverId = res;
  //       setFormData({ ...formData });
  //     });
  //   }
  //   if (typeof formData.driverLicense !== "string") {
  //     await uploadImage("Drivers/", formData.driverLicense).then((res) => {
  //       formData.driverLicense = res;
  //       setFormData({ ...formData });
  //     });
  //   }
  //   if (typeof formData.portId !== "string") {
  //     await uploadImage("Drivers/", formData.portId).then((res) => {
  //       formData.portId = res;
  //       setFormData({ ...formData });
  //     });
  //   }
  //   let udata = {
  //     ...values,
  //     driverIdUpload: formData.driverId,
  //     driverLicenseUpload: formData.driverLicense,
  //     portIdUpload: formData.portId,
  //   };
  //   await UpdateDriver(data.uid, udata)
  //     .then(() => {
  //       setLoading(false);
  //       form.resetFields();
  //       handleCancel();
  //       message.success("Add Successull!");
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //       message.error(JSON.stringify(e));
  //     });
  // };
  useEffect(() => {
    if (Object.keys(data).length > 0) {
      formData.driverId = data.driverIdUpload;
      formData.portId = data.portIdUpload;
      formData.driverLicense = data.driverLicenseUpload;
      form.setFieldsValue(data);
      if (data.driverIdUpload !== "") {
        setIdFile([
          {
            uid: "-1",
            name: "ID Uplaod",
            status: "done",
            url: data.driverIdUpload,
          },
        ]);
      }
      if (data.portIdUpload !== "") {
        setPortIdFile([
          {
            uid: "-2",
            name: "Port ID Uplaod",
            status: "done",
            url: data.portIdUpload,
          },
        ]);
      }
      if (data.driverLicense !== "") {
        setLicenseFile([
          {
            uid: "-3",
            name: "License Uplaod",
            status: "done",
            url: data.driverLicenseUpload,
          },
        ]);
      }

      setFormData({ ...formData });
    }
  }, [data]);

  return (
    <div>
      <Modal
        title={`${t("Modal.edit")} ${t("Modal.driver")}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form
            form={form}
            name="add_driver_form"
            // initialValues={data}
            onFinish={onFinish}
          >
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="name"
                    // label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder={t("placeholders.name")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="age"
                    // label="Age"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      type={"number"}
                      placeholder={t("placeholders.age")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="nationality"
                    // label="Nationality"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder={t("placeholders.nationality")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="address"
                    // label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder={t("placeholders.address")} />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                      {
                        min: 8,
                        message: "Minimun length of 8 characters",
                      },
                    ]}
                  >
                    <Input.Password
                      type={"password"}
                      placeholder={t("placeholders.password")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="idNo"
                    // label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder={t("placeholders.idNumber")} />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <div className="form_row_div">
              <Row gutter={24}>
                <Col span={12}>
                  <Upload
                    accept="image/*"
                    listType="picture"
                    multiple={false}
                    maxCount={1}
                    fileList={idFile}
                    onChange={(e) => {
                      if (e.file.status === "removed") {
                        formData.driverId = "";
                        setFormData({
                          ...formData,
                        });
                        setIdFile([]);
                      } else {
                        formData.driverId = e.file.originFileObj;
                        setFormData({
                          ...formData,
                        });
                        setIdFile(e.fileList);
                      }
                    }}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("placeholders.uploadDriverId")}
                    </Button>
                  </Upload>
                </Col>
                <Col span={12}>
                  <Upload
                    accept="image/*"
                    listType="picture"
                    multiple={false}
                    maxCount={1}
                    fileList={licenseFile}
                    onChange={(e) => {
                      if (e.file.status === "removed") {
                        formData.driverLicense = "";
                        setFormData({
                          ...formData,
                        });
                        setLicenseFile([]);
                      } else {
                        formData.driverLicense = e.file.originFileObj;
                        setFormData({
                          ...formData,
                        });
                        setLicenseFile(e.fileList);
                      }
                    }}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("placeholders.uploadLicense")}
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </div>
            <div className="form_row_div">
              <Row gutter={24}>
                <Col span={12}>
                  <Upload
                    accept="image/*"
                    listType="picture"
                    multiple={false}
                    maxCount={1}
                    fileList={portIdFile}
                    onChange={(e) => {
                      if (e.file.status === "removed") {
                        formData.portId = "";
                        setFormData({
                          ...formData,
                        });
                        setPortIdFile([]);
                      } else {
                        formData.portId = e.file.originFileObj;
                        setFormData({
                          ...formData,
                        });
                        setPortIdFile(e.fileList);
                      }
                    }}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("placeholders.uploadPortId")}
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </div>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {t("Modal.edit")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
