import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot, where, doc } from "firebase/firestore";
import { db } from "../firebase";
export default function useAlert() {
  const [alrt, setAlert] = useState(false);
  const [alrtNotifications, setAlertNotifications] = useState([]);

  const getData = async () => {
    const id = JSON.parse(localStorage.getItem("uid"));
    let q = query(
      collection(db, "notifications"),
      where("to", "array-contains", id)
    );
    const unsub = onSnapshot(q, (querySnapShot) => {
      if (!querySnapShot.empty) {
        querySnapShot.docChanges().forEach((change) => {
          if (change.type === "added") {
            if (change.doc.metadata.hasPendingWrites) {
              setAlertNotifications((prve) => [...prve, change.doc.data()]);
              setAlert(true);
            }
          }
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getData();
  }, []);

  return { alrt, alrtNotifications };
}
