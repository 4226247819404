import { gql } from "@apollo/client";

export const GET_ALL_Combinations = gql`
  query AllCombinations($page: Int!) {
    allCombinations(page: $page) {
      totalDocs
      currentPage
      totalPages
      combinations {
        from
        to
        capacity
        height
        type
        estimateTime
        distance
        price
        commission
        createdAt
        vat
        isRelation
      }
    }
  }
`;
