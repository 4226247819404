import "./drivermap.css";
import {
  DirectionsRenderer,
  GoogleMap,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Form, Input, message, Popconfirm, Spin } from "antd";

import { Button, Modal, Space } from "antd";
import { useCallback, useState } from "react";
import {
  collection,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import React, { useEffect } from "react";
import { db } from "../../firebase";

import { uploadImage } from "../../API/API";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DriverMap = () => {
  const [currentPosition, setCurrentPosition] = useState({});
  const [origin, setOrigin] = useState({});
  const [destination, setDestination] = useState({});
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [directions, setDirections] = useState(null);
  const [locName, setLocName] = useState(null);
  const [requestData, setRequestData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [eirFile, setEirFile] = useState("");
  const [deliveryNoteFile, setDeliveryNoteFile] = useState("");
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  // --------- get delivery-file-path ----------

  const uploadEirFile = (e) => {
    let file = e?.target?.files[0];
    setEirFile(file);
  };

  const uploadDeleveryNote = (e) => {
    let file = e?.target?.files[0];
    setDeliveryNoteFile(file);
  };

  // -------- cofirm-modal --------
  const confirm = (e) => {
    setIsModalOpen(true);
  };
  const cancel = (e) => {};
  // ------- modal ------------
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // ----- form-modal --------
  const onFinish = async (values) => {
    if (eirFile === "" && deliveryNoteFile === "") {
      alert("select files plz");
      return;
    }
    setIsLoading(true);
    try {
      const eirurl = await uploadImage("documents/eir/", eirFile);
      const deliveryNoteurl = await uploadImage(
        "documents/delivery/",
        deliveryNoteFile
      );
      const docRef = doc(db, "customer_transport_requests", requestData.uid);
      await updateDoc(docRef, {
        eir: eirurl,
        deliveryNote: deliveryNoteurl,
        status: "finished",
      });

      form.setFieldsValue({ File1: "" });
      form.setFieldsValue({ File2: "" });

      alert(
        "Ride Finished successfully",
        setIsLoading(false),
        setIsModalOpen(false)
      );
      navigate("/driver/driverhistory");
    } catch (error) {
      alert(error.message);
    }
    setIsLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // ----- owner-request ---------
  const getOwnerRequests = async () => {
    const uid = JSON.parse(localStorage.getItem("uid"));
    try {
      let q = query(
        collection(db, "customer_transport_requests"),
        where("status", "==", "underProcess"),
        where("isStarted", "==", true)
      );
      const unsub = onSnapshot(q, (querySnapShot) => {
        if (!querySnapShot.empty) {
          querySnapShot.forEach((val) => {
            if (val.data().acceptedBy.driverId == uid) {
              let dlocation = {
                lat: val.data()?.driverLocation?.latitude,
                lng: val.data()?.driverLocation?.longitude,
              };
              let dorigin = {
                lat: val.data()?.coordinates?.from?.latitude,
                lng: val.data()?.coordinates?.from?.longitude,
              };
              let ddestination = {
                lat: val.data()?.coordinates?.to?.latitude,
                lng: val.data()?.coordinates?.to?.longitude,
              };
              setRequestData(val.data());
              setCurrentPosition(dlocation);
              setOrigin(dorigin);
              setDestination(ddestination);
              setShouldUpdate(true);
            }
          });
        }
      });
      return () => unsub();
    } catch (error) {
      alert(error.message);
    }
  };
  useEffect(() => {
    getOwnerRequests();
  }, []);

  // ----- google-map-----
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDVy7PgC1ETDS-C914uyDubLNxceaCM0Io",
  });
  const mapStyles = {
    height: "50vh",
    width: "100%",
  };

  const getDirection = () => {
    const google = window.google;
    const directionsService = new google.maps.DirectionsService();
    directionsService.route(
      {
        destination: new google.maps.LatLng(
          requestData?.coordinates?.to?.latitude,
          requestData?.coordinates?.to?.longitude
        ),
        origin: new google.maps.LatLng(
          requestData?.coordinates?.from?.latitude,
          requestData?.coordinates?.from?.longitude
        ),

        travelMode: google?.maps?.TravelMode?.DRIVING,
      },
      (result, status) => {
        if (status === google?.maps?.DirectionsStatus.OK) {
          setDirections(result);
          setDistance(result.routes[0].legs[0].distance.value);
          setDuration(result.routes[0].legs[0].duration.value);
        } else {
          console.error("error fetching directions", result, status);
        }
      }
    );
  };

  const getLocationName = (currentPosition) => {
    if (Object.keys(currentPosition).length > 0) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: currentPosition }, function(
        results,
        status
      ) {
        if (status === window.google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            setLocName(results[0].formatted_address);
          }
        }
      });
    }
  };

  useEffect(() => {
    if (isLoaded) {
      if (shouldUpdate) {
        getDirection();
        getLocationName(currentPosition);
      }
    }
  }, [requestData, isLoaded, shouldUpdate]);

  function RenderMap() {
    const [map, setMap] = useState(null);
    const onLoad = useCallback(function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(currentPosition);
      map.fitBounds(bounds);
      setMap(map);
    }, []);
    const onUnmount = useCallback(function callback(map) {
      setMap(null);
    }, []);
    if (!requestData) return null;
    return isLoaded ? (
      <>
        <Space>
          <p>
            {t("driver.currentlocation")}:{`${locName}`}
          </p>
        </Space>
        <GoogleMap
          mapContainerStyle={mapStyles}
          center={currentPosition}
          zoom={6}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          <MarkerF
            title="Truck"
            position={currentPosition}
            animation="BOUNCE"
            icon={{
              url: "/assets/logo.png",
              scaledSize: { width: 50, height: 40 },
            }}
          />

          <MarkerF
            title="Truck"
            position={origin}
            animation="BOUNCE"
            // icon={{
            //   url: "/assets/logo.png",
            //   scaledSize: { width: 50, height: 40 },
            // }}
          />

          <MarkerF
            title="Truck"
            position={destination}
            animation="BOUNCE"
            // icon={{
            //   url: "/assets/logo.png",
            //   scaledSize: { width: 50, height: 40 },
            // }}
          />
          {/* ------- show-direction --------- */}
          {directions && (
            <DirectionsRenderer
              directions={directions}
              options={{
                polylineOptions: {
                  stokeColor: "blue",
                  strokeOpacity: 1,
                  strokeWeight: 1,
                },

                icon: { scale: 3 },
              }}
            />
          )}

          <></>
        </GoogleMap>
      </>
    ) : (
      <>Driver start ride soon...</>
    );
  }
  React.memo(RenderMap);

  return (
    <div className="driver-map">
      <div className="map-btns">
        <Popconfirm
          title="Are You Sure you want to finish the ride ?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary">{t("driver.ridefinished")}</Button>
        </Popconfirm>

        <Modal
          title="Basic Modal"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={false}
        >
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            scrollToFirstError={true}
          >
       
              <Form.Item
              label="Delivery Note"
              name="File1"
              rules={[
                {
                  required: true,
                  message:t("Please upload a Delivery Note File")
                },
              ]}
            >
              <Input
                type="file"
                accept="image/*, application/pdf"
                onChange={uploadEirFile}
              />
            </Form.Item>

       
             <Form.Item
              label="EIR "
              name="File2"
              rules={[
                {
                  required: true,
                  message:t("Please upload a EIR File")
                },
              ]}
            >
              <Input
                type="file"
                accept="image/*, application/pdf"
                onChange={uploadDeleveryNote}
              />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 5,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>

      {isLoading && (
        <div className="backdrop">
          <div className="loader">
            <Space size="middle">
              <Spin size="large" />
            </Space>
          </div>
        </div>
      )}
      <RenderMap />
    </div>
  );
};

export default DriverMap;
