import React from "react";
import "./driver_sidebar.css";
import { useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { BsPersonCircle } from "react-icons/bs";
import { AiOutlineHistory } from "react-icons/ai";
import { BiGitPullRequest } from "react-icons/bi";
// import { ImCoinDollar } from "react-icons/im";
// import { auth } from "../../../../firebase";
import { useTranslation } from "react-i18next";
import { Logout, readNotifications } from "../../../../API/API";
import useNotifications from "../../../../Hooks/useNotifications";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { useState } from "react";
const { Sider } = Layout;

const DriverSidebar = ({ collapsed }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifications } = useNotifications();

  const [loading, setLoading] = useState(false);
  let requests =
    notifications &&
    notifications.filter(
      (v) => !v.isRead && v.type?.toLowerCase().includes("request")
    );
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div
      className="sidebar_main_div"
      data-aos="fade-right"
      data-aos-duration="3000"
    >
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <div className="logo_div">
            <img className="car-img" width={60} src="/assets/logo.png" />
          </div>
        </div>

        <Menu
          theme="dark"
          mode="inline"
          // defaultSelectedKeys={["1"]}
          selectedKeys={[window.location.pathname]}
          items={[
            {
              key: "/driver/driverprofile",
              icon: <BsPersonCircle />,
              label: t("routes.dprofile"),

              onClick: () => {
                navigate("/driver/driverprofile");
              },
            },
            {
              key: "/driver/request",
              icon: <BiGitPullRequest />,
              label: t("routes.drequest"),

              onClick: () => {
                navigate("/driver/driverrequest");
              },
            },
            {
              key: "/driver/history",
              icon: <AiOutlineHistory />,
              label: t("routes.dhistory"),

              onClick: () => {
                navigate("/driver/driverhistory");
              },
            },
            {
              key: "/",
              icon: <LogoutOutlined />,
              label: t("routes.logout"),
              onClick: Logout,
            },
          ]}
        ></Menu>
      </Sider>
    </div>
  );
};

export default DriverSidebar;
