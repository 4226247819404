import React, { useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const Banner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <div className="banner" onClick={handleClick}>
        <div className="mainBannerDiv">
          <div
            data-aos="fade-down"
            data-aos-duration="1000"
            className="container"
          >
            <div className="mainBannerSubDiv row">
              <div className="BannerHeading col-sm-5 col-md-5 col-lg-6">
                <h2 className="test">{t("OAC EXPRESS")}</h2>
                <br />
                <p>
                  {t("Oac Means That The Best Platform To Join Any Vehicles")}
                </p>
                <button className="btnnn" onClick={() => navigate("/home")}>
                  {t("Booking Now")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Main-box" data-aos="fade-up" data-aos-duration="1000">
        <div className="uderbox">
          <div className="box">
            <div className="card">
              <div className="card-img-top" style={{ height: "9.2rem" }}>
                <p className="text-car"></p>
              </div>

              <div className="card-body">
                <h5 className="card-title">{t("Online Truck Booking")}</h5>
                <p style={{ color: "white" }} className="card-text">
                  {t(
                    "The latest advances in technology and easy access to the internet have made it possible for everyone to get the advantage of booking trucks online."
                  )}
                </p>
                {/* <button className="btnn ">{t("Go Somewhere")}</button> */}
              </div>
            </div>
          </div>

          <div className="box">
            <div className="card">
              <div className="card-img-topp" style={{ height: "9.2rem" }}>
                <p className="text-car" style={{ fontSize: "12px" }}></p>
              </div>

              <div className="card-body">
                <h5 className="card-title">{t("On-Time, Every Time")}</h5>
                <p style={{ color: "white" }} className="card-text">
                  {t(
                    "We understand the value of time for you as well as for us.That is why we try to deliver your consignment on time, every time."
                  )}
                </p>
                {/* <button className="btnn ">{t("Go Somewhere")}</button> */}
              </div>
            </div>
          </div>

          <div className="box">
            <div className="card">
              <div className="card-img-top2" style={{ height: "9.2rem" }}>
                <p className="text-car" style={{ fontSize: "12px" }}>
                  {/* LONGDISTANCE MOVING */}
                </p>
              </div>

              <div className="card-body">
                <h5 className="card-title">{t("Reliability and safety")}</h5>
                <p style={{ color: "white" }} className="card-text">
                  {t(
                    "We drive reliability and safety in transport operations through the effective implementation of advanced security tools and technologies."
                  )}
                </p>
                {/* <button className="btnn ">{t("Go Somewhere")}</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
