import { useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";
// import { GET_MY_DRIVER_TRUCK } from "../../Graphql/queries/driverTrucksQueries";
import { GET_MY_DRIVER_TRUCK } from "../../graphql/Mutation/owner/addDriverTruckMutation";

function useGetAllDriverTruck(trigger, paginationFilters, ownerId) {
  console.log("🚀 ~ file: useTrucks.js:79 ~ useGetMyTrucks ~ paginationFilters:", paginationFilters)
  const [driverTrucks, setDriverTrucks] = useState([]);
  const [count, setCount] = useState(0);

  const { loading, error, data, refetch } = useQuery(GET_MY_DRIVER_TRUCK, {
    variables: { page: 1, ownerId },
    fetchPolicy: "network-only",
  });
  console.log(data,"11111111111111111111111111")

  const getMyDriverTrucks = useCallback(async () => {
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    const { myDriverTrucks } = data;
    if (!myDriverTrucks) {
      console.log("No Owner data available.");
      return;
    }
    const { driverTrucks, totalDocs } = myDriverTrucks;
    setDriverTrucks(driverTrucks);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getMyDriverTrucks();
    if (trigger) {
      refetch({ page: 1 });
    }
  }, [trigger, getMyDriverTrucks, 1, refetch]);

  return { loading, error, driverTrucks, count };
}

export default useGetAllDriverTruck;

