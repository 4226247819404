import React, { useEffect, useState } from 'react'
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { db } from '../firebase';

const useGetOwnerAllMaintenanceRequest = () => {
    const [ownerMaintenance, setOwnerMaintenance] = useState([])
    const uid = JSON.parse(localStorage.getItem('uid'))

    const getAllOwnerMaintenanceRequest = () => {
        const q = query(collection(db, "maintenance-service_request"), where("ownerId", "==", uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const arr = [];
            querySnapshot.forEach((doc) => {
                arr.push(doc.data());
            });
            setOwnerMaintenance(arr)
        });
        return () => unsubscribe()
    }

    useEffect(() => {
        getAllOwnerMaintenanceRequest()
    }, [])
    return { ownerMaintenance }
}

export default useGetOwnerAllMaintenanceRequest
