import React, { useState, useEffect, useRef } from "react";
// import "./customer_header.css";
import { useNavigate } from "react-router-dom";
import NotificationSound from "../../../../../notifications-sound-127856.mp3";
import {
  Layout,
  Avatar,
  Dropdown,
  Radio,
  Card,
  Badge,
  Drawer,
  notification,
  Tag,
} from "antd";
import { BiUser } from "react-icons/bi";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  SettingOutlined,
  EditOutlined,
  EllipsisOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { IoNotificationsOutline } from "react-icons/io5";
import moment from "moment";
//Hooks
import useUser from "../../../../Hooks/useUser";
import useAlert from "../../../../Hooks/useAlret";
import useNotifications from "../../../../Hooks/useNotifications";
//Firebae
import { auth } from "../../../../firebase";
//i18n
import { useTranslation } from "react-i18next";
//Redux
import { useDispatch, useSelector } from "react-redux";
import { Language } from "../../../../../Redux/Actions/userActions";
import { Logout } from "../../../../API/API";
import EditProfileModal from "../../../../Modals/EditProfileModal";
import AOS from "aos";
import "aos/dist/aos.css";
const { Header } = Layout;
const { Meta } = Card;

export default function CustomerHeader({ collapsed, setCollapsed }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { lng } = useSelector((state) => state.userReducer);
  const { user } = useUser();
  const { notifications } = useNotifications();
  const { alrt, alrtNotifications } = useAlert();
  let type = JSON.parse(localStorage.getItem("type")) || "";
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visible, setVisible] = useState(false);

  //notification sound
  const audioPlayer = useRef(null);
  function playAudio() {
    audioPlayer.current.play();
  }

  useEffect(() => {
    playAudio();
  }, [notifications.length]);
  //end notification

  const showDrawer = () => {
    setVisible(true);
  };
  function refreshPage() {
    window.location.reload(false);
  }
  const onClose = () => {
    setVisible(false);
  };
  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  const Profile = () => {
    return (
      <Card
        style={{
          width: 250,
        }}
        actions={[
          <LogoutOutlined key="logout" onClick={Logout} />,
          <EditOutlined key="edit" onClick={showModal} />,
        ]}
      >
        <Meta
          avatar={
            user.profileImage ? (
              <img src={user.profileImage} width={50} height={50} />
            ) : (
              <Avatar src="https://joeschmoe.io/api/v1/random" />
            )
          }
          title={type}
          description={user.name}
        />
      </Card>
    );
  };
  const openNotification = (title, desc) => {
    const args = {
      message: title,
      description: desc,
      // duration: ,
    };
    notification.open(args);
  };
  useEffect(() => {
    if (alrt) {
      alrtNotifications.map((v, i) => {
        openNotification(v.title, v.desc);
      });
    }
  }, [alrt]);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div>
      <EditProfileModal
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
      />
      <>
        <Drawer
          title="Notifications"
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          {notifications &&
            notifications
              ?.sort((a, b) =>
                b.updatedAt
                  ? b.updatedAt - a.updatedAt
                  : b.createdAt - a.createdAt
              )
              .map((v, i) => {
                return (
                  <p key={i}>
                    <Badge.Ribbon
                      text={` ${moment(
                        v.createdAt?.seconds * 1000
                      ).calendar()}`}
                      // color={"gold"}
                    >
                      <Badge dot={!v.isRead}>
                        <Card title={v.title} size="small">
                          {v.desc}
                          <div>
                            <Tag key={v.uid} color={"gold"}>
                              {`From ${v.from}`}
                            </Tag>
                          </div>
                        </Card>
                      </Badge>
                    </Badge.Ribbon>
                  </p>
                );
              })}
        </Drawer>
      </>

      {/* <Drawer
          title="Notifications"
          placement="right"
          onClose={onClose}
          visible={visible}
        >
          {notifications &&
            notifications
              ?.sort((a, b) =>
                b.updatedAt
                  ? b.updatedAt - a.updatedAt
                  : b.createdAt - a.createdAt
              )
              .map((v, i) => {
                return (
                  <p key={i}>
                    <Badge.Ribbon
                      //  text={`From ${v.from}`}
                      text={` ${moment(
                        v.updatedAt
                          ? v.updatedAt.seconds * 1000
                          : v.createdAt?.seconds * 1000
                      ).calendar()}`}
                      color={"primary"}
                    >
                      <Card title={v.title} size="small">
                        <Badge dot={!v.isRead}>
                          <span>{v.desc}</span>
                        </Badge>
                        <Tag
                          key={v.uid}
                          color={
                            v.from === ("Customer" || "customers")
                              ? "orange"
                              : "cyan"
                          }
                        >
                          {`From ${v.from}`}
                        </Tag>
                      </Card>
                    </Badge.Ribbon>
                  </p>
                );
              })}
        </Drawer> */}
      <Header className="site-layout-background owner_header">
        <div className="header_main_div">
          <div className="">
            <div className="header_trigger_div">
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: () => setCollapsed(!collapsed),
                }
              )}
            </div>
          </div>
          <div
            className="header_content_div"
            data-aos="fade-down"
            data-aos-duration="3000"
          >
            <div className="header_contnet_name_div">
              <h4 className="">OAC EXPRESS</h4>
            </div>
            <div className="sign-ema">
              <div className="header_content_profile_div">
                <div className="change-locale">
                  <button
                    className={` ar ${lng == "ar" ? "hello-arabic" : ""}`}
                    onClick={() => {
                      dispatch(Language("ar"));
                      i18n.changeLanguage("ar");
                    }}
                  >
                    العربية
                  </button>
                  <button
                    className={` ar ${lng == "en" ? "hello-english" : ""}`}
                    onClick={() => {
                      dispatch(Language("en"));
                      i18n.changeLanguage("en");
                    }}
                  >
                    English
                  </button>
                </div>
                <div className="header_profile_div">
                  <div className="profile_avatar">
                    <Dropdown
                      overlay={Profile}
                      trigger={["click"]}
                      className="profile-pic"
                    >
                      <a onClick={(e) => e.preventDefault()}>
                        <Avatar
                          style={{
                            backgroundColor: "#87d068",
                          }}
                          icon={<UserOutlined />}
                        />
                      </a>
                    </Dropdown>
                  </div>
                  <div
                    style={{ marginTop: "3px" }}
                    className="profile_dropdown_div"
                  >
                    <Dropdown overlay={Profile} trigger={["click"]}>
                      <a onClick={(e) => e.preventDefault()}>
                        <div>{user.name}</div>
                      </a>
                    </Dropdown>
                  </div>
                  <div>
                    <span>
                      <Badge
                        // onClick={showDrawer}
                        onClick={() => {
                          showDrawer();
                        }}
                        count={
                          notifications &&
                          notifications.filter((v) => v.isRead === false).length
                        }
                        // count={alrtNotifications && alrtNotifications.length}
                      >
                        <audio ref={audioPlayer} src={NotificationSound} />

                        <i className="n_icon">
                          <IoNotificationsOutline />
                          <audio ref={audioPlayer} src={NotificationSound} />
                        </i>
                      </Badge>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Header>
    </div>
  );
}
