export const footerItem = [
  {
    title: "About",
    items: [
      {
        item: "Landing",
        link: "/",
      },
      {
        item: "About Us",
        link: "/about_us",
      },
      {
        item: "FAQ",
        link: "/faq",
      },
    ],
  },
  {
    title: "Social Links",
    items: [
      {
        item: "Facebook",
        link: "https://www.facebook.com/oacexpress",
      },
      {
        item: "Pinterest",
        link: "https://www.pinterest.com/oacexpress/",
      },
      {
        item: "linkedin",
        link: "https://www.linkedin.com/in/oac-express-9a5009255/",
      },
      {
        item: "Twitter",
        link: "https://twitter.com/OacExpress",
      },
    ],
  },
  {
    title: "My Accounts",
    items: [
      {
        item: "Login",
        link: "/home",
      },
      {
        item: "Sign UP",
        link: "/home",
      },
    ],
  },
];
