import React, { useState } from "react";
import { Table, Input, 
  // Popconfirm, Space, Tag,
   Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";

import useOwnerRequests from "../Hooks/useOwnerRequests";
import useTransports from "../Hooks/useTransport";
// import { render } from "@testing-library/react";
import { useTranslation } from "react-i18next";
import useCustomerRequests from "../Hooks/useCustomerRequests";

export default function OwnerFinishedReqTable() {
  const navigate = useNavigate();
  const { ownerRequests } = useOwnerRequests();
  const { transports } = useTransports();
  const [search, setSearch] = useState("");
  const { t } = useTranslation();







  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest, count} = useCustomerRequests(updateTrigger, paginationFilters);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };

  const columns = [
    // {
    //   title: t("tableTabs.id"),
    //   render: (record) => (
    //     <span title={record?.requestId}>{record?.requestId}</span>
    //   ),
    // },
    {
      title: t("tableTabs.id"),
      render: (record, i, index) => (
        <span title={record?._id}>{record?._id.slice(-4)}</span>
      ),
    },

    {
      title: t("tableTabs.createdAt"),
      render: (record) => (
        <span>
          {new Date(record?.createdAt).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      ),
    },
    // {
    //   title: t("tableTabs.createdAt"),
    //   render: (record) => (
    //     <span>
    //       {new Date(record?.createdAt?.seconds * 1000).toLocaleString("en-US", {
    //         month: "short",
    //         day: "2-digit",
    //         year: "2-digit",
    //         hour: "numeric",
    //         minute: "numeric",
    //         hour12: true,
    //       })}
    //     </span>
    //   ),
    // },
    {
      title: t("tableTabs.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },

    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("tableTabs.capacity"),
      dataIndex: "serviceType",
      key: "capacity",
    },

    {
      title: t("tableTabs.commission"),
      render: (record) => <span>{`${record.commission}%`}</span>,
    },
    // {
    //   title: t("tableTabs.driverName"),
    //   render: (record) => (
    //     <span>
    //       {
    //         transports.find((v) => v?._id === record?.acceptedBy?.transportId)
    //           ?.driver?.driverName
    //       }
    //     </span>
    //   ),
    // },

    // {
    //   title: t("tableTabs.driverId"),
    //   render: (record) => (
    //     <span>
    //       {
    //         transports.find((v) => v?.id === record?.acceptedBy?.transportId)
    //           ?.driver?.driverIdNumber
    //       }
    //     </span>
    //   ),
    // },
    // {
    //   title: t("tableTabs.truck"),
    //   render: (record) => (
    //     <span>
    //       {
    //         transports.find((v) => v?.id === record?.acceptedBy?.transportId)
    //           ?.truck?.truckPlate
    //       }
    //     </span>
    //   ),
    // },
    {
      title: t("tableTabs.action"),
      render: (record) => (
        <span
          className="eyes-btn"
          onClick={() => {
            let transport = transports?.find(
              (v) => v?.id === record.acceptedBy?.transportId
            );
            let data = {
              request: record,
              transport: transport ? transport : {},
            };
            navigate(`/owner/request_detail/${record?.id}`, {
              state: data,
            });
          }}
        >
          <EyeOutlined />
        </span>
      ),
    },
  ];
  return (
    <div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        columns={columns}
        pagination={false}
        dataSource={
          allRequest 
          // &&
          // allRequest.length > 0 &&
          // allRequest
          //   .sort((a, b) => b.createdAt - a.createdAt)
          //   .filter((val) => val.status === "finished" && val.isAccept === true)
          //   .filter((val) => {
          //     if (search == "") {
          //       return val;
          //     } else if (
          //       val &&
          //       Object.keys(val).some((v) =>
          //         val[v]
          //           .toString()
          //           .toLowerCase()
          //           .includes(search.toString().toLowerCase())
          //       )
          //     ) {
          //       return val;
          //     }
          //   })
        }
      />
        <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </div>
  );
}
