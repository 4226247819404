import React from "react";
import { Navber } from "../Landing/components/Home/navber/navber";
import { Footer } from "../Landing/components/Footer/Footer";
import { useTranslation } from "react-i18next";
import Chatt from "../../chat-box/Chat-box";
export default function Aboutus() {
  const { t } = useTranslation();

  return (
    <div>
      <Navber />
      <Chatt />
      <div style={{ padding: "20px" }}>
        <h2>{t("About Us")}</h2>
        <p style={{ fontSize: "25px" }}>
          {t(
            "The mission of OACExpress is to connect buyers and sellers of business services. To achieve our mission, OACExpress’s website assists buyers in identifying prospective service providers, including by providing reviews of such companies. We also assist service providers in marketing their companies and services.As part of your use of the Services, you may have the opportunity to create a OACExpress account. You are responsible for your account and agree to provide, upon registration, and at all times maintain, accurate, current, and complete information. OACExpress is an identification, verification and source tool for professional services providers (“Vendors”) and potential clients of such providers seeking quality services (“Clients”)"
          )}
        </p>
      </div>
      <Footer />
    </div>
  );
}
