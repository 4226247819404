import React, { useEffect, useState } from "react";
import { collection, onSnapshot, where, query } from "firebase/firestore";
import { db } from "../firebase";
import { get_Relations } from "../../graphql/queries/relations/getRelations";
import { useQuery } from "@apollo/client";

export default function useRelationData() {
  let uid = JSON.parse(localStorage.getItem("uid")) || "";
  const [relationsData, setRelationsData] = useState([]);

  const getData = async () => {
    if (uid) {
      const q = query(
        collection(db, "relations"),
        where("customerId", "==", uid)
      );
      const unsub = onSnapshot(q, (querySnapShot) => {
        const data = [];
        querySnapShot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });
        setRelationsData(data);
      });

      return () => unsub();
    } else {
      setRelationsData([]);
    }
  };
  useEffect(() => {
    getData();
  }, [uid]);

  const getRelations = useQuery(get_Relations, {
    variables: {
      page: 1,
    },
  });

  return { relationsData, getRelations };
}
