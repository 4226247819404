import React, { useState } from "react";
import { Table, 
  Button, Input, message, Popconfirm, Space, 
  // Select, 
  Tag, Pagination } from "antd";
import {
  PlusCircleOutlined,
  SearchOutlined,
  // UploadOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import AddTruckModal from "../Modals/AddTruckModal";
import { useMutation } from "@apollo/client";
import useTrucks from "../Hooks/useTrucks";
// import { DeleteTruck } from "../API/API";
import EditTruckModal from "../Modals/EditTruckModal";
import { useTranslation } from "react-i18next";
import { DELETE_TRUCKS } from "../../graphql/Mutation/owner/addTruckMutation";
import Loader from "../Loaders/Loader";
export default function TruckManagmentTable() {
  // const { trucks } = useTrucks();

  const [search, setSearch] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [truck, setTruck] = useState({});
  const { t } = useTranslation();
  const showModal = () => {
    setIsModalVisible(true);
  };
  const showEditModal = () => {
    setIsEditModalVisible(!isEditModalVisible);
  };

  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { trucks, count} = useTrucks(updateTrigger, paginationFilters);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };


  


  const [deleteTruckMutation] = useMutation(DELETE_TRUCKS);
  const handleDeleteTruck = async (id) => {
    setLoading(true);
    console.log(id)
    try {
      const { data } = await deleteTruckMutation({
        variables: {
          id: id,
        },
      });

  //     if (data && data.deleteTruck.success) {
  //       // Handle success
  //       console.log("Truck deleted successfully");
  //     } else {
  //       // Handle error
  //       console.error("Error deleting driver:", data.deleteTruck.message);
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while deleting the driver:", error);
  //   }
  // };
  if (data && data.deleteTruck.success) {
    setLoading(true);
    setTimeout(() => {
      console.log("Truck deleted successfully");
      message.success({
        content: "Deleted successfully",
        duration: 5,
      });
      setLoading(false);
    }, 2000); 
  }
  
  else {
    // Handle error
    console.error("Error deleting driver:", data.deleteDriver.message);
  }
} catch (error) {
  console.error("An error occurred while deleting the driver:", error);
}
};
  // const getFile = (e) => {
  //   console.log("Upload event:", e);

  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };
  // type: values.truckType, 
  // status: "active", 
  // serviseType: values.serviseType, 
  // owner: ownerId,
  // noPlate: values.truckPlate, 
  // model: values.truckModel, 
  // imageUrl: "active", 
  // height: values.truckHeight,
  const columns = [
    {
      title: t("tableTabs.modal"),
      dataIndex: "model",
      key: "truckModel",
    },
    {
      title: t("tableTabs.plate"),
      dataIndex: "noPlate",
      key: "truckplate",
    },
    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "truckType",
    },
    {
      title: t("tableTabs.capacity"),
      dataIndex: "capacity",
      key: "truckCapacity",
    },
    {
      title: t("tableTabs.height"),
      dataIndex: "height",
      key: "truckHeight",
    },
    {
      title: t("tableTabs.merged"),
      render: (record) => (
        <span>
          <Tag color={record.isDriver ? "green" : "pink"}>
            {record.isDriver ? "Yes" : "No"}
          </Tag>
        </span>
      ),
    },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            onClick={() => {
              setTruck(record);
              showEditModal();
            }}
            className="eyes-btn"
          >
            <EditOutlined />
          </span>

          <Popconfirm
            title={"Are you sure?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => 
              {
              if (record?.isDriver) {
                alert("Please remove merging tarnsport first");
              } else {
                // DeleteTruck(record?.uid, record);
                handleDeleteTruck(record?._id);
              }
            }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <AddTruckModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <EditTruckModal
        isModalVisible={isEditModalVisible}
        setIsModalVisible={setIsEditModalVisible}
        data={truck}
      />

      <div className="table_topar_add_div_">
        <Button
          type="light"
          className="lang-btn"
          // shape="circle"
          icon={<PlusCircleOutlined />}
          size={"middle"}
          onClick={showModal}
        >
          {t("tableTabs.addTruck")}
        </Button>
      </div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      {loading ? <Loader /> : ""}
      <Table
        columns={columns}
        pagination={false}
        // expandable={{
        //   expandedRowRender: (record) => (
        //     <p
        //       style={{
        //         margin: 0,
        //       }}
        //     >
        //       {record.description}
        //     </p>
        //   ),
        //   rowExpandable: (record) => record.name !== "Not Expandable",
        // }}
        dataSource={trucks.filter((val) => {
          if (search == "") {
            return val;
          } else if (
            val &&
            Object.keys(val).some((v) =>
              val[v]
                .toString()
                .toLowerCase()
                .includes(search.toString().toLowerCase())
            )
          ) {
            return val;
          }
        })}
      />
       <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </div>
  );
}
