import React, { useState } from "react";
import { Table, Button, Input, Space, Popconfirm, Tag, Pagination, message } from "antd";
import Loader from "../Loaders/Loader";

// import Animate from "rc-animate";

import "./table.css";
import {
  PlusCircleOutlined,
  SearchOutlined,
  // UploadOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import AddDriverModal from "../Modals/AddDriverModal";
import EditDriverModal from "../Modals/EditDriverModal";
import useDrivers from "../Hooks/useDrivers";
import { useTranslation } from "react-i18next";
import {
  // CREATE_DRIVER,
  DELETE_DRIVERS,
} from "../../graphql/Mutation/owner/addDriverMutation";
import { useMutation } from "@apollo/client";
import { duration } from "moment";

export default function DriverManagmentTable() {
  // const { drivers } = useDrivers();
  const [search, setSearch] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [driver, setDriver] = useState({});
  const { t } = useTranslation();
  // const [createDriver] = useMutation(CREATE_DRIVER);
  const ownerId = JSON.parse(localStorage.getItem("id"));

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };
  const showEditModal = () => {
    setIsEditModalVisible(!isEditModalVisible);
  };

  const [deleteDriverMutation] = useMutation(DELETE_DRIVERS);

  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { drivers, count } = useDrivers(updateTrigger, paginationFilters, ownerId);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  console.log(drivers);


  const handleDeleteDriver = async (id) => {
    setLoading(true);
    console.log(id);
    try {
      const { data } = await deleteDriverMutation({
        variables: {
          id: id,
          isArchive: true,
        },
      });

      // if (data && data.deleteDriver.success) {
      //   // Handle success
        
        
      //   console.log("Driver deleted successfully");
        
      //   message.success({
      //     content: `Deleted successfully`,
      //     duration: 5
      //   })
      //   setLoading(false)
      // } 
      if (data && data.deleteDriver.success) {
        setLoading(true);
        setTimeout(() => {
          console.log("Driver deleted successfully");
          message.success({
            content: "Deleted successfully",
            duration: 5,
          });
          setLoading(false);
        }, 2000); 
      }
      
      else {
        // Handle error
        console.error("Error deleting driver:", data.deleteDriver.message);
      }
    } catch (error) {
      console.error("An error occurred while deleting the driver:", error);
    }
  };

  const columns = [
    {
      title: t("tableTabs.driverName"),
      dataIndex: "name",
      key: "driverName",
    },
    {
      title: t("tableTabs.age"),
      dataIndex: "age",
      key: "driverAge",
    },
    {
      title: t("tableTabs.nationality"),
      dataIndex: "nationality",
      key: "driverNationality",
    },
    {
      title: t("tableTabs.address"),
      dataIndex: "address",
      key: "driverAddress",
    },
    {
      title: t("tableTabs.idNumber"),
      dataIndex: "idNo",
      key: "driverIdNumber",
    },
    {
      title: t("tableTabs.merged"),
      render: (record) => (
        <span>
          <Tag color={record.isTruck ? "green" : "pink"}>
            {record.isTruck ? "Yes" : "No"}
          </Tag>
        </span>
      ),
    },
    {
      title: t("tableTabs.createdAt"),
      dataIndex: "createdAt",
      key: "driverCreatedAt",
      render: (createdAt) => (
        <span>
          {new Date(createdAt).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      ),
    },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            onClick={() => {
              setDriver(record);
              showEditModal();
            }}
            className="eyes-btn"
          >
            <EditOutlined />
          </span>
          <Popconfirm
            title={"Are you sure?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => {
              if (record?.isTruck) {
                alert("Please remove merging transport first");
              } else {
                handleDeleteDriver(record?._id);
              }
            }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <AddDriverModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <EditDriverModal
        isModalVisible={isEditModalVisible}
        setIsModalVisible={setIsEditModalVisible}
        data={driver}
      />
      <div className="table_topar_add_div_">
        <Button
          className="lang-btn"
          // shape="circle"
          icon={<PlusCircleOutlined />}
          size={"middle"}
          onClick={showModal}
        >
          {t("tableTabs.addDriver")}
        </Button>
      </div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      {/* <Table
        className="table-div"
        columns={columns}
        pagination={false}
        dataSource={drivers
          ?.sort((a, b) => b.createdAt - a.createdAt)
          .filter((val) => {
            if (search == "") {
              return val;
            } else if (
              val &&
              Object.keys(val).some((v) =>
                val[v]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              )
            ) {
              return val;
            }
          })}
      /> */}
      {loading ? <Loader /> : ""}
      <Table
        className="table-div"
        columns={columns}
        pagination={false}
        dataSource={
          
          drivers &&
          drivers?.length > 0 &&
          drivers?.filter((val) => {
            if (search == "") {
              return val;
            } else if (
              (val &&
                Object.keys(val.driver).some((v) =>
                  val[v]
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase())
                )) ||
              Object.keys(val.truck).some((v) =>
                val[v]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              )
            ) {
              return val;
            }
          })
        
            // ? [...drivers]
            //     .sort((a, b) => b.createdAt - a.createdAt)
            //     .filter((val) => {
            //       if (!search) {
            //         return true; // Return true to include all items when no search query
            //       }

            //       return (
            //         val &&
            //         Object.keys(val).some(
            //           (v) =>
            //             val[v]
            //               ?.toString()
            //               .toLowerCase()
            //               .includes(search.toString().toLowerCase()) || false // Make sure to return a boolean
            //         )
            //       );
            //     })
            // : []
        }
      />
      {/* Add Pagination component */}
      <Pagination
        defaultCurrent={1}
        defaultPageSize={paginationFilters.pageSize}
        onChange={handleChange}
        total={count}
        className="Pagination"
      />
    </div>
  );
}
// expandable={{
//   expandedRowRender: (record) => (
//     <p
//       style={{
//         margin: 0,
//       }}
//     >
//       {record.description}
//     </p>
//   ),
//   rowExpandable: (record) => record.name !== "Not Expandable",
// }}
