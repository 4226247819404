import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Pagination, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useClearanceNewRequest from "../Hooks/useClearanceNewRequest";
import { useState } from "react";
import useCustomerRequests from "../Hooks/useCustomerRequests";

const ClearanceNewRequestTable = () => {
  const { clearanceRequest } = useClearanceNewRequest();
  // console.log("clearance request ====>", clearanceRequest);
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest, count} = useCustomerRequests(updateTrigger, paginationFilters);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };

  const columns = [
    {
      title: "Pill Of Lading",
      dataIndex: "billOfLandingClearance",
      key: "pilloflading",
    },
    {
      title: "Customer Name",
      dataIndex: "customerNameClearance",
      key: "cname",
    },
    // {
    //   title: "Invoice",
    //   dataIndex: "invoice",
    //   key: "invoice",
    // },
    // {
    //   title: "Saber",
    //   dataIndex: "saber",
    //   key: "saber",
    // },
    // {
    //   title: "SFDA",
    //   dataIndex: "sfda",
    //   key: "sfda",
    // },
    // {
    //   title: "D/O",
    //   dataIndex: "DO",
    //   key: "DO",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    // },
  ];

  return (
    <>
      <div className="dd_div" style={{ marginBottom: "1rem" }}>
        <Button
          className="make-btn"
          type="primary"
          icon={<PlusCircleOutlined className="iconsss" />}
          size={"middle"}
          onClick={() => navigate("/customer/clearance/new")}
        >
          {t("tableTabs.makeNewRequest")}
        </Button>
      </div>
      <div style={{ marginBottom: ".6rem", marginTop: "1rem" }}>
        <Input prefix={<SearchOutlined />} placeholder="search" />
      </div>
      <Table
        dataSource={allRequest?.filter((val) => val.status == "created")}
        columns={columns}
       pagination={false} 
      />
       <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </>
  );
};

export default ClearanceNewRequestTable;
