import { gql } from "@apollo/client";

const single_Customers = gql`
  query AllCustomers($id: ID!) {
    customer(ID: $id) {
      _id
      crNumber
      crNumberUpload
      createdAt
      email
      emailVerified
      idNumber
      idNumberUpload
      isApproved
      isDeleted
      isHold
      name
      isRelation
      phoneNumber
      proVerified
      profileImage
      type
      token
      userName
    }
  }
`;

const single_Customer_ONLY = gql`
query Customer($id: ID!) {
  customer(id: $id) {
    _id
    crNumber
    crNumberUpload
    createdAt
    email
    emailVerified
    idNumber
    idNumberUpload
    isApproved
    isDeleted
    isHold
    name
    isRelation
    phoneNumber
    proVerified
    profileImage
    type
    token
    userName
  }
}

`
// query Customer($id: ID!) {
//   customer(ID: $id) {
//     _id
//     crNumber
//     crNumberUpload
//     createdAt
//     email
//     emailVerified
//     idNumber
//     idNumberUpload
//     isApproved
//     isDeleted
//     isHold
//     name
//     isRelation
//     phoneNumber
//     proVerified
//     profileImage
//     type
//     token
//     userName
//   }
// }

export { single_Customers , single_Customer_ONLY};
