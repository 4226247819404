import React from "react";
import { Navigate } from "react-router-dom";

const DriverPrivateRoutes = ({ children }) => {

  // let type = JSON.parse(localStorage.getItem("usertype"));
  let uid = localStorage?.getItem("id");
  console.log("ttttttttttttttidddd driverid here <<<<<<<<<<<", uid);
  let type = localStorage?.getItem("userType");
  console.log("ttttttttttttttidddd driverid here <<<<<<<<<<<", type);
  return type === "Driver" ? children 
  :
   <Navigate to="/" />;
};




// console.log("ttttttttttttttCustomerS", type);
//   return uid !== null && type !== null && type === "Driver" ? (
//     children
//   ) : (
//     <Navigate to="/" />
//   );
// };

export default DriverPrivateRoutes;



