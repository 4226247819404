import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";
import { useQuery } from "@apollo/client";
import { GET_ALL_CITIES } from "../../graphql/queries/cities/getCities";
export default function useCities() {
  const [cities, setcities] = useState([]);
  const getData = async () => {
    let q = query(collection(db, "services", "data", "cities"));
    const unsub = onSnapshot(q, (querySnapShot) => {
      setcities([]);
      if (!querySnapShot.empty) {
        querySnapShot.forEach((val) => {
          setcities((prev) => [...prev, val.data()]);
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getData();
  }, []);

  const getCities = useQuery(GET_ALL_CITIES, {
    variables: {
      page: 1,
    },
  });

  return { cities, getCities };
}
