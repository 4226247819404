import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Upload,
  Row,
  Col,
  Select,
  // message,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
// import { AddTruck, uploadImage, UpdateTruck } from "../API/API";
import Loader from "../Loaders/Loader";
import useCapacites from "../Hooks/useCapacities";
import useHeights from "../Hooks/useHeights";
import useTypes from "../Hooks/useTypes";
import { useTranslation } from "react-i18next";
import { UPDATE_TRUCKS } from "../../graphql/Mutation/owner/addTruckMutation";
import { useMutation } from "@apollo/client";
export default function EditTruckModal({
  isModalVisible,
  setIsModalVisible,
  data,
}) {
  const { Option } = Select;

  const { capacities } = useCapacites();
  const { types } = useTypes();
  const { heights } = useHeights();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };
  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  const [formData, setFormData] = useState({
    truckLicense: "",
  });
  const [licenseFile, setLicenseFile] = useState([]);

  // const onFinish = async (values) => {
  //   setLoading(true);
  //   if (typeof formData.truckLicense !== "string") {
  //     await uploadImage("Trucks/", formData.truckLicense).then((res) => {
  //       formData.truckLicense = res;
  //       setFormData({ ...formData });
  //     });
  //   }
  //   let udata = {
  //     ...values,
  //     truckLicenseUpload: formData.truckLicense,
  //   };
  //   await UpdateTruck(data.uid, udata)
  //     .then(() => {
  //       setLoading(false);
  //       form.resetFields();
  //       handleCancel();
  //       message.success("Add Successull!");
  //     })
  //     .catch((e) => {
  //       setLoading(false);
  //       message.error(JSON.stringify(e));
  //     });
  // };

  const [updateTruck] = useMutation(UPDATE_TRUCKS);
 
  const onFinish = async (values) => {
    try {
      setLoading(true);
      if (values.truckLicenseUpload && values.truckLicenseUpload.file) {
        const licenseFile = values.truckLicenseUpload.file.originFileObj;
        values.truckLicenseUpload = licenseFile;
      }

      const { data: { updateTruck: { success, message } } } = await updateTruck({
        variables: {
          id: data._id,
          updateTruckInput: {
            type: values.type,
            status: "no",
            serviceType: values.serviceType,
            noPlate: values.noPlate,
            model: values.model,
            imageUrl: "null",
            height: values.height,
            capacity: values.capacity,
          },
        },
      });

      if (success) {
        setLoading(false);
        form.resetFields();
        handleCancel();
        message.success('Update Successful!');
      } else {
        setLoading(false);
        // message.error(message);
        message.success('Update Successful!');
      }
    } catch (error) {
      setLoading(false);
      // message.error(error.message);
    }
  };


  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      if (data.truckLicenseUpload !== "") {
        setLicenseFile([
          {
            uid: "-1",
            name: "License Upload",
            status: "done",
            url: data.truckLicenseUpload,
          },
        ]);
      }
    }
  }, [data, form]);

  return (
    <div>
      <Modal
        title={`${t("Modal.edit")} ${t("Modal.truck")}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form
            form={form}
            name="add_trcuk_form"
            initialValues={data}
            onFinish={onFinish}
          >
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    className="inp-emai"
                    hasFeedback
                    name="model"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder={t("placeholders.modal")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="noPlate"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder={t("placeholders.plate")} />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>

            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="capacity"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select placeholder={t("placeholders.selectCapacity")}>
                      {capacities &&
                        capacities.map((v, i) => {
                          return (
                            <Option key={i} value={v.capacity}>
                              {v.capacity}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="height"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select placeholder={t("placeholders.selectHight")}>
                      {}
                      {heights &&
                        heights.map((v, i) => {
                          return (
                            <Option key={i} value={v.height}>
                              {v.height}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select placeholder={t("placeholders.selectType")}>
                      {types &&
                        types.map((v, i) => {
                          return (
                            <Option key={i} value={v.type}>
                              {v.type}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="truckLicenseUpload"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Upload
                      multiple={false}
                      maxCount={1}
                      name="logo"
                      listType="picture"
                      accept="image/*"
                      fileList={licenseFile}
                      onChange={(e) => {
                        if (e.file.status === "removed") {
                          formData.truckLicense = "";
                          setFormData({
                            ...formData,
                          });

                          setLicenseFile([]);
                        } else {
                          formData.truckLicense = e.file.originFileObj;
                          setFormData({
                            ...formData,
                          });
                          setLicenseFile(e.fileList);
                        }
                      }}
                    >
                      <Button icon={<UploadOutlined />}>
                        {t("placeholders.uploadLiense")}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                {t("Modal.edit")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
