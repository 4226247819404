import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
export default function useOwners() {
  const [owners, setowners] = useState([]);
  const getowners = async () => {
    let q = query(collection(db, "owners"), orderBy("createdAt", "desc"));
    const unsub = onSnapshot(q, (querySnapShot) => {
      setowners([]);
      if (!querySnapShot.empty) {
        querySnapShot.forEach((val) => {
          if (val.data().isApproved && val.data().emailVerified)
            setowners((prev) => [...prev, val.data()]);
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getowners();
  }, []);

  return owners;
}
