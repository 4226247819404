import {
  Input,
  Modal,
  Row,
  Col,
  Button,
  Space,
  Divider,
  message,
  Typography,
} from "antd";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  serverTimestamp,
  addDoc,
  deleteDoc,
  collection,
} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { uploadImage } from "../API/API";
import { db } from "../firebase";
import useOwners from "../Hooks/useOwners";
import Loader from "../Loaders/Loader";
import useRelationInvoice from '../Hooks/useRelationInvoive'
const { Title } = Typography;
export default function InvoiceModal({
  isModalVisible,
  setIsModalVisible,
  state:request,
}) {
  // console.log("🚀 ~ file: InvoiceModal.jsx:35 ~ request:", request)
 
  const [receipt, setReceipt] = useState("");
  const [ticket, setTicket] = useState("");
  const [finalByan, setFinalByan] = useState("");
  const [receiptFile, setReceiptFile] = useState("");
const [ticketFile, setTicketFile] = useState("");
const [finalByanFile, setFinalByanFile] = useState("");
  const [clickCount, setClickCount] = useState(0);
  const {relation} = useRelationInvoice()
  // console.log("🚀 ~ file: InvoiceModal.jsx:45 ~ relation:", relation)

    // logic for sending request to owners that if owner is in relation he cannot pick anyother request only recive relation customer request
    const requestFilter =request && request.length > 0 ? request.filter(
      (v) =>
        v?.status === "pending" &&
        v?.offlinePaid === true &&
        v?.isPaid !== false
    ):[];
    const finalCustomerIDs =  requestFilter?.map((item)=> item?.customerId)
    const ownersArrayFromRelation = relation
    ?.filter((relationItem) => finalCustomerIDs?.includes(relationItem?.customerId))
    ?.map((matchedRelationItem) => matchedRelationItem?.owners);
  const ownerIds = ownersArrayFromRelation?.flatMap((ownersArray) => ownersArray?.map((owner) => owner?.ownerId));
  // end whole logic
  

  //get owner uid where these condition is met
  const owners = useOwners();
  const filteredOwners = owners.filter((item) => item.isAccept === true && item.rRA === false);
  let ownersUIDS;
  if (filteredOwners.length > 0) {
    ownersUIDS = filteredOwners.map((o) => o.uid);
  }

  //end owners

  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleUpload = async (SaveSate,file) => {
    if (file) {
      try {
        setLoading(true);
        await uploadImage("request/", file).then(async (res) => {
          SaveSate(res);
          setLoading(false);
        });
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }
  };



  const handleSave = async () => {
    if (!receipt || !finalByan) {
      console.log(receipt, "receipt");
      console.log(finalByan, "finalByan");
    } else {
      console.log("heeloo", request);
      const userX = await getDoc(doc(db, "customers", request?.customerId));
  
      console.log(userX.data(), "hee");
  
      let updateRequest = {};
      if (userX.data().isHold) {
        const updateData = {
          receipt: receipt,
          finalByan: finalByan,
          status: "pending",
          offlinePaid: true,
        };
  
        if (ticket) {
          updateData.ticket = ticket;
        }
  
        updateDoc(doc(db, "customer_transport_requests", request.uid), updateData).then(() => {
          alert("upload successfull!");
          handleCancel();
        });
      } else {
        let d = {
          receipt: receipt,
          finalByan: finalByan,
          status: "underProcess",
          offlinePaid: true,
        };
        if (request.isSpecific) {
          d.sendTo = request.specific;
        } else {

          //split the requests according to quantity
          if(request.quantity > 1){
            const pricePerQuantity = request.price / request.quantity;

              for (let i = 0; i < request.quantity; i++) {
                const newData = {
                  ...request,
                  status:"underProcess",
                  receipt: receipt,
                  finalByan: finalByan,
                  sendTo: ownersUIDS,
                  quantity: 1,
                  price: pricePerQuantity,
                  offlinePaid:true
                };
                if (ticket) {
                  newData.ticket = ticket;
                }

                // Create a copy of the request in the "customer_transport_requests" collection
                const copyRef = await addDoc(
                  collection(db, "customer_transport_requests"),
                  newData
                );
                //update the document id with the uid
                await updateDoc(copyRef, { uid: copyRef.id });
              }
              await deleteDoc(
                doc(db, "customer_transport_requests", request.uid)
              );
          }else{
            if(ownerIds.length > 0){
              d.sendTo = ownerIds
            }
            else{
              d.sendTo = ownersUIDS;
            }
            // d.sendTo = ownersUIDS
          }
        }
        if (ticket) {
          d.ticket = ticket;
        }
        updateDoc(doc(db, "customer_transport_requests", request.uid), d).then(
          async () => {
            await setDoc(doc(db, "finance", request.uid), {
              receivedFromCustomer: true,
              sendToOwner: false,
              customerId: request.customerId,
              uid: request.uid,
              fare: request.price,
              commission: request.commission,
              requestId: request.requestId,
              createdAt: serverTimestamp(),
            });
            message.success("Uploaded Successfully");
            handleCancel();
          }
        );
      }
    }
  };
  
  useEffect(() => {
    if (request?.receipt !== undefined && request?.receipt !== null && request?.receipt !== "") {
      setReceipt(request?.receipt);
    } else {
      setReceipt("");
    }
    if (request?.ticket !== undefined && request?.ticket !== null && request?.ticket !== "") {
      setTicket(request?.ticket);
    } else {
      setTicket("");
    }
    if (request?.finalByan !== undefined && request?.finalByan !== null && request?.finalByan !== "") {
      setFinalByan(request?.finalByan);
    } else {
      setFinalByan("");
    }
  }, [request]);





  const handleClick = () => {
  
    if (clickCount === 0) {
      setClickCount(1);
      handleUpload(setReceipt, receiptFile);
        handleUpload(setTicket, ticketFile);
      handleUpload(setFinalByan, finalByanFile);
    } else if (clickCount === 1) {
      setClickCount(2);
      handleUpload(setReceipt, receiptFile);
        handleUpload(setTicket, ticketFile);
      handleUpload(setFinalByan, finalByanFile);
    }else if (clickCount === 2){
      handleSave()
      setClickCount(0)
      setIsModalVisible(!isModalVisible);
    }
  }; 

 
  
  return (
    <div>
      {loading ? <Loader /> : ""}
      <Modal
        title={t("Modal.attactFiles")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={
          // <Button
          //   className="check-btn"
          //   onClick={() => {
          //     if (window.confirm("Are u Sure")) {
          //       handleSave();
          //       handleUpload(setReceipt);
          //       handleUpload(setTicket);
          //       handleUpload(setFinalByan);
                
          //     }
          //   }}
          // >
          //  {t("placeholders.upload")}
          // </Button>
           <Button className="check-btn" onClick={() => {
                if (window.confirm("Are u Sure")){
                  handleClick()
              }}}>
           {clickCount === 0 ? "Preview" : clickCount === 1 ? t("placeholders.upload") : clickCount === 2 ? t("Modal.submit") : ''}
         </Button>
        }
      >
        <Space direction="vertical" style={{ width: "90%" }}>
          <div>
            <Title level={5}>{t("Modal.receipt")}</Title>
          </div>
          <Row gutter={"24"}>
            <Col span={"18"}>
              <Input
                style={{ border: "2px solid  #2d808b", width: "280px" }}
                type="file"
                onClick={(e) => e.target.value}
                onChange={(e) => {
                  setReceiptFile(e.target.files[0]);
                }}
              />
            </Col>
            <Col span={"6"}>
              {/* <Button
                className="check-btn"
                onClick={() => {
                  handleUpload(setReceipt);
                }}
                size="large"
              >
                {t("placeholders.upload")}
              </Button> */}
            </Col>
          </Row>
          {receipt !== "" ? (
            <Space>
              <img
                className="img"
                src={receipt}
                width={100}
                height={100}
                alt={"Click to view"}
                onClick={() => {
                  window.open(receipt);
                }}
              />
              <span>{t("Modal.receipt")}</span>
            </Space>
          ) : (
            <span>{t("Modal.notUploadYet")}</span>
          )}
        </Space>
        <Divider />
        <Space direction="vertical" style={{ width: "90%" }}>
          <div>
            <Title level={5}>{t("Modal.ticket")}</Title>
          </div>
          <Row gutter={"24"}>
            <Col span={"18"}>
              <Input
                type="file"
                style={{ border: "2px solid  #2d808b", width: "280px" }}
                onClick={(e) => e.target.value}
                onChange={(e) => {
                  setTicketFile(e.target.files[0]);
                }}
              />
            </Col>
            <Col span={"6"}>
              {/* <Button
                className="check-btn"
                onClick={() => {
                  handleUpload(setTicket);
                }}
                size="large"
              >
                {t("placeholders.upload")}
              </Button> */}
            </Col>
          </Row>
          {ticket !== "" ? (
            <Space>
              <img
                className="img"
                src={ticket}
                width={100}
                height={100}
                alt={"Click to view"}
                onClick={() => {
                  window.open(ticket);
                }}
              />
              <span>{t("Modal.ticket")}</span>
            </Space>
          ) : (
            <span>{t("Modal.notUploadYet")}</span>
          )}
        </Space>
        <Divider />
        <Space direction="vertical" style={{ width: "90%" }}>
          <div>
            <Title level={5}>{t("Modal.finalByan")}</Title>
          </div>
          <Row gutter={"24"}>
            <Col span={"18"}>
              <Input
                style={{ border: "2px solid  #2d808b", width: "280px" }}
                type="file"
                onClick={(e) => e.target.value}
                onChange={(e) => {
                  setFinalByanFile(e.target.files[0]);
                }}
              />
            </Col>
            <Col span={"6"}>
              {/* <Button
                className="check-btn"
                onClick={() => {
                  handleUpload(setFinalByan);
                }}
                size="large"
              >
                {t("placeholders.upload")}
              </Button> */}
            </Col>
          </Row>
          {finalByan !== "" ? (
            <Space>
              <img
                className="img"
                src={finalByan}
                width={100}
                height={100}
                alt={"Click to view"}
                onClick={() => {
                  window.open(finalByan);
                }}
              />
              <span>{"Final Byan"}</span>
            </Space>
          ) : (
            <span>{t("Modal.notUploadYet")}</span>
          )}
        </Space>
      </Modal>
    </div>
  );
}
