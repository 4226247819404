import { Modal, Descriptions, Image } from "antd";
import React, { useState, useEffect } from "react";
import moment from "moment";
import useTransports from "../Hooks/useTransport";
import { useTranslation } from "react-i18next";
import ActiveRideMap from "./ActiveRideMap";
export default function ViewRequestModal({
  isModalVisible,
  setIsModalVisible,
  state,
}) {
  const [transport, setTranspot] = useState({});
  const { transports } = useTransports(state?.acceptedBy?.ownerId);
  const { t } = useTranslation();
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };
  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  useEffect(
    () => {
      if (state?.isAccept) {
        let transport = transports?.find(
          (v) => v.uid === state?.acceptedBy?.transportId
        );

        if (transport) {
          setTranspot(transport);
        } else {
          setTranspot({});
        }
      }
    },
    [JSON.stringify(transports)],
    JSON.stringify(state)
  );

  return (
    <div>
      <Modal
        title={`${t("tableTabs.requst")} ${t("tableTabs.detail")}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
        width={"80%"}
      >
        <>
          <Descriptions
            bordered
            title={t("tableTabs.requst")}
            layout={"vertical"}
          >
            <Descriptions.Item
              label={t("tableTabs.customerName")}
              className="car-img"
            >
              {state?.name}
            </Descriptions.Item>
            <Descriptions.Item
              span={2}
              label={t("Phone Number")}
              className="car-img1"
            >
              {state?.phoneNumber}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("tableTabs.capacity")}
              className="car-img"
            >
              {state?.capacity}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("tableTabs.hight")}
              className="car-img2"
            >
              {state?.height}
            </Descriptions.Item>
            <Descriptions.Item label={t("tableTabs.type")} className="car-img1">
              {state?.type}
            </Descriptions.Item>
            <Descriptions.Item label={t("tableTabs.from")} className="car-img">
              {state?.from}
            </Descriptions.Item>
            <Descriptions.Item
              span={2}
              label={t("tableTabs.senderContact")}
              className="car-img1"
            >
              {state?.senderContact}
            </Descriptions.Item>
            <Descriptions.Item label={t("tableTabs.to")} className="car-img">
              {state?.to}
            </Descriptions.Item>
            <Descriptions.Item
              span={2}
              label={t("tableTabs.receiverContact")}
              className="car-img1"
            >
              {state?.receiverContact}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("tableTabs.distance")}
              className="car-img"
            >
              {state?.distance}
            </Descriptions.Item>
            <Descriptions.Item
              span={2}
              label={t("tableTabs.price")}
              className="car-img1"
            >
              {state?.price}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("tableTabs.blNumber")}
              className="car-img"
            >
              {state?.blNumber}
            </Descriptions.Item>
            <Descriptions.Item span={2} label={"EIR"} className="car-img1">
              {state?.eir && state?.eir !== null && state?.eir !== "" ? (
                <img
                  className="img"
                  onClick={() => {
                    window.open(state?.eir);
                  }}
                  alt={"Click to View Doc"}
                  src={state?.eir}
                />
              ) : (
                <span>Not upload yet</span>
              )}
            </Descriptions.Item>

            <Descriptions.Item
              label={`IRO ${t("tableTabs.date")}`}
              className="car-img"
            >
              {moment(state?.iroDate?.seconds * 1000).format("lll")}
            </Descriptions.Item>
            <Descriptions.Item
              span={2}
              label={`IRO ${t("tableTabs.upload")}`}
              className="car-img1"
            >
              <img
                className="img"
                onClick={() => {
                  window.open(state?.iroDateUpload || state?.iroUpload);
                }}
                alt={"Click to View"}
                src={state?.iroDateUpload || state?.iroUpload}
              />
            </Descriptions.Item>
            <Descriptions.Item
              label={t("tableTabs.invoice")}
              className="car-img"
            >
              {state?.invoice ? (
                <img
                  className="img"
                  onClick={() => {
                    window.open(state?.invoice);
                  }}
                  alt={"Click to View"}
                  src={state?.invoice}
                />
              ) : (
                <span>{t("Modal.notUploadYet")}</span>
              )}
            </Descriptions.Item>

            <Descriptions.Item
              span={2}
              label={t("tableTabs.receipt")}
              className="car-img1"
            >
              {state?.receipt ? (
                <img
                  className="img"
                  onClick={() => {
                    window.open(state?.receipt);
                  }}
                  alt={"Click to View"}
                  src={state?.receipt}
                />
              ) : (
                <span>{t("Modal.notUploadYet")}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("tableTabs.finalByan")}
              className="car-img"
            >
              {state?.finalByan ? (
                <img
                  className="img"
                  onClick={() => {
                    window.open(state?.finalByan);
                  }}
                  alt={"Click to View"}
                  src={state?.finalByan}
                />
              ) : (
                <span>{t("Modal.notUploadYet")}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              span={2}
              label={t("tableTabs.ticket")}
              className="car-img1"
            >
              {state?.ticket ? (
                <img
                  className="img"
                  onClick={() => {
                    window.open(state?.ticket);
                  }}
                  alt={"Click to View Doc"}
                  src={state?.ticket}
                />
              ) : (
                <span>{t("Modal.notUploadYet")}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              label={t("tableTabs.deliveryNote")}
              className="car-img"
            >
              {state?.deliveryNote &&
              state?.deliveryNote !== null &&
              state?.deliveryNote !== "" ? (
                <img
                  className="img"
                  onClick={() => {
                    window.open(state?.deliveryNote);
                  }}
                  alt={"Click to View"}
                  src={state?.deliveryNote}
                />
              ) : (
                <span>Not upload yet</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              span={2}
              label={`${t("tableTabs.requst")} ${t("tableTabs.upload")}`}
              className="car-img1"
            >
              <img
                className="img"
                onClick={() => {
                  window.open(state?.blNumberUpload);
                }}
                alt={"Click to View"}
                src={state?.blNumberUpload}
              />
            </Descriptions.Item>
          </Descriptions>
          <div>
            <Descriptions
              bordered
              title={t("tableTabs.sendTo")}
              layout={"vertical"}
            >
              {Object.keys(transport).length > 0 ? (
                <>
                  <Descriptions.Item label={t("tableTabs.driverName")}>
                    {transport?.driver?.driverName}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={`${t("tableTabs.driver")} ${t("tableTabs.id")}`}
                  >
                    {transport?.driver?.driverIdNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.driverAddress")}>
                    {transport?.driver?.driverAddress}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label={`${t("tableTabs.driver")} ${t("tableTabs.id")} ${t(
                      "tableTabs.upload"
                    )}`}
                  >
                    <img
                      className="img"
                      onClick={() => {
                        window.open(transport?.driver?.driverIdUpload);
                      }}
                      alt={"Click to View"}
                      src={transport?.driver?.driverIdUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.driverLicenseUpload")}>
                    <img
                      className="img"
                      onClick={() => {
                        window.open(transport?.driver?.driverLicenseUpload);
                      }}
                      alt={"Click to View"}
                      src={transport?.driver?.driverLicenseUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.driverPortIdUpload")}>
                    <img
                      className="img"
                      onClick={() => {
                        window.open(transport?.driver?.portIdUpload);
                      }}
                      alt={"Click to View"}
                      src={transport?.driver?.portIdUpload}
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truck")}>
                    {transport?.truck?.truckModel}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckNumber")}>
                    {transport?.truck?.truckPlate}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckCapacity")}>
                    {transport?.truck?.truckCapacity}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckHeight")}>
                    {transport?.truck?.truckHeight}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckType")}>
                    {transport?.truck?.truckType}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("tableTabs.truckLicenseUpload")}>
                    <img
                      className="img"
                      onClick={() => {
                        window.open(transport?.truck?.truckLicenseUpload);
                      }}
                      alt={"Click to View"}
                      src={transport?.truck?.truckLicenseUpload}
                    />
                  </Descriptions.Item>
                </>
              ) : (
                <Descriptions.Item label={t("tableTabs.notSendedYet")} />
              )}
            </Descriptions>
          </div>
          <ActiveRideMap coordinates={state?.coordinates} id={state?.uid} />
        </>
      </Modal>
    </div>
  );
}
