import React from "react";
import { footerItem } from "./data";
import { FooterComponent } from "./FooterComponent";
import oca from "../../assets/home/OAC-LOGO.png";
import "./style.css";
import { RiFacebookCircleLine } from "react-icons/ri";
import { TbBrandTwitter } from "react-icons/tb";
import { AiFillLinkedin } from "react-icons/ai";
import { ImPinterest2 } from "react-icons/im";
import img1 from "../../assets/home/OAC LOGO2.png";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import { CiLinkedin } from "react-icons/ci";

// import { FiYoutube } from "react-icons/fi";

export const Footer = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="footer row footer-x">
      <div
        className="col-sm-12 col-md-3 col-lg-3"
        data-aos="zoom-in"
        data-aos-duration="3000"
      >
        <img
          src={img1}
          alt="Picswagger"
          style={{ marginBottom: "1rem ", width: "50%" }}
          className="img-x"
        ></img>
        <p className="footer_heading">{t("OAC Express")}</p>
        <div className="icons-x">
          <a href="https://www.facebook.com/oacexpress">
            <RiFacebookCircleLine className="icons" />
          </a>
          <a href="https://twitter.com/OacExpress">
            <TbBrandTwitter className="icons" />
          </a>
          <a href="https://www.pinterest.com/oacexpress/">
            <ImPinterest2 className="icons" />
          </a>
          <a href="https://www.linkedin.com/in/oac-express-9a5009255/">
            <AiFillLinkedin className="icons" />
          </a>
          {/* <AiOutlineInstagram className="icons" /> */}
        </div>
      </div>
      {footerItem?.map((record) => (
        <FooterComponent title={record.title} items={record.items} />
      ))}
      <p
        className="text-center about-text"
        style={{ fontSize: "20px", color: "white", marginTop: "50px" }}
      ></p>
    </div>
  );
};
