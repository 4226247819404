import { gql } from "@apollo/client";

const CREATE_TRUCK = gql`
mutation CreateTruck($registerTruckInput: RegisterTruckInput) {
  createTruck(registerTruckInput: $registerTruckInput) {
    success
    message
    code
    data {
      type
      status
      serviseType
      noPlate
      model
      imageUrl
      height
      createdAt
      _id
      owner {
        type
        token
        rRA
        profileImage
        phoneNumber
        ownerName
        name
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
    }
  }
}
`;

const MY_TRUCKS = gql`
query MyTrucks($ownerId: ID!, $page: Int!) {
  myTrucks(ownerId: $ownerId, page: $page) {
    trucks {
      type
      status
      serviseType
      owner {
        type
        token
        rRA
        profileImage
        phoneNumber
        ownerName
        isDeleted
        name
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
      noPlate
      model
      isDriver
      imageUrl
      height
      createdAt
      capacity
      _id
    }
  }
}

`;

const UPDATE_TRUCKS = gql`
mutation Mutation($id: ID!, $updateTruckInput: UpdateTruckInput) {
    updateTruck(ID: $id, updateTruckInput: $updateTruckInput) {
      success
      message
      code
      data {
        type
        status
        serviseType
        owner {
          phoneNumber
          profileImage
          rRA
          token
          type
          ownerName
          name
          isDeleted
          isApproved
          isAccept
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          bankInfo {
            iBanNumber
            beneficiaryName
            bankIdCode
          }
          _id
        }
        noPlate
        model
        imageUrl
        height
        createdAt
        _id
      }
    }
  }
`;
const DELETE_TRUCKS = gql`
mutation Mutation($id: ID!) {
  deleteTruck(ID: $id) {
    success
    message
    code
    data {
      type
      status
      serviseType
      owner {
        phoneNumber
        profileImage
        rRA
        type
        token
        ownerName
        name
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
      noPlate
      model
      imageUrl
      createdAt
      _id
      height
    }
  }
}
`;



export {CREATE_TRUCK, MY_TRUCKS, UPDATE_TRUCKS ,DELETE_TRUCKS };