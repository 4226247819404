import React from "react";
import { Route, Routes } from "react-router-dom";
import DriverMap from "../Components/DriverComponent/driverMap/DriverMap";
import History from "../Components/DriverComponent/history/History";
import DriverProfile from "../Components/DriverComponent/profile/DriverProfile";
import Request from "../Components/DriverComponent/request/Request";
import DriverLayout from "../Components/Layouts/DriverLayout/Layout/DriverLayout";

const DriverRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path="driverprofile"
          element={
            <DriverLayout>
              <DriverProfile />
            </DriverLayout>
          }
        />
        <Route
          path="driverrequest"
          element={
            <DriverLayout>
              <Request />
            </DriverLayout>
          }
        />
        <Route
          path="driverhistory"
          element={
            <DriverLayout>
              <History />
            </DriverLayout>
          }
        />
        <Route
          path="drivermap"
          element={
            <DriverLayout>
              <DriverMap />
            </DriverLayout>
          }
        />
      </Routes>
    </>
  );
};

export default DriverRoutes;
