import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  getDoc,
  doc,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
export default function useFinance() {
  const [finance, setFinance] = useState([]);
  const getData = async () => {
    const id = JSON.parse(localStorage.getItem("uid"));
    const type = JSON.parse(localStorage.getItem("type"));

    let cq = query(collection(db, "finance"), where("customerId", "==", id));
    let oq = query(collection(db, "finance"), where("ownerId", "==", id));
    if (type === "owners") {
      const unsub = onSnapshot(oq, (querySnapShot) => {
        if (!querySnapShot.empty) {
          setFinance([]);
          let arr = [];
          querySnapShot.forEach(async (val) => {
            let data = {};
            let ownersnap = await getDoc(doc(db, "owners", val.data().ownerId));

            let customersnap = await getDoc(
              doc(db, "customers", val.data().customerId)
            );
            const req = await getDoc(
              doc(db, "customer_transport_requests", val.data().uid)
            );

            data = {
              ...val.data(),
              customer: customersnap.data(),
              from: req.data().from,
              to: req.data().to,
              owner: ownersnap.data(),
            };
            arr.push(data);
            setFinance([...arr]);
          });
        }
      });

      return () => unsub();
    } else {
      const unsub = onSnapshot(cq, (querySnapShot) => {
        if (!querySnapShot.empty) {
          setFinance([]);
          let arr = [];
          querySnapShot.forEach(async (val) => {
            let data = {};

            let customersnap = await getDoc(
              doc(db, "customers", val.data().customerId)
            );
            const req = await getDoc(
              doc(db, "customer_transport_requests", val.data().uid)
            );
            if (customersnap.exists()) {
              data = {
                ...val.data(),
                from: req.data().from,
                to: req.data().to,
                customer: customersnap.data(),
              };
              arr.push(data);
              setFinance([...arr]);
            }
          });
        }
      });

      return () => unsub();
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return { finance };
}
