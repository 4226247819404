// import React, { useEffect, useState } from "react";
import { useEffect, useState } from "react"

import {
  collection,
  query,
  onSnapshot,
  where,
  getDoc,
  doc,
} from "firebase/firestore";

import { db } from "../firebase";
export default function useTransports(Id) {
  const id = Id !== undefined ? Id : JSON.parse(localStorage.getItem("id"));
  const [transportss, setTransports] = useState([]);
  const getTrucks = async () => {
    let q = query(collection(db, "transports"), where("ownerId", "==", id));
    const unsub = onSnapshot(q, (querySnapShot) => {
      setTransports([]);
      if (!querySnapShot.empty) {
        setTransports([]);
        querySnapShot.forEach(async (val) => {
          let driver = await getDoc(doc(db, "drivers", val.data().driver.uid));
          let truck = await getDoc(doc(db, "trucks", val.data().truck.uid));
          if (driver.exists() && truck.exists()) {
            let data = {
              uid: val.data().uid,
              ownerId: val.data().ownerId,
              driver: driver.data(),
              truck: truck.data(),
            };
            setTransports((prev) => [...prev, data]);
          }
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getTrucks();
  }, [Id]);
  let key = "uid";
  let transports = [
    ...new Map(transportss.map((item) => [item[key], item])).values(),
  ];

  return { transports };
}
