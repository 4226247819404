import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot,orderBy } from "firebase/firestore";
import { db } from "../firebase";
export default function useTypes() {
  const [types, setTypes] = useState([]);
  const getData = async () => {
    let q = query(collection(db, "services", "data", "types"),orderBy("price","asc"));
    const unsub = onSnapshot(q, (querySnapShot) => {
      setTypes([]);
      if (!querySnapShot.empty) {
        querySnapShot.forEach((val) => {
          setTypes((prev) => [...prev, val.data()]);
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getData();
  }, []);

  return { types };
}
