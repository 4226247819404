import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot,orderBy } from "firebase/firestore";
import { db } from "../firebase";
export default function useHeights() {
  const [heights, setheights] = useState([]);
  const getData = async () => {
    let q = query(
      collection(db, "services", "data", "heights"),
      orderBy("price", "asc")
    );
    const unsub = onSnapshot(q, (querySnapShot) => {
      setheights([]);
      if (!querySnapShot.empty) {
        querySnapShot.forEach((val) => {
          setheights((prev) => [...prev, val.data()]);
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getData();
  }, []);
  return { heights };
}

// import { useEffect, useState, useCallback } from "react";
// import { useQuery } from "@apollo/client";
// import { GET_ALL_HEIGHTS } from "../../Graphql/queries/servicesQueries";

// function useHeights(trigger, paginationFilters) {
//   const [heights, setHeights] = useState([]);
//   const [count, setCount] = useState(0);

//   const { loading, error, data, refetch } = useQuery(GET_ALL_HEIGHTS, {
//     variables: { page: paginationFilters?.pageNo },
//     fetchPolicy: "network-only",
//   });

//   const getAllHeights = useCallback(async () => {
//     if (loading) return;
//     if (error) {
//       console.log("Error:", error.message);
//       return;
//     }
    
//     const { heights } = data;
//     if (!heights) {
//       console.log("No Owner data available.");
//       return;
//     }
//     const { totalDocs } = heights;
//     setHeights(heights.heights);
//     setCount(totalDocs);
//   }, [loading, error, data]);

//   useEffect(() => {
//     getAllHeights();
//     if (trigger) {
//       refetch({ page: paginationFilters?.pageNo });
//     }
//   }, [trigger, getAllHeights, paginationFilters?.pageNo, refetch]);

//   return { loading, error, heights, count };
// }

// export default useHeights;
