import React, { useState } from "react";
import { Table, Input, Popconfirm, Space, Select, Badge, Tag, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import {
  PlusCircleOutlined,
  SearchOutlined,
  UploadOutlined,
  DeleteOutlined,
  EyeOutlined,
  SendOutlined,
} from "@ant-design/icons";

// import useOwnerRequests from "../Hooks/useOwnerRequests";
import { updateRequest } from "../API/API";
import useTransports from "../Hooks/useTransport";
import { useTranslation } from "react-i18next";
import useCustomerRequests from "../Hooks/useCustomerRequests";
export default function OwnerRequestTable() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const { ownerRequests } = useOwnerRequests();
  // console.log("🚀 ~ file: OwnerRequestsTable.jsx:21 ~ OwnerRequestTable ~ ownerRequests:", ownerRequests.filter((item)=>item.status=='underProcess' && item.isAccept !== true))
  const { transports } = useTransports();
  const [search, setSearch] = useState("");
  // console.log("ownerRequests data:", ownerRequests);




  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest, count} = useCustomerRequests(updateTrigger, paginationFilters);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };

  console.log(allRequest)
  // console.log(transports, " transports");
  const columns = [
    // {
    //   title: t("tableTabs.id"),
    //   render: (record) => (
    //     <span title={record?.requestId}>{record?.requestId}</span>
    //   ),
    // },
    // {
    //   title: t("tableTabs.id"),
    //   render: (record, i, index) => (
    //     <span title={record?._id}>{index + 1}</span>
    //   ),
    // },
    {
      title: t("tableTabs.id"),
      render: (record, i, index) => (
        <span title={record?._id}>{record?._id.slice(-4)}</span>
      ),
    },
    


    {
      title: t("tableTabs.createdAt"),
      render: (record) => (
        <span>
          {new Date(record?.createdAt).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      ),
    },
    // {
    //   title: t("tableTabs.createdAt"),
    //   dataIndex: "createdAt",
    //   key: "driverCreatedAt",
    //   render: (createdAt) => (
    //     <span>
    //       {createdAt ? (
    //         new Date(createdAt.seconds * 1000).toLocaleString("en-US", {
    //           month: "short",
    //           day: "2-digit",
    //           year: "2-digit",
    //           hour: "numeric",
    //           minute: "numeric",
    //           hour12: true,
    //         })
    //       ) : (
    //         "Invalid date"
    //       )}
    //     </span>
    //   ),
    // },
    
    



    // {
    //   title: t("tableTabs.name"),
    //   dataIndex: "name",
    //   key: "name",
    // },

    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },

    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "type",
    },

    {
      title: t("tableTabs.commission"),
      render: (record) => <span>{`${record.commission}%`}</span>,
    },
    {
      title: "Status",
      render: (record) => <Tag key={record.id}>{record?.status}</Tag>,
    },
    {
      title: t("tableTabs.sended"),
      render: (record) => (
        <Tag
          key={record.id}
          color={
            record?.isAccept !== undefined && record?.isAccept
              ? "green"
              : "pink"
          }
        >
          {record?.isAccept !== undefined && record?.isAccept ? "Yes" : "No"}
        </Tag>
      ),
    },

    {
      title: t("tableTabs.approved"),
      render: (record) => (
        <Tag
          key={record.id}
          color={
            record?.isStarted !== undefined && record?.isStarted
              ? "green"
              : "pink"
          }
        >
          {record?.isStarted !== undefined && record?.isStarted ? "Yes" : "No"}
        </Tag>
      ),
    },
    // {
    //   title: t("tableTabs.tChanged"),
    //   render: (record) => (
    //     <Tag
    //       key={record.uid}
    //       color={
    //         record?.isChangeTransport !== undefined && record?.isChangeTransport
    //           ? "green"
    //           : "pink"
    //       }
    //     >
    //       {record?.isChangeTransport !== undefined && record?.isChangeTransport
    //         ? "Yes"
    //         : "No"}
    //     </Tag>
    //   ),
    // },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          {record?.isAccept !== undefined &&
          record?.isAccept &&
          (record.isChangeTransport === undefined ||
            !record.isChangeTransport) ? (
            <span
              className="eyes-btn"
              onClick={() => {
                let transport = transports?.find(
                  (v) => v._id === record.acceptedBy?.transportId
                );
                let data = {
                  request: record,
                  transport: transport ? transport : {},
                };
                navigate(`/owner/request_detail/${record._id}`, {
                  state: data,
                });
              }}
            >
              <EyeOutlined />
            </span>
          ) : (
            <>
              <span
                className="eyes-btn"
                onClick={() => {
                  let transport = transports?.find(
                    (v) => v._id === record.acceptedBy?.transportId
                  );
                  let data = {
                    request: record,
                    transport: transport ? transport : {},
                  };
                  navigate(`/owner/request_detail/${record._id}`, {
                    state: data,
                  });
                }}
              >
                <EyeOutlined />
              </span>
              <span
                className="eyes-btn"
                title="send Request"
                onClick={() => {
                  navigate("/owner/send_request/" + record._id);
                }}
              >
                <SendOutlined />
              </span>

              <Popconfirm
                title={"Are you sure?"}
                okText="Ok"
                cancelText="Cancel"
                onConfirm={() => {
                  if (record.isStarted === undefined || !record.isStarted) {
                    let arr = record.sendTo;
                    let index = record.sendTo.indexOf(
                      record.sendTo[0]
                      );
                    arr.splice(index, 1);

                    updateRequest(record?._id, { sendTo: arr });
                  } else {
                    alert("Started Request cannot remove");
                  }

                  // DeleteRequest(record?.uid, record);
                }}
              >
                <span className="ant-btn ant-btn-danger">
                  <DeleteOutlined />
                </span>
              </Popconfirm>
            </>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      {/* <Table
        columns={columns}
        pagination={false}
        dataSource={
          allRequest &&
          allRequest.length > 0 &&
          allRequest
            .sort((a, b) => b.createdAt - a.createdAt)
            .filter(
              (item) => item.status === "created" && item.isAccept !== true
            )
            .filter((val) => {
              if (search == "") {
                return val;
              } else if (
                val &&
                Object.keys(val).some((v) =>
                  val[v]
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase())
                )
              ) {
                return val;
              }
            })
        }
      /> */}
      <Table
  columns={columns}
  pagination={false}
  dataSource={
    allRequest
    //  &&
    // allRequest.length > 0 &&
    // [...allRequest] // Make a shallow copy of the array
    //   .sort((a, b) => b.createdAt - a.createdAt)
    //   .filter(
    //     (item) => item.status === "created" && item?.isAccept !== true
    //   )
    //   .filter((val) => {
    //     if (search === "") {
    //       return val;
    //     } else if (
    //       val &&
    //       Object.keys(val).some((v) =>
    //         val[v]
    //           .toString()
    //           .toLowerCase()
    //           .includes(search.toString().toLowerCase())
    //       )
    //     ) {
    //       return val;
    //     }
    //   })
  }
/>
       <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
       
    </div>
  );
}
