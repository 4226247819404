import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot, doc, getDoc } from "firebase/firestore";
import { db } from ".././firebase";
export default function useActiveRideLocation(id) {
  //   const id = JSON.parse(localStorage.getItem("uid"));
  const [activeRequest, setactiveRequest] = useState({});
  const getData = async () => {
    const unsub = onSnapshot(
      doc(db, "customer_transport_requests", id),
      (doc) => {
        setactiveRequest(doc.data());
      }
    );
    // let snap = await getDoc(doc(db, "customer_transport_requests", id));
    // if (snap.exists()) {
    //     setactiveRequest(snap.data())
    // }
    return () => unsub();
  };
  useEffect(() => {
    if (id !== null && id !== undefined) {
      getData();
    }
  }, [id]);
  return { activeRequest };
}
