import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./owner_sidebar.css";
import { Badge, Layout, Menu, Popconfirm } from "antd";
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import { BsTools, BsTruck } from "react-icons/bs";
import { ImCoinDollar } from "react-icons/im";
import { auth } from "../../../../firebase";
import { useTranslation } from "react-i18next";
import { Logout, readNotifications } from "../../../../API/API";
import useNotifications from "../../../../Hooks/useNotifications";
import AOS from "aos";
import "aos/dist/aos.css";
const { Sider } = Layout;

export default function OwnerSideBar({ collapsed }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { notifications } = useNotifications();

  const [loading, setLoading] = useState(false);
  let requests =
    notifications &&
    notifications.filter(
      (v) => !v.isRead && v.type?.toLowerCase().includes("request")
    );
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div
      className="sidebar_main_div"
      data-aos="fade-right"
      data-aos-duration="3000"
    >
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo">
          <div className="logo_div">
            <img className="car-img" width={60} src="/assets/logo.png" />
          </div>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          // defaultSelectedKeys={["1"]}
          selectedKeys={[window.location.pathname]}
          items={[
            {
              key: "/owner/fleet_managment",
              icon: <SettingOutlined />,
              label: t("routes.fleet_managment"),
              onClick: () => {
                navigate("/owner/fleet_managment");
              },
            },
            {
              key: "/owner/transportation_requests",
              icon: <BsTruck />,
              label: (
                <Badge
                  onClick={() => {
                    readNotifications(requests);
                  }}
                  dot={requests && requests.length > 0 ? true : false}
                >
                  <span className="badge_span">
                    {t("routes.transportation_request")}
                    {/* {"T Requests"} */}
                  </span>
                </Badge>
              ),
              onClick: () => {
                navigate("/owner/transportation_requests");
              },
            },
            {
              key: "/owner/finance",
              icon: <ImCoinDollar />,
              label: t("routes.finance"),
              onClick: () => {
                navigate("/owner/finance");
              },
            },
            {
              key: "/owner/maintenance",
              icon: <BsTools />,
              label: t("routes.maintenance"),
              onClick: () => {
                navigate("/owner/maintenance");
              },
            },
            {
              key: "/",
              icon: <LogoutOutlined />,
              label: t("routes.logout"),
              onClick: Logout,
            },
          ]}
        >
          {/* <Menu.Item key={"truck"} icon={<BsTruck />}>
              <NavLink to={"/"}>Truck</NavLink>
            </Menu.Item> */}
        </Menu>
      </Sider>
    </div>
  );
}
