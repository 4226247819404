import React, { useEffect, useState } from 'react'
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from '../firebase';

const useGetAllMaintenanceServices = () => {
    const [maintenaceService, setMaintenanceService] = useState([])

    const getAllMaintenanceService = () => {
        const q = query(collection(db, "maintenance-services"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const cities = [];
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    cities.push(doc.data());
                });
            }
            setMaintenanceService(cities)
        });
        return () => unsubscribe()
    }

    useEffect(() => {
        getAllMaintenanceService()
    }, [])

    return { maintenaceService }
}

export default useGetAllMaintenanceServices
