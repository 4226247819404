// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot, where } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useTransports() {
//   const id = JSON.parse(localStorage.getItem("uid"));
//   const [customerRequests, setcustomerRequests] = useState([]);
//   const getcustomerRequests = async () => {
//     let q = query(
//       collection(db, "customer_transport_requests"),
//       where("customerId", "==", id)
//     );
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setcustomerRequests([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setcustomerRequests((prev) => [...prev, val.data()]);
//         });
//       }
//     });
//     return () => unsub();
//   };
//   useEffect(() => {
//     getcustomerRequests();
//   }, []);

//   return { customerRequests };
// }
// import { useQuery } from '@apollo/client';
// import { useEffect, useState } from 'react';
// import { GET_MYCUSTOMER_TRANSPORT_REQUEST } from '../../graphql/Mutation/customer/transportRequestMutation';

// export default function useCustomerRequests() {
//   const customerId = JSON.parse(localStorage.getItem("id"));
//   console.log("id useCustomerId here",customerId)
//   const [customerRequests, setCustomerRequests] = useState([]);
//   // const { loading, error, data } = useQuery(GET_MYCUSTOMER_TRANSPORT_REQUEST, {
//     const variables = {
//       customerId: customerId,
//       page: 1, 
//     };
//     console.log(variables)
//     const { loading, error, data } = useQuery(GET_MYCUSTOMER_TRANSPORT_REQUEST, {
//       variables: variables,
//     });
  
//     useEffect(() => {
//       if (!loading && data) {
//         setCustomerRequests(data.myRequests.requests);
//       }
//     }, [loading, data]);
  
//     return { customerRequests, loading, error };
//   };
  

import { useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";



import { GET_ALL_CUSTOMER_REQUEST } from "../../graphql/Mutation/customer/transportRequestMutation";

// ... other imports ...

function useCustomerRequests(trigger, paginationFilters) {
  const [allRequest, setAllRequest] = useState([]);
  const [count, setCount] = useState(0);
  // const ownerId = JSON.parse(localStorage.getItem("id"));
  // const customerId = JSON.parse(localStorage.getItem("id"));

  const { loading, error, data, refetch } = useQuery( GET_ALL_CUSTOMER_REQUEST, 
    {
      variables:{ page: paginationFilters?.pageNo },
      fetchPolicy: "network-only",
    }
  );

  const getAllRequest = useCallback(() => {  
    if (loading) return;
    if (error) {
      return;
    }
    const requestData = data.customerRequests;
    if (!requestData) {
      console.log("No driver data available.");
      return;
    }
    const { requests, totalDocs } = requestData;
    setAllRequest(requests);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getAllRequest();
    if (trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getAllRequest, paginationFilters?.pageNo, refetch]);
// console.log("ALLReQueste555",allRequest)
  return { loading, error, allRequest, count };
}

export default useCustomerRequests;
  