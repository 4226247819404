import React from "react";
import { useTranslation } from "react-i18next";
import SendRequestTable from "../../Tables/SendRequestTable";
export default function SendRequest() {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        <h3>{"Choose Transport For This Request"}</h3>
      </div>
      <div>
        <SendRequestTable />
      </div>
    </div>
  );
}
