import React, { useState, useEffect } from "react";
import AppRoutes from "./Routes/Routes";
import "antd/dist/antd.min.css";
import { ConfigProvider, notification } from "antd";
import { useSelector } from "react-redux/es/exports";

import "./App.less";
function App() {
  const { lng } = useSelector((state) => state.userReducer);


  return (
    <>
      <ConfigProvider direction={lng === "en" ? "ltr" : "rtl"}>
        <AppRoutes />
      </ConfigProvider>
    </>
  );
}

export default App;
