import React, { useEffect, useState } from "react";
import { collection, onSnapshot, where, query } from "firebase/firestore";
import { db } from "../firebase";

export default function useRelationInvoice() {
  const [relation, setRelation] = useState([]);

  const getData = async () => {
        const q = query(collection(db, "relations"));
        const unsub = onSnapshot(q, (querySnapShot) => {
          const data = [];
          querySnapShot.forEach((doc) => {
            data.push({...doc.data() });
          });
          setRelation(data);
        });
  
        return () => unsub();
      
    }
  useEffect(() => {
    getData();
  }, []);

  return { relation };
}