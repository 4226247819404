import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import OwnerPrivateRoute from "./OwnerPrivateRoute";
import CustomerPrivateRoute from "./CustomerPrivateRoute";

import Login from "../Components/Auth/Login";
import Signup from "../Components/Auth/Signup";
import OwnerRoutes from "./OwnerRoutes";
import CustomerRoutes from "./CustomerRoutes";
import { Landing } from "../Components/Landing/pages/Home";
import Aboutus from "../Components/AboutUs/Aboutus";
import FAQ from "../Components/FAQ/FAQ";
import SendEmail from "../Components/Landing/components/Home/SendingEmail/SendEmail";
import DriverPrivateRoutes from "./DriverPrivateRoutes";
import DriverRoutes from "./DriverRoutes";
import PriceList from "../Components/Landing/components/PriceListPage/PriceList";
export default function AppRoutes() {
  let uid = localStorage?.getItem("id");
  let type = localStorage?.getItem("userType");

  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route path="/price" element={<PriceList />} />
          <Route
            path="/home"
            element={
              uid !== null && type !== null && type === "Owner" ? (
                <Navigate to={"//fleet_manowneragment"} />
              ) : uid !== null && type !== null && type === "customer" ? (
                <Navigate to={"/customer/tarnsport_requests"} />
              ) : uid !== null && type !== null && type === "driver" ? (
                <Navigate to={"/driver/driverprofile"} />
              ) : (
                <Login />
              )
            }
          />
          <Route path="/signup" element={<Signup />} />
          <Route path="/about_us" element={<Aboutus />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/email" element={<SendEmail />} />
          <Route
            path="/owner/*"
            element={
              <OwnerPrivateRoute>
                <OwnerRoutes />
              </OwnerPrivateRoute>
            }
          />
          <Route
            path="/customer/*"
            element={
              <CustomerPrivateRoute>
                <CustomerRoutes />
              </CustomerPrivateRoute>
            }
          />
          <Route
            path="/driver/*"
            element={
              <DriverPrivateRoutes>
                <DriverRoutes />
              </DriverPrivateRoutes>
            }
          />
        </Routes>
      </Router>
    </>
  );
}
