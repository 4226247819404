import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Row,
  Col,
  Select,
  message,
  Space,
  Upload,
  Image,
  DatePicker,
  Switch,
  Tooltip,
  Popover,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import "./viewRequest.scss";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase";

import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import Loader from "../../Loaders/Loader";
import { MakeRequestAction } from "../../../Redux/Actions/DataActions";
import { useSelector, useDispatch } from "react-redux";
import { getPreciseDistance } from "geolib";
//Api
import { AddRequest, uploadImage } from "../../API/API";
import CustomAlert from "../../Modals/CustomAlert";
//hooks
import { useNavigate } from "react-router-dom";
import useCapacites from "../../Hooks/useCapacities";
import useCities from "../../Hooks/useCities";
import useHeights from "../../Hooks/useHeights";
import useTypes from "../../Hooks/useTypes";
import useCombinations from "../../Hooks/useCombinations";
import useUser from "../../Hooks/useUser";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ClearanceNewRequest from "../Clearance/ClearanceNewRequest";

import { useTranslation } from "react-i18next";
import moment from "moment";
import useRelation from "../../Hooks/useRelation";
import SeaFreightNewRequest from "../see-freight/SeaFreightNewRequest";

import useDestination from "../../Hooks/useDestination";
import useSource from "../../Hooks/useSource";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import useExtraCare from "../../Hooks/useExtraCare";
import useSaberPrice from "../../Hooks/useSaberPrice";
import useCustomerRequests from "../../Hooks/useCustomerRequests";
import useRelationData from "../../Hooks/useRelationsData";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_CUSTOMER_TRANSPORT_REQUEST } from "../../../graphql/Mutation/customer/transportRequestMutation";
import { ALL_SERVICES } from "../../../graphql/Mutation/customer/servicesQueries";
import { CREATE_CUSTOMER } from "../../../graphql/Mutation/adminMutation";
import { single_Customer_ONLY } from "../../../graphql/Mutation/customer/getSingleCustomer";

const { TextArea } = Input;
export default function NewRequest() {
  const ALLSERVICES = useQuery(ALL_SERVICES);
  // console.log(ALLSERVICES?.data, "allservices");
  const { extracare } = useExtraCare();
  const { saber } = useSaberPrice();
  const { relationsData, getRelations } = useRelationData();
  // console.log(getRelations?.data?.relations?.relations);
  const navigate = useNavigate();
  const { GetCombinations } = useCombinations();
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const fromValues = GetCombinations?.data?.allCombinations?.combinations.map(
    (obj) => obj.from
  );
  const tooValues = GetCombinations?.data?.allCombinations?.combinations.map(
    (obj) => obj.to
  );
  const { getSingleCustomer } = useUser();
  const relation = useRelation();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const { getCities } = useCities();
  const { capacities } = useCapacites();
  const { types } = useTypes();
  const { heights } = useHeights();
  const [type, setType] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [height, setHeight] = useState("");
  const [capacity, setCapacity] = useState("");
  const [time, setTime] = useState("");
  const [distance, setDistance] = useState("");
  const [price, setPrice] = useState("");
  const [commission, setCommission] = useState(1);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [showAreas, setShowAreas] = useState(false);
  const [extraCareChecked, setExtraCareChecked] = useState(false);
  const [saberPrice, setSaberPrice] = useState(false);
  const [originData, setOriginData] = useState(null);
  const [fromOriginData, setFromOriginData] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [quantity, setQuantity] = useState(1);

  //functions building start

  const handleQuantityChange = (e) => {
    const newQuantity = Number(e.target.value);
    setQuantity(newQuantity);
  };

  //filtered out the latest request data
  const { customerRequests } = useCustomerRequests();
  const filteredRequests = customerRequests
    ?.filter((req) => req.isSwitchOn === true)
    .sort((a, b) => b.createdAt - a.createdAt);
  const latestRequest =
    filteredRequests?.length > 0 ? filteredRequests[0] : null;
  //end

  // Fetch the from values for is relation users
  let uniqueExtractedFromValues = [];
  let uniqueExtractedToValues = [];
  const obj = relationsData[0];
  const dataArr = obj?.data;
  const extractedFromValues = dataArr?.map((item) => item.from);
  const extractedToValues = dataArr?.map((item) => item.to);
  uniqueExtractedFromValues = [...new Set(extractedFromValues)];
  uniqueExtractedToValues = [...new Set(extractedToValues)];
  //end

  //find matches value of to from relation data
  let relationToValues = [];
  let uniqueRelationToValues = [];
  if (uniqueExtractedFromValues.includes(from)) {
    for (const relationData of dataArr) {
      if (relationData.from === from) {
        relationToValues.push(relationData.to);
        uniqueRelationToValues = [...new Set(relationToValues)];
      }
    }
  }

  //end to

  //find matches value of type or capacity from relation data
  let relationTypeValues = [];
  let uniqueRelationTypeValues = [];
  if (
    uniqueExtractedFromValues.includes(from) &&
    uniqueExtractedToValues.includes(to)
  ) {
    for (const relationData of dataArr) {
      if (relationData.from === from && relationData.to === to) {
        relationTypeValues.push(relationData?.capacity);
        uniqueRelationTypeValues = [...new Set(relationTypeValues)];
      }
    }
  }

  //end type

  //find matches value of height or capacity from relation data
  // Initialize the arrays outside of the if condition
  let relationHeightValues = [];
  let uniqueRelationHeightValues = [];

  // Check the conditions and populate the arrays
  if (
    uniqueExtractedFromValues.includes(from) &&
    uniqueExtractedToValues.includes(to)
  ) {
    for (const relationData of dataArr) {
      if (relationData.from === from && relationData.to === to) {
        relationHeightValues.push(relationData.height);
      }
    }

    // Move the unique set creation outside the loop
    uniqueRelationHeightValues = [...new Set(relationHeightValues)];
  }

  //end height

  //find matches value of type or capacity from relation data
  let relationContainerValues = [];
  let uniqueRelationContainerValues = [];
  if (
    uniqueExtractedFromValues.includes(from) &&
    uniqueExtractedToValues.includes(to)
  ) {
    for (const relationData of dataArr) {
      if (relationData.from === from && relationData.to === to) {
        relationContainerValues.push(relationData.type);
        uniqueRelationContainerValues = [...new Set(relationContainerValues)];
      }
    }
  }
  //end type

  //seafrieght data
  const { destinationn } = useDestination();
  const { sourcce } = useSource();
  const [destination, setDestination] = useState("");
  const [source, setSource] = useState("");
  const id = JSON.parse(localStorage.getItem("id"));

  //clearnace localstorgae
  const C_id = JSON.parse(localStorage.getItem("id"));

  //clearance end

  const handleChange = (value) => {
    // console.log(`selected ${value}`);
  };

  //seafreight end

  //get cordinates from cities collection if to value == cities.city value
  useEffect(() => {
    const findOriginData = () => {
      const matchingCity = getCities?.data?.cities?.cities?.find(
        (city) => city.cityName === to?.cityName
      );

      if (matchingCity) {
        setOriginData(matchingCity.origin);
      } else {
        setOriginData(null);
      }
    };

    if (to) {
      findOriginData();
    }
  }, [to, getCities?.data?.cities?.cities]);

  //end cordinates fetch

  //get cordinates from cities collection if from value == cities.city value
  useEffect(() => {
    const findOriginData = () => {
      const matchingCity = getCities?.data?.cities?.cities?.find(
        (city) => city.cityName === from?.cityName
      );

      if (matchingCity) {
        setFromOriginData(matchingCity.origin);
      } else {
        setFromOriginData(null);
      }
    };

    if (from) {
      findOriginData();
    }
  }, [from, getCities?.data?.cities?.cities]);

  //end cordinates fetch

  const { makeRequestData } = useSelector((state) => state.DataReducer);

  //when user click on cancel button user get email
  const handleCancelClick = async () => {
    // console.log("handleCancelClick");
    const userEmail = localStorage.getItem("currentUserEmail");
    const currentTime = new Date();

    const querySnapshot = await getDocs(collection(db, "form-cancel-emails"));

    let emailExists = false;
    let existingDocId = null;

    querySnapshot.forEach((doc) => {
      if (doc.data().email === userEmail) {
        emailExists = true;
        existingDocId = doc.id;
      }
    });

    if (emailExists) {
      message.warning(`User email ${userEmail} is already added.`);
    } else {
      const ref = doc(collection(db, "form-cancel-emails"));

      try {
        const newDocRef = await addDoc(collection(db, "form-cancel-emails"), {
          email: userEmail,
          timestamp: currentTime,
          uid: ref.id, // Store the document ID as 'uid'
        });
        message.success(
          `User email ${userEmail} stored with document ID ${newDocRef.id}.`
        );
      } catch (error) {
        message.error("An error occurred while storing the email:", error);
      }
    }
    // if (existingDocId) {
    //   console.log(
    //     `User with email ${userEmail} clicked on cancel. Existing doc ID: ${existingDocId}`
    //   );
    // } else {
    //   console.log(`User with email ${userEmail} clicked on cancel.`);
    // }
    navigate(-1);
  };
  //end cancel click button

  //form onfinish function where we send data in collection and create new request
  // .................................................................................
  const [blUploadUrl, setBlUploadUrl] = useState(null);
  const [uploading, setUploading] = useState(false);
  const handleImageUpload1 = (file) => {
    if (!file) return;
    const imageRef = ref(storage, `CustomerRequest/${file.name}`);
    setUploading(true);
    uploadBytes(imageRef, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((url) => {
        setBlUploadUrl(url);
        console.log("Upload successful. URL:", url);
      })
      .catch((error) => {
        console.error("Error during image upload:", error);
      })
      .finally(() => {
        setUploading(false);
      });
  };
  const handleImageDelete = () => {
    setBlUploadUrl(null);
  };
  const customRequest = ({ file }) => {
    handleImageUpload1(file);
  };
  const handleChange1 = ({ fileList }) => {
    console.log(fileList);
  };
  // Function to handle image upload 2
  const [odUploadUrl, setOdUploadUrl] = useState(null);
  const [odUploading, setOdUploading] = useState(false);

  const handleImageUpload2 = (file) => {
    if (!file) return;
    const imageRef = ref(storage, `CustomerRequest/${file.name}`);
    setOdUploading(true);
    uploadBytes(imageRef, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((url) => {
        setOdUploadUrl(url);
        console.log("Upload successful. URL:", url);
      })
      .catch((error) => {
        console.error("Error during image upload:", error);
      })
      .finally(() => {
        setOdUploading(false);
      });
  };
  const handleImageDelete2 = () => {
    setOdUploadUrl(null);
  };
  const customRequest2 = ({ file }) => {
    handleImageUpload2(file);
  };
  const handleChange2 = ({ fileList }) => {
    console.log(fileList);
  };

  //  // Function to handle image upload 3
  const [iroUploadUrl, setIroUploadUrl] = useState(null);
  const [iroUploading, setIroUploading] = useState(false);

  const handleImageUpload3 = (file) => {
    if (!file) return;
    const imageRef = ref(storage, `CustomerRequest/${file.name}`);
    setIroUploading(true);
    uploadBytes(imageRef, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((url) => {
        setIroUploadUrl(url);
        console.log("Upload successful. URL:", url);
      })
      .catch((error) => {
        console.error("Error during image upload:", error);
      })
      .finally(() => {
        setIroUploading(false);
      });
  };
  const handleImageDelete3 = () => {
    setIroUploadUrl(null);
  };
  const customRequest3 = ({ file }) => {
    handleImageUpload3(file);
  };
  const handleChange3 = ({ fileList }) => {
    console.log(fileList);
  };
  //  // Function to handle image upload 4
  const [clearanceUploadUrl, setClearanceUploadUrl] = useState(null);
  const [clearanceUploading, setIClearanceUploading] = useState(false);

  const handleImageUpload4 = (file) => {
    if (!file) return;
    const imageRef = ref(storage, `CustomerRequest/${file.name}`);
    setIClearanceUploading(true);
    uploadBytes(imageRef, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((url) => {
        setClearanceUploadUrl(url);
        console.log("Upload successful. URL:", url);
      })
      .catch((error) => {
        console.error("Error during image upload:", error);
      })
      .finally(() => {
        setIClearanceUploading(false);
      });
  };
  const handleImageDelete4 = () => {
    setClearanceUploadUrl(null);
  };
  const customRequest4 = ({ file }) => {
    handleImageUpload4(file);
  };
  const handleChange4 = ({ fileList }) => {
    console.log(fileList);
  };

  const [createRequest] = useMutation(CREATE_CUSTOMER_TRANSPORT_REQUEST);
  const customerId = JSON.parse(localStorage.getItem("id"));
  const customerName = localStorage.getItem("name");

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const customerRequestInput = {
        blNumber: values?.blNumber,
        blUpload: blUploadUrl,
        customer: customerId,
        distance: distance,
        estimateTime: time,
        from: values?.from,
        height: values?.height[0],
        iroUpload: iroUploadUrl,
        location: null,
        odUpload: odUploadUrl,
        name: customerName,
        price: String(price),
        quantity: values?.quantity,
        receiverContact: values?.receiverContact,
        senderContact: values?.senderContact,
        serviceType: values?.serviceType,
        to: values?.to,
        type: values?.type,
        status: "created",

        billOfLandingClearance: values?.pilloflading,
        customerNameClearance: values?.cname,
        uploadClearance: clearanceUploadUrl,
        routeFromSeaFreight: values?.source,
        routeToSeaFreight: values?.destination,
        containerSizeSeaFreight: values?.containertype,
        containerQuantitySeaFreight: values?.quantity,
        containerWeightSeaFreight: values?.number,
        customerNameSeaFreight: values?.cname,
        customerEmailSeaFreight: values?.email,
        customerPhoneSeaFreight: values?.phone,
      };

      const { data } = await createRequest({
        variables: { customerRequestInput },
      });
      setLoading(false);
      if (data.createRequest.success) {
        // setLoading(false);
        // form.resetFields();
        // setCapacity("");
        // setHeight("");
        // setType("");
        // setFrom("");
        // setTo("");

        form.setFieldsValue({
          // from: "",
          // to: "",
          // senderContact: "",
          // receiverContact: "",
        });
        message.success("Add Successful!");
        setLoading(false);
        navigate(-1);
      } else {
        setLoading(false);
        message.error(data.createRequest.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in onFinish function:", error);
      console.error("Error in onFinish function:", error.message);
      // message.error(error.message);
    }
    // if (values.clearance !== undefined && values.clearance !== false) {
    //   let clearanceData = {
    //     cname: values.cname,
    //     pilloflading: values.pilloflading,
    //     files: values.files,
    //   };
    //   await handleClearance(clearanceData);
    // }
    // if (values.seaFreight) {
    //   let seaFreightData = {
    //     cname: values.cname,
    //     source: values.source,
    //     destination: values.destination,
    //     containertype: values.containertype,
    //     weight: values.weight,
    //     quantity: values.quantity,
    //     email: values.email,
    //     phone: values.phone,
    //   };
    //   await handleSeaFreight(seaFreightData);
    // }
  };

  // ....................................................................
  // ....................................................................

  // handle clearance method make new clearnace request
  const handleClearance = async (values) => {
    let attachments = [];
    // console.log("handleClearance");
    const { pilloflading, cname, files } = values;

    try {
      const ref = doc(collection(db, "clearance_request"));
      setLoading(true);
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const res = await uploadImage("clearance/", file);
          attachments.push(res);
        }
      }
      await setDoc(ref, {
        uid: ref.id,
        customerId: C_id,
        pilloflading,
        cname,
        attachments,
        status: "created",
      });
      form.resetFields();
      setUploadedFiles([]);
    } catch (error) {
      alert(error.message);
    }

    setLoading(false);
  };
  //end clearance
  // ..........................................................................
  // ..........................................................................

  //make new request for seafreight function
  const handleSeaFreight = async (values) => {
    // console.log("handelEaFreight");
    const { cname: customerName, ...rest } = values;
    try {
      const ref = doc(collection(db, "sea-freight"));
      setLoading(true);
      const data = {
        customerName,
        ...rest,
        status: "created",
        uid: ref.id,
        customerId: id,
      };
      await setDoc(ref, data);
      message.success("created!");
      // console.log("Success:", values);
      form.resetFields();
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };
  //end seafreight function

  //find matches value of to from combination collection
  let toValues = [];
  let uniqueToValues = [];
  if (fromValues?.includes(from.cityName)) {
    for (const combination of GetCombinations?.data?.allCombinations
      ?.combinations) {
      if (combination.from === from.cityName) {
        toValues?.push(combination.to);
        uniqueToValues = [...new Set(toValues)];
      }
    }
  }

  // console.log(uniqueToValues, "toValues");
  //end to

  //find capicity values if both from and to exit in
  let capacityValue = [];
  let uniqueCapacityValues = [];
  if (
    fromValues?.includes(from.cityName) &&
    tooValues?.includes(to?.cityName)
  ) {
    for (const combinationn of GetCombinations?.data?.allCombinations
      ?.combinations) {
      if (
        combinationn.from === from.cityName &&
        combinationn.to === to?.cityName
      ) {
        capacityValue?.push(combinationn?.capacity);
        uniqueCapacityValues = [...new Set(capacityValue)];
      }
    }
  }

  //end capacity

  //find height values if all three are true
  let heightValues = [];
  let uniqueHeightValues = [];
  if (
    fromValues?.includes(from.cityName) &&
    tooValues?.includes(to?.cityName)
  ) {
    for (const combination of GetCombinations?.data?.allCombinations
      ?.combinations) {
      if (
        combination.from === from.cityName &&
        combination.to === to?.cityName
      ) {
        heightValues.push(combination.height);
        uniqueHeightValues = [...new Set(heightValues)];
      }
    }
  }
  // console.log(uniqueHeightValues, "uniqueHeightValues");

  //height end

  // find type if all conditions matches
  let typeValues = [];
  let uniqueTypeValues = [];
  if (
    fromValues?.includes(from.cityName) &&
    tooValues?.includes(to?.cityName)
  ) {
    for (const combination of GetCombinations?.data?.allCombinations
      ?.combinations) {
      if (
        combination.from === from.cityName &&
        combination.to === to?.cityName
      ) {
        typeValues.push(combination.type);
        uniqueTypeValues = [...new Set(typeValues)];
      }
    }
  }
  //end type

  // get priice, distance and time function when form is fill
  const getDistanceTimePrice = () => {
    // console.log("get DistancePrice");
    if (
      getSingleCustomer?.data?.customer?.isRelation === true &&
      uniqueExtractedFromValues.length > 0
    ) {
      let v = dataArr.find(
        (v) =>
          v?.type == type &&
          v?.capacity == capacity &&
          v?.height == height &&
          v?.from == from &&
          v?.to == to
      );
      if (v !== undefined) {
        let updatedPrice = parseFloat(v.price);

        if (
          extraCareChecked &&
          typeof extracare[0]?.extracare === "number" &&
          !isNaN(extracare[0]?.extracare)
        ) {
          updatedPrice *= extracare[0].extracare;
        }
        const quantityNumber = quantity;
        if (!isNaN(quantityNumber) && quantityNumber > 1) {
          updatedPrice *= quantityNumber;
        }
        let SaberTotalPrice = 0;
        if (
          saberPrice &&
          typeof saber[0]?.saber === "number" &&
          !isNaN(saber[0]?.saber)
        ) {
          SaberTotalPrice = parseFloat(saber[0].saber);
        }
        updatedPrice += SaberTotalPrice;

        setDistance(v.distance);
        setTime(v.time);
        setPrice(updatedPrice);
        setCommission(v.commission);
      } else {
        setDistance("");
        setTime("");
        setPrice("");
        setCommission("");
        // alert("This combination not available please choose another combination");
      }
    } else {
      let v = GetCombinations?.data?.allCombinations?.combinations.find(
        (v) =>
          v?.type == type &&
          v?.capacity == capacity[0] &&
          v?.height == height[0] &&
          v?.from == from?.cityName &&
          v?.to == to?.cityName
      );

      if (v !== undefined) {
        let updatedPrice = parseFloat(v.price);

        if (
          extraCareChecked &&
          typeof extracare[0]?.extracare === "number" &&
          !isNaN(extracare[0]?.extracare)
        ) {
          updatedPrice *= extracare[0].extracare;
        }

        const quantityNumber = quantity;

        if (!isNaN(quantityNumber) && quantityNumber > 1) {
          updatedPrice *= quantityNumber;
        }
        let SaberTotalPrice = 0;

        if (
          saberPrice &&
          typeof saber[0]?.saber === "number" &&
          !isNaN(saber[0]?.saber)
        ) {
          SaberTotalPrice = parseFloat(saber[0].saber);
        }

        updatedPrice += SaberTotalPrice;

        setDistance(v.distance);
        setTime(v.estimateTime);
        setPrice(updatedPrice);
        setCommission(v.commission);
      } else {
        setDistance("");
        setTime("");
        setPrice("");
        setCommission("");
        // alert("This combination not available please choose another combination");
      }
    }
  };
  // console.log({ price, distance, time, commission });

  // end getdistancetimeprice function

  //call the values in useefffect to get price
  useEffect(() => {
    if (
      type !== "" &&
      height !== "" &&
      capacity !== "" &&
      from !== "" &&
      to !== ""
    ) {
      getDistanceTimePrice();
    }
  }, [
    type,
    height,
    capacity,
    from,
    to,
    extraCareChecked,
    saberPrice,
    extracare,
    saber,
    quantity,
  ]);

  //end here useeffect for price

  //this useeffect is used when user change from value then these value input show empty
  useEffect(() => {
    form.setFieldsValue({ to: null, capacity: null, height: null, type: null });
  }, [from]);
  // end the clear form useeffect

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };
  const [showClearance, setShowClearance] = useState(false);
  const [showSeaFreight, setShowSeaFreight] = useState(false);

  // clearnacedata check button
  const clearanceData = (e) => {
    setShowClearance(e.target.checked);
  };
  //end  checkbutton

  //seafreight check button value handle
  const seaFreightData = (e) => {
    setShowSeaFreight(e.target.checked);
  };
  // end seafreight checkbtn

  // simple input to upload multiple files
  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  //end

  // function for simple input when he upload multiple files
  const MAX_COUNT = 10;
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  //end multiple files upload function

  //delete selected files
  const handleFileDelete = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  };
  //end
  useEffect(() => {
    form.setFieldsValue({ files: uploadedFiles });
  }, [uploadedFiles]);

  //end
  const onChange = (checked) => {
    setIsSwitchOn(checked);
  };

  useEffect(() => {
    form.setFieldsValue(latestRequest);
  }, [latestRequest]);
  // console.log(types, "     dfghjkl");

  return (
    <div>
      {loading ? <Loader /> : ""}
      <div>
        <div
          className="newRequest"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h4>{t("Create a new request")}</h4>
          <label
            htmlFor=""
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <p>Save Data for Next</p>
            <Switch defaultChecked={isSwitchOn} onChange={onChange} />
          </label>
        </div>
        <Form form={form} name="make_request_form" onFinish={onFinish}>
          <Input.Group>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  className="inp-emai"
                  hasFeedback
                  name="from"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  {getSingleCustomer?.data?.customer?.isRelation === true &&
                  uniqueExtractedFromValues.length > 0 ? (
                    <Select
                      style={{ border: "2px solid #1890ff" }}
                      placeholder={t("placeholders.from")}
                      onChange={(v) => {
                        let value = uniqueExtractedFromValues[v];
                        form.setFieldsValue({ from: value });
                        setFrom(value);
                        dispatch(
                          MakeRequestAction({ ...makeRequestData, from: value })
                        );
                      }}
                    >
                      {uniqueExtractedFromValues &&
                        uniqueExtractedFromValues.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v}
                            </Option>
                          );
                        })}
                    </Select>
                  ) : (
                    <Select
                      style={{ border: "2px solid #1890ff" }}
                      placeholder={t("placeholders.from")}
                      onChange={(val) => {
                        let value = getCities?.data?.cities?.cities[val];
                        form.setFieldsValue({ from: value.cityName });
                        setFrom(value);
                        dispatch(
                          MakeRequestAction({ ...makeRequestData, from: value })
                        );
                      }}
                    >
                      {getCities?.data?.cities?.cities &&
                        getCities?.data?.cities?.cities?.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.cityName}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ border: "2px solid #1890ff" }}
                  hasFeedback
                  name="to"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <Select
                    ct
                    placeholder="To"
                    className="sign-ema"
                    onChange={(val) => {
                      let value = getCities?.data?.cities?.cities[val];
                      form.setFieldsValue({ to: value.cityName });
                      setTo(value);
                      dispatch(
                        MakeRequestAction({ ...makeRequestData, to: value })
                      );
                    }}
                  >
                    {getCities?.data?.cities?.cities &&
                      getCities?.data?.cities?.cities.map((v, i) => {
                        return (
                          <Option key={i} value={i}>
                            {v.cityName}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}></Col>
              {/* <Col span={2}>
                  <Switch checked={showAreas} onChange={setShowAreas} />
              </Col> */}
            </Row>
          </Input.Group>
          <Input.Group>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  className="inp-emai"
                  hasFeedback
                  name="senderContact"
                  style={{ border: "2px solid #1890ff" }}
                  onChange={(val) => {
                    form.setFieldsValue({
                      senderContact: val.target.value,
                    });

                    dispatch(
                      MakeRequestAction({
                        ...makeRequestData,
                        senderContact: val.target.value,
                      })
                    );
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <PhoneInput
                    country={"sa"}
                    placeholder={t("placeholders.senderContact")}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="sign-ema"
                  hasFeedback
                  style={{ border: "2px solid #1890ff" }}
                  name="receiverContact"
                  onChange={(val) => {
                    form.setFieldsValue({
                      receiverContact: val.target.value,
                    });
                    dispatch(
                      MakeRequestAction({
                        ...makeRequestData,
                        receiverContact: val.target.value,
                      })
                    );
                  }}
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <PhoneInput
                    country={"sa"}
                    placeholder={t("placeholders.receiverContact")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>

          <Input.Group>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  hasFeedback
                  name="serviceType"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  {getSingleCustomer?.data?.customer?.isRelation === true &&
                  uniqueRelationTypeValues.length > 0 ? (
                    <Select
                      style={{ border: "2px solid #1890ff" }}
                      className="inp-emai"
                      placeholder={t("placeholders.selectCapacity")}
                      onChange={(v) => {
                        let value = [v];
                        form.setFieldsValue({ capacity: value });
                        setCapacity(value);
                        dispatch(
                          MakeRequestAction({
                            ...makeRequestData,
                            capacity: value,
                          })
                        );
                      }}
                    >
                      {uniqueRelationTypeValues &&
                        uniqueRelationTypeValues.map((v, i) => {
                          return (
                            <Option key={i} value={v}>
                              {v?.capacity}
                            </Option>
                          );
                        })}
                    </Select>
                  ) : (
                    <Select
                      style={{ border: "2px solid #1890ff" }}
                      className="inp-emai"
                      placeholder={t("placeholders.selectCapacity")}
                      onChange={(v) => {
                        let value = [v];
                        form.setFieldsValue({ capacity: value });
                        setCapacity(value);
                        dispatch(
                          MakeRequestAction({
                            ...makeRequestData,
                            capacity: value,
                          })
                        );
                      }}
                    >
                      {uniqueCapacityValues &&
                        uniqueCapacityValues.map((v, i) => {
                          return (
                            <Option key={i} value={v}>
                              {v?.capacity}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ border: "2px solid #1890ff" }}
                  hasFeedback
                  name="height"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  {getSingleCustomer?.data?.customer?.isRelation == true &&
                  uniqueRelationHeightValues.length > 0 ? (
                    <Select
                      className="sign-ema"
                      placeholder={t("Select Height")}
                      onChange={(v) => {
                        let value = [v];
                        form.setFieldsValue({ height: value });
                        setHeight(value);
                        dispatch(
                          MakeRequestAction({
                            ...makeRequestData,
                            height: value,
                          })
                        );
                      }}
                    >
                      {uniqueRelationHeightValues &&
                        uniqueRelationHeightValues.map((v, i) => {
                          return (
                            <Option key={i} value={v}>
                              {v.height}
                            </Option>
                          );
                        })}
                    </Select>
                  ) : (
                    <Select
                      className="sign-ema"
                      placeholder={t("Select Height")}
                      onChange={(v) => {
                        let value = [v];
                        form.setFieldsValue({ height: value });
                        setHeight(value);
                        dispatch(
                          MakeRequestAction({
                            ...makeRequestData,
                            height: value,
                          })
                        );
                      }}
                    >
                      {uniqueHeightValues &&
                        uniqueHeightValues.map((v, i) => {
                          return (
                            <Option key={i} value={v}>
                              {v.height}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>

          <Input.Group>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  hasFeedback
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  {getSingleCustomer?.data?.customer?.isRelation === true &&
                  uniqueRelationContainerValues.length > 0 ? (
                    <Select
                      className="inp-emai"
                      style={{ border: "2px solid #1890ff" }}
                      placeholder={t("placeholders.selectType")}
                      onChange={(v) => {
                        let value = v;
                        form.setFieldsValue({ type: value });
                        setType(value);
                        dispatch(
                          MakeRequestAction({ ...makeRequestData, type: value })
                        );
                      }}
                    >
                      {uniqueRelationContainerValues &&
                        uniqueRelationContainerValues.map((v, i) => {
                          return (
                            <Option key={i} value={v}>
                              {v.type}
                            </Option>
                          );
                        })}
                    </Select>
                  ) : (
                    <Select
                      className="inp-emai"
                      style={{ border: "2px solid #1890ff" }}
                      placeholder={t("placeholders.selectType")}
                      onChange={(v) => {
                        let value = v;
                        form.setFieldsValue({ type: value });
                        setType(value);
                        dispatch(
                          MakeRequestAction({ ...makeRequestData, type: value })
                        );
                      }}
                    >
                      {uniqueTypeValues &&
                        uniqueTypeValues.map((v, i) => {
                          return (
                            <Option key={i} value={v}>
                              {v.type}
                            </Option>
                          );
                        })}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="quantity"
                  rules={[
                    {
                      required: true,
                      message: "required!",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    onChange={handleQuantityChange}
                    defaultValue={quantity}
                    min={1}
                    placeholder="Container Quantity"
                    style={{ width: "100%", border: "2px solid #1890ff" }}
                  />
                </Form.Item>

                {/* testing point here  */}
              </Col>
            </Row>
          </Input.Group>
          <Input.Group>
            <Row gutter={24}>
              <Col span={12}>
                <Row>
                  <Col span={12}>
                    <div className="bi-div">
                      <Form.Item
                        style={{ border: "2px solid #1890ff" }}
                        className="inp-emai"
                        hasFeedback
                        name="blNumber"
                        onChange={(val) => {
                          // console.log(val.target.value);
                          form.setFieldsValue({
                            blNumber: val.target.value,
                          });

                          dispatch(
                            MakeRequestAction({
                              ...makeRequestData,
                              blNumber: val.target.value,
                            })
                          );
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Requerd Field!",
                          },
                        ]}
                      >
                        <Input placeholder={t("placeholders.blNumber")} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      hasFeedback
                      className="inp-emai"
                      name="blNumberUpload"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Requerd Field!",
                      //   },
                      // ]}
                    >
                      <div
                        className="upload-img-div"
                        style={{
                          border: "2px solid #1890ff",
                          marginLeft: "10px",
                        }}
                      >
                        <Upload
                          customRequest={customRequest}
                          onChange={handleChange1}
                          fileList={
                            blUploadUrl
                              ? [
                                  {
                                    uid: "-1",
                                    name: "image.png",
                                    status: "done",
                                    url: blUploadUrl,
                                  },
                                ]
                              : []
                          }
                          showUploadList={{
                            showPreviewIcon: false,
                            showRemoveIcon: true,
                            removeIcon: (
                              <DeleteOutlined onClick={handleImageDelete} />
                            ),
                          }}
                        >
                          <Button icon={<UploadOutlined />} loading={uploading}>
                            BI Number
                          </Button>
                        </Upload>
                        {blUploadUrl && (
                          <div style={{ marginTop: "10px" }}>
                            <Image src={blUploadUrl} width={50} />
                          </div>
                        )}
                        {/* <Upload
                          className="upload-img"
                          listType="picture"
                          multiple={false}
                          maxCount={1}
                          onChange={(e) => {
                            if (e.file.status === "removed") {
                              form.setFieldsValue({
                                blNumberUpload: "",
                              });
                            } else {
                              form.setFieldsValue({
                                blNumberUpload: e.file.originFileObj,
                              });
                            }
                            dispatch(
                              MakeRequestAction({
                                ...makeRequestData,
                                blNumberUpload: e.file.originFileObj,
                              })
                            );
                          }}
                        >
                          <Button icon={<UploadOutlined />}>BI Number</Button>
                        </Upload> */}
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={12}>
                  {/* <Col span={7}>
                    <Form.Item
                      className="sign-ema"
                      hasFeedback
                      name="iroDate"
                      onChange={(val) => {
                        form.setFieldsValue({
                          iroDate: val.target.value,
                        });

                        dispatch(
                          MakeRequestAction({
                            ...makeRequestData,
                            iroDate: val.target.value,
                          })
                        );
                      }}
                      // onChange={(date, dateString) => {
                      //   form.setFieldsValue({
                      //     iroDate: date,
                      //   });
                      
                      //   dispatch(
                      //     MakeRequestAction({
                      //       ...makeRequestData,
                      //       iroDate: date,
                      //     })
                      //   );
                      // }}
                     
                    >
                      <DatePicker
                        style={{ border: "2px solid #1890ff" }}
                        inputReadOnly
                        placeholder="IRO"
                        className="dateiro"
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col> */}
                  <Col span={12}>
                    <Form.Item
                      style={{ border: "2px solid #1890ff" }}
                      hasFeedback
                      className="sign-ema"
                      name="odUpload"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Requerd Field!",
                      //   },
                      // ]}
                    >
                      {/* <Upload
                        listType="picture"
                        multiple={false}
                        maxCount={1}
                        onChange={(e) => {
                          if (e.file.status === "created") {
                            form.setFieldsValue({
                              odUpload: "",
                            });
                          } else {
                            form.setFieldsValue({
                              odUpload: e.file.originFileObj,
                            });
                          }
                          dispatch(
                            MakeRequestAction({
                              ...makeRequestData,
                              odUpload: e.file.originFileObj,
                            })
                          );
                        }}
                      >
                        <Button icon={<UploadOutlined />}>O/D</Button>
                      </Upload> */}
                      <Upload
                        customRequest={customRequest2}
                        onChange={handleChange2}
                        fileList={
                          odUploadUrl
                            ? [
                                {
                                  uid: "-1",
                                  name: "image.png",
                                  status: "done",
                                  url: odUploadUrl,
                                },
                              ]
                            : []
                        }
                        showUploadList={{
                          showPreviewIcon: false,
                          showRemoveIcon: true,
                          removeIcon: (
                            <DeleteOutlined onClick={handleImageDelete2} />
                          ),
                        }}
                      >
                        <Button icon={<UploadOutlined />} loading={odUploading}>
                          O/D
                        </Button>
                      </Upload>
                      {odUploadUrl && (
                        <div style={{ margin: "10px" }}>
                          <Image src={odUploadUrl} width={50} />
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      style={{ border: "2px solid #1890ff" }}
                      hasFeedback
                      className="sign-ema"
                      name="iroUpload"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Requerd Field!",
                      //   },
                      // ]}
                    >
                      <Upload
                        customRequest={customRequest3}
                        onChange={handleChange3}
                        fileList={
                          iroUploadUrl
                            ? [
                                {
                                  uid: "-1",
                                  name: "image.png",
                                  status: "done",
                                  url: iroUploadUrl,
                                },
                              ]
                            : []
                        }
                        showUploadList={{
                          showPreviewIcon: false,
                          showRemoveIcon: true,
                          removeIcon: (
                            <DeleteOutlined onClick={handleImageDelete3} />
                          ),
                        }}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          loading={iroUploading}
                        >
                          IRO
                        </Button>
                      </Upload>
                      {iroUploadUrl && (
                        <div style={{ margin: "10px" }}>
                          <Image src={iroUploadUrl} width={50} />
                        </div>
                      )}
                      {/* <Upload
                        listType="picture"
                        multiple={false}
                        maxCount={1}
                        onChange={(e) => {
                          if (e.file.status === "removed") {
                            form.setFieldsValue({
                              iroUpload: "",
                            });
                          } else {
                            form.setFieldsValue({
                              iroUpload: e.file.originFileObj,
                            });
                          }
                          dispatch(
                            MakeRequestAction({
                              ...makeRequestData,
                              iroUpload: e.file.originFileObj,
                            })
                          );
                        }}
                      >
                        <Button icon={<UploadOutlined />}>IRO</Button>
                      </Upload> */}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Input.Group>
          <Input.Group>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  className="inp-emai"
                  // label={t("Modal.estimateTime")}
                  // hasFeedback
                  // name="time"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Requerd Field!",
                  //   },
                  // ]}
                >
                  <Input
                    value={time}
                    disabled
                    className="inp-emai"
                    style={{ border: "2px solid #1890ff" }}
                    placeholder={t("placeholders.estimateTime")}
                    addonAfter=" Day"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  className="sign-ema"
                  label={t("Modal.distance")}
                  hasFeedback
                  // name="distance"
                  rules={[
                    {
                      // required: true,
                      // message: "Requerd Field!",
                    },
                  ]}
                >
                  <Input
                    value={distance}
                    disabled
                    style={{ border: "2px solid #1890ff" }}
                    placeholder={t("placeholders.distance")}
                    addonAfter="KM"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Input.Group>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  className="inp-emai"
                  label={t("Modal.price")}
                  hasFeedback
                  name="price"
                  rules={[
                    {
                      // required: true,
                      // message: "Requerd Field!",
                    },
                  ]}
                >
                  <Input
                    className="inp-emai"
                    value={price}
                    disabled
                    style={{ border: "2px solid #1890ff" }}
                    placeholder={t("placeholders.price")}
                    addonAfter="SAR and VAT Included"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item className="inp-emai">
                  <Checkbox
                    checked={extraCareChecked}
                    onChange={(e) => setExtraCareChecked(e.target.checked)}
                  >
                    Extra Care
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item className="inp-emai">
                  <Checkbox
                    checked={saberPrice}
                    onChange={(e) => setSaberPrice(e.target.checked)}
                  >
                    Saber
                  </Checkbox>
                </Form.Item>
              </Col>

              {getSingleCustomer?.data?.customer?.isRelation === true &&
                relation?.customerId && (
                  <Col span={12}>
                    <Form.Item
                      hasFeedback
                      name="specific"
                      rules={[
                        {
                          // required: true,
                          message: "Requerd Field!",
                        },
                      ]}
                    >
                      <Select
                        style={{ border: "2px solid #1890ff" }}
                        className="inp-emai"
                        mode="multiple"
                        placeholder={"Send Your request to specific owners"}
                      >
                        {relation?.owners?.map((v) => {
                          return (
                            <Option key={v.ownerId} value={v.ownerId}>
                              {v.ownerName}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
            </Row>
          </Input.Group>
          <Form.Item>
            <Row gutter={12}>
              <Col span={6}>
                <Form.Item className="sign-ema" name="clearance" hasFeedback>
                  <Checkbox
                    onChange={(val) => {
                      form.setFieldsValue({ clearance: val.target.checked });
                      clearanceData(val);
                    }}
                    name="clearance"
                  >
                    Clearance
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item className="sign-ema" name="seaFreight" hasFeedback>
                  <Checkbox
                    onChange={(val) => {
                      form.setFieldsValue({ seaFreight: val.target.checked });
                      seaFreightData(val);
                    }}
                    name="seaFreight"
                  >
                    Sea Freight
                  </Checkbox>
                </Form.Item>
              </Col>
              {showClearance && (
                <Col span={24}>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        name="pilloflading"
                        style={{ border: "2px solid #1890ff" }}
                        rules={[
                          {
                            required: true,
                            message: "input required",
                          },
                        ]}
                      >
                        <Input placeholder="Bill Of Lading" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="cname"
                        style={{ border: "2px solid #1890ff" }}
                        rules={[
                          {
                            required: true,
                            message: "input required",
                          },
                        ]}
                      >
                        <Input placeholder="Customer Name" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        style={{ border: "2px solid #1890ff" }}
                        // name="files"
                        name="uploadClearance"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => e && e.fileList}
                        // rules={[
                        //   {
                        //     required: true,
                        //     message: "Please upload at least one file",
                        //   },
                        // ]}
                      >
                        <Upload
                          customRequest={customRequest4}
                          onChange={handleChange4}
                          fileList={
                            clearanceUploadUrl
                              ? [
                                  {
                                    uid: "-1",
                                    name: "image.png",
                                    status: "done",
                                    url: clearanceUploadUrl,
                                  },
                                ]
                              : []
                          }
                          showUploadList={{
                            showPreviewIcon: false,
                            showRemoveIcon: true,
                            removeIcon: (
                              <DeleteOutlined onClick={handleImageDelete4} />
                            ),
                          }}
                        >
                          <Button
                            icon={<UploadOutlined />}
                            loading={clearanceUploading}
                          >
                            UPLOAD
                          </Button>
                        </Upload>
                        {clearanceUploadUrl && (
                          <div style={{ margin: "10px" }}>
                            <Image src={clearanceUploadUrl} width={50} />
                          </div>
                        )}

                        {/* <label
                          htmlFor="fileUpload"
                          style={{
                            border: "2px solid rgb(24, 144, 255)",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "1.1rem",
                            color: "d9d9d9",
                            margin: "10px",
                          }}
                        >
                          <UploadOutlined
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "5px",
                            }}
                          />
                          Upload
                          <input
                            id="fileUpload"
                            type="file"
                            className="upload-img"
                            // name=""
                            multiple
                            accept="image/*,.doc,.docx,.pdf"
                            onChange={handleFileEvent}
                            disabled={fileLimit}
                            style={{ display: "none" }}
                          />
                        </label>
                        <div className="uploaded-files-list">
                          {uploadedFiles.map((file, index) => (
                            <div
                              style={{
                                border: "1px solid red",
                                margin: "10px",
                              }}
                            >
                              <div>{file.name}</div>
                              <img
                                src={URL.createObjectURL(file)}
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "5px",
                                  margin: "5px",
                                }}
                                alt={file.name}
                              />
                              <button
                                onClick={() => handleFileDelete(index)}
                                style={{
                                  display: "flex",
                                  float: "right",
                                  border: "none",
                                  color: "red",
                                  background: "transparent",
                                  alignContent: "center",
                                  textAlign: "center",
                                  marginRight: "20px",
                                  fontSize: "1.4rem",
                                  cursor: "pointer",
                                }}
                              >
                                <DeleteOutlined />
                              </button>
                            </div>
                          ))}
                        </div> */}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
              {showSeaFreight && (
                <Col span={24}>
                  <h5 style={{ fontWeight: "600" }}>Route </h5>
                  <Row gutter={24}>
                    <Col span={12}>
                      <Form.Item
                        hasFeedback
                        name="source"
                        rules={[
                          {
                            required: true,
                            message: "Requerd Field!",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%", border: "2px solid #1890ff" }}
                          onChange={(val) => {
                            let value = sourcce[val];
                            form.setFieldsValue({ source: value.source });
                            setSource(value);
                          }}
                          placeholder="Source"
                        >
                          {sourcce &&
                            sourcce.map((v, i) => {
                              return (
                                <Option key={i} value={i}>
                                  {v.source}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        hasFeedback
                        name="destination"
                        rules={[
                          {
                            required: true,
                            message: "Requerd Field!",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%", border: "2px solid #1890ff" }}
                          onChange={(val) => {
                            let value = destinationn[val];
                            form.setFieldsValue({
                              destination: value.destination,
                            });
                            setDestination(value);
                          }}
                          placeholder="Destination"
                        >
                          {destinationn &&
                            destinationn.map((v, i) => {
                              return (
                                <Option key={i} value={i}>
                                  {v.destination}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <h5 style={{ marginTop: ".7rem", fontWeight: "600" }}>
                    Container{" "}
                  </h5>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        hasFeedback
                        name="containertype"
                        rules={[
                          {
                            required: true,
                            message: "Requerd Field!",
                          },
                        ]}
                      >
                        <Select
                          style={{ width: "100%", border: "2px solid #1890ff" }}
                          onChange={handleChange}
                          placeholder="Select Type"
                          options={[
                            {
                              value: "small",
                              label: "Small",
                            },
                            {
                              value: "medium",
                              label: "Medium",
                            },
                            {
                              value: "large",
                              label: "Large",
                            },
                          ]}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="quantity"
                        rules={[
                          {
                            required: true,
                            message: "required!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          defaultValue={1}
                          min={1}
                          placeholder="Container Quantity"
                          style={{ width: "100%", border: "2px solid #1890ff" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="weight"
                        rules={[
                          {
                            required: true,
                            message: "required!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Container Weight (kg)"
                          style={{
                            border: "2px solid #1890ff",
                            padding: ".3rem ",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <h5 style={{ marginTop: ".7rem", fontWeight: "600" }}>
                    Customer Information
                  </h5>
                  <Row gutter={24}>
                    <Col span={8}>
                      <Form.Item
                        name="cname"
                        rules={[
                          {
                            required: true,
                            message: "required!",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          placeholder="Customer Name"
                          style={{
                            border: "2px solid #1890ff",
                            padding: ".3rem ",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "required!",
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          placeholder="Customer Email"
                          style={{
                            border: "2px solid #1890ff",
                            padding: ".3rem ",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: "required!",
                          },
                        ]}
                      >
                        <Input
                          type="number"
                          placeholder="Customer Phone"
                          style={{
                            border: "2px solid #1890ff",
                            padding: ".3rem ",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            <Space>
              <Button className="make-btn" type="primary" htmlType="submit">
                <span>{t("Submit")}</span>
              </Button>
              <Button onClick={handleCancelClick}>{"Cancel"}</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
