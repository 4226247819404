import React from "react";
import { Tabs } from "antd";
import DriverManagmentTable from "../../Tables/DriverManagmentTable";
import TruckManagmentTable from "../../Tables/TruckManagmentTable";
import TruckDriverTable from "../../Tables/TruckDriverTable";
import { useTranslation } from "react-i18next";
export default function FleetManagment() {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  return (
    <div>
      <div className="inp-emai">
        <h3>{t("routes.fleet_managment")}</h3>
      </div>
      <div className="inp-emai">
        <Tabs defaultActiveKey="1">
          <TabPane tab={t("tableTabs.driver")} key="1">
            <DriverManagmentTable />
          </TabPane>
          <TabPane tab={t("tableTabs.truck")} key="2">
            <TruckManagmentTable />
          </TabPane>
          <TabPane tab={t("Truck And Driver")} key="3">
            <TruckDriverTable />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
