import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Row,
  Col,
  Select,
  message,
  Space,
  Upload,
  DatePicker,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Loader from "../Loaders/Loader";
import { MakeRequestAction } from "../.././Redux/Actions/DataActions";
import { useSelector, useDispatch } from "react-redux";
// import { getPreciseDistance } from "geolib";
//Api
import { AddRequest, uploadImage } from "../API/API";

//hooks
import useCapacites from "../Hooks/useCapacities";
import useCities from "../Hooks/useCities";
import useHeights from "../Hooks/useHeights";
import useTypes from "../Hooks/useTypes";
import useCombinations from "../Hooks/useCombinations";
import useUser from "../Hooks/useUser";
import { useTranslation } from "react-i18next";
import moment from "moment";

const { TextArea } = Input;
export default function MakeRequestModal({
  isModalVisible,
  setIsModalVisible,
}) {
  const { combinations } = useCombinations();
  const { user } = useUser();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const { cities } = useCities();
  const { capacities } = useCapacites();
  const { types } = useTypes();
  const { heights } = useHeights();
  const [type, setType] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [height, setHeight] = useState("");
  const [capacity, setCapacity] = useState("");
  const [time, setTime] = useState("");
  const [distance, setDistance] = useState("");
  const [price, setPrice] = useState("");
  const [commission, setCommission] = useState(1);
  const [isOfPmnt, setIsOfPmnt] = useState(true);
  const [isOnPmnt, setIsOnPmnt] = useState(true);
  const [paymentEnabled, setPaymentEnabled] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { makeRequestData } = useSelector((state) => state.DataReducer);
  // console.log(makeRequestData);
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  const onFinish = async (values) => {
    if (price !== "" && distance !== "" && time !== "" && commission !== "") {
      let blNumberUpload = "";
      let iroUpload = "";
      setLoading(true);
      if (values.blNumberUpload !== "") {
        await uploadImage("request/", values.blNumberUpload).then((res) => {
          blNumberUpload = res;
        });
      }
      if (values.iroUpload !== "") {
        await uploadImage("request/", values.iroUpload).then((res) => {
          iroUpload = res;
        });
      }

      let data = {
        ...values,
        iroDate: values.iroDate._d,
        coordinates: {
          from: from.origin,
          to: to.origin,
        },
        name: user.name,
        phoneNumber: user.phoneNumber,
        distance: distance,
        time: time,
        price: price,
        iroUpload: iroUpload ? iroUpload : "",
        blNumberUpload: blNumberUpload ? blNumberUpload : "",
        commission: commission,
        status: "pending",
      };

      AddRequest({ ...data })
        .then(() => {
          setLoading(false);
          form.resetFields();
          setCapacity("");
          setHeight("");
          setType("");
          setFrom("");
          setTo("");

          handleCancel();
          message.success("Add Successull!");
          setPaymentEnabled(true);
        })
        .catch((e) => {
          setLoading(false);
          message.error(e.code);
        });
    } else {
      alert("This combination not available plaese choose another combination");
    }
  };

  const getDistanceTimePrice = () => {
    let v = combinations.find(
      (v) =>
        v.type === type?.type &&
        v.capacity === capacity?.capacity &&
        v.height === height?.height &&
        v.from === from?.city &&
        v.to === to?.city
    );

    if (v !== undefined) {
      setDistance(v.distance);
      setTime(v.time);
      setPrice(v.price);
      setCommission(v.commission);

      // dispatch(
      //   MakeRequestAction({
      //     ...makeRequestData,
      //     price: v.price,
      //     commission: v.commission,
      //     time: v.time,
      //     distance: v.distance,
      //   })
      // );
    } else {
      setDistance("");
      setTime("");
      setPrice("");
      setCommission("");
      alert("This combination not available please choose another combination");
    }
  };
  //handle ofline payment
  const handleOflinePayment = () => {
    setPaymentEnabled(false);
    setIsOfPmnt(false);
    setIsOnPmnt(true);
  };
  const handleOnlinePayment = () => {
    setPaymentEnabled(false);
    setIsOfPmnt(true);
    setIsOnPmnt(false);
  };
  useEffect(() => {
    if (
      type !== "" &&
      height !== "" &&
      capacity !== "" &&
      from !== "" &&
      to !== ""
    ) {
      getDistanceTimePrice();
    }
  }, [type, height, capacity, from, to]);
  useEffect(() => {
    if (Object.keys(makeRequestData).length > 0) {
      setFrom(makeRequestData.from);
      setCapacity(makeRequestData.capacity);
      setHeight(makeRequestData.height);
      setTo(makeRequestData.to);
      // setType(makeRequestData.type);

      form.setFieldsValue({ from: makeRequestData.from?.city });
      // form.setFieldsValue({ to: makeRequestData.to.city });
      form.setFieldsValue({ capacity: makeRequestData?.capacity?.capacity });
      form.setFieldsValue({ height: makeRequestData?.height?.height });
      // form.setFieldsValue({ type: makeRequestData.type.type });
      form.setFieldsValue({
        senderContact: makeRequestData.senderContact,
      });
      form.setFieldsValue({
        receiverContact: makeRequestData.receiverContact,
      });
      form.setFieldsValue({
        blNumber: makeRequestData.blNumber,
      });

      form.setFieldsValue({
        iroDate: moment(makeRequestData.iroDate),
      });
    }
  }, []);

  return (
    <div>
      <Modal
        title={t("Modal.makeNewRequest")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
        
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form form={form} name="make_request_form" onFinish={onFinish}>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    className="inp-emai"
                    hasFeedback
                    name="from"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      placeholder={t("placeholders.from")}
                      onChange={(val) => {
                        let value = cities[val];
                        form.setFieldsValue({ from: value.city });
                        setFrom(value);
                        dispatch(
                          MakeRequestAction({ ...makeRequestData, from: value })
                        );
                      }}
                    >
                      {cities &&
                        cities.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.city}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="to"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      className="sign-ema"
                      placeholder={t("placeholders.to")}
                      onChange={(val) => {
                        let value = cities[val];
                        form.setFieldsValue({ to: value.city });
                        setTo(value);
                        dispatch(
                          MakeRequestAction({ ...makeRequestData, to: value })
                        );
                      }}
                    >
                      {cities &&
                        cities.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.city}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    className="inp-emai"
                    hasFeedback
                    name="senderContact"
                    onChange={(val) => {
                      // console.log(val.target.value);
                      // let value = val;
                      form.setFieldsValue({
                        senderContact: val.target.value,
                      });

                      dispatch(
                        MakeRequestAction({
                          ...makeRequestData,
                          senderContact: val.target.value,
                        })
                      );
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <TextArea placeholder={t("placeholders.senderContact")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="sign-ema"
                    hasFeedback
                    name="receiverContact"
                    onChange={(val) => {
                      form.setFieldsValue({
                        receiverContact: val.target.value,
                      });
                      dispatch(
                        MakeRequestAction({
                          ...makeRequestData,
                          receiverContact: val.target.value,
                        })
                      );
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <TextArea placeholder={t("placeholders.receiverContact")} />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="capacity"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      className="inp-emai"
                      placeholder={t("placeholders.selectCapacity")}
                      onChange={(val) => {
                        let value = capacities[val];
                        form.setFieldsValue({ capacity: value.capacity });
                        setCapacity(value);
                        dispatch(
                          MakeRequestAction({
                            ...makeRequestData,
                            capacity: value,
                          })
                        );
                      }}
                    >
                      {capacities &&
                        capacities.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.capacity}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="height"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      className="sign-ema"
                      placeholder={t("Select Height")}
                      onChange={(val) => {
                        let value = heights[val];
                        form.setFieldsValue({ height: value.height });
                        setHeight(value);
                        dispatch(
                          MakeRequestAction({
                            ...makeRequestData,
                            height: value,
                          })
                        );
                      }}
                    >
                      {heights &&
                        heights.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.height}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    hasFeedback
                    name="type"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      className="inp-emai"
                      placeholder={t("placeholders.selectType")}
                      onChange={(val) => {
                        let value = types[val];
                        form.setFieldsValue({ type: value.type });
                        setType(value);
                        dispatch(
                          MakeRequestAction({ ...makeRequestData, type: value })
                        );
                      }}
                    >
                      {types &&
                        types.map((v, i) => {
                          return (
                            <Option key={i} value={i}>
                              {v.type}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <div className="bi-div">
                        <Form.Item
                          className="inp-emai"
                          hasFeedback
                          name="blNumber"
                          onChange={(val) => {
                            // console.log(val.target.value);
                            form.setFieldsValue({
                              blNumber: val.target.value,
                            });

                            dispatch(
                              MakeRequestAction({
                                ...makeRequestData,
                                blNumber: val.target.value,
                              })
                            );
                          }}
                          rules={[
                            {
                              required: true,
                              message: "Requerd Field!",
                            },
                          ]}
                        >
                          <Input placeholder={t("placeholders.blNumber")} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        hasFeedback
                        className="inp-emai"
                        name="blNumberUpload"
                        rules={[
                          {
                            required: true,
                            message: "Requerd Field!",
                          },
                        ]}
                      >
                        <div className="upload-img-div">
                          <Upload
                            className="upload-img"
                            listType="picture"
                            multiple={false}
                            maxCount={1}
                            onChange={(e) => {
                              if (e.file.status === "removed") {
                                form.setFieldsValue({
                                  blNumberUpload: "",
                                });
                              } else {
                                form.setFieldsValue({
                                  blNumberUpload: e.file.originFileObj,
                                });
                              }
                              dispatch(
                                MakeRequestAction({
                                  ...makeRequestData,
                                  blNumberUpload: e.file.originFileObj,
                                })
                              );
                            }}
                          >
                            <Button icon={<UploadOutlined />}>
                              {t("placeholders.upload")}
                            </Button>
                          </Upload>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        className="sign-ema"
                        hasFeedback
                        name="iroDate"
                        onChange={(val) => {
                          form.setFieldsValue({
                            iroDate: val.target.value,
                          });

                          dispatch(
                            MakeRequestAction({
                              ...makeRequestData,
                              iroDate: val.target.value,
                            })
                          );
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Requerd Field!",
                          },
                        ]}
                      >
                        <DatePicker
                          inputReadOnly
                          placeholder="IRO"
                          className="sign-ema"
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        hasFeedback
                        className="sign-ema"
                        name="iroUpload"
                        rules={[
                          {
                            required: true,
                            message: "Requerd Field!",
                          },
                        ]}
                      >
                        <Upload
                          listType="picture"
                          multiple={false}
                          maxCount={1}
                          onChange={(e) => {
                            if (e.file.status === "removed") {
                              form.setFieldsValue({
                                iroUpload: "",
                              });
                            } else {
                              form.setFieldsValue({
                                iroUpload: e.file.originFileObj,
                              });
                            }
                            dispatch(
                              MakeRequestAction({
                                ...makeRequestData,
                                iroUpload: e.file.originFileObj,
                              })
                            );
                          }}
                        >
                          <Button>{t("placeholders.upload")}</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    className="inp-emai"
                    label={t("Modal.estimateTime")}
                    // hasFeedback
                    // name="time"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Requerd Field!",
                    //   },
                    // ]}
                  >
                    <Input
                      value={time}
                      disabled
                      className="inp-emai"
                      placeholder={t("placeholders.estimateTime")}
                      addonAfter=" Day"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="sign-ema"
                    label={t("Modal.distance")}
                    // hasFeedback
                    // name="distance"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Requerd Field!",
                    //   },
                    // ]}
                  >
                    <Input
                      value={distance}
                      disabled
                      placeholder={t("placeholders.distance")}
                      addonAfter="KM"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    className="inp-emai"
                    label={t("Modal.price")}
                    // hasFeedback
                    // name="price"

                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Requerd Field!",
                    //   },
                    // ]}
                  >
                    <Input
                      className="inp-emai"
                      value={price}
                      disabled
                      placeholder={t("placeholders.price")}
                      addonAfter="SRA"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Form.Item>
              <Space size={user?.proVerified ? 150 : 280}>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={paymentEnabled}
                >
                  {t("Modal.make")}
                </Button>
                <Space className="btn-payments ">
                  {user?.proVerified && (
                    <button
                      className="btn-green"
                      // type="primary"
                      onClick={handleOflinePayment}
                      style={{
                        backgroundColor: `${!isOfPmnt ? "green" : ""}`,
                        border: "none",
                      }}
                    >
                      {t("Modal.oflinePayment")}
                    </button>
                  )}
                  <button
                    className="btn-green"
                    onClick={handleOnlinePayment}
                    style={{
                      backgroundColor: `${!isOnPmnt ? "green" : ""}`,
                      border: "none",
                    }}
                  >
                    {t("Modal.onlinePayment")}
                  </button>
                </Space>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
