// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot, where } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useDrivers() {
//   const id = JSON.parse(localStorage.getItem("uid"));
//   const [drivers, setDrivers] = useState([]);
//   const getDrivers = async () => {
//     let q = query(collection(db, "drivers"), where("ownerId", "==", id));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setDrivers([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setDrivers((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getDrivers();
//   }, []);

//   return { drivers };
// }


// import { useEffect, useState } from "react";
// import { useQuery } from "@apollo/client";
// import { MY_DRIVERS } from "../../graphql/Mutation/owner/addDriverMutation";

// export default function useDrivers() {
//   const [drivers, setDrivers] = useState([]);
//   const { data, loading, error } = useQuery(MY_DRIVERS, {
//     variables: {
//       page: 1,
//     },
//   });

//   useEffect(() => {
//     if (!loading && !error) {
//       setDrivers(data?.allDrivers?.drivers);
//     }
//   }, [data, loading, error]);
//   return { drivers };
// }

import { useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";

import { MY_DRIVERS } from "../../graphql/Mutation/owner/addDriverMutation";


function useDrivers(trigger, paginationFilters) {
  const [drivers, setDrivers] = useState([]);
  const [count, setCount] = useState(0);
  const ownerId = JSON.parse(localStorage.getItem("id"));

  const { loading, error, data, refetch } = useQuery( MY_DRIVERS, 
    {
      variables:{ page: paginationFilters?.pageNo, ownerId },
      fetchPolicy: "network-only",
    }
  );

  const getDrivers = useCallback(() => {  
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    // console.log(getDrivers)
    const driversData = data.myDrivers;
    if (!driversData) {
      console.log("No driver data available.");
      return;
    }
    const { drivers, totalDocs } = driversData;
    setDrivers(drivers);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getDrivers();
    if (!trigger) {
      refetch({ variables: { page: paginationFilters?.pageNo } });
    }
  }, [trigger, getDrivers, paginationFilters?.pageNo, refetch]);
  
console.log(drivers)
  return { loading, error, drivers, count, refetch };
}

export default useDrivers;
