import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../firebase";
import { GET_ALL_Combinations } from "../../graphql/queries/combinations/getAllCombinations";
import { useQuery } from "@apollo/client";
export default function useCombinations() {
  const [combinations, setcombinations] = useState([]);
  const getData = async () => {
    let q = query(collection(db, "combinations"), orderBy("createdAt", "desc"));
    const unsub = onSnapshot(q, (querySnapShot) => {
      setcombinations([]);
      if (!querySnapShot.empty) {
        querySnapShot.forEach((val) => {
          setcombinations((prev) => [...prev, val.data()]);
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getData();
  }, []);

  const GetCombinations = useQuery(GET_ALL_Combinations, {
    variables: {
      page: 1,
    },
  });

  return { combinations, GetCombinations };
}
