import React from "react";
import "./sendemail.css";
import { Container, Col, Row, Form } from "react-bootstrap";
function SendEmail() {
  return (
    <>
      <form className="container lg-6 w-50  from-div">
        <div class="  form-group emaildata   mt-5 py-1">
          <h4>New Message</h4>
        </div>
        <input type="email" className="inp" placeholder="To" />
        <input
          type="text"
          name=""
          id=""
          placeholder="Subject"
          className="inp"
        />
        <textarea name="" id="" className="inp py-5"></textarea>
        <button type="button" class="btn btn-primary">
          Sending
        </button>
      </form>
    </>
  );
}

export default SendEmail;
