import React from "react";
import "./style.css";
import track from "../../../assets/home/track.jpg";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const ReadyPicSwagger = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="mainReadyPicSwaggerDiv">
        <div className="container" data-aos="zoom-in" data-aos-duration="3000">
          <div class="mainReadyPicSwaggerSubDiv row">
            <div className="ReadyPicSwaggerHeading col-sm-5 col-md-5 col-lg-8">
              <h1 className="white_color">{t("OAC EXPRESS")}</h1>

              <p>
                {t(
                  "Introducing OAC EXPRESS, an online platform that matches carriers with shippers. Cost efficiencies, flexibility, savings and best of all no more backload losses. Now, let’s check out how customers get benefited from an online truck booking Service. We are your trusted partner in your digital transformation journey.Our online truck booking app and online portal will allow you to book truck online anywhere, anytime."
                )}
              </p>
            </div>
            <div className="ReadyPicSwaggerImg col-sm-12 col-md-7 col-lg-7">
              {/* <img className="ready-pic float-end" src={track} alt="" /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
