// import React, { useState, useEffect } from "react";
// import { Table, Button, Input, Popconfirm, Space, Select, Tag } from "antd";

// import {
//   PlusCircleOutlined,
//   SearchOutlined,
//   UploadOutlined,
//   DeleteOutlined,
//   EditOutlined,
// } from "@ant-design/icons";
// import AddTruckDriverModal from "../Modals/AddTruckDriverModal";
// import useTransports from "../Hooks/useTransport";
// import { DeleteTruckDriver } from "../API/API";
// import useOwnerRequests from "../Hooks/useOwnerRequests";
// import useGetAllRequests from "../Hooks/useGetAllRequests";
// import { useTranslation } from "react-i18next";
// export default function TruckDriverTable() {
//   const { transports } = useTransports();
//   const { ownerRequests } = useOwnerRequests();

//   const [search, setSearch] = useState("");

//   const { t } = useTranslation();

//   const [isModalVisible, setIsModalVisible] = useState(false);
//   const showModal = () => {
//     setIsModalVisible(true);
//   };
//   const ownerId = JSON.parse(localStorage.getItem("id"));


//   // useEffect(() => {
//   //   if (!userLoading) {
//   //     console.log("User data:", userData);
//   //   }
//   // }, [userLoading, userData]);
//   const columns = [
//     {
//       title: t("tableTabs.modal"),
//         dataIndex: "truckModel",
//         key: "truckModel",
//       render: (record) => <Space>{record?.truck?.truckModel}</Space>,
//     },
//     {
//       title: t("tableTabs.plate"),
//         dataIndex: "truckPlate",
//         key: "truckplate",
//       render: (record) => <Space>{record?.truck?.truckPlate}</Space>,
//     },
//     {
//       title: t("tableTabs.type"),
//         dataIndex: "truckType",
//         key: "truckType",
//       render: (record) => <Space>{record?.truck?.truckType}</Space>,
//     },
//     {
//       title: t("tableTabs.capacity"),
//         dataIndex: "capacity",
//         key: "truckCapacity",
//       render: (record) => <Space>{record?.truck?.truckCapacity}</Space>,
//     },
//     {
//       title: t("tableTabs.height"),
//         dataIndex: "capacity",
//         key: "truckCapacity",
//       render: (record) => <Space>{record?.truck?.truckHeight}</Space>,
//     },
//     {
//       title: t("tableTabs.driverName"),
//       dataIndex: "driverName",
//       key: "driver",
//       render: (record) => <Space>{record?.driver?.driverName}</Space>,
//     },
//     {
//       title: t("tableTabs.driverAge"),
//       dataIndex: "age",
//       key: "driver",
//       render: (record) => <Space>{record?.driver?.driverAge}</Space>,
//     },
//     {
//       title: t("tableTabs.driverAddress"),
//       dataIndex: "driver",
//       key: "driver",
//       render: (record) => <Space>{record?.driver?.driverAddress}</Space>,
//     },
//     {
//       title: t("tableTabs.booked"),
//       dataIndex: "driver",
//       key: "driver",
//       render: (record) => (
//         <Space>
//           {
//             <Tag
//               color={
//                 ownerRequests?.length > 0 &&
//                 ownerRequests?.some(
//                   (v) =>
//                     v.status !== "finished" &&
//                     v.isAccept !== undefined &&
//                     v.acceptedBy !== undefined &&
//                     v.acceptedBy?.transportId === record.uid
//                 )
//                   ? "green"
//                   : "pink"
//               }
//             >
//               {ownerRequests?.length > 0 &&
//               ownerRequests?.some(
//                 (v) =>
//                   v.status !== "finished" &&
//                   v.isAccept !== undefined &&
//                   v.isAccept &&
//                   v.acceptedBy !== undefined &&
//                   v.acceptedBy?.transportId === record.uid
//               )
//                 ? "Yes"
//                 : "No"}
//             </Tag>
//           }
//         </Space>
//       ),
//     },
//     {
//       title: t("tableTabs.action"),
//       dataIndex: "",
//       key: "x",
//       align: "center",
//       render: (record) => (
//         <Space>
//           {/* <span className="ant-btn  ant-btn-warn">
//             <EditOutlined />
//           </span> */}

//           <Popconfirm
//             title={"Are you sure?"}
//             okText="Ok"
//             cancelText="Cancel"
//             onConfirm={() => {
//               DeleteTruckDriver(record?.uid, record);
//             }}
//           >
//             <span className="ant-btn ant-btn-danger">
//               <DeleteOutlined />
//             </span>
//           </Popconfirm>
//         </Space>
//       ),
//     },
//   ];

//   return (
//     <div>
//       <AddTruckDriverModal
//         isModalVisible={isModalVisible}
//         setIsModalVisible={setIsModalVisible}
//       />

//       <div className="table_topar_add_div_">
//         <Button
//           type="light"
//           className="lang-btn"
//           // shape="circle"
//           icon={<PlusCircleOutlined />}
//           size={"middle"}
//           onClick={showModal}
//         >
//           {t("Truck And Driver")}
//         </Button>
//       </div>
//       <div className="table_search_div">
//         <Input
//           allowClear
//           size="middle"
//           placeholder={t("placeholders.search")}
//           prefix={<SearchOutlined />}
//           onChange={(e) => {
//             setSearch(e.target.value);
//           }}
//         />
//       </div>
//       <Table
//         columns={columns}
//         // expandable={{
//         //   expandedRowRender: (record) => (
//         //     <p
//         //       style={{
//         //         margin: 0,
//         //       }}
//         //     >
//         //       {record.description}
//         //     </p>
//         //   ),
//         //   rowExpandable: (record) => record.name !== "Not Expandable",
//         // }}
//         dataSource={
//           transports &&
//           transports.length > 0 &&
//           transports.filter((val) => {
//             if (search == "") {
//               return val;
//             } else if (
//               (val &&
//                 Object.keys(val.driver).some((v) =>
//                   val[v]
//                     .toString()
//                     .toLowerCase()
//                     .includes(search.toString().toLowerCase())
//                 )) ||
//               Object.keys(val.truck).some((v) =>
//                 val[v]
//                   .toString()
//                   .toLowerCase()
//                   .includes(search.toString().toLowerCase())
//               )
//             ) {
//               return val;
//             }
//           })
//         }
//       />
//     </div>
//   );
// }
import React, { useEffect, useState } from "react";
import { Table, Button, Input, Popconfirm, Space, 
  // Select, 
  Pagination, message } from "antd";

import {
  PlusCircleOutlined,
  SearchOutlined,
  // UploadOutlined,
  DeleteOutlined,
  // EditOutlined,
} from "@ant-design/icons";
import AddTruckDriverModal from "../Modals/AddTruckDriverModal";
// import useTrucks from "../Hooks/useTrucks";
// import useTransports from "../Hooks/useTransport";
// import { DeleteTruckDriver } from "../API/API";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
// import Loader from "../Loaders/Loader";
// import SimpleLoader from "../Loaders/simpleLoader";
import { 
  // DELETE_DRIVERS, 
  UPDATE_DRIVERS } from "../../graphql/Mutation/owner/addDriverMutation";
import { UPDATE_TRUCKS } from "../../graphql/Mutation/owner/addTruckMutation";
import useGetAllDriverTruck from "../Hooks/useGetAllRequests";
import { DELETE_DRIVERS_TRUCKS } from "../../graphql/Mutation/owner/addDriverTruckMutation";
import SimpleLoader from "../Loaders/simpleLoader";
export default function TruckDriverTable() {
  const ownerId = JSON.parse(localStorage.getItem("id"));

  // const { Option } = Select;
  // const { transports } = useTransports({ id });
  const [loading, setLoading] = useState(false);
  
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { driverTrucks, count} = useGetAllDriverTruck(updateTrigger,paginationFilters,ownerId);
  console.log("🚀 ~ file: TruckDriverTable.jsx:32 ~ TruckDriverTable ~ driverTrucks:", driverTrucks)
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const [deleteDriverTruck] = useMutation(DELETE_DRIVERS_TRUCKS);
  const [updateDriver] = useMutation(UPDATE_DRIVERS);
  const [updateTruck] = useMutation(UPDATE_TRUCKS);
  const [search, setSearch] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { t } = useTranslation();
  const showModal = () => {
    setIsModalVisible(true);
  };
  const confirm = async (driverTruck, action) => {
    setUpdateTrigger(false);
    try {
      const { data } = await deleteDriverTruck({
        variables: { id: driverTruck._id },
      });
      if(data.deleteDriverTruck.success){
        await updateDriver({
          variables: {
            id: driverTruck.driver._id,
            updateDriverInput: {
              isTruck: false,
            },
          },
          
        });
    
        await updateTruck({
          variables: {
            id: driverTruck.truck._id,
            updateTruckInput: {
              isDriver: false,
            },
          },
        });
      }
      // Remove the deleted user from the list
      // setDrivers((prevUsers) =>
      //   prevUsers.filter((user) => user._id !== truck._id)
      // );
      // Update the total count of users
      // setCount(count - 1);
      message.success(data.deleteDriverTruck.message);
      console.log(data.deleteDriverTruck.message)
      setUpdateTrigger(true);
    } catch (error) {
      // Handle the error if needed
      console.log(error);
    }
  };
  const columns = [
    {
      title: t("tableTabs.modal"),
      //   dataIndex: "truckModel",
      //   key: "truckModel",
      render: (record) => <Space>{record?.truck?.model}</Space>,
    },
    {
      title: t("tableTabs.plate"),
      //   dataIndex: "truckPlate",
      //   key: "truckplate",
      render: (record) => <Space>{record?.truck?.noPlate}</Space>,
    },
    {
      title: t("tableTabs.type"),
      //   dataIndex: "truckType",
      //   key: "truckType",
      render: (record) => <Space>{record?.truck?.type}</Space>,
    },
    {
      title: t("tableTabs.capacity"),
      //   dataIndex: "truckCapacity",
      //   key: "truckCapacity",
      render: (record) => <Space>{record?.truck?.capacity}</Space>,
    },
    {
      title: t("tableTabs.driverName"),
      // dataIndex: "driver",
      // key: "driver",
      render: (record) => <Space>{record?.driver?.name}</Space>,
    },
    {
      title: t("tableTabs.driverAge"),
      // dataIndex: "driver",
      // key: "driver",
      render: (record) => <Space>{record?.driver?.age}</Space>,
    },
    {
      title: t("tableTabs.driverAddress"),
      // dataIndex: "driver",
      // key: "driver",
      render: (record) => <Space>{record?.driver?.address}</Space>,
    },
    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          {/* <span className="ant-btn  ant-btn-warn">
            <EditOutlined />
          </span> */}

          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => confirm(record, "del")}
            // onConfirm={() => {
            //   DeleteTruckDriver(record?.uid, record);
            // }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
              
            </span>
            
          </Popconfirm>
        </Space>
      ),
    },
  ];
  useEffect(() => {
    // setDrivers(drivers);
    if (driverTrucks?.length > 0) {
      setLoading(false);
    } 
    else {
      setLoading(true);
    }
  }, [driverTrucks]);
  return (
    <div>
      <AddTruckDriverModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        ownerId= {ownerId}
      />

      <div className="table_topar_add_div_">
        <Button
          type="light"
          // shape="circle"
          icon={<PlusCircleOutlined />}
          size={"middle"}
          onClick={showModal}
        >
          {t("Add Truck & Nationality")}
        </Button>
      </div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      {loading ? <SimpleLoader /> : ""}
      <Table
       pagination={false}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.description}
            </p>
          ),
          rowExpandable: (record) => record.name !== "Not Expandable",
        }}
        dataSource={
          driverTrucks &&
          driverTrucks?.length > 0 &&
          driverTrucks?.filter((val) => {
            if (search == "") {
              return val;
            } else if (
              (val &&
                Object.keys(val.driver).some((v) =>
                  val[v]
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase())
                )) ||
              Object.keys(val.truck).some((v) =>
                val[v]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              )
            ) {
              return val;
            }
          })
        }
      />
       <Pagination
        defaultCurrent={1}
        defaultPageSize={paginationFilters.pageSize}
        onChange={handleChange}
        total={count}
        className="Pagination"
      />
    </div>
  );
}