import React from "react";

import './price.css';
import {Navber} from '../Home/navber/navber';
import PriceTable from "../../../Tables/PriceTable";

const PriceList = () => {
  return (
    <>
    <div className="price_list_background">
    <Navber/>
        <div className="Price_table">
            <PriceTable/>
       
        </div>
    </div>
    </>
  )
}

export default PriceList