import { gql } from "@apollo/client";

const CREATE_CUSTOMER = gql`
mutation Mutation($registerCustomerInput: RegisterCustomerInput) {
  createCustomer(registerCustomerInput: $registerCustomerInput) {
    success
    message
    code
    data {
      userName
      type
      token
      profileImage
      proVerified
      phoneNumber
      name
      isHold
      isDeleted
      isApproved
      idNumberUpload
      idNumber
      emailVerified
      email
      createdAt
      crNumberUpload
      crNumber
      _id
    }
  }
}
`;
const CREATE_OWNER = gql`
mutation Mutation($registerOwnerInput: RegisterOwnerInput) {
  createOwner(registerOwnerInput: $registerOwnerInput) {
    success
    message
    data {
      type
      token
      rRA
      profileImage
      phoneNumber
      ownerName
      name
      isDeleted
      isApproved
      isAccept
      idNumberUpload
      idNumber
      emailVerified
      email
      createdAt
      crNumberUpload
      crNumber
      bankInfo {
        iBanNumber
        beneficiaryName
        bankIdCode
      }
      _id
    }
    code
  }
}
`;

const LOGIN_CUSTOMER = gql`
mutation Mutation($loginInput: LoginInputCustomer) {
  loginCustomer(loginInput: $loginInput) {
    success
    message
    data {
      userName
      type
      token
      profileImage
      proVerified
      phoneNumber
      name
      isHold            
      isDeleted
      isApproved
      idNumberUpload
      idNumber
      emailVerified
      email
      createdAt
      crNumberUpload
      crNumber
      _id
    }
    code
  }
}
`;

const LOGIN_OWNER = gql`
mutation LoginOwner($loginInput: LoginInputOwner) {
  loginOwner(loginInput: $loginInput) {
    success
    message
    code
    data {
      type
      rRA
      profileImage
      phoneNumber
      token
      ownerName
      name
      isDeleted
      isApproved
      isAccept
      idNumberUpload
      idNumber
      emailVerified
      email
      createdAt
      crNumberUpload
      crNumber
      bankInfo {
        iBanNumber
        beneficiaryName
        bankIdCode
      }
      _id
    }
  }
}
`;
const FORGET_PASSWORD_CUSTOMER = gql`
mutation ForgetPasswordC($forgetPasswordInput: ForgetPasswordInput) {
  forgetPasswordC(forgetPasswordInput: $forgetPasswordInput) {
    success
    message
    code
  }
}
`;
const FORGET_PASSWORD_OWNER = gql`
mutation ForgetPassword($forgetPasswordInput: ForgetPasswordInput) {
  forgetPassword(forgetPasswordInput: $forgetPasswordInput) {
    success
    message
    code
  }
}
`;




export {CREATE_CUSTOMER ,CREATE_OWNER,LOGIN_CUSTOMER , LOGIN_OWNER,FORGET_PASSWORD_CUSTOMER,
  FORGET_PASSWORD_OWNER};