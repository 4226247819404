import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Upload,
  Row,
  Col,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import {UpdateUser, uploadImage } from "../API/API";
import useUser from "../Hooks/useUser";
import Loader from "../Loaders/Loader";
export default function EditProfileModal({
  isModalVisible,
  setIsModalVisible,
}) {
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
   const [form] = Form.useForm();
   const [formData, setFormData] = useState({});
   const [idFile, setIdFile] = useState([]);
   const [crFile, setCrFile] = useState([]);
   const [pImg, setPimg] = useState([]);

   const [crUpload, setCrUpload] = useState("");
   const [idUpload, setIdUpload] = useState("");
   const [profileImage, setProfileImage] = useState("");

   const handleOk = () => {
     setIsModalVisible(!isModalVisible);
   };

   const handleCancel = () => {
     setIsModalVisible(!isModalVisible);
   };
   const onFinish = async (values) => {
     setLoading(true);
     let crNumber = "";
     let idNumber = "";
     let profileimage = "";

     if (crUpload !== "") {
       await uploadImage("profile/", crUpload).then((res) => {
         crNumber = res;
       });
     }
     if (idUpload !== "") {
       await uploadImage("profile/", idUpload).then((res) => {
         idNumber = res;
       });
     }
     if (profileImage !== "") {
       await uploadImage("profile/", profileImage).then((res) => {
         profileimage = res;
       });
     }

     let data = {
       ...values,
       idNumberUpload: idNumber !== "" ? idNumber : user.idNumberUpload,
       crNumberUpload: crNumber !== "" ? crNumber : user.crNumberUpload,
       profileImage:
         profileImage !== ""
           ? profileimage
           : user.profileImage
           ? user.profileImage
           : "",
     };
    
     await UpdateUser(user.uid, data).then(() => {
       setLoading(false);
       form.resetFields();
       handleCancel();
     });
   };
   useEffect(() => {
     if (Object.keys(user).length > 0) {
       setFormData(user);
       form.setFieldsValue(user);
       if (user.crNumberUpload !== "") {
         setCrFile([
           {
             uid: "-1",
             name: "crNumber",
             status: "done",
             url: user.crNumberUpload,
           },
         ]);
       }
       if (user.idNumberUpload !== "") {
         setIdFile([
           {
             uid: "-2",
             name: "IdNumber",
             status: "done",
             url: user.idNumberUpload,
           },
         ]);
       }
       if (user.profileImage !== undefined || user.profileImage !== "") {
         setPimg([
           {
             uid: "-2",
             name: "profileImage",
             status: "done",
             url: user.profileImage,
           },
         ]);
       }
     }
   }, [user]);
  return (
    <div>
      <Modal
        title="Edit Profile"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form
            form={form}
            name="add_driver_form"
            // initialValues={formData}
            onFinish={onFinish}
          >
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder="Name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input disabled type={"email"} placeholder="Email" />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    hasFeedback
                    name="phoneNumber"
                    // label="Nationality"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder="Phone No" />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="idNumber"
                    // label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder="ID Number" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="crNumber"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder="CR Number" />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>

            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="idNumberUpload"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Upload
                      listType="picture"
                      fileList={idFile}
                      multiple={false}
                      maxCount={1}
                      onChange={(e) => {
                        if (e.file.status === "removed") {
                          setIdUpload("");
                          form.setFieldsValue({ idNumberUpload: "" });
                          setIdFile([]);
                        } else {
                          setIdFile(e.fileList);
                          setIdUpload(e.file.originFileObj);
                          form.setFieldsValue({ idNumberUpload: "file" });
                        }
                      }}
                    >
                      <Button icon={<UploadOutlined />}>
                        ID Number Upload
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="crNumberUpload"
                    // label="Address"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Upload
                      listType="picture"
                      multiple={false}
                      fileList={crFile}
                      maxCount={1}
                      onChange={(e) => {
                        if (e.file.status === "removed") {
                          setCrUpload("");
                          form.setFieldsValue({ crNumberUpload: "" });
                          setCrFile([]);
                        } else {
                          setCrFile(e.fileList);
                          setCrUpload(e.file.originFileObj);
                          form.setFieldsValue({ crNumberUpload: "file" });
                        }
                      }}
                    >
                      <Button icon={<UploadOutlined />}>
                        CR Number Upload
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="profileImage"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: "Requerd Field!",
                    //   },
                    // ]}
                  >
                    <Upload
                      listType="picture"
                      fileList={pImg}
                      multiple={false}
                      maxCount={1}
                      onChange={(e) => {
                        if (e.file.status === "removed") {
                          setProfileImage("");
                          form.setFieldsValue({ profileImage: "" });
                          setPimg([]);
                        } else {
                          setPimg(e.fileList);
                          setProfileImage(e.file.originFileObj);
                          form.setFieldsValue({ profileImage: "file" });
                        }
                      }}
                    >
                      <Button icon={<UploadOutlined />}>
                        Profile Image upload
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Edit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
