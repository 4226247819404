import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Row, Col, Descriptions, Space, Button, Steps, Divider } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ActiveRideMap from "./ActiveRideMap";
import "./viewRequest.scss";
export default function ViewRequest() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { request } = state;

  const { t } = useTranslation();
  return (
    <div>
      <div>
        <div>
          <h3 className="car-img">{`${t("tableTabs.requst")} ${t(
            "tableTabs.detail"
          )}`}</h3>
        </div>
        <div>
          <div>
            <div className="upper_btn_div car-img">
              <Space>
                <Button
                  type="light"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {t("tableTabs.back")}
                </Button>
              </Space>
            </div>
          </div>
          <div className="main-view-div">
            <div className="view-request">
              <Row className="mb-3 mr-4">
                <Col span={2}>
                  <p className="car-img">
                    <b>{t("Request Id:")}</b>
                  </p>
                </Col>
                <Col span={22}>
                  <p className="car-img">{request?.requestId}</p>
                </Col>
              </Row>
              <Row>
                <Col span={2}>
                  <p className="car-img">
                    <b>{t(" Date:")}</b>
                  </p>
                </Col>
                <Col span={15}>
                  <p className="car-img">
                    {moment(request?.iroDate?.seconds * 1000).format("lll")}
                  </p>
                </Col>
                <Col span={2}>
                  <p>
                    <b className="car-img1">{t("tableTabs.price")}</b>
                  </p>
                </Col>
                <Col span={5}>{request?.price}</Col>
              </Row>
              {/* <Row type="flex" justify="center" align="middle"> */}
              <div style={{ width: "" }}>
                <div className="car-img">
                  <Steps
                    direction="vertical"
                    progressDot
                    current={1}
                    items={[
                      {
                        title: (
                          <Space size="large">
                            <p>
                              <b>{t("placeholders.from")}:</b>
                            </p>
                            <p
                              style={{
                                marginLeft: "-30px",
                              }}
                            >
                              {request.from}
                            </p>
                          </Space>
                        ),
                      },
                      {
                        title: (
                          <Space size="large">
                            <p>
                              <b>{t("tableTabs.to")}:</b>
                            </p>
                            <p
                              style={{
                                marginLeft: "-30px",
                              }}
                            >
                              {request.to}
                            </p>
                          </Space>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
              <Divider />
              <Row>
                <Col span={3}>
                  <Space direction="vertical" className="car-img">
                    <p>
                      <b>{t("tableTabs.name")}</b>
                    </p>
                    <p>{request?.name}</p>
                  </Space>
                </Col>
                <Col span={3}>
                  <Space direction="vertical" className="car-img">
                    <p>
                      <b>{t("tableTabs.type")}</b>
                    </p>
                    <p>{request?.type}</p>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space direction="vertical" className="car-img">
                    <p>
                      <b>{t("tableTabs.capacity")}</b>
                    </p>
                    <p>{request?.capacity}</p>
                  </Space>
                </Col>
                <Col span={3}>
                  <Space direction="vertical" className="car-img2 ">
                    <p>
                      <b>{t("tableTabs.hight")}</b>
                    </p>
                    <p>{request?.height}</p>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space direction="vertical" className="car-img1">
                    <p>
                      <b>{t("tableTabs.senderContact")}</b>
                    </p>
                    <p>{request?.senderContact}</p>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space direction="vertical" className="car-img1">
                    <p>
                      <b>{t("tableTabs.receiverContact")}</b>
                    </p>
                    <p>{request?.receiverContact}</p>
                  </Space>
                </Col>
                <Col span={3}>
                  <Space direction="vertical" className="car-img1">
                    <p>
                      <b>{t("tableTabs.distance")}</b>
                    </p>
                    <p>{request?.distance}Km</p>
                  </Space>
                </Col>
              </Row>
              <Divider />
              <Descriptions
                bordered
                title={t("Attachments")}
                layout={"vertical"}
              >
                <Descriptions.Item label={t("tableTabs.finalByan")}>
                  {/* {state?.request?.finalByan !== undefined &&
                  state?.request?.finalByan !== null ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.finalByan);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.finalByan}
                    />
                  ) : (
                    <span>{t("Not upload yet")}</span>
                  )} */}

                  {state?.request?.finalByan &&
                  state?.request?.finalByan !== null &&
                  state?.request?.finalByan !== "" ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.finalByan);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.finalByan}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={t("tableTabs.ticket")}>
                  {/* {state?.request?.ticket !== undefined &&
                  state?.request?.ticket !== null ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.ticket);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.ticket}
                    />
                  ) : (
                    <span>{t("tableTabs.notUploadYet")}</span>
                  )} */}

                  {state?.request?.ticket &&
                  state?.request?.ticket !== null &&
                  state?.request?.ticket !== "" ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.ticket);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.ticket}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={`${t("tableTabs.blNumber")} ${t("tableTabs.upload")}`}
                >
                  {/* {state?.request?.blNumberUpload &&
                  state?.request?.blNumberUpload !== null &&
                  state?.request?.blNumberUpload !== "" ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.blNumberUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.blNumberUpload}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )} */}
                  {state?.request?.blNumberUpload &&
                  state?.request?.blNumberUpload !== null &&
                  state?.request?.blNumberUpload !== "" ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.blNumberUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.blNumberUpload}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={`IRO ${t("tableTabs.upload")}`}>
                  {state?.request?.iroUpload &&
                  state?.request?.iroUpload !== null &&
                  state?.request?.iroUpload !== "" ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.iroUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.iroUpload}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label={t("tableTabs.deliveryNote")}>
                  {state?.request?.deliveryNote &&
                  state?.request?.deliveryNote !== null &&
                  state?.request?.deliveryNote !== "" ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.deliveryNote);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.deliveryNote}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={t("Receipt")}>
                  {state?.request?.receipt &&
                  state?.request?.receipt !== null &&
                  state?.request?.receipt !== "" ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.receipt);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.receipt}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={"EIR"}>
                  {state?.request?.eir &&
                  state?.request?.eir !== null &&
                  state?.request?.eir !== "" ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.eir);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.eir}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>
              </Descriptions>
              <Row>
                <Col span={24}>
                  <Descriptions
                    bordered
                    title={t("tableTabs.sendTo")}
                    layout={"vertical"}
                  >
                    {/* <Descriptions.Item label={"Owner Name"}>
                    {state.owner.name}
                  </Descriptions.Item>
                  <Descriptions.Item span={2} label={"Owner Phone No"}>
                    {state.owner.phoneNumber}
                  </Descriptions.Item> */}
                    {Object.keys(state?.transport).length > 0 ? (
                      <>
                        <Descriptions.Item label={t("tableTabs.driverName")}>
                          {state?.transport?.driver?.driverName}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("Driver ID")}>
                          {state?.transport?.driver?.driverIdNumber}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("Driver Address")}>
                          {state?.transport?.driver?.driverAddress}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("tableTabs.truck")}>
                          {state?.transport?.truck?.truckModel}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("tableTabs.truckCapacity")}>
                          {state?.transport?.truck?.truckCapacity}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("tableTabs.truckHeight")}>
                          {state?.transport?.truck?.truckHeight}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("tableTabs.truckNumber")}>
                          {state?.transport?.truck?.truckPlate}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("tableTabs.truckType")}>
                          {state?.transport?.truck?.truckType}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("driver Id Upload")}>
                          <img
                            className="img"
                            onClick={() => {
                              window.open(
                                state?.transport?.driver?.driverIdUpload
                              );
                            }}
                            alt={"Click to View"}
                            src={state?.transport?.driver?.driverIdUpload}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={t("tableTabs.driverLicenseUpload")}
                        >
                          <img
                            className="img"
                            onClick={() => {
                              window.open(
                                state?.transport?.driver?.driverLicenseUpload
                              );
                            }}
                            alt={"Click to View"}
                            src={state?.transport?.driver?.driverLicenseUpload}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={t("tableTabs.driverPortIdUpload")}
                        >
                          <img
                            className="img"
                            onClick={() => {
                              window.open(
                                state?.transport?.driver?.portIdUpload
                              );
                            }}
                            alt={"Click to View"}
                            src={state?.transport?.driver?.portIdUpload}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item
                          label={t("tableTabs.truckLicenseUpload")}
                        >
                          <img
                            className="img"
                            onClick={() => {
                              window.open(
                                state?.transport?.truck?.truckLicenseUpload
                              );
                            }}
                            alt={"Click to View"}
                            src={state?.transport?.truck?.truckLicenseUpload}
                          />
                        </Descriptions.Item>
                      </>
                    ) : (
                      <Descriptions.Item label={t("tableTabs.notSendedYet")} />
                    )}
                  </Descriptions>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <ActiveRideMap
          coordinates={state?.request?.coordinates}
          id={state?.request?.uid}
        />
      </div>
    </div>
  );
}
