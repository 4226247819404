import React, { useState, useEffect, useRef } from "react";
import "./login.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import Chatt from "../../chat-box/Chat-box";
import { useNavigate } from "react-router-dom";
import logo from '../Landing/assets/home/OAC LOGO2.png'
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import { CREATE_CUSTOMER } from "../../graphql/Mutation/adminMutation";


import {
  LockOutlined,
  UserOutlined,
  MailOutlined,
  UploadOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { BsTelephone } from "react-icons/bs";

// 
import {
  Button,
  Checkbox,
  Form,
  Input,
  Radio,
  Switch,
  Divider,
  Row,
  Col,
  Alert,
  message,
  Upload,
} from "antd";

import ForgetPasswordModal from "../Modals/ForgetPasswordModal";
//Api
import { FindUser, AddUser, uploadImage } from "../API/API";
//loader
import Loader from "../Loaders/Loader";
//Redux
import { Language } from "../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { isSupported } from "firebase/messaging";
import { LOGIN_DRIVER } from "../../graphql/Mutation/driver/LoginDriverMutatoin";

import {
  LOGIN_CUSTOMER,
  CREATE_OWNER,
  LOGIN_OWNER,
} from "../../graphql/Mutation/adminMutation";
import { border } from "@mui/system";
export default function Signup() {
  const [loginCustomer] = useMutation(LOGIN_CUSTOMER);
const [createCustomer] = useMutation(CREATE_CUSTOMER);

  const [type, setType] = useState("owner");
const [loginDriver] = useMutation(LOGIN_DRIVER);
  const [form] = Form.useForm();
  const signupFormRef = useRef();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { lng } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    crNumberUpload: "",
    idNumberUpload: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleLogin = async (values) => {
    setLoading(true);
    try {
      if (selectedUser === "owners") {
        await onFinishLogin(values);
      } else if (selectedUser === "customers") {
        await onFinishLogin1(values);
      } else if (selectedUser === "driver") {
        await onFinishLogin2(values);
      } else {
      }
    } catch (error) {
      console.error("GraphQL Error:", error);
    }
    setLoading(false);
  };
  const onFinishLogin = (values) => {
    setLoading(true);
    let data = {
      ...values,
      userEmail: values.userEmail.toLowerCase(),
    };
    localStorage.setItem("user_email", data.userEmail);

    FindUser(data)
      .then((res) => {
        if (res.status === "success") {
          setLoading(false);
          message.success("Login Successfully!");
          if (values.userType === "owners") {
            navigate("/owner/fleet_managment");
          } else if (values.userType === "Customer") {
            navigate("/customer/tarnsport_requests");
          }
        } else {
          setLoading(false);
          message.error(res.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error(e.message);
      });
  };
  const [selectedUser, setSelectedUser] = useState("driver");
  const onFinishLogin1 = async (values) => {
    setLoading(true);
    try {
      const res = await loginCustomer({
        variables: {
          loginInput: {
            email: values.email,
            password: values.password,
          },
        },
      });
      const { message, success, data } = res.data.loginCustomer;
      localStorage.setItem("id", JSON.stringify(data._id));
      localStorage.setItem("userType", data.__typename);
      localStorage.setItem("currentUserEmail", values.email);
      navigate("/customer/tarnsport_requests");
      if (success === true) {
        setLoading(false);
        message.success("Login Successfully!");
        navigate("/customer/tarnsport_requests");
      } else {
        setLoading(false);
        message.error(message);
      }
      // }
    } catch (error) {
      console.error("GraphQL Error:", error);
      setLoading(false);
    }
  };

  const onFinishLogin2 = async (values) => {
    setLoading(true);
    try {
      const res = await loginDriver({
        variables: {
          loginInput: {
            idNo: values.idNo,
            password: values.password,
          },
        },
      });
      const { message, success, data } = res.data.loginDriver;
      localStorage.setItem("idNo", JSON.stringify(data.idNo));
      localStorage.setItem("id", JSON.stringify(data._id));
      localStorage.setItem("userType", data.__typename);
      navigate("/driver/driverprofile"); 
      if (success === true) {
        setLoading(false);
        message.success("Login Successfully!");
        navigate("/driver/driverprofile");
      } else {
        setLoading(false);
        // message.error(message);
        message.error("Invalid Credentials")
      }
      // }
    } catch (error) {
      console.error("GraphQL Error:", error);
      setLoading(false);
    }
  };
  const onFinishSignup = async (values) => {
    setLoading(true);
    if (formData.crNumberUpload !== "") {
      await uploadImage(values.type + "/", formData.crNumberUpload).then(
        (res) => {
          formData.crNumberUpload = res;
          setFormData({ ...formData });
        }
      );
    }
    if (formData.idNumberUpload !== "") {
      await uploadImage(values.type + "/", formData.idNumberUpload).then(
        (res) => {
          formData.idNumberUpload = res;
          setFormData({ ...formData });
        }
      );
    }
    delete values.confirmPassword;
    let data = {
      ...values,
      email: values.email.toLowerCase(),
      ...formData,
      isDeleted:true,

      isApproved: values.type == "customers" ? true : false,
      isAccept:false,
      rRA:false,
    };

    AddUser(data)
      .then((res) => {
        if (res.status === "success") {
          // message.success(
          //   "user add successfully and send to admin for approvel after approvel you will b able to login"
          // );
          message.success(
            "Please check you gmail inbox or spam for email verification"
          );

          signupFormRef.current.resetFields();
          setLogin(true);
          setLoading(false);
        } else {
          setLoading(false);
          message.error(res.message);
        }
      })
      .catch((e) => {
        setLoading(false);
        message.error(JSON.stringify(e));
      });
  };
  const handleSignup = async (values) => {
    setLoading(true);
    const { type, ...restValues } = values;
    if (type === "owner") {
      await onFinishSignup(restValues);
    } else if (type === "customer") {
      await onFinishSignup1(restValues);
    }
    setLoading(false);
  };
  const [idNumberImageUrl, setIdNumberImageUrl] = useState(null);
  const [crNumberImageUrl, setCrNumberImageUrl] = useState(null);
  // Function to handle image upload
  const handleImageUpload1 = (image, setImageUrl) => {
    if (!image) return Promise.reject("Image is undefined");
    const imageRef = ref(storage, `signUp/${image.name}`);
    return uploadBytes(imageRef, image)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .then((url) => {
        setImageUrl(url);
        console.log(url);
        return url; 
      })
      .catch((error) => {
        console.error("Error while uploading or getting the image URL", error);
        throw error; 
      });
  };
  const handleImageUpload2 = (image, setImageUrl) => {
    if (!image) return Promise.reject("Image is undefined");
    const imageRef = ref(storage, `signUp/${image.name}`);
    return uploadBytes(imageRef, image)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .then((url) => {
        setCrNumberImageUrl(url);
        console.log(url);
        return url; 
      })
      .catch((error) => {
        console.error("Error while uploading or getting the image URL", error);
        throw error; 
      });
  };

  const onFinishSignup1 = async (values) => {
    setLoading(true);
    console.log(values.name)
    
    try {
      const res = await createCustomer({
        variables: {
          registerCustomerInput: {
            profileImage: "active",
            phoneNumber: values.phoneNumber,
            name: values?.name,
            idNumberUpload: idNumberImageUrl,
            idNumber: values.idNumber,
            email: values.email,
            password: values.password,
            crNumberUpload: crNumberImageUrl,
            crNumber: values.crNumber,
          },
        },
      });

      setLogin(true);
    } catch (error) {
      console.error("Error while uploading images or saving to MongoDB", error);
    } finally {
      setLoading(false);
    }
   
  };
  useEffect(() => {
    isSupported()
      .then((value) => {
        if (value) {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              console.log("Notification permission granted.");
            } else {
              alert(
                "Notification are not allowed for this site please check setting and allow notifications for this site"
              );
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  
  return (
    <div className="main_login_div">
      {loading ? <Loader /> : ""}
      <ForgetPasswordModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <div className="login_background_div">
        <Chatt />
        <div
          className="login_content_div"
          data-aos="zoom-in"
          data-aos-duration="3000"
        >
          <div className="login_wlp_div"></div>
          <div className="login_form_div">
            <div className="login_form_content_div">
              <div className="login_form_content_upper_div">
                <div className="login_form_content_upper_logo_div ">
                  <div className="logo_div">
                    <img className="logo_img" src={logo} />
                  </div>
                </div>
                <div className="login_form_content_upper_lng_div">
                  <div className="change-locale">
                    <button
                      className={` ar ${lng == "ar" ? "hello-arabic" : ""}`}
                      onClick={() => {
                        dispatch(Language("ar"));
                        i18n.changeLanguage("ar");
                      }}
                    >
                      العربية
                    </button>
                    <button
                      className={` ar ${lng == "en" ? "hello-english" : ""}`}
                      onClick={() => {
                        dispatch(Language("en"));
                        i18n.changeLanguage("en");
                      }}
                    >
                      English
                    </button>
                  </div>
                </div>
              </div>
              <div className="login_form_content_from_div">
                <div className="form_div">
                  <div>
                    <h2 className="inp-emai">
                      {t("welcome")}

                      {/* {"Welcome to OAC EXPRESS"} */}
                    </h2>
                  </div>
                  {login ? (
                    <>
                      <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={handleLogin}
                      >
                        {selectedUser === "driver" ? (
                          <Form.Item
                            name="idNo"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: t("errorsTxt.requiredField"),
                              },
                            ]}
                          >
                            <Input
                              className="inp-emai"
                              type="number"
                              prefix={
                                <MailOutlined className="site-form-item-icon" />
                              }
                              placeholder="Type Number"
                            
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            name="email"
                            hasFeedback
                            rules={[
                              {
                                type: "email",
                                message: t("errorsTxt.invalidEmail"),
                              },
                              {
                                required: true,
                                message: t("errorsTxt.requiredField"),
                              },
                            ]}
                          >
                            <Input
                              className="inp-emai"
                              type="email"
                              prefix={
                                <MailOutlined className="site-form-item-icon" />
                              }
                              placeholder={t("placeholders.inputEmail")}
                            />
                          </Form.Item>
                        )}

                        <Form.Item
                          name="password"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: t("errorsTxt.requiredField"),
                            },
                            {
                              min: 8,
                              message: t("errorsTxt.minimumLength"),
                            },
                          ]}
                        >
                          <Input.Password
                            prefix={
                              <LockOutlined className="site-form-item-icon" />
                            }
                            className="inp-emai"
                            type="password"
                            placeholder={t("placeholders.inputPass")}
                            minLength={8}
                          />
                        </Form.Item>
                        <Form.Item>
                          <div className="forget_div">
                            <a className="login-form-forgot">
                              <b
                                className="own-div radio-btn"
                                onClick={showModal}
                              >
                                {t("account.forget_password?")}
                              </b>
                            </a>
                          </div>
                        </Form.Item>

                        <Form.Item>
                          <Button
                            // type="primary"
                            htmlType="submit"
                            className="login-form-button submit_btn inp-emai"
                          >
                            <b>{t("buttonsTxt.login")}</b>
                          </Button>
                        </Form.Item>

                        <div className="own-div">
                          <Divider orientation="center">
                            {t("account.dont_have_account?")}{" "}
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                setLogin(!login);
                              }}
                            >
                              {t("account.register_now")}
                            </a>
                          </Divider>
                        </div>
                      </Form>

                      <div style={{ margin: "1rem 0" }}>
                        <Radio.Group
                          onChange={(e) => setSelectedUser(e.target.value)} 
                          defaultValue="driver"
                        >
                          <Radio value={"owners"}>{t("Owner")}</Radio>
                          <Radio value={"customers"}>{t("Customer")}</Radio>
                          <Radio value={"driver"}>{t("Driver")}</Radio>
                        </Radio.Group>
                      </div>
                    </>
                  ) : (
                    <>
                      <Form
                        // ref={signupFormRef}
                        name="signup"
                        className="signup-form"
                        onFinish={handleSignup}
                      >
                        <Row gutter={24}>
                          <Col span={12}>
                            {type === "owner" ? (
                              <Form.Item
                                name="name"
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: t("errorsTxt.requiredField"),
                                  },
                                ]}
                              >
                                <Input
                                  className="inp-emai"
                                  type="text"
                                  prefix={
                                    <UserOutlined className="site-form-item-icon" />
                                  }
                                  placeholder={t("placeholders.inputName")}
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                name="name"
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: t("errorsTxt.requiredField"),
                                  },
                                ]}
                              >
                                <Input
                                  type="text"
                                  className="inp-emai"
                                  prefix={
                                    <UserOutlined className="site-form-item-icon" />
                                  }
                                  placeholder={t("placeholders.inputName")}
                                />
                              </Form.Item>
                            )}
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="email"
                              hasFeedback
                              rules={[
                                {
                                  type: "email",
                                  message: t("errorsTxt.invalidEmail"),
                                },
                                {
                                  required: true,
                                  message: t("errorsTxt.requiredField"),
                                },
                              ]}
                            >
                              <Input
                                type="email"
                                className="sign-ema"
                                prefix={
                                  <MailOutlined className="site-form-item-icon" />
                                }
                                placeholder={t("placeholders.inputEmail")}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={24}>
                            <Form.Item
                              name="phoneNumber"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: t("errorsTxt.requiredField"),
                                },
                              ]}
                            >
                              <Input
                                className="inp-emai"
                                prefix={
                                  <BsTelephone className="site-form-item-icon" />
                                }
                                type="tel"
                                placeholder={t("placeholders.inputPhoneNumber")}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              name="password"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: t("errorsTxt.requiredField"),
                                },
                                {
                                  min: 8,
                                  message: t("errorsTxt.minimumLength"),
                                },
                                {
                                  pattern: new RegExp(
                                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
                                  ),
                                  message: t(
                                    "Password must contain one lowercase,uppercase letter,number&special character"
                                  ),
                                },
                              ]}
                            >
                              <Input.Password
                                className="inp-emai"
                                prefix={
                                  <LockOutlined className="site-form-item-icon" />
                                }
                                type="password"
                                placeholder={t("placeholders.inputPass")}
                                minLength={8}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              className="sign-ema"
                              name="confirmPassword"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: t("errorsTxt.requiredField"),
                                },
                                {
                                  min: 8,
                                  message: t("errorsTxt.minimumLength"),
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      getFieldValue("password") === value
                                    ) {
                                      return Promise.resolve();
                                    }

                                    return Promise.reject(
                                      new Error(t("errorsTxt.passwordMatch"))
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Input.Password
                                prefix={
                                  <LockOutlined className="site-form-item-icon" />
                                }
                                type="password"
                                placeholder={t("placeholders.ConfirmPass")}
                                minLength={8}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col span={12}>
                            <Row>
                              <Col span={12}>
                                <Form.Item
                                  className="inp-emai"
                                  name="idNumber"
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      message: t("errorsTxt.requiredField"),
                                    },
                                  ]}
                                >
                                  <Input
                                    // prefix={
                                    //   <PhoneOutlined className="site-form-item-icon" />
                                    // }
                                    type="text"
                                    placeholder={t("placeholders.idNumber")}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                {/* <Form.Item
                                  name="idNumberUpload"
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      message: t("errorsTxt.requiredField"),
                                    },
                                  ]}
                                >
                                  <Upload
                                    listType="picture"
                                    multiple={false}
                                    maxCount={1}
                                    // accept="image/*"
                                    onChange={(e) => {
                                      if (e.file.status === "removed") {
                                        formData.crNumberUpload = "";
                                        setFormData({
                                          ...formData,
                                        });
                                        form.setFieldsValue({
                                          crNumberUpload: "",
                                        });
                                      } else {
                                        formData.crNumberUpload =
                                          e.file.originFileObj;
                                        setFormData({
                                          ...formData,
                                        });
                                        form.setFieldsValue({
                                          idNumbecrNumberUploadrUpload:
                                            e.file.originFileObj,
                                        });
                                      }
                                    }}
                                  >
                                    <Button
                                      icon={<UploadOutlined />}
                                      className="sign-ema"
                                    >
                                      {t("placeholders.upload")}
                                    </Button>
                                  </Upload>
                                </Form.Item> */}
                                {/* <input
                                  type="file"
                                  onChange={(event) => {
                                    setImageUpload(event.target.files[0]);
                                  }}
                                />
                                <button onClick={uploadImage}>upload</button> */}
                                {/* customer side */}
                                <Form.Item
                                  name="idNumberUpload"
                                  hasFeedback
                                  type="file"
                                  valuePropName="fileList" 
                                  getValueFromEvent={(e) =>
                                    e.fileList.slice(10)
                                  } 
                                >
                                  <Upload
                                    customRequest={({ file }) => {
                                      return new Promise((resolve, reject) => {
                                        handleImageUpload1(
                                          file,
                                          setIdNumberImageUrl
                                        )
                                          .then(() => {
                                            console.log("Upload successful");
                                            resolve();
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Error during customRequest:",
                                              error
                                            );
                                            reject(error);
                                          });
                                      });
                                    }}
                                  >
                                    <Button
                                      icon={<UploadOutlined />}
                                      className="sign-ema"
                                    >
                                      Upload
                                    </Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>

                          <Col span={12}>
                            <Row>
                              <Col span={12}>
                                <Form.Item
                                  className="sign-ema"
                                  name="crNumber"
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      message: t("errorsTxt.requiredField"),
                                    },
                                  ]}
                                >
                                  <Input
                                    type="text"
                                    placeholder={t("placeholders.carNumber")}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                {/* <Form.Item
                                  name="crNumberUpload"
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      message: t("errorsTxt.requiredField"),
                                    },
                                  ]}
                                >
                                  <Upload
                                    listType="picture"
                                    multiple={false}
                                    maxCount={1}
                                    // accept="image/*"
                                    onChange={(e) => {
                                      if (e.file.status === "removed") {
                                        formData.crNumberUpload = "";
                                        setFormData({
                                          ...formData,
                                        });
                                        form.setFieldsValue({
                                          crNumberUpload: "",
                                        });
                                      } else {
                                        formData.crNumberUpload =
                                          e.file.originFileObj;
                                        setFormData({
                                          ...formData,
                                        });
                                        form.setFieldsValue({
                                          idNumbecrNumberUploadrUpload:
                                            e.file.originFileObj,
                                        });
                                      }
                                    }}
                                  >
                                    <Button
                                      icon={<UploadOutlined />}
                                      className="sign-ema"
                                    >
                                      {t("placeholders.upload")}
                                    </Button>
                                  </Upload>
                                </Form.Item> */}
                                <Form.Item
                                  name="crNumberUpload"
                                  hasFeedback
                                  type="file"
                                  valuePropName="fileList" 
                                  getValueFromEvent={(e) =>
                                    e.fileList.slice(10)
                                  } 
                                >
                                  <Upload
                                    customRequest={({ file }) => {
                                      return new Promise((resolve, reject) => {
                                        handleImageUpload2(
                                          file,
                                          setCrNumberImageUrl
                                        )
                                          .then(() => {
                                            console.log("Upload successful");
                                            resolve();
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Error during customRequest:",
                                              error
                                            );
                                            reject(error);
                                          });
                                      });
                                    }}
                                  >
                                    <Button
                                      icon={<UploadOutlined />}
                                      className="sign-ema"
                                    >
                                      Upload
                                    </Button>
                                  </Upload>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Form.Item
                          style={{ border: "none" }}
                          className="own-div"
                          name={"type"}
                          rules={[
                            {
                              required: true,
                              message: t("errorsTxt.requiredField"),
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={(e) => setType(e.target.value)}
                            value={type}
                          >
                            <Radio value={"owner"}>{t("Owner")}</Radio>
                            <Radio value={"customer"}>{t("Customer")}</Radio>
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button submit_btn inp-emai"
                          >
                            <b>
                              <b>{t("buttonsTxt.register")}</b>
                            </b>
                          </Button>
                        </Form.Item>

                        <div className="own-div">
                          <Divider orientation="center">
                            {t("account.already_have_account?")}
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                setLogin(!login);
                              }}
                            >
                              {t("buttonsTxt.login")}
                            </a>
                          </Divider>
                        </div>
                      </Form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
