import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot, doc } from "firebase/firestore";
import { db } from "../firebase";
export default function useRelation() {
  let uid = JSON.parse(localStorage.getItem("uid")) || "";
  const [relation, setRelation] = useState({});
  const getData = async () => {
    let q = doc(db, "relations", uid);
    const unsub = onSnapshot(q, (querySnapShot) => {
      setRelation(querySnapShot.data());
    });

    return () => unsub();
  };
  useEffect(() => {
    getData();
  }, []);

  return { ...relation };
}
