import React from "react";
import { Routes, Route } from "react-router-dom";
import OwnerLayout from "../Components/Layouts/OwnerLayout/Layout/OwnerLayout";
import FleetManagment from "../Components/OwnerComponents/Fleet Mangment/FleetManagment";
import TRequests from "../Components/OwnerComponents/TRequests/TRequests";
import Finance from "../Components/OwnerComponents/Finance/Finance";
import SendRequest from "../Components/OwnerComponents/TRequests/SendRequest";
import ViewRequest from "../Components/OwnerComponents/TRequests/ViewRequest";
import ChatOwner from "./Chat-Onwer";
import Maintenance from "../Components/OwnerComponents/Maintenance/Maintenance";
export default function OwnerRoutes() {
  return (
    <div>
      <Routes>
        <Route
          path="fleet_managment"
          element={
            <OwnerLayout>
              <ChatOwner />
              <FleetManagment />
            </OwnerLayout>
          }
        />
        <Route
          path="transportation_requests"
          element={
            <OwnerLayout>
              <ChatOwner />
              <TRequests />
            </OwnerLayout>
          }
        />
        <Route
          path="send_request/:id"
          element={
            <OwnerLayout>
              <ChatOwner />
              <SendRequest />
            </OwnerLayout>
          }
        />
        <Route
          path="request_detail/:id"
          element={
            <OwnerLayout>
              <ChatOwner />

              <ViewRequest />
            </OwnerLayout>
          }
        />
        <Route
          path="finance"
          element={
            <OwnerLayout>
              <ChatOwner />
              <Finance />
            </OwnerLayout>
          }
        />
        <Route
          path="maintenance"
          element={
            <OwnerLayout>
              <Maintenance />
            </OwnerLayout>
          }
        />
      </Routes>
    </div>
  );
}
