// import './table.css';
import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Row,
  Col,
  Select,
  message,
  Space,
  Upload,
  DatePicker,
  Switch,
  Tooltip,
  Popover,
} from "antd";
import dayjs from "dayjs";
import "../CustomerComponents/TransportRequests/viewRequest.scss";

import { MakeRequestAction } from "../../Redux/Actions/DataActions";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCapacites from "../Hooks/useCapacities";
import useCities from "../Hooks/useCities";
import useHeights from "../Hooks/useHeights";
import useTypes from "../Hooks/useTypes";
import useCombinations from "../Hooks/useCombinations";
import useUser from "../Hooks/useUser";
import "react-phone-input-2/lib/style.css";

import { useTranslation } from "react-i18next";
import useRelation from "../Hooks/useRelation";

const PriceTable = () => {
  const navigate = useNavigate();
  const { combinations } = useCombinations();
  const fromValues = combinations.map((obj) => obj.from);
  const tooValues = combinations.map((obj) => obj.to);
  const { user } = useUser();
  const relation = useRelation();
  const [form] = Form.useForm();
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const { cities } = useCities();
  const { capacities } = useCapacites();
  const { types } = useTypes();
  const { heights } = useHeights();
  const [type, setType] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [height, setHeight] = useState("");
  const [capacity, setCapacity] = useState("");
  const [time, setTime] = useState("");
  const [distance, setDistance] = useState("");
  const [price, setPrice] = useState("");
  const [commission, setCommission] = useState(1);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { makeRequestData } = useSelector((state) => state.DataReducer);

  //find matches value of to from combination collection
  let toValues = [];
  let uniqueToValues = [];
  if (fromValues.includes(from.city)) {
    for (const combination of combinations) {
      if (combination.from === from.city) {
        toValues.push(combination.to);
        uniqueToValues = [...new Set(toValues)];
      }
    }
  }
  //end to

  //find capicity values if both from and to exit in
  let capacityValue = [];
  let uniqueCapacityValues = [];
  if (fromValues.includes(from.city) && tooValues.includes(to)) {
    for (const combinationn of combinations) {
      if (combinationn.from === from.city && combinationn.to === to) {
        capacityValue.push(combinationn.capacity);
        uniqueCapacityValues = [...new Set(capacityValue)];
      }
    }
  }

  //end capacity

  //find height values if all three are true
  let heightValues = [];
  let uniqueHeightValues = [];
  if (fromValues.includes(from.city) && tooValues.includes(to)) {
    for (const combinationn of combinations) {
      if (combinationn.from === from.city && combinationn.to === to) {
        heightValues.push(combinationn.height);
        uniqueHeightValues = [...new Set(heightValues)];
      }
    }
  }

  //height end

  // find type ia all conditions matches
  let typeValues = [];
  let uniqueTypeValues = [];
  if (fromValues.includes(from.city) && tooValues.includes(to)) {
    for (const combinationn of combinations) {
      if (combinationn.from === from.city && combinationn.to === to) {
        typeValues.push(combinationn.type);
        uniqueTypeValues = [...new Set(typeValues)];
      }
    }
  }
  //end type

  const getDistanceTimePrice = () => {
    let v = combinations.find(
      (v) =>
        v.type == type &&
        v.capacity == capacity &&
        v.height == height &&
        v.from == from?.city &&
        v.to == to
    );

    if (v !== undefined) {
      setDistance(v.distance);
      setTime(v.time);
      setPrice(v.price);
      setCommission(v.commission);
    } else {
      setDistance("");
      setTime("");
      setPrice("");
      setCommission("");
      // alert("This combination not available please choose another combination");
    }
  };

  useEffect(() => {
    if (
      type !== "" &&
      height !== "" &&
      capacity !== "" &&
      from !== "" &&
      to !== ""
    ) {
      getDistanceTimePrice();
    }
  }, [type, height, capacity, from, to]);

  useEffect(() => {
    form.setFieldsValue({ to: null, capacity: null, height: null, type: null });
  }, [from]);

  return (
    <div>
      <div>
        <h4 style={{ color: "#fff" }}>{t("Route Prices")}</h4>
        <Form form={form} name="make_request_form">
          <Input.Group>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  className="inp-emai"
                  hasFeedback
                  name="from"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <Select
                    style={{ border: "2px solid #1890ff" }}
                    placeholder={t("placeholders.from")}
                    onChange={(val) => {
                      let value = cities[val];
                      form.setFieldsValue({ from: value.city });
                      setFrom(value);
                      dispatch(
                        MakeRequestAction({ ...makeRequestData, from: value })
                      );
                    }}
                  >
                    {cities &&
                      cities.map((v, i) => {
                        return (
                          <Option key={i} value={i}>
                            {v.city}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  style={{ border: "2px solid #1890ff" }}
                  hasFeedback
                  name="to"
                  rules={[
                    {
                      required: true,
                      message: "Required Field!",
                    },
                  ]}
                >
                  <Select
                    placeholder="To"
                    className="sign-ema"
                    onChange={(v) => {
                      let value = v;
                      form.setFieldsValue({ to: value });
                      setTo(value);
                      dispatch(
                        MakeRequestAction({ ...makeRequestData, to: value })
                      );
                    }}
                  >
                    {uniqueToValues &&
                      uniqueToValues.map((v, i) => (
                        <Option key={i} value={v}>
                          {v.city}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Input.Group>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  hasFeedback
                  name="capacity"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <Select
                    style={{ border: "2px solid #1890ff" }}
                    className="inp-emai"
                    placeholder={t("placeholders.selectCapacity")}
                    onChange={(v) => {
                      let value = [v];
                      form.setFieldsValue({ capacity: value });
                      setCapacity(value);
                      dispatch(
                        MakeRequestAction({
                          ...makeRequestData,
                          capacity: value,
                        })
                      );
                    }}
                  >
                    {uniqueCapacityValues &&
                      uniqueCapacityValues.map((v, i) => {
                        return (
                          <Option key={i} value={v}>
                            {v.capacity}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  style={{ border: "2px solid #1890ff" }}
                  hasFeedback
                  name="height"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <Select
                    className="sign-ema"
                    placeholder={t("Select Height")}
                    onChange={(v) => {
                      let value = [v];
                      form.setFieldsValue({ height: value });
                      setHeight(value);
                      dispatch(
                        MakeRequestAction({
                          ...makeRequestData,
                          height: value,
                        })
                      );
                    }}
                  >
                    {uniqueHeightValues &&
                      uniqueHeightValues.map((v, i) => {
                        return (
                          <Option key={i} value={v}>
                            {v.height}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Input.Group>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  hasFeedback
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <Select
                    className="inp-emai"
                    style={{ border: "2px solid #1890ff" }}
                    placeholder={t("placeholders.selectType")}
                    onChange={(v) => {
                      let value = v;
                      form.setFieldsValue({ type: value });
                      setType(value);
                      dispatch(
                        MakeRequestAction({ ...makeRequestData, type: value })
                      );
                    }}
                  >
                    {uniqueTypeValues &&
                      uniqueTypeValues.map((v, i) => {
                        return (
                          <Option key={i} value={v}>
                            {v.type}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Input.Group>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item className="inp-emai">
                  <Input
                    value={time}
                    disabled
                    className="inp-emai"
                    style={{ border: "2px solid #1890ff" }}
                    placeholder={t("placeholders.estimateTime")}
                    addonAfter=" Day"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item className="sign-ema">
                  <Input
                    value={distance}
                    disabled
                    style={{ border: "2px solid #1890ff" }}
                    placeholder={t("placeholders.distance")}
                    addonAfter="KM"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Input.Group>
          <Input.Group>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item className="inp-emai">
                  <Input
                    className="inp-emai"
                    value={price}
                    disabled
                    style={{ border: "2px solid #1890ff" }}
                    placeholder={t("placeholders.price")}
                    addonAfter="SAR"
                  />
                </Form.Item>
              </Col>
              {user.isRelation && relation?.customerId && (
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="specific"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      style={{ border: "2px solid #1890ff" }}
                      className="inp-emai"
                      mode="multiple"
                      placeholder={"Send Your request to specific owners"}
                    >
                      {relation?.owners?.map((v) => {
                        return (
                          <Option key={v.ownerId} value={v.ownerId}>
                            {v.ownerName}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Input.Group>
          <Form.Item>
            <Space>
              <Button onClick={() => window.history.back()}>{"Cancel"}</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default PriceTable;
