import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal,Form,Input,message } from "antd";
import {
  MailOutlined,
} from "@ant-design/icons";
import { auth } from "../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { FORGET_PASSWORD_CUSTOMER , FORGET_PASSWORD_OWNER} from "../../graphql/Mutation/adminMutation";
import { useMutation } from "@apollo/client";
import Loader from "../Loaders/Loader";
import {
  LockOutlined, // New import for password fields
} from "@ant-design/icons";
export default function ForgetPasswordModal({ isModalVisible, setIsModalVisible }) {
  const [sendPasswordResetEmailCustomer] = useMutation(FORGET_PASSWORD_CUSTOMER);
  const [sendPasswordResetEmailOwner] = useMutation(FORGET_PASSWORD_OWNER);
  const { t, i18n } = useTranslation();
  const [loading,setLoading]=useState(false)
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  // const onFinish = (values) => {
  //   setLoading(true)
  //   let email = (values.email).toLowerCase();
  //    sendPasswordResetEmail(auth, email)
  //      .then(() => {
  //        let msg =
  //          "An email reset password link has been sent to " +
  //          email +
  //          "\n Check yor gmail inbox or spam to reset the password and login!";
  //          setLoading(false);
  //        message.success(msg);
  //        handleCancel();

         
  //      })
  //      .catch((e) => {
  //        setLoading(false);
  //        message.error(e.code);

  //      });
  // }
  // const checkOwnerExists = async (email) => {
  //   // Replace this with your actual database or API query logic to check for the owner's existence
  //   // You might use a library like Mongoose, Sequelize, or an API request, depending on your setup.
  //   // Return the owner data if it exists, or null if not found.
  // };
  // const onFinish = async (values) => {
  //   console.log("Input values:", values); // Log the input values to the console
  //   setLoading(true);
  //   try {
  //     if (!values || !values.email || !values.newPassword || !values.confirmPassword) {
  //       throw new Error("Invalid input data.");
  //     }
  
  //     // Perform a query to check if the email exists in the owner collection
  //     const owner = await checkOwnerExists(values.email);
  
  //     if (owner) {
  //       // Email exists in the owner collection, proceed with the password reset
  //       const { data } = await sendPasswordResetEmailOwner({
  //         variables: {
  //           email: values.email,
  //           newPassword: values.newPassword,
  //           confirmPassword: values.confirmPassword,
  //         },
  //       });
  
  //       if (data && data.sendPasswordResetEmailOwner && data.sendPasswordResetEmailOwner.success) {
  //         message.success(data.sendPasswordResetEmailOwner.message);
  //         message.success("An email reset password link has been sent to your Gmail inbox or spam. Check your email to reset your password and log in.");
  //       } else {
  //         message.error(data.sendPasswordResetEmailOwner.message);
  //       }
  //     } else {
  //       // Email doesn't exist in the owner collection
  //       message.error("Email not found in the owner collection.");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while sending the reset email:", error);
  //     message.error("An error occurred while sending the reset email.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // const [forgetPassword, {  error }] = useMutation(FORGET_PASSWORD_OWNER);

  // const onFinish = async (values) => {
  //   try {
  //     setLoading(true);
  //     const email = values.email.toLowerCase();

  //     const { data } = await forgetPassword({
  //       variables: {
  //         forgetPasswordInput: {
  //           email: email,
  //           newPassword: values.newPassword,
  //           confirmPassword: values.confirmPassword,
  //         },
  //       },
  //     });

  //     if (data.forgetPassword.success) {
  //       const msg = `An email reset password link has been sent to ${email}. Check your email inbox or spam to reset the password and login!`;
  //       setLoading(false);
  //       message.success(msg);
  //       handleCancel();
  //     } else {
  //       setLoading(false);
  //       message.error(data.forgetPassword.message);
  //     }
  //   } catch (e) {
  //     setLoading(false);
  //     message.error('An error occurred while processing your request.');
  //   }
  // };
  const [forgetPasswordOwner, { error: ownerError }] = useMutation(FORGET_PASSWORD_OWNER);
  const [forgetPasswordCustomer, { error: customerError }] = useMutation(FORGET_PASSWORD_CUSTOMER);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const email = values.email.toLowerCase();

      const ownerResponse = await forgetPasswordOwner({
        variables: {
          forgetPasswordInput: {
            email: email,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword,
          },
        },
      });

      if (ownerResponse.data.forgetPassword.success) {
        const msg = `An email reset password link has been sent to ${email}. Check your email inbox or login!`;
        setLoading(false);
        message.success(msg);
        handleCancel();
      } else {
        // Check if the email is a customer's email
        const customerResponse = await forgetPasswordCustomer({
          variables: {
            forgetPasswordInput: {
              email: email,
              newPassword: values.newPassword,
              confirmPassword: values.confirmPassword,
            },
          },
        });

        if (customerResponse.data.forgetPasswordC.success) {
          const msg = `An email reset password link has been sent to ${email}. Check your email inbox or login as a customer!`;
          setLoading(false);
          message.success(msg);
          handleCancel();
        } else {
          setLoading(false);
          message.error('Email not found.');
        }
      }
    } catch (e) {
      setLoading(false);
      message.error('An error occurred while processing your request.');
    }
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <Modal
        title={t("Modal.forgetorResestPassword")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        <div>
          <Form name="normal_login" className="login-form" onFinish={onFinish}>
            <Form.Item
              name="email"
              hasFeedback
              rules={[
                {
                  type: "email",
                  message: t("errorsTxt.invalidEmail"),
                },
                {
                  required: true,
                  message: t("errorsTxt.requiredField"),
                },
              ]}
            >
              <Input
                type="email"
                prefix={<MailOutlined className="site-form-item-icon" />}
                placeholder={t("placeholders.email")}
              />
            </Form.Item>
            <Form.Item
              name="newPassword"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please enter your new password.",
                },
              ]}
            >
              <Input
                type="password"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="New Password"
              />
            </Form.Item>
            <Form.Item
              name="confirmPassword"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match.")
                    );
                  },
                }),
              ]}
            >
              <Input
                type="password"
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Confirm Password"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button submit_btn"
              >
                <b>{t("Modal.submit")}</b>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
}