import { gql } from "@apollo/client";

const LOGIN_DRIVER = gql`
mutation LoginDriver($loginInput: LoginInputDriver) {
    loginDriver(loginInput: $loginInput) {
      success
      message
      code
      data {
        token
        status
        owner {
          type
          token
          rRA
          profileImage
          phoneNumber
          ownerName
          name
          isDeleted
          isApproved
          isAccept
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          bankInfo {
            iBanNumber
            beneficiaryName
            bankIdCode
          }
          _id
        }
        nationality
        name
        isTruck
        imageUrl
        idNo
        createdAt
        age
        address
        _id
      }
    }
  }
`;

const GET_FindDriverTruckByDriverOrTruckID = gql`
query FindDriverTruckByDriverOrTruckID($driverOrTruckId: ID!) {
    findDriverTruckByDriverOrTruckID(driverOrTruckID: $driverOrTruckId) {
      truckNo
      truck {
        type
        status
        serviseType
        owner {
          type
          token
          rRA
          profileImage
          phoneNumber
          ownerName
          name
          isDeleted
          isApproved
          isAccept
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          bankInfo {
            iBanNumber
            beneficiaryName
            bankIdCode
          }
          _id
        }
        noPlate
        model
        isDriver
        imageUrl
        height
        createdAt
        capacity
        _id
      }
      owner {
        type
        token
        rRA
        phoneNumber
        profileImage
        name
        ownerName
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
      driverName
      driver {
        token
        status
        owner {
          _id
          bankInfo {
            bankIdCode
            beneficiaryName
            iBanNumber
          }
          crNumber
          type
          token
          rRA
          profileImage
          phoneNumber
          ownerName
          name
          isDeleted
          isApproved
          isAccept
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
        }
        nationality
        name
        isTruck
        imageUrl
        idNo
        createdAt
        age
        address
        _id
      }
      createdAt
      _id
    }
  }
`;

const GET_DRIVER = gql`
query Driver($id: ID!) {
    driver(ID: $id) {
      _id
      name
      age
      address
      nationality
      idNo
      isTruck
      token
      createdAt
      imageUrl
      status
      owner {
        type
        token
        profileImage
        phoneNumber
        ownerName
        rRA
        name
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
    }
  }
`;
const GET_TRUCK_WITH_DRIVER_ID = gql`
query TruckIdNo($idNo: String) {
  truckIdNo(idNo: $idNo) {
    _id
    model
    noPlate
    serviseType
    isDriver
    capacity
    height
    type
    createdAt
    imageUrl
    status
  }
}
`;




export { LOGIN_DRIVER, GET_DRIVER, GET_FindDriverTruckByDriverOrTruckID,
  GET_TRUCK_WITH_DRIVER_ID
};