import React, { useState } from "react";
import {
  Modal,
  Button,
  Input,
  Form,
  Upload,
  Row,
  Col,
  Select,
  message,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import { AddTruck, uploadImage } from "../API/API";
import Loader from "../Loaders/Loader";
import useCapacites from "../Hooks/useCapacities";
import useHeights from "../Hooks/useHeights";
import useTypes from "../Hooks/useTypes";
import useTrucks from "../Hooks/useTrucks";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { CREATE_TRUCK } from "../../graphql/Mutation/owner/addTruckMutation";
export default function AddTruckModal({ isModalVisible, setIsModalVisible }) {
  const { Option } = Select;
  const { capacities } = useCapacites();
  const { types } = useTypes();
  const { heights } = useHeights();
  const { trucks } = useTrucks();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [createTruck] = useMutation(CREATE_TRUCK);
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };
  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };
  const [formData, setFormData] = useState({
    truckLicense: "",
  });
  const onFinish = async (values) => {
    console.log(values,"1234567890qwertyuiopasdfghjklzxcvbnm,sa")
    if (!trucks?.some((v) => v.truckPlate === values.truckPlate)) {
      setLoading(true);
      if (formData.truckLicense !== "") {
        await uploadImage("Trucks/", formData.truckLicense).then((res) => {
          formData.truckLicense = res;
          setFormData({ ...formData });
        });
      }
      const ownerId = JSON.parse(localStorage.getItem("id"));
      let data = {
        registerTruckInput: {
          type: values.truckType, 
        status: "active", 
        serviseType: values.serviseType, 
        owner: ownerId,
        noPlate: values.truckPlate, 
        model: values.truckModel, 
        imageUrl: "active", 
        height: values.truckHeight,
        capacity:values.truckCapacity
        }
      };
  
      await createTruck({
        variables: {
          registerTruckInput: data.registerTruckInput
        }
      }).then(() => {
        setLoading(false);
        form.resetFields();
        handleCancel();
        message.success("Add Successful!");
      }).catch((e) => {
        setLoading(false);
        message.error("error while creating the Truck");
      });
    } else {
      alert("Truck plate number already exists. Please change it.");
    }
  };
  // const onFinish = async (values) => {
  //   if (!trucks?.some((v) => v.truckPlate === values.truckPlate)) {
  //     setLoading(true);
  //     if (formData.truckLicense !== "") {
  //       await uploadImage("Trucks/", formData.truckLicense).then((res) => {
  //         formData.truckLicense = res;
  //         setFormData({ ...formData });
  //       });
  //     }
  //     let data = {
  //       ...values,
  //       truckLicenseUpload: formData.truckLicense,
  //       isDriver: false,
  //     };
  //     await AddTruck(data)
  //       .then(() => {
  //         setLoading(false);
  //         form.resetFields();
  //         handleCancel();
  //         message.success("Add Successull!");
  //       })
  //       .catch((e) => {
  //         setLoading(false);
  //         message.error(JSON.stringify(e));
  //       });
  //   } else {
  //     alert("truck plate number already exist please change it");
  //   }
  // };
  return (
    <div>
      <Modal
        title={t("Modal.addNewTruck")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form form={form} name="add_trcuk_form" onFinish={onFinish}>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    className="inp-emai"
                    hasFeedback
                    name="truckModel"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("placeholders.modal")}
                      style={{ border: "2px solid #2d808b" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="truckPlate"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      style={{ border: "2px solid #2d808b" }}
                      placeholder={t("placeholders.plate")}
                      className="sign-ema"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>

            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="truckCapacity"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      className="inp-emai"
                      style={{ border: "2px solid #2d808b" }}
                      placeholder={t("placeholders.selectCapacity")}
                    >
                      {capacities &&
                        capacities.map((v, i) => {
                          return (
                            <Option key={i} value={v.capacity}>
                              {v.capacity}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="sign-ema"
                    hasFeedback
                    name="truckHeight"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      placeholder={t("placeholders.selectHight")}
                      style={{ border: "2px solid #2d808b" }}
                    >
                      {}
                      {heights &&
                        heights.map((v, i) => {
                          return (
                            <Option key={i} value={v.height}>
                              {v.height}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    className="inp-emai"
                    hasFeedback
                    name="truckType"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Select
                      placeholder={t("placeholders.selectType")}
                      style={{ border: "2px solid #2d808b" }}
                    >
                      {types &&
                        types.map((v, i) => {
                          return (
                            <Option key={i} value={v.type}>
                              {v.type}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    style={{ border: "2px solid #2d808b" }}
                    className="sign-ema"
                    hasFeedback
                    name="truckLicenseUpload"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Upload
                      // accept="image/*"
                      multiple={false}
                      maxCount={1}
                      name="logo"
                      listType="picture"
                      onChange={(e) => {
                        if (e.file.status === "removed") {
                          formData.truckLicense = "";
                          setFormData({
                            ...formData,
                          });
                        } else {
                          formData.truckLicense = e.file.originFileObj;
                          setFormData({
                            ...formData,
                          });
                        }
                      }}
                    >
                      <Button icon={<UploadOutlined />}>
                        <div className="btn-uplo">
                          {t("placeholders.uploadLiense")}
                        </div>
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>

            <Button
              style={{ border: "2px solid #2d808b" }}
              htmlType="submit"
              className="login-form-button lang-btn"
            >
              {t("Modal.add")}
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
