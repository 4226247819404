import { db, auth, storage, fcmToken } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import {
  serverTimestamp,
  collection,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { message } from "antd";
import { isSupported } from "firebase/messaging";
import axios from "axios";
import { DELETE_DRIVERS } from "../../graphql/Mutation/owner/addDriverMutation";
import { useMutation } from "@apollo/client";

export const setFCMToken = async (id, data) => {
  let Ref = doc(collection(db, "fcm_tokens"), id);
  setDoc(Ref, { ...data }, { merge: true });
};


// Create user with email and password
export const AddUser = async (data) => {
  return new Promise(async (resolve, reject) => {
    await createUserWithEmailAndPassword(auth, data.email, data.password)
      .then(async (userCredential) => {
        let userAuth = userCredential.user;
        await sendEmailVerification(userAuth);
        const userRef = doc(collection(db, data.type), auth.currentUser.uid);
        delete data.password;
        let user = {
          ...data,
          uid: auth.currentUser.uid,
          createdAt: serverTimestamp(),
          emailVerified: userAuth.emailVerified,
        };
        await setDoc(userRef, user)
          .then(() => {
            const nRef = doc(collection(db, "notifications"));
            let dta = {
              title: `New ${user.type}!`,
              desc: `New User are comming for Approvel ${data.name} have Phone Number ${data.phoneNumber}`,
              status: `new_${user.type}`,
              to: ["admin"],
              type: "new_user",
              from: user.type,
              isRead: false,
              uid: nRef.id,
              createdAt: serverTimestamp(),
            };
            setDoc(nRef, dta);
            resolve({
              status: "success",
              message: "ok",
            });
          })
          .catch((e) => {
            reject({
              status: "fail",
              message: e.code,
            });
          });
      })
      .catch((e) => {
        console.log('dddddddddddddddd',e)
        reject({
          status: "fail",
          message: e.code,
          
        });
      });
      console.log("new Data Here ")
  });
};



// export const FindUser = async (data) => {

//   if (data?.userType === 'driver') {
//     const q = query(
//       collection(db, 'drivers'),
//       where("driverIdNumber", "==", data?.drivernumber)
//       );

//       console.log("++dddd",data.drivernumber)
//       console.log('++++++++',q)
//       const querySnapshot = await getDocs(q);
//     let driverId;
//     querySnapshot.forEach((doc) => {
//       console.log(doc.id, " => ", doc.data());
//       driverId = doc.id;
//     });

//     console.log("Driver ID: ", driverId);
//     console.log("Query Snapshot: ", querySnapshot);

//     localStorage.setItem("uid", JSON.stringify(driverId));
//     localStorage.setItem("type", JSON.stringify(data.userType));
//     return new Promise((resolve, reject) => {
//       resolve({
//         status: "success",
//         message: "ok",
//       })
//       isSupported().then((value) => {
//         if (value) {
//           Notification.requestPermission().then(async (permission) => {
//             if (permission === "granted") {
//               await fcmToken().then(async (res) => {
//                 if (res !== "") {
//                   let tokenData = {
//                     wToken: res,
//                     type: 'driver',
//                     uid: driverId,
//                   };
//                   await setFCMToken(driverId, tokenData).then(() => {
//                     resolve({
//                       status: "success",
//                       message: "ok",
//                     });
//                   });
//                 } else {
//                   resolve({
//                     status: "success",
//                     message: "ok",
//                   });
//                 }
//               });
//             } else {
//               resolve({
//                 status: "success",
//                 message: "ok",
//               });
//             }
//           });
//         } else {
//           resolve({
//             status: "success",
//             message: "ok",
//           });
//         }
//       });
//       reject(err => alert(err.message))
//     })

export const FindUser = async (data) => {
  if (data?.userType === 'driver') {
    const q = query(
      collection(db, 'drivers'),
      where("driverIdNumber", "==", data?.drivernumber),
       where("password", "==", data?.userPassword)
    );
    // console.log("++dddd", data.drivernumber);
    // console.log('++++++++', q);
    const querySnapshot = await getDocs(q);
    let driverId;
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
      driverId = doc.id;
    });

    if (driverId) {
      // console.log("Driver ID: ", driverId);
      // console.log("Query Snapshot: ", querySnapshot);

      localStorage.setItem("uid", JSON.stringify(driverId));
      localStorage.setItem("type", JSON.stringify(data.userType));
      
      return new Promise((resolve, reject) => {
        resolve({
          status: "success",
          message: "ok",
        });
        isSupported().then((value) => {
          if (value) {
            Notification.requestPermission().then(async (permission) => {
              if (permission === "granted") {
                await fcmToken().then(async (res) => {
                  if (res !== "") {
                    let tokenData = {
                      wToken: res,
                      type: 'driver',
                      uid: driverId,
                    };
                    await setFCMToken(driverId, tokenData).then(() => {
                      resolve({
                        status: "success",
                        message: "ok",
                      });
                    });
                  } else {
                    resolve({
                      status: "success",
                      message: "ok",
                    });
                  }
                });
              } else {
                resolve({
                  status: "success",
                  message: "ok",
                });
              }
            });
          } else {
            resolve({
              status: "success",
              message: "ok",
            });
          }
        });
      });
    }  else {
      if (data?.userType === 'driver') {
        const q = query(
          collection(db, 'drivers'),
          where("driverIdNumber", "==", data?.drivernumber),
        );
      
        const querySnapshot = await getDocs(q);
      
        if (!querySnapshot.empty) {
        } else {
          message.error("Your driver ID is wrong.");
        }
      }

     if (data?.userType === 'driver') {
        const q = query(
          collection(db, 'drivers'),
          where("password", "==", data?.userPassword)
        );
        const querySnapshot = await getDocs(q);
      
        if (!querySnapshot.empty) {
        } else {
          message.error("Your password is wrong.");
        }
      }
     
       else {
        message.error("Driver not found");
      }
      // return {
      //   status: "fail",
      //   message: "Credentials are Incorrect.",
      // };
    }
  }
  else {
    const userQuery = query(
      collection(db, data.userType),
      where("email", "==", data.userEmail)
    );
    return new Promise(async (resolve, reject) => {
      await signInWithEmailAndPassword(auth, data.userEmail, data.userPassword)
        .then(async (userCredential) => {
          const user = userCredential.user;
          if (user.emailVerified) {
            await updateDoc(doc(db, data.userType, auth.currentUser.uid), {
              emailVerified: user.emailVerified,
            });
            let snap = await getDocs(userQuery);
            if (!snap.empty) {
              if (snap.docs[0].data().isApproved) {
                let id = auth.currentUser.uid;
                localStorage.setItem("uid", JSON.stringify(id));
                localStorage.setItem("type", JSON.stringify(data.userType));
                isSupported().then((value) => {
                  if (value) {
                    Notification.requestPermission().then(async (permission) => {
                      if (permission === "granted") {
                        await fcmToken().then(async (res) => {
                          if (res !== "") {
                            let tokenData = {
                              token: res,
                              type: data.userType,
                              uid: id,
                            };
                            await setFCMToken(id, tokenData).then(() => {
                              resolve({
                                status: "success",
                                message: "ok",
                              });
                            });
                          } else {
                            resolve({
                              status: "success",
                              message: "ok",
                            });
                          }
                        });
                      } else {
                        resolve({
                          status: "success",
                          message: "ok",
                        });
                      }
                    });
                  } else {
                    resolve({
                      status: "success",
                      message: "ok",
                    });
                  }
                });
              } else {
                reject({
                  status: "fail",
                  message:
                    "Not approved yet please contact with admin for approvel",
                });
              }
            } else {
              reject({
                status: "fail",
                message: "user not found in " + data.userType,
              });
            }
          } else {
            reject({
              status: "fail",
              message:
                "Email not verified please check your email and spam for email verification!",
            });
          }
        })
        .catch((e) => {
          reject({
            status: "fail",
            message: e.code,
          });
        });
    });
  }
};


export const uploadImage = async (path, file) => {

  const Id = JSON.parse(localStorage.getItem("uid"));
  if (file !== "" && file?.name && file?.name !== undefined) {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, "uploads/" + path + Id + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.log(error);
          reject(error);
        },

        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            resolve(url);
          });
        }
      );
    });
  }
};
// export const Logout = async () => {
//   if (window.confirm("Are you sure you want to logout?")) {
//     await auth
//       .signOut()
//       .then(() => {
//         localStorage.clear();
//         window.location.replace("/");
//       })
//       .catch((e) => {
//         alert(e);
//       });
//   }
// };
export const Logout = async () => {
  if (window.confirm("Are you sure you want to logout?")) {
    await auth
      .signOut()
      .then(() => {
        localStorage.clear();
        window.location.replace("/");
        

        // Clear the browser history by replacing the current URL with the homepage URL
        window.history.replaceState(null, null, '/');
        

        // Add a listener to the onpopstate event
        const handlePopState = () => {
          // Redirect the user back to the homepage if they try to navigate using the back button
          window.location.replace('/');
        };

        window.addEventListener('popstate', handlePopState);

        // Perform any additional cleanup or logout-related operations here

      })
      .catch((e) => {
        alert(e);
      });
  }
};

//user
export const UpdateUser = async (id, data) => {
  let type = JSON.parse(localStorage.getItem("type")) || "";

  let Ref = doc(db, type, id);
  await updateDoc(Ref, data).then(() => {
    message.success("update Successfully!");
  });
};
///Owner

export const AddDriver = async (data) => {
  const Id = JSON.parse(localStorage.getItem("uid"));
  const Ref = doc(collection(db, "drivers"));
  await setDoc(Ref, {
    ...data,
    uid: Ref.id,
    ownerId: Id,
    createdAt: serverTimestamp(),
  });
};

export const UpdateDriver = async (id, data) => {
  let Ref = doc(db, "drivers", id);
  await updateDoc(Ref, data).then(() => { });
};


// export const DeleteDriver = async (id, data) => {
//   if (data.isTruck) {
//     let tRef = doc(db, "trucks", data.truckId);
//     await updateDoc(tRef, { isDriver: false, driverId: "" });
//   }
//   let dRef = doc(db, "drivers", id);
//   await deleteDoc(dRef);
// };
// const [updateDriver] = useMutation(UPDATE_DRIVERS);

export const DeleteDriver = async (id) => {
  try {
    const [deleteDriverMutation] = useMutation(DELETE_DRIVERS);
    const { data } = await deleteDriverMutation({
      variables: {
        id: id,
        isArchive: true, 
      },
    });

    // Check the response and handle the success or error
    if (data && data.deleteDriver.success) {
      // Handle success
      console.log("Driver deleted successfully");
    } else {
      // Handle error
      console.error("Error deleting driver:", data.deleteDriver.message);
    }
  } catch (error) {
    console.error("An error occurred while deleting the driver:", error);
  }
};



export const AddTruck = async (data) => {
  const Id = JSON.parse(localStorage.getItem("uid"));
  const Ref = doc(collection(db, "trucks"));
  await setDoc(Ref, { ...data, uid: Ref.id, ownerId: Id }).then(async () => { });
};
export const UpdateTruck = async (id, data) => {
  let Ref = doc(db, "trucks", id);
  await updateDoc(Ref, data).then(async () => { });
};
export const DeleteTruck = async (id, data) => {
  if (data.isDriver) {
    let Ref = doc(db, "drivers", data.driverId);
    await updateDoc(Ref, { isTruck: false, truckId: "" });
  }
  let Ref = doc(db, "trucks", id);
  await deleteDoc(Ref);
};
export const MergeTruckDriver = async (data) => {
  const Id = JSON.parse(localStorage.getItem("uid"));

  let Ref = doc(collection(db, "transports"));
  await setDoc(Ref, { ...data, uid: Ref.id, ownerId: Id }).then(async () => {
    let Ref = doc(db, "drivers", data.driver.uid);
    await updateDoc(Ref, { isTruck: true, truckId: data.truck.uid }).then(
      async () => {
        let Ref = doc(db, "trucks", data.truck.uid);
        await updateDoc(Ref, { isDriver: true, driverId: data.driver.uid });
      }
    );
  });
};
export const DeleteTruckDriver = async (id, data) => {
  let Ref = doc(db, "drivers", data.driver.uid);
  await updateDoc(Ref, { isTruck: false, truckId: "" })
    .then(async () => {
      let Ref = doc(db, "trucks", data.truck.uid);
      await updateDoc(Ref, { isDriver: false, driverId: "" });
    })
    .then(async () => {
      let Ref = doc(db, "transports", id);
      await deleteDoc(Ref);
    });
};

///Customer

export const AddRequest = async (data) => {
  console.log(data)
  const Id = JSON.parse(localStorage.getItem("uid"));
  const Ref = doc(collection(db, "customer_transport_requests"));
  const nRef = doc(collection(db, "notifications"));
  var rid = Math.floor(1000000000 + Math.random() * 9000000000);
  let notificationId = nRef.id;
  let rdata = {
    ...data,
    uid: Ref.id,
    customerId: Id,
    createdAt: serverTimestamp(),
    notificationId: notificationId,
    requestId: rid,
  };
  await setDoc(Ref, rdata).then(async () => {
    let dta = {
      title: "New Request!",
      desc: `New Request for transport are comming from ${data.name} have Phone Number ${data.phoneNumber} from ${data.from} to ${data.to}`,
      status: "created",
      type: "request",
      to: ["admin"],
      from: "Customer",
      isRead: false,
      uid: notificationId,
      createdAt: serverTimestamp(),
    };

    setDoc(nRef, dta);
  });
};

export const DeleteRequest = async (id, data) => {
  let Ref = doc(db, "customer_transport_requests", id);
  await deleteDoc(Ref);
};
export const updateRequest = async (id, data, ndata) => {
  let Ref = doc(db, "customer_transport_requests", id);
  await updateDoc(Ref, { ...data }).then(() => {
    if (data.isAccept) {
      const nRef = doc(db, "notifications", ndata.uid);
      updateDoc(nRef, {
        ...ndata,
        updatedAt: serverTimestamp(),
        isRead: false,
      });
    }
  });
};
export const readNotifications = async (data) => {
  if (data && data.length > 0) {
    data.map((v) => {
      updateDoc(doc(db, "notifications", v.uid), { isRead: true });
    });
  }
};

const checkToken = () => {
  let id = auth.currentUser.uid;
  let tokensQuery = query(collection(db, "fcm_tokens"));
};
export const sendMessage = async (data) => {
  console.log("notify ", data);
  let url = "https://fcm.googleapis.com/fcm/send";
  let headers = {
    "Content-Type": "application/json",
    Authorization:
      "key=AAAA8rN4ed4:APA91bHi2tICNsH0Ijok2Yir7tjeB5JPCEwTfKyUJ-cjxm3Er6LkC_6qfzc6AZ4eRHZOIUKbB_DV4Ao0MSOGBP_0jgae7PSHAm-cLTWMYsvorhrAQWIb8hveMbA3diTnhfa3ZR-UMcrO",
  };
  let body = JSON.stringify({
    notification: {
      title: data.title,
      body: data.body,
    },
    // data: {
    //   ...data.data,
    // },
    registration_ids: data.token,
  });
  axios({
    method: "post",
    url: url,
    data: body,
    headers: headers,
  }).then((res) => {
    if (res.status === 200) {
      console.log(res);
      // message.success('notification send succssfully!');
    }
  });
};

