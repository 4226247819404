import { PlusCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Input, Pagination, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useSeaFreightNewRequst from "../Hooks/useSeaFreightNewRequst";
import { useState } from "react";
import useCustomerRequests from "../Hooks/useCustomerRequests";

const SeaFreightFinanceTable = () => {
  const { requestData } = useSeaFreightNewRequst();
  const filterData = requestData.filter((el) => el.status === "finance");
  const [search, setSearch] = useState("");

  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest, count} = useCustomerRequests(updateTrigger, paginationFilters);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const columns = [
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "Customer Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Customer Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Container Type",
      dataIndex: "containertype",
      key: "containertype",
    },
    {
      title: "Container Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Container Weight",
      key: "quantity",
      render(record) {
        return <span>{record.weight} (kg)</span>;
      },
    },
  ];
  return (
    <>
      <div style={{ marginBottom: ".6rem", marginTop: "1rem" }}>
        <Input
          onChange={(e) => setSearch(e.target.value)}
          prefix={<SearchOutlined />}
          placeholder="search"
        />
      </div>
      <Table pagination={false}
      dataSource={allRequest} columns={columns} />
      <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </>
  );
};

export default SeaFreightFinanceTable;
