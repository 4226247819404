import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Button, 
  // Input, Popconfirm, 
  Space, 
  // Select, 
  Pagination } from "antd";

// import {
// //   PlusCircleOutlined,
// //   SearchOutlined,
// //   UploadOutlined,
// //   DeleteOutlined,
// //   EditOutlined,
// // } from "@ant-design/icons";

import useTransports from "../Hooks/useTransport";
import useOwnerRequets from "../Hooks/useOwnerRequests";
// import { sendMessage, updateRequest } from "../API/API";
// import { doc, getDoc } from "firebase/firestore";
// import { db } from "../firebase";
import useGetAllDriverTruck from "../Hooks/useGetAllRequests";

import { SEND_TRANSPORT_REQUEST } from "../../graphql/Mutation/customer/sendTransportRequestMutation";
import { useMutation } from "@apollo/client";
export default function SendRequestTable() {
  const { transports } = useTransports();
  const ownerId = JSON.parse(localStorage.getItem("id"));
  
  const { ownerRequests } = useOwnerRequets();
  const { id } = useParams();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  // const [selected, setSelected] = useState([);

  
  const [selected, setSelected] = useState(null);

  const [loading, setLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { driverTrucks, count} = useGetAllDriverTruck(updateTrigger,paginationFilters, ownerId);
  console.log("🚀 ~ file: TruckDriverTable.jsx:32 ~ TruckDriverTable ~ driverTrucks:", driverTrucks)
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  


  const columns = [
    {
      title: "Model",
        // dataIndex: "truckModel",
        key: "truckModel",
      render: (record) => <Space>{record?.truck?.model}</Space>,
    },
    {
      title: "Plate",
        // dataIndex: "truckPlate",
        key: "truckplate",
      render: (record) => <Space>{record?.truck?.noPlate}</Space>,
    },
    {
      title: "Type",
        // dataIndex: "truckType",
        key: "truckType",
      render: (record) => <Space>{record?.truck?.type}</Space>,
    },
    {
      title: "Capacity",
        // dataIndex: "truckCapacity",
        key: "truckCapacity",
      render: (record) => <Space>{record?.truck?.capacity}</Space>,
    },
    {
      title: "Height",
        // dataIndex: "truckCapacity",
        key: "truckCapacity",
      render: (record) => <Space>{record?.truck?.height}</Space>,
    },
    {
      title: "Driver Name",
      // dataIndex: "driver",
      key: "driver",
      render: (record) => <Space>{record?.driver?.name}</Space>,
    },
    {
      title: "Driver Age",
      // dataIndex: "driver",
      key: "driver",
      render: (record) => <Space>{record?.driver?.age}</Space>,
    },
    {
      title: "Driver Address",
      // dataIndex: "driver",
      key: "driver",
      render: (record) => <Space>{record?.driver?.address}</Space>,
    },
  ];

  const rowSelection = {
    type: "radio",
    selectedRowKeys: selected ? [selected.id] : [],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelected(selectedRows[0]); 
    },
  };
  
  
  
  // console.log(
  //   transports.filter((val) =>
  //     !ownerRequests?.some(
  //       (v) =>
  //         (
  //           v.status !== "finished" &&
  //           v.isAccept !== undefined &&
  //           v.acceptedBy !== undefined &&
  //           v.acceptedBy?.transportId === val.uid
  //         )
  //     )
  //   )
  // );

  const [updateRequest] = useMutation(SEND_TRANSPORT_REQUEST);

  // const handleSendRequest = async () => {
  //   if (selected.length === 0) {
  //     alert("Please select at least one transport.");
  //     return;
  //   }
  
  //   const selectedTransport = selected[0]; // Get the first selected item
  
  //   try {
  //     // Execute the GraphQL mutation to update the request
  //     const { data } = await updateRequestTransport({
  //       variables: {
  //         id: selectedTransport.id, // Use the ID of the selected transport
  //         updateRequestInput: {
  //           isAccept: true,
  //           acceptedBy: {
  //             ownerId: selectedTransport.ownerId,
  //             transportId: selectedTransport.id,
  //             driverId: selectedTransport.driver.id,
  //           },
  //         },
  //       },
  //     });
  
  //     // Handle the response here
  //     if (data.updateRequest.success) {
  //       // The request was successfully updated
  //       navigate(-1); // Navigate to the desired location
  //     } else {
  //       // Handle any errors or display an error message
  //       alert(`Error: ${data.updateRequest.message}`);
  //     }
  //   } catch (error) {
  //     console.error("Error updating the request:", error);
  //     // Handle errors here, e.g., display an error message to the user.
  //   }
  // };
  // const { id } = useParams();

  const handleSendRequest = async () => {
    if (!selected) {
      alert("Please select a transport.");
      return;
    }
  
  
    // const selectedTransport = selected; 
  
    try {
      const { data } = await updateRequest({
        variables: {
          // id: "654255f924ad33ec4365e252",
          id: id,
          updateRequestInput: {
            isAccept: true,
            acceptedBy: {
              ownerId: ownerId,
              transportId: selected._id,
              driverId: selected.driver._id,
            },
          },
        },
      });
      console.log(data,"................................mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm...............................")
      if (data.updateRequest.success) {
        navigate(-1); 
      } else {
        alert(`Error: ${data.updateRequest.message}`);
      }
    } catch (error) {
      if (error.graphQLErrors) {
        console.error("GraphQL validation errors:", error.graphQLErrors);
      }
      console.error("Error updating the request:", error);
    }
  }
  
  


  return (
    <div>
      <div className="table_topar_add_div_">
        {/* <Button
          type="primary"
          size={"middle"}
          onClick={async () => {
            let request = ownerRequests.find((v) => v.id === id);
            if (selected.length > 0) {
              if (request?.isChangeTransport) {
                let data = {
                  acceptedBy: {
                    ownerId: selected[0].ownerId,
                    transportId: selected[0].id,
                    driverId: selected[0].driver.id,
                  },
                  isAccept: true,
                  isChangeTransport: false,
                  isStarted: false,
                };
                let ndata = {
                  title: "Transport Updated!",
                  desc: `Request from  ${request.name} for ${request.from} to ${request.to} tranport is updated`,
                  status: "accepted",
                  type: "request",
                  to: ["admin"],
                  from: "Owner",
                  uid: request.notificationId,
                };
                await updateRequest(id, data, ndata).then(async () => {
                  navigate(-1);
                });
              } else {
                let data = {
                  ...request,
                  acceptedBy: {
                    ownerId: selected[0].ownerId,
                    transportId: selected[0].uid,
                    driverId: selected[0].driver.uid,
                  },
                  sendTo: [selected[0].ownerId],
                  isAccept: true,
                  isStarted: true,
                  driverStarted: false,
                };
                let ndata = {
                  title: "Request Accepted!",
                  desc: `Request from  ${request.name} for ${request.from} to ${request.to} are accepted`,
                  status: "approved",
                  type: "request",
                  to: [selected[0].driver.uid, request.customerId],
                  from: "Owner",
                  uid: request.notificationId,
                };
                const fcmToken = await getDoc(
                  doc(db, "fcm_tokens", selected[0].driver.uid)
                );
                sendMessage({
                  title: "New Fleet Request",
                  body: "Hi You received new fleet request from your owner.",
                  token: [fcmToken?.data()?.token, fcmToken?.data()?.wToken],
                });
                await updateRequest(id, data, ndata).then(async () => {
                  // ///khan
                  // try {
                  //   let url = `http://localhost:5000/api/payments/requestSchedule/${request.uid}`;
                  //   // let url = `https://oac-apis.vercel.app/api/payments/requestSchedule/${request.uid}`;
                  //   const response = await fetch(url, {
                  //     method: "POST",
                  //   });
                  //   const result = await response.json();
                  //   console.log("request schedule result is ", result);
                  // } catch (e) {
                  //   console.log("schedule is failed ", e);
                  // }
                  // //khan
                  navigate(-1);
                });
              }
            } else {
              alert("please select at least on transport");
            }
          }}
        >
          {"Send"}
        </Button> */}
        <Button type="primary" size={"middle"} onClick={handleSendRequest}>
        Send
      </Button>
      </div>
      <div className="table_search_div">
        {/* <Input
          allowClear
          size="middle"
          placeholder="Serach"
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        /> */}
      </div>
      <Table
        columns={columns}
        pagination={false}
        rowKey={(record) => record.id}
        rowSelection={{
          ...rowSelection,
          checkStrictly: true,
          type: "radio",
        }}
        dataSource={
          driverTrucks &&
          driverTrucks.length > 0 &&
          driverTrucks
            .filter(
              (val) =>
                !ownerRequests?.some(
                  (v) =>
                    v.status !== "finished" &&
                    v.isAccept !== undefined &&
                    v.isAccept &&
                    v.acceptedBy !== undefined &&
                    v.acceptedBy?.transportId === val.id
                )
            )
            .filter((val) => {
              if (search == "") {
                return val;
              } else if (
                (val &&
                  Object.keys(val.driver).some((v) =>
                    val[v]
                      .toString()
                      .toLowerCase()
                      .includes(search.toString().toLowerCase())
                  )) ||
                Object.keys(val.truck).some((v) =>
                  val[v]
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase())
                )
              ) {
                return val;
              }
            })
        }
      />
      <Pagination
        defaultCurrent={1}
        defaultPageSize={paginationFilters.pageSize}
        onChange={handleChange}
        total={count}
        className="Pagination"
      />
    </div>
  );
}
