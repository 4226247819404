import React,{useEffect} from "react";
import { Banner } from "../components/Home/Banner/Banner";
import { Gallery } from "../components/Home/InspirationGallery/Gallery";
import { PerfectSwagger } from "../components/Home/PerfectSwagger/PerfectSwagger";
import { ReadyPicSwagger } from "../components/Home/ReadyPicSwagger/ReadyPicSwagger";
import { SwaggerWork } from "../components/Home/SwaggerWork/SwaggerWork";
import { PublicLayout } from "../components/PublicLayout/PublicLayout";
import { Navber } from "../components/Home/navber/navber";
import Chatt from "../../../chat-box/Chat-box";
export const Landing = () => {
  // useEffect(() => {
  //   const disableBackButton = (event) => {
  //     event.preventDefault();
  //     window.history.pushState(null, '', '/');
  //   };

  //   window.addEventListener('popstate', disableBackButton);

  //   return () => {
  //     window.removeEventListener('popstate', disableBackButton);
  //   };

  // }, []);

  return (
    <PublicLayout>
      <Chatt />
      <Navber />
      <Banner />
      <PerfectSwagger />
      <SwaggerWork />
      <Gallery />
      <ReadyPicSwagger />
    </PublicLayout>
  );
};
