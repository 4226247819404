import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { useNavigate } from "react-router-dom";
const { confirm } = Modal;
export default function CustomAlert({
  title,
  content,
  isNavigate,
  navigateUrl,
}) {
  const navigate = useNavigate();
  confirm({
    title,
    icon: <ExclamationCircleFilled />,
    content,
    onOk() {
      isNavigate && navigate(navigateUrl);
    },
  });
}
