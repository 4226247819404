import React from "react";
import { Routes, Route } from "react-router-dom";
import CustomerLayout from "../Components/Layouts/CustomerLayout/Layout/CustomerLayout";
import Finance from "../Components/CustomerComponents/Finance/Finance";
import CustmorChat from "./Customer/Custmor-chat";
import PaymentSuccess from "../Components/CustomerComponents/payments-success-error/PaymentSuccess";
import PaymentError from "../Components/CustomerComponents/payments-success-error/PaymentError";
import NewRequest from "../Components/CustomerComponents/TransportRequests/NewRequest";
import RequestDetails from "../Components/CustomerComponents/TransportRequests/RequestDetails";
import Clearance from "../Components/CustomerComponents/Clearance/Clearance";
import ClearanceNewRequest from "../Components/CustomerComponents/Clearance/ClearanceNewRequest";
import SeeFreight from "../Components/CustomerComponents/see-freight/SeeFreight";
import SeaFreightNewRequest from "../Components/CustomerComponents/see-freight/SeaFreightNewRequest";
import { TransportRequest } from "../Components/CustomerComponents/TransportRequests/TransportRequest";
// import RequestDetails from "../Components/OwnerComponents/TRequests/RequestDetails";
// import RequestDetails from "../Components/CustomerComponents/"
export default function CustomerRoutes() {
  return (
    <div>
      <Routes>
        <Route
          path="tarnsport_requests"
          element={
            <CustomerLayout>
              <CustmorChat />
              <TransportRequest />
            </CustomerLayout>
          }
        />
        <Route
           path="request_detail/:id"
          element={
            <CustomerLayout>
              <CustmorChat />
              <RequestDetails />
            </CustomerLayout>
          }
        />
        <Route
          path="tarnsport_requests/new"
          element={
            <CustomerLayout>
              <CustmorChat />
              <NewRequest />
            </CustomerLayout>
          }
        />
        <Route
          path="finance"
          element={
            <CustomerLayout>
              <CustmorChat />
              <Finance />
            </CustomerLayout>
          }
        />
        <Route
          path="payment-success"
          element={
            <CustomerLayout>
              <CustmorChat />
              <PaymentSuccess />
            </CustomerLayout>
          }
        />
        <Route
          path="payment-error"
          element={
            <CustomerLayout>
              <CustmorChat />
              <PaymentError />
            </CustomerLayout>
          }
        />
        <Route
          path="clearance"
          element={
            <CustomerLayout>
              <Clearance />
            </CustomerLayout>
          }
        />
        <Route
          path="/clearance/new"
          element={
            <CustomerLayout>
              <ClearanceNewRequest />
            </CustomerLayout>
          }
        />
        <Route
          path="/see-freight"
          element={
            <CustomerLayout>
              <SeeFreight />
            </CustomerLayout>
          }
        />
        <Route
          path="/sea-freight/new"
          element={
            <CustomerLayout>
              <SeaFreightNewRequest />
            </CustomerLayout>
          }
        />
      </Routes>
    </div>
  );
}
