import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import OwnerRequestTable from "../../Tables/OwnerRequestsTable";
import OwnerFinishedReqTable from "../../Tables/OwnerFinishedReqTable";
import { Badge, Tabs } from "antd";
import UnderProcess from "../../Tables/UnderProcess";
import useOwnerRequests from "../../Hooks/useOwnerRequests";
import useCheckActiveTab from "../../../hooks/useCheckActiveTab";

export default function TRequests() {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  // const { ownerRequests } = useOwnerRequests();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { ownerRequests } = useOwnerRequests(
    updateTrigger,
    paginationFilters
  );
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const { activeTab, handleTabChange } = useCheckActiveTab();
  console.log(ownerRequests)

  return (
    <div className="inp-emai">
      <div>
        <h3>{t("routes.transportation_request")}</h3>
      </div>
      <div>
        <Tabs onChange={handleTabChange} activeKey={activeTab}>
          <TabPane
            key="1"
            tab={
              <Badge
                offset={[10, -6]}
                count={
                  <span
                    style={{
                      backgroundColor: " grey",
                      marginTop: "-7px",
                      marginRight: "10px",
                    }}
                    className="Span-reqest"
                  >
                    {
                      ownerRequests?.filter(
                        (item) =>
                          item.status === "created"
                      ).length
                    }
                  </span>
                }
              >
                {t("tableTabs.request")}
              </Badge>
            }
          >
            <OwnerRequestTable />
          </TabPane>

          <TabPane
            key="2"
            tab={
              <Badge
                offset={[10, -6]}
                count={
                  <span
                    style={{
                      backgroundColor: "blue",
                      marginTop: "-7px",
                      marginRight: "10px",
                    }}
                    className="Span-reqest"
                  >
                    {
                      ownerRequests?.filter(
                        (item) =>
                          item.status === "created"
                      ).length
                    }
                  </span>
                }
              >
                {t("Under Processing")}
              </Badge>
            }
          >
            <UnderProcess />
          </TabPane>

          <TabPane
            key="3"
            tab={
              <Badge
                className="text-badge"
                offset={[10, 0]}
                count={
                  <span
                    style={{
                      backgroundColor: " #ceb745",
                      marginTop: "-6.5px",
                      marginRight: "10px",
                    }}
                    className="Span-reqest"
                  >
                    {
                      ownerRequests?.filter(
                        (val) =>
                          val.status === "created"
                      ).length
                    }
                  </span>
                }
              >
                {t("tableTabs.finishedRequests")}
              </Badge>
            }
          >
            <OwnerFinishedReqTable />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
