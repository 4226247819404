import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  Row,
  Space,
  Upload,
  message,
} from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { uploadImage } from "../../API/API";
import { collection, doc, setDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../firebase";
import Loader from "../../Loaders/Loader";
import { CREATE_CUSTOMER_TRANSPORT_REQUEST } from "../../../graphql/Mutation/customer/transportRequestMutation";
import { useMutation } from "@apollo/client";
import {  useNavigate } from "react-router-dom";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const ClearanceNewRequest = () => {
  const navigate = useNavigate();
  const C_id = JSON.parse(localStorage.getItem("uid"));
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);

  // simple input to upload multiple files

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  //end

  const MAX_COUNT = 10;
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.forEach((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  //delete selected files
  const handleFileDelete = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles.splice(index, 1);
    setUploadedFiles(updatedFiles);
  };
  //end

  // onFinish request there to create clearance
  //  // Function to handle image upload 4
  const [clearanceUploadUrl, setClearanceUploadUrl] = useState(null);
  const [clearanceUploading, setIClearanceUploading] = useState(false);

  const handleImageUpload4 = (file) => {
    if (!file) return;
    const imageRef = ref(storage, `CustomerRequest/${file.name}`);
    setIClearanceUploading(true);
    uploadBytes(imageRef, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((url) => {
        setClearanceUploadUrl(url);
        console.log("Upload successful. URL:", url);
      })
      .catch((error) => {
        console.error("Error during image upload:", error);
      })
      .finally(() => {
        setIClearanceUploading(false);
      });
  };
  const handleImageDelete4 = () => {
    setClearanceUploadUrl(null);
  };
  const customRequest4 = ({ file }) => {
    handleImageUpload4(file);
  };
  const handleChange4 = ({ fileList }) => {
    console.log(fileList);
  };

const [ closeModal1, setCloseModal1 ] = useState(false)
const closeModal = () => {
  // Use the setCloseModal1 function to update the state
  setCloseModal1(false);
};
  const [createRequest] = useMutation(CREATE_CUSTOMER_TRANSPORT_REQUEST);
  const customerId = JSON.parse(localStorage.getItem("id"));

  const onFinish = async (values) => {
    setLoading(true);
    console.log(values);
    try {
      const customerRequestInput = {
        customer: customerId,
        status: "created",
        billOfLandingClearance: values?.pilloflading,
        customerNameClearance: values?.cname,
        uploadClearance: clearanceUploadUrl,
      };

      const { data } = await createRequest({
        variables: { customerRequestInput },
      });
      setLoading(false);
      if (data.createRequest.success) {
        form.setFieldsValue({});
        message.success("Add Successful!");
        setLoading(false);
        navigate(-1);
        closeModal();
        setUploadedFiles([]);
        closeModal();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error in onFinish function:", error);
    }
  };
  // const onFinish = async (values) => {
  //   let attachments = [];

  //   const { pilloflading, cname,files } = values;
  //   try {
  //     const ref = doc(collection(db, "clearance_request"));
  //     const nRef = doc(collection(db, "notifications"));
  //     var rid = Math.floor(1000000000 + Math.random() * 9000000000);
  //     let notificationId = nRef.id;
  //     setLoading(true);

  //     if (files.length > 0) {
  //       for (let i = 0; i < files.length; i++) {
  //         const file = files[i];
  //         const res = await uploadImage("clearance/", file);
  //         attachments.push(res);
  //       }
  //     }
  //     const data = {
  //       uid: ref.id,
  //       customerId: C_id,
  //       pilloflading,
  //       cname,
  //       attachments,
  //       status: "created",
  //       notificationId: notificationId,
  //       requestId: rid,
  //     };

  //     // Create the document in the Firebase collection with the attachments array
  //     await setDoc(ref, data).then(async () => {
  //       let dta = {
  //         title: "New Request!",
  //         desc: `New Request for Clearance are comming from ${data.cname}`,
  //         status: "created",
  //         type: "request",
  //         to: ["admin"],
  //         from: "Customer",
  //         isRead: false,
  //         uid: notificationId,
  //         createdAt: serverTimestamp(),
  //       };

  //       setDoc(nRef, dta);
  //     });

  //     message.success("created successfully");
  //     form.resetFields();
  //     setUploadedFiles([])

  //   } catch (error) {
  //     alert(error.message);
  //   }

  //   setLoading(false);
  // };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    form.setFieldsValue({ files: uploadedFiles });
  }, [uploadedFiles]);

  return (
    <>
      {loading ? <Loader /> : ""}
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="pilloflading"
              style={{ border: "2px solid #1890ff" }}
              rules={[
                {
                  required: true,
                  message: "input required",
                },
              ]}
            >
              <Input placeholder="Bill Of Lading" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="cname"
              style={{ border: "2px solid #1890ff" }}
              rules={[
                {
                  required: true,
                  message: "input required",
                },
              ]}
            >
              <Input placeholder="Customer Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              style={{ border: "2px solid #1890ff" }}
              name="files"
              valuePropName="fileList"
              getValueFromEvent={(e) => e && e.fileList}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please upload at least one file",
              //   },
              // ]}
            >
              <Upload
                customRequest={customRequest4}
                onChange={handleChange4}
                fileList={
                  clearanceUploadUrl
                    ? [
                        {
                          uid: "-1",
                          name: "image.png",
                          status: "done",
                          url: clearanceUploadUrl,
                        },
                      ]
                    : []
                }
                showUploadList={{
                  showPreviewIcon: false,
                  showRemoveIcon: true,
                  removeIcon: <DeleteOutlined onClick={handleImageDelete4} />,
                }}
              >
                <Button icon={<UploadOutlined />} loading={clearanceUploading}>
                  UPLOAD
                </Button>
              </Upload>
              {clearanceUploadUrl && (
                <div style={{ margin: "10px" }}>
                  <Image src={clearanceUploadUrl} width={50} />
                </div>
              )}
              {/* <label
                htmlFor="fileUpload"
                style={{
                  border: "2px solid rgb(24, 144, 255)",
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "1.1rem",
                  color: "d9d9d9",
                  margin: "10px",
                }}
              >
                <UploadOutlined
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "5px",
                  }}
                />
                Upload
                <input
                  id="fileUpload"
                  type="file"
                  className="upload-img"
                  multiple
                  accept="image/*,.doc,.docx,.pdf"
                  onChange={handleFileEvent}
                  disabled={fileLimit}
                  style={{ display: "none" }}
                />
              </label>
              <div className="uploaded-files-list">
                {uploadedFiles.map((file, index) => (
                  <div style={{ border: "1px solid red", margin: "10px" }}>
                    <div>{file.name}</div>
                    <img
                      src={URL.createObjectURL(file)}
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "5px",
                        margin: "5px",
                      }}
                      alt={file.name}
                    />
                    <button
                      onClick={() => handleFileDelete(index)}
                      style={{
                        display: "flex",
                        float: "right",
                        border: "none",
                        color: "red",
                        background: "transparent",
                        alignContent: "center",
                        textAlign: "center",
                        marginRight: "20px",
                        fontSize: "1.4rem",
                        cursor: "pointer",
                      }}
                    >
                      <DeleteOutlined />
                    </button>
                  </div>
                ))}
              </div> */}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <Button className="make-btn" type="primary" htmlType="submit">
              <span>Create</span>
            </Button>
            <Button onClick={() => window.history.back()}>{"Cancel"}</Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default ClearanceNewRequest;
