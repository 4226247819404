import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, message } from "antd";
  
  import { collection, doc, setDoc,serverTimestamp } from "firebase/firestore";
import { db } from "../../firebase";
import Loader from "../../Loaders/Loader";
import useDestination from "../../Hooks/useDestination";
import useSource from "../../Hooks/useSource";
import { CREATE_CUSTOMER_TRANSPORT_REQUEST } from "../../../graphql/Mutation/customer/transportRequestMutation";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";



const SeaFreightNewRequest = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const id = JSON.parse(localStorage.getItem("uid"));
  const { destinationn } = useDestination();
  const {sourcce} = useSource();  
  const { Option } = Select;
  const [destination, setDestination] = useState("");
  const [source, setSource] = useState("");

// onFinished function here 
const [createRequest] = useMutation(CREATE_CUSTOMER_TRANSPORT_REQUEST);
const customerId = JSON.parse(localStorage.getItem("id"));

const onFinish = async (values) => {
  setLoading(true);
  console.log(values);
  try {
    const customerRequestInput = {
      customer: customerId,
      status: "created",
      routeFromSeaFreight: values?.source,
      routeToSeaFreight: values?.destination,
      containerSizeSeaFreight: values?.containertype,
      containerQuantitySeaFreight: values?.quantity,
      containerWeightSeaFreight: values?.weight,
      customerNameSeaFreight: values?.cname,
      customerEmailSeaFreight: values?.email,
      customerPhoneSeaFreight: values?.phone,
    };

    const { data } = await createRequest({
      variables: { customerRequestInput },
    });
    setLoading(false);
    if (data.createRequest.success) {
      form.setFieldsValue({});
      message.success("Add Successful!");
      setLoading(false);
      navigate(-1);
    } else {
      setLoading(false);
    }
  } catch (error) {
    setLoading(false);
    console.error("Error in onFinish function:", error);
  }
};

  // const onFinish = async (values) => {
  //   const { cname: customerName, ...rest } = values;
  //   try {
  //     const ref = doc(collection(db, "sea-freight"));
  //     const nRef = doc(collection(db, "notifications"));
  //     var rid = Math.floor(1000000000 + Math.random() * 9000000000);
  //     let notificationId = nRef.id;
  //     setLoading(true);
  //     const data = {
  //       customerName,
  //       ...rest,
  //       status: "created",
  //       uid: ref.id,
  //       customerId: id,
  //       createdAt: new Date(),
  //       notificationId: notificationId,
  //       requestId: rid,
  //     };

  //     await setDoc(ref, data).then(async () => {
  //       let dta = {
  //         title: "New Request!",
  //         desc: `New Request for SeaFreight are comming from ${data.customerName}`,
  //         status: "created",
  //         type: "request",
  //         to: ["admin"],
  //         from: "Customer",
  //         isRead: false,
  //         uid: notificationId,
  //         createdAt: serverTimestamp(),
  //       };
    
  //       setDoc(nRef, dta);
  //     });

  //     // await setDoc(ref, data);
  //     message.success("created!");
  //     console.log("Success:", values);
  //     form.resetFields();
  //   } catch (error) {
  //     message.error(error.message);
  //   }
  //   setLoading(false);
  // };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  return (
    <>
      {loading ? <Loader /> : ""}
      <Form
        form={form}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <h5 style={{ fontWeight: "600" }}>Route </h5>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              hasFeedback
              name="source"
              rules={[
                {
                  required: true,
                  message: "Requerd Field!",
                },
              ]}
            >
              <Select
                style={{ width: "100%", border: "2px solid #1890ff" }}
                // onChange={handleChange}
                onChange={(val) => {
                  let value = sourcce[val];
                  form.setFieldsValue({ source: value.source });
                  setSource(value);
                 
                }}
                placeholder="Source"
                // options={[
                //   {
                //     value: "saudiarabia",
                //     label: "Saudi Arabia",
                //   },
                // ]}
              >
                {sourcce &&
                  sourcce.map((v, i) => {
                    return (
                      <Option key={i} value={i}>
                        {v.source}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              hasFeedback
              name="destination"
              rules={[
                {
                  required: true,
                  message: "Requerd Field!",
                },
              ]}
            >
              <Select
                style={{ width: "100%", border: "2px solid #1890ff" }}
                // onChange={handleChange}
                onChange={(val) => {
                  let value = destinationn[val];
                  form.setFieldsValue({ destination: value.destination });
                  setDestination(value);
                 
                }}
                placeholder="Destination"
                // options={[
                //   {
                //     value: "pakistan",
                //     label: "Pakistan",
                //   },
                //   {
                //     value: "india",
                //     label: "India",
                //   },
                //   {
                //     value: "iran",
                //     label: "Iran",
                //   },
                //   {
                //     value: "dubai",
                //     label: "Dubai",
                //   },
                // ]}
              >
                 {destinationn &&
                  destinationn.map((v, i) => {
                    return (
                      <Option key={i} value={i}>
                        {v.destination}
                      </Option>
                    );
                  })}

              </Select>
            </Form.Item>
          </Col>
        </Row>
        <h5 style={{ marginTop: ".7rem", fontWeight: "600" }}>Container </h5>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              hasFeedback
              name="containertype"
              rules={[
                {
                  required: true,
                  message: "Requerd Field!",
                },
              ]}
            >
              <Select
                style={{ width: "100%", border: "2px solid #1890ff" }}
                onChange={handleChange}
                
                placeholder="Select Type"

                
                options={[
                  {
                    value: "small",
                    label: "Small",
                  },
                  {
                    value: "medium",
                    label: "Medium",
                  },
                  {
                    value: "large",
                    label: "Large",
                  },
                ]}
              >
               
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="quantity"
              rules={[
                {
                  required: true,
                  message: "required!",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Container Quantity"
                style={{ width: "100%", border: "2px solid #1890ff" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="weight"
              rules={[
                {
                  required: true,
                  message: "required!",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Container Weight (kg)"
                style={{ border: "2px solid #1890ff", padding: ".3rem " }}
              />
            </Form.Item>
          </Col>
        </Row>
        <h5 style={{ marginTop: ".7rem", fontWeight: "600" }}>
          Customer Information
        </h5>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              name="cname"
              rules={[
                {
                  required: true,
                  message: "required!",
                },
              ]}
            >
              <Input
                type="text"
                placeholder="Customer Name"
                style={{ border: "2px solid #1890ff", padding: ".3rem " }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "required!",
                },
              ]}
            >
              <Input
                type="email"
                placeholder="Customer Email"
                style={{ border: "2px solid #1890ff", padding: ".3rem " }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "required!",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Customer Phone"
                style={{ border: "2px solid #1890ff", padding: ".3rem " }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item style={{ marginTop: "2rem" }}>
          <Space>
            <Button className="make-btn" type="primary" htmlType="submit">
              <span>Create</span>
            </Button>
            <Button onClick={() => window.history.back()}>{"Cancel"}</Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default SeaFreightNewRequest;
