import React, { useState } from "react";
import { Avatar, Badge, Button, Tabs } from "antd";
import useCheckActiveTab from "../../../hooks/useCheckActiveTab";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ClearanceNewRequestTable from "../../Tables/ClearanceNewRequestTable";
import ClearanceUnderProcessRequest from "../../Tables/ClearanceUnderProcessRequest";
import ClearanceFinalizingTable from "../../Tables/ClearanceFinalizingTable";
import ClearanceFinanaceTable from "../../Tables/ClearanceFinanaceTable";
import ClearanceCompleteTable from "../../Tables/ClearanceCompleteTable";
import useClearanceNewRequest from "../../Hooks/useClearanceNewRequest";
import useCustomerRequests from "../../Hooks/useCustomerRequests";

const Clearance = () => {
  const { activeTab, handleTabChange } = useCheckActiveTab();
  const { clearanceRequest} = useClearanceNewRequest();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest } = useCustomerRequests(
    updateTrigger,
    paginationFilters
  );
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };

  const { TabPane } = Tabs;
  const { t } = useTranslation();

  return (
    <>
      <Tabs defaultActiveKey={activeTab} onChange={handleTabChange}>
        <TabPane
          tab={
            <Badge
              offset={[10, 0]}
              count={
                <span
                  style={{
                    backgroundColor: "green",
                    marginTop: "-7px",
                    marginRight: "10px",
                  }}
                  className="Span-reqest"
                >
                  {
                    allRequest?.filter((el) => el.status === "created")
                      .length
                  }
                </span>
              }
            >
              <span>{t("tableTabs.newRequests")}</span>
            </Badge>
          }
          key="1"
        >
          <ClearanceNewRequestTable />
        </TabPane>
        <TabPane
          tab={
            <Badge
              offset={[10, 0]}
              count={
                <span
                  style={{
                    backgroundColor: "blue",
                    marginTop: "-7px",
                    marginRight: "10px",
                  }}
                  className="Span-reqest"
                >
                  {
                    allRequest?.filter(
                      (el) => el.status === "underprocess"
                    ).length
                  }
                </span>
              }
            >
              <span>Under Process Request</span>
            </Badge>
          }
          key="2"
        >
          <ClearanceUnderProcessRequest />
        </TabPane>
        <TabPane
          tab={
            <Badge
              offset={[10, 0]}
              count={
                <span
                  style={{
                    backgroundColor: "#001529",
                    marginTop: "-7px",
                    marginRight: "10px",
                  }}
                  className="Span-reqest"
                >
                  {
                    allRequest?.filter((el) => el.status === "finalizing")
                      .length
                  }
                </span>
              }
            >
              <span>Finalizing</span>
            </Badge>
          }
          key="3"
        >
          <ClearanceFinalizingTable />
        </TabPane>
        <TabPane
          tab={
            <Badge
              offset={[10, 0]}
              count={
                <span
                  style={{
                    backgroundColor: "#d06d2c",
                    marginTop: "-7px",
                    marginRight: "10px",
                  }}
                  className="Span-reqest"
                >
                  {
                    allRequest?.filter((el) => el.status === "finance")
                      .length
                  }
                </span>
              }
            >
              <span>Finance</span>
            </Badge>
          }
          key="4"
        >
          <ClearanceFinanaceTable />
        </TabPane>
        <TabPane
          tab={
            <Badge
              offset={[10, 0]}
              count={
                <span
                  style={{
                    backgroundColor: "brown",
                    marginTop: "-7px",
                    marginRight: "10px",
                  }}
                  className="Span-reqest"
                >
                  {
                    allRequest?.filter((el) => el.status === "complete")
                      .length
                  }
                </span>
              }
            >
              <span>Complete</span>
            </Badge>
          }
          key="5"
        >
          <ClearanceCompleteTable />
        </TabPane>
      </Tabs>
    </>
  );
};

export default Clearance;
