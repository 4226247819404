import "./request.css";
import { Button, Modal, Pagination, Space, Switch } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import {
  collection,
  onSnapshot,
  query,
  where,
  doc,
  updateDoc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";

import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SEND_TRANSPORT_REQUEST } from "../../../graphql/Mutation/customer/sendTransportRequestMutation";
import { useQuery } from "@apollo/client";
import useCustomerRequests from "../../Hooks/useCustomerRequests";

const Request = () => {
  const [requestData, setRequestData] = useState([]);

  const [isDisabled, setIsDisabled] = useState(false);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [showLocationError, setShowLocationError] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  //function to download the image
  const downloadImage = (imgUrl) => {
    saveAs(imgUrl, "image.jpg");
  };
  //end download image func

  //location function if location is not on

  //end location func

  let driverName,
    truckPlate,
    driverId,
    from,
    to,
    truckId,
    driveruid,
    owneruid,
    notificationId;
  const id = JSON.parse(localStorage.getItem("id"));

  // ------- get-owners-request ---------

  // const { loading, error, data } = useQuery(SEND_TRANSPORT_REQUEST, {
  //   variables: { page: 1 },
  // });

  // useEffect(() => {
  //   if (data && data.customerRequests) {
  //     setRequestData(data.customerRequests.requests);
  //   }
  // }, [data]);

  const [updateTrigger, setUpdateTrigger] = useState(true);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest, count } = useCustomerRequests(
    updateTrigger,
    paginationFilters
  );

  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  //function to download the image

  //end download image func

  //location function if location is not on

  //end location func

  // ------- get-owners-request ---------

  // const { loading, error, data } = useQuery(SEND_TRANSPORT_REQUEST, {
  //   variables: { page: 1 },
  // });

  // useEffect(() => {
  //   if (data && data.customerRequests) {
  //     setRequestData(data.customerRequests.requests);
  //   }
  // }, [data]);

  // console.log("Data in component:", allRequest, count);
  // // const id = JSON.parse(localStorage.getItem("id"));
  // console.log("🚀 ~ file: Request.jsx:88 ~ Request ~ id:", id);

  let arr = allRequest?.filter((val) => val.acceptedBy?.driverId === id);
  // console.log("🚀 ~ file: Request.jsx:91 ~ Request ~ arr:", arr);

  // console.log("data", arr);

  // const getOwnerRequests = async () => {
  //   let q = query(
  //     collection(db, "customer_transport_requests"),
  //     where("status", "!=", "finished"),
  //     where("isStarted", "==", true)
  //   );
  //   const unsub = onSnapshot(q, (querySnapShot) => {
  //     if (!querySnapShot.empty) {
  //       let arr = [];
  //       querySnapShot.forEach((val) => {
  //         if (val.data().acceptedBy.driverId == uid) {
  //           notificationId = val.data().notificationId;
  //           driverId = val.data().acceptedBy.driverId;
  //           from = val.data()?.from;
  //           to = val.data()?.to;
  //           arr.push(val.data());
  //         }
  //         setRequestData(arr);
  //       });
  //     } else {
  //       setRequestData([]);
  //     }
  //   });
  //   return () => unsub();
  // };

  // useEffect(() => {
  //   getOwnerRequests();
  // }, []);

  requestData.forEach((item) => {
    notificationId = item?.notificationId;
    driverId = item?.acceptedBy.driverId;
    from = item?.from;
    to = item?.to;
  });

  // -------- get current-location ----------
  const successCallback = (position) => {
    setLatitude(position?.coords?.latitude);
    setLongitude(position?.coords?.longitude);
  };
  // const errorCallback = (error) => {
  //   console.log(error);
  // };
  const errorCallback = (error) => {
    if (error.code === 1) {
      // setShowLocationError(true);
    } else {
      console.log("Geolocation error:", error.message);
    }
  };
  
  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
  const driverLocation = {
    latitude: latitude,
    longitude: longitude,
  };



  
  // -------- update-firebase-documents ------------
  //also handle the loaction permission if loaction is not on show modal and if on continue as usual
  const updateStatus = async () => {
    if ("geolocation" in navigator) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
        console.log(position,"position here")
        setIsDisabled(true);
        localStorage.setItem("isButtonDisabled", JSON.stringify(true));
        // const ref = doc(db, "customer_transport_requests", requestData[0].uid);
        const ref = doc(db, requestData[0].uid);
        await updateDoc(ref, {
          driverStarted: true,
          driverLocation,
        });
        // const driverRef = doc(db, "drivers", driverId);
        const driverRef = doc(db, driverId);
        const driverDoc = await getDoc(driverRef);
        if (driverDoc.exists()) {
          driverName = driverDoc.data()?.driverName;
          truckId = driverDoc.data()?.truckId;
          owneruid = driverDoc.data()?.ownerId;
          driveruid = driverDoc.data()?.uid;
        }
        // const truck = doc(db, "trucks", truckId);
        const truck = doc(db, truckId);
        const truckDoc = await getDoc(truck);
        if (truckDoc.exists()) {
          truckPlate = truckDoc.data()?.truckPlate;
        }
        // const updateNotificationRef = doc(db, "ificationnots", notificationId);
        const updateNotificationRef = doc(db, notificationId);
        await updateDoc(updateNotificationRef, {
          title: "Ride Started",
          desc: `A ride has been started for ${driverName} with truck plate ${truckPlate} from ${from} to ${to}.`,
          status: "started",
          to: ["admin", owneruid, driveruid, requestData[0]?.customerId],
          type: "request",
          from: "Driver",
          uid: notificationId,
          isRead: false,
          createdAt: serverTimestamp(),
        });
        // const financeRef = doc(db, "finance", requestData[0].uid);
        const financeRef = doc(db,requestData[0].uid);
        await updateDoc(financeRef, {
          ownerId: owneruid,
          driverId: driveruid,
        });

        navigate("/driver/drivermap");
      } catch (error) {
        setShowLocationError(true);
        console.error(error);
      }
    } else {
      console.error("Geolocation not available in this browser.");
    }
  };

  const showMapHandler = () => {
    navigate("/driver/drivermap");
  };

  // const currentDriverId = JSON.parse(localStorage.getItem("id"))
  return (
    <>
      {arr.length !== 0 ? (
        arr.map((el, id) => (
          <div key={id} className="request">
            <div className="inp-emai">
              <h3 style={{ textAlign: "center" }}>{t("driver.request")}</h3>
            </div>
            <div className="request-detail">
              <div className="request-inner">
                <div>
                  <h6>{t("driver.requestid")}</h6>
                  {/* <span>{el?._id}</span> */}
                  <span>{el?._id.slice(-4)}</span>
                </div>
                <div>
                  <h6>{t("driver.name")}</h6>  
                  <span>{el?.name}</span>
                </div>
              </div>
              <div className="request-inner">
                <div>
                  <h6>{t("driver.from")}</h6>
                  <span>{el?.from}</span>
                </div>
                <div>
                  <h6>{t("driver.to")}</h6>
                  <span>{el?.to}</span>
                </div>
              </div>
              <div className="request-inner">
                <div>
                  <h6>{t("driver.distance")}</h6>
                  <span>{el?.distance}</span>
                </div>
                <div>
                  <h6>{t("driver.price")}</h6>
                  <span>{el?.price}</span>
                </div>
              </div>
              <div className="request-inner">
                <div>
                  <h6>{t("driver.type")}</h6>
                  <span>{el?.type}</span>
                </div>
                <div>
                  <h6>{t("driver.capacity")}</h6>
                  <span>{el?.serviceType}</span>
                </div>
              </div>
              <div
                className="request-inner"
                style={{ display: "flex", alignItems: "center" }}
              >
                <div>
                  <h6>Ticket</h6>
                </div>
                <div>
                  <a href={el.ticket} download target="_blank">
                    <img
                      src={el.ticket}
                      alt=""
                      style={{ width: "160px", height: "250x" }}
                      onClick={() => downloadImage(el.ticket)}
                    />
                  </a>
                  <Button onClick={() => downloadImage(el.ticket)}>
                    <DownloadOutlined />
                  </Button>
                </div>
              </div>
              <div className="request-inner">
                <div>
                  <h6>{t("driver.createddate")}</h6>
                  <span>
                    {new Date(el?.createdAt).toLocaleString("en-US", {
                      month: "short",
                      day: "2-digit",
                      year: "2-digit",
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })}
                  </span>
                </div>
                {/* <div className="request-btn">
                  {requestData[0]?.driverStarted === false && (
                    <Button type="primary" onClick={updateStatus}>
                      {t("driver.start")}
                    </Button>
                  )}
                  {requestData[0]?.driverStarted === true && (
                    <Button type="primary" onClick={showMapHandler}>
                      {t("driver.showmap")}
                    </Button>
                  )}
                </div> */}
                <div className="request-btn">
                  {requestData[0]?.driverStarted === false && (
                    <Button type="primary" onClick={updateStatus}>
                      {t("driver.start")}
                    </Button>
                  )}
                  {requestData[0]?.driverStarted === true && (
                    <Button type="primary" onClick={showMapHandler}>
                      {t("driver.showmap")}
                      {console.log(showMapHandler)}
                    </Button>
                  )}
                </div>

                {/* Modal for showing location error */}
                <Modal
                  title="Location Permission Required"
                  visible={showLocationError}
                  onCancel={() => setShowLocationError(false)}
                  footer={null}
                >
                  <p>
                    To start the ride, you need to grant location permission.
                    Please allow location access in your browser settings.
                  </p>
                </Modal>
              </div>
            </div>
          </div>
        ))
      ) : (
        <h5
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {t("driver.norequest")}
        </h5>
      )}
      <Pagination
        defaultCurrent={1}
        defaultPageSize={paginationFilters.pageSize}
        onChange={handleChange}
        total={count}
        className="Pagination"
      />
    </>
  );
};

export default Request;
