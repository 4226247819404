// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot, where } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useTrucks() {
//   const id = JSON.parse(localStorage.getItem("uid"));
//   const [trucks, setTrucks] = useState([]);
//   const getTrucks = async () => {
//     let q = query(collection(db, "trucks"), where("ownerId", "==", id));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setTrucks([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           setTrucks((prev) => [...prev, val.data()]);
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getTrucks();
//   }, []);

//   return { trucks };
// }
// import React, { useEffect, useState } from "react";
// import { useQuery } from "@apollo/client";
// import { useEffect, useState } from "react";
// import {  MY_TRUCKS } from "../../graphql/Mutation/owner/addTruckMutation";

// export default function useTrucks() {
//   const [trucks, setTrucks] = useState([]);
//   const { loading, error, data } = useQuery(MY_TRUCKS, {
//     variables: { page: 1 },
//   });

//   useEffect(() => {
//     if (!loading && !error && data) {
//       setTrucks(data.allTrucks.trucks);
//     }
//   }, [loading, error, data]);

//   return { trucks };
// }



import { useEffect, useState, useCallback } from "react";
import { useQuery } from "@apollo/client";


import { MY_TRUCKS } from "../../graphql/Mutation/owner/addTruckMutation";

// ... other imports ...

function useTrucks(trigger, paginationFilters) {
  const [trucks, setTrucks] = useState([]);
  const [count, setCount] = useState(0);
  const ownerId = JSON.parse(localStorage.getItem("id"));

  const { loading, error, data, refetch } = useQuery( MY_TRUCKS, 
    {
      variables:{ page: paginationFilters?.pageNo, ownerId },
      fetchPolicy: "network-only",
    }
  );

  const getTrucks = useCallback(() => {  
    if (loading) return;
    if (error) {
      console.log("Error:", error.message);
      return;
    }
    const trucksData = data.myTrucks;
    if (!trucksData) {
      console.log("No driver data available.");
      return;
    }
    const { trucks, totalDocs } = trucksData;
    setTrucks(trucks);
    setCount(totalDocs);
  }, [loading, error, data]);

  useEffect(() => {
    getTrucks();
    if (!trigger) {
      refetch({ page: paginationFilters?.pageNo });
    }
  }, [trigger, getTrucks, paginationFilters?.pageNo, refetch]);

  return { loading, error, trucks, count };
}

export default useTrucks;

