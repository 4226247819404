import {
    Input,
    Modal,
    Row,
    Col,
    Button,
    Space,
    Divider,
    message,
    Typography,
  } from "antd";
  import {
    doc,
    getDoc,
    setDoc,
    updateDoc,
    serverTimestamp,
    deleteDoc,
    collection,
    addDoc,
  } from "firebase/firestore";
  import React, { useState, useEffect } from "react";
  import { useTranslation } from "react-i18next";
  import { uploadImage } from "../API/API";
  import { db } from "../firebase";
  import useOwners from "../Hooks/useOwners";
  import Loader from "../Loaders/Loader";
  const { Title } = Typography;
  export default function InvoiceRecieptModal({
    isModalVisible,
    setIsModalVisible,
    state: request,
  }) {
    const [ticket, setTicket] = useState("");
  const [ticketFile, setTicketFile] = useState("");
    const [clickCount, setClickCount] = useState(0);
    
    const owners = useOwners();
    let ownersUIDS;
    if (owners.length > 0) {
      ownersUIDS = owners.map((o) => o.uid);
    }
  
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
  
    const handleOk = () => {
      setIsModalVisible(!isModalVisible);
    };
  
    const handleCancel = () => {
      setIsModalVisible(!isModalVisible);
    };
  
    const handleUpload = async (SaveSate,file) => {
      if (file) {
        try {
          setLoading(true);
          await uploadImage("request/", file).then(async (res) => {
            SaveSate(res);
            setLoading(false);
          });
        } catch (e) {
          setLoading(false);
          console.log(e);
        }
      }
    };
  
  
  
    const handleSave = async () => {
      if (!ticket) {
        console.log(ticket,"ticket")
      } else {
        console.log("heeloo", request);
        const userX = await getDoc(doc(db, "customers", request?.customerId));
    
        console.log(userX.data(), "hee");
    
        let updateRequest = {};
        if (userX.data().isHold) {
          const updateData = {
            ticket: ticket,
            status: "underProcess",
            offlinePaid: true,
          };
          updateDoc(doc(db, "customer_transport_requests", request.uid), updateData).then(() => {
            alert("upload successfull!");
            handleCancel();
          });
        } else {
          let d = {
            ticket: ticket,
            status: "underProcess",
            offlinePaid: true,
          };
          if (request.isSpecific) {
            d.sendTo = request.specific;
          } else {
            if(request.quantity > 1){
              console.log("🚀 ~ file: InvoiceRecieptModal.jsx:102 ~ handleSave ~ request.quantity:", request.quantity)
              const pricePerQuantity = request.price / request.quantity;
              console.log("🚀 ~ file: InvoiceRecieptModal.jsx:104 ~ handleSave ~ pricePerQuantity:", pricePerQuantity)
  
                for (let i = 0; i < request.quantity; i++) {
                  const newData = {
                    ...request,
                    sendTo: ownersUIDS,
                    quantity: 1,
                    price: pricePerQuantity,
                  };
                  console.log("🚀 ~ file: InvoiceRecieptModal.jsx:108 ~ handleSave ~ newData:", newData)
  
                  // Create a copy of the request in the "customer_transport_requests" collection
                  const copyRef = await addDoc(
                    collection(db, "customer_transport_requests"),
                    newData
                  );
                  //update the document id with the uid
                  await updateDoc(copyRef, { uid: copyRef.id });
                }
                await deleteDoc(
                  doc(db, "customer_transport_requests", request.uid)
                );
            }else{
              d.sendTo = ownersUIDS;
            }
          }
          updateDoc(doc(db, "customer_transport_requests", request.uid), d).then(
            async () => {
              await setDoc(doc(db, "finance", request.uid), {
                receivedFromCustomer: true,
                sendToOwner: false,
                customerId: request.customerId,
                uid: request.uid,
                fare: request.price,
                commission: request.commission,
                requestId: request.requestId,
                createdAt: serverTimestamp(),
              });
              message.success("Uploaded Successfully");
              handleCancel();
            }
          );
        }
      }
    };
    
    useEffect(() => {
      
      if (request?.ticket !== undefined && request?.ticket !== null && request?.ticket !== "") {
        setTicket(request?.ticket);
      } else {
        setTicket("");
      }
    }, [request]);
  
  
  
  
  
    const handleClick = () => {
    
      if (clickCount === 0) {
        setClickCount(1);
          handleUpload(setTicket, ticketFile);
      } else if (clickCount === 1) {
        setClickCount(2);
        handleUpload(setTicket, ticketFile);
        handleSave()
      }
    }; 
  
   
    
    return (
      <div>
        {loading ? <Loader /> : ""}
        <Modal
          title={t("Modal.attactFiles")}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          destroyOnClose
          footer={
          
             <Button className="check-btn" onClick={() => {
                  if (window.confirm("Are u Sure")){
                    handleClick()
                }}}>
             {clickCount === 0 ? t("placeholders.upload") : clickCount === 1 ? t("Modal.submit") : ''}
           </Button>
          }
        >
          <Space direction="vertical" style={{ width: "90%" }}>
            <div>
              <Title level={5}>{t("Modal.ticket")}</Title>
            </div>
            <Row gutter={"24"}>
              <Col span={"18"}>
                <Input
                  type="file"
                  style={{ border: "2px solid  #2d808b", width: "280px" }}
                  onClick={(e) => e.target.value}
                  onChange={(e) => {
                    setTicketFile(e.target.files[0]);
                  }}
                />
              </Col>
              <Col span={"6"}>
              </Col>
            </Row>
            {ticket !== "" ? (
              <Space>
                <img
                  className="img"
                  src={ticket}
                  width={100}
                  height={100}
                  alt={"Click to view"}
                  onClick={() => {
                    window.open(ticket);
                  }}
                />
                <span>{t("Modal.ticket")}</span>
              </Space>
            ) : (
              <span>{t("Modal.notUploadYet")}</span>
            )}
          </Space>
          <Divider />
        </Modal>
      </div>
    );
  }
  