import React, { useEffect } from "react";
import "./style.css";
import pic from "../../../assets/home/photo.jpg";
// import "./../../../utils/i18n";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
export const PerfectSwagger = () => {
  const { t } = useTranslation();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div>
      <div className="menu"
       data-aos="fade-down"
       data-aos-anchor-placement="center-bottom"
       data-aos-duration="3000">
        <div className="left ">
          <div className="leftInner">
            <h1 style={{ paddingTop: "10px" }} className="white_color">
              {t(" OverView")}
            </h1>
            <p>
              {t(
                "The latest technological advancements and widespread internet connection have made it possible for anyone to benefit from the convenience of booking trucks online. Fast, safe, secure guaranteed and reliable transportation to match your budget and needs."
              )}
            </p>
          </div>
        </div>
        <div className="right ">
          <img src={pic} alt="" className="picSetting" />
        </div>
      </div>
    </div>
  );
};
