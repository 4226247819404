import React from "react";
import { Navigate } from "react-router-dom";

const OwnerPrivateRoute = ({ children }) => {
  let UserId = localStorage?.getItem("id");
  // let type = localStorage?.getItem("type");
  // let uid = JSON.parse(localStorage?.getItem("uid"));
  // let type = localStorage?.getItem("userType");  
  let type;
  try {
    type = JSON.parse(localStorage?.getItem("userType"));
  } catch (error) {
    // Handle the parsing error, provide a default value, or take appropriate action.
    console.error("Error parsing userType:", error);
    type = "defaultUserType";
  }

  // console.log("ttttttttttttttowner id", UserId);
  // console.log("ttttttttttttttowner type", type);
  return UserId !== null && type !== null && type === "Owner" ? (
    children
  ) : (
    <Navigate to="/" />
  );
};

export default OwnerPrivateRoute;
// import React from "react";
// import { Navigate } from "react-router-dom";

// import React from "react";
// import { Navigate } from "react-router-dom";

// const OwnerPrivateRoute = ({ children }) => {
//   let UserId = localStorage?.getItem("id");
//   let type = localStorage?.getItem("userType");

//   return UserId !== null && type === "Owner" ? children : <Navigate to="/" />;
// };

// export default OwnerPrivateRoute;
