import { collection, query, where, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";

const useClearanceNewRequest = () => {
    const [clearanceRequest, setClearanceRequst] = useState([])
    const uid = JSON.parse(localStorage.getItem('uid'))
    const getClearanceRequest = () => {

        const q = query(collection(db, "clearance_request"), where("customerId", "==", uid));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const arr = [];
            querySnapshot.forEach((doc) => {
                arr.push(doc.data())
            });
            console.log(arr);
            setClearanceRequst([...arr])
        });
        return () => unsubscribe()
    }

    useEffect(() => { getClearanceRequest() }, [])

    return { clearanceRequest }
}

export default useClearanceNewRequest
