import React from 'react'
import { useState, useEffect } from "react";

const useCheckActiveTab = (initialTab = "1") => {

    const [activeTab, setActiveTab] = useState(
        sessionStorage.getItem("activeTab") || initialTab
    );
    const handleTabChange = (key) => {
        setActiveTab(key);
    };
    useEffect(() => {
        sessionStorage.setItem("activeTab", activeTab);
    }, [activeTab]);

    useEffect(() => {
        const storedActiveTab = sessionStorage.getItem("activeTab");
        if (storedActiveTab && storedActiveTab !== activeTab) {
            setActiveTab(storedActiveTab);
        }
    }, []);

    return { activeTab, handleTabChange };
};


export default useCheckActiveTab
