import { gql } from "@apollo/client";

const CREATE_DRIVER = gql`
mutation Mutation($registerDriverInput: RegisterDriverInput) {
  createDriver(registerDriverInput: $registerDriverInput) {
    success
    message
    code
    data {
      token
      status
      owner {
        type
        token
        rRA
        phoneNumber
        profileImage
        ownerName
        name
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
      nationality
      name
      isTruck
      imageUrl
      idNo
      createdAt
      age
      address
      _id
    }
  }
}
`;

// const GET_DRIVERS = gql`
// query AllDrivers($page: Int!) {
//   allDrivers(page: $page) {
//     totalPages
//     totalDocs
//     currentPage
//     drivers {
//       token
//       status
//       owner {
//         type
//         token
//         rRA
//         profileImage
//         phoneNumber
//         ownerName
//         name
//         isDeleted
//         isApproved
//         isAccept
//         idNumberUpload
//         idNumber
//         emailVerified
//         email
//         createdAt
//         crNumberUpload
//         crNumber
//         bankInfo {
//           iBanNumber
//           beneficiaryName
//           bankIdCode
//         }
//         _id
//       }
//       nationality
//       name
//       isTruck
//       imageUrl
//       createdAt
//       idNo
//       age
//       address
//       _id
//     }
//   }
// }
// `;

// my drivers 
const MY_DRIVERS = gql`
query MyDrivers($ownerId: ID!, $page: Int!) {
  myDrivers(ownerId: $ownerId, page: $page) {
    totalPages
    totalDocs
    drivers {
      token
      status
      owner {
        type
        token
        rRA
        profileImage
        phoneNumber
        ownerName
        name
        isVat
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
      nationality
      name
      lincense
      isTruck
      imageUrl
      idUpload
      idNo
      driverId
      createdAt
      age
      address
      _id
    }
  }
}
`;

const UPDATE_DRIVERS = gql`
mutation UpdateDriver($id: ID!, $updateDriverInput: UpdateDriverInput) {
  updateDriver(ID: $id, updateDriverInput: $updateDriverInput) {
    success
    message
    code
    data {
      token
      status
      owner {
        type
        token
        rRA
        profileImage
        phoneNumber
        ownerName
        name
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
      nationality
      name
      isTruck
      imageUrl
      idNo
      createdAt
      age
      address
      _id
    }
  }
}
`;
const DELETE_DRIVERS = gql`
mutation Mutation($id: ID!, $isArchive: Boolean) {
  deleteDriver(ID: $id, isArchive: $isArchive) {
    success
    message
    code
    data {
      token
      status
      owner {
        type
        token
        rRA
        profileImage
        phoneNumber
        ownerName
        name
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
      nationality
      name
      isTruck
      imageUrl
      idNo
      createdAt
      age
      address
      _id
    }
  }
}
`;



export {CREATE_DRIVER, MY_DRIVERS, UPDATE_DRIVERS , DELETE_DRIVERS };