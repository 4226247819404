// import React, { useEffect, useState } from "react";
// import { collection, query, onSnapshot, where } from "firebase/firestore";
// import { db } from "../firebase";
// export default function useOwnerRequests() {
//   const id = JSON.parse(localStorage.getItem("uid"));
//   const [ownerRequests, setownerRequests] = useState([]);
//   const getOwnerRequests = async () => {
//     let q = query(collection(db, "customer_transport_requests"));
//     const unsub = onSnapshot(q, (querySnapShot) => {
//       setownerRequests([]);
//       if (!querySnapShot.empty) {
//         querySnapShot.forEach((val) => {
//           if (
//             val.data().acceptedBy === undefined ||
//               Object.keys(val.data().acceptedBy).length === 0
//               ? val.data().sendTo !== undefined &&
//               val.data().sendTo.some((v) => v === id)
//               : val.data().acceptedBy !== undefined &&
//               val.data().acceptedBy.ownerId === id
//           ) {
//             setownerRequests((prev) => [...prev, val.data()]);
//           }
//         });
//       }
//     });

//     return () => unsub();
//   };
//   useEffect(() => {
//     getOwnerRequests();
//   }, []);

//   return { ownerRequests };
// }


// useOwnerRequests.js
// import React, { useEffect, useState } from "react";
// import { useQuery } from "@apollo/client";
// import { GET_REQUEST_BY_STATUS } from "../../graphql/Mutation/owner/getRequestByStatus";

// export default function useOwnerRequests() {
//   const id = JSON.parse(localStorage.getItem("id"));
//   const [ownerRequests, setOwnerRequests] = useState([]);

//   const { loading, error, data } = useQuery(GET_REQUEST_BY_STATUS, {
//     variables: {
//       status: "created"
//     },
//   });

//   console.log(data, "........................getRequestsWithStatus.");

//   useEffect(() => {
//     if (!loading && !error && data && data.getRequestsWithStatus) {
//       const ownerRequests = data.getRequestsWithStatus.filter((request) => {
//         return (
//           (request.acceptedBy === undefined ||
//             !request.acceptedBy.transportId) &&
//           (request.sendTo !== undefined && request.sendTo.includes(id))
//         );
//       });

//       setOwnerRequests(ownerRequests);
//     }
//   }, [loading, error, data]);

//   return { ownerRequests };
// }


import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
// import { GET_REQUEST_BY_STATUS } from "../../graphql/Mutation/owner/getRequestByStatusMutation";
import { GET_ALL_CUSTOMER_REQUEST } from '../../graphql/Mutation/customer/transportRequestMutation';

export default function useOwnerRequests() {
  const ownerId = JSON.parse(localStorage.getItem("id"));
  
  console.log("id useCustomerId here", ownerId);
  const [ownerRequests, setOwnerRequests] = useState([]);
  const [count, setCount] = useState(0)

  const variables = {
    status: "created",
    page: 1
  };

  const { loading, error, data } = useQuery(GET_ALL_CUSTOMER_REQUEST, {
    variables: variables,
  });
  
  useEffect(() => {
    if (!loading && !error && data && data.getRequestsWithStatus) {
      const filteredRequests = data.getRequestsWithStatus.filter((request) => {
        return (
          (request.acceptedBy ||
            request.acceptedBy.ownerId)
        );
      });

      setOwnerRequests(filteredRequests);
    }
  }, [loading, error, data]);
console.log(ownerRequests,"t")
  return { ownerRequests, loading, error };
}




  