import React from "react";
import { Navber } from "../Landing/components/Home/navber/navber";
import { Footer } from "../Landing/components/Footer/Footer";
import "./faq.style.css";
import { useTranslation } from "react-i18next";
import Chatt from "../../chat-box/Chat-box";

export default function FAQ() {
  const { t } = useTranslation();

  return (
    <div>
      <Navber />
      <Chatt />
      <div className="faq_main_div">
        <h2>{t("FAQ")}</h2>
        <p>
          {t(
            "The mission of OACExpress is to connect buyers and sellers of business services. To achieve our mission, OACExpress's website assists buyers in identifying prospective service providers, including by providing reviews of such companies. We also assist service providers in marketing their companies and services. The terms we, us, our refer to OACExpress, a Delaware corporation, 1800 Massachusetts Ave, Suite 200, Washington, DC 20036. The term you, users, buyers or 'service provides refers to users who visit the OACExpress website(Website) and/or contribute content to OACExpress via the Website,email, telephone, or otherwise."
          )}
        </p>
        <h4>
          {t(
            "BEFORE USING OACExpress'S SERVICES READ THIS TERMS OF USE (TERMS)CAREFULLY. IT IS A LEGAL CONTRACT GOVERNING YOUR USE OF OACExpress' WEBSITE AND SERVICES, INCLUDING UPGRADES THERETO AND MATERIALS MADE AVAILABLE"
          )}
        </h4>

        <p>
          {t(
            "THE OACExpress OACExpress OBTAINING INFORMATION FROM OACExpress SUCH AS RESEARCH PUBLICATIONS USING THE OACExpress API OR MOBILE APPLICATION CONTRIBUTING CONTENT TO OACExpress BY ANY CHANNEL INCLUDING BY POSTING INFORMATION ON THE WEBSITE RESPONDING TO A SURVEY SUBMITTING AN EMAIL OR PARTICIPATING IN A TELEPHONE INTERVIEW COLLECTIVELY OACExpress OR THE SERVICES YOU YOUR HEIRS AND ASSIGNS COLLECTIVELY YOU OR YOURAGREE TO BE BOUND AND ANY APPLICABLE OACExpress INVOICES INVOICE COLLECTIVELY THE TERMS.If you are entering into these Terms on behalf of a company or other legal entity you represent that you have the authority to bind such entity and its affiliates to the Terms. In that case the terms you or your shall also refer to such entity and its affiliates, as applicable. If you do not have such authority  or you do not accept all of these Terms  do not use the Services or provide information to OACExpress. Be sure to return to this page periodically to review the most current version of the Terms. We reserve the right at any time at our sole discretion  to change or otherwise modify the Terms without prior notice, and your continued access to or use of the Services signifies your acceptance of the updated or modified Terms.We retain the right at our sole discretion to deny access to anyone to the Services we offer at any time and for any reason including but not limited to for violation of these Terms."
          )}
        </p>
        <h1>{t("Eligility")}</h1>
        <p>
          {t(
            "You must be 18+ years of age or older in order to use the Services including to register for an account, use the Website or submit reviews or other User Content (defined below)."
          )}
        </p>
        <h1>{t("Acount Registration")}</h1>
        <p>
          {t(
            "As part of your use of the Services, you may have the opportunity to create a OACExpress account. You are responsible for your account and agree to provide, upon registration, and at all times maintain, accurate, current, and complete information. OACExpress reserves the right, in its sole discretion, to refuse to keep accounts for, or provide Services to, any individual. OACExpress reserves the right to suspend or terminate your account if any information provided during the registration process or at other times proves to be inaccurate, not current or incomplete. You are responsible for ensuring the confidentiality and security of your account information, including your username and password. You will immediately notify OACExpress of any unauthorised use of your account. OACExpress cannot and will not be liable for any loss or damage arising from your failure to properly comply with this section."
          )}
        </p>
        <h1>{t("Your Use of the Services.")}</h1>
        <p>
          {t(
            "Comply with all applicable laws, including, without limitation,privacy laws, intelectual property laws, tax laws, and regulatory requirements; Provide accurate information to us and update it as necessary;"
          )}
        </p>
        <h1>
          {t("User Content Your Responsibility For the Content You Submit")}
        </h1>
        <p>
          {t(
            "The Services may enable you to provide, submit post, upload, or otherwise make available via the Website, email, telephone, online survey, or otherwise collectively, submit content such as reviews comments, messages, and other content collectively User Content that may or may not be viewable by other users. You acknowledge and agree that you are solely responsible for all User Content you submit.Once User Content is submitted, it cannot be withdrawn. Any User Content you submit must be accurate and up to date. You agree that you have all required rights to submit use, or disseminate such User Content without violating any third-party rights. You assume al risk for any User Content you submit, including anyone's reliance upon such User Content. You may expose yourself to liability if you submit User Content that is false, misleading. defamatory, violates any third-party right or contractual restriction or contains unlawful material. You agree that you will indemnify, defend, and hold harmless OACExpress for all claims resulting from User Content that you submit, including but not imited to claims alleging defamation. We reserve the right, at our own expense, to assume the exclusive defence and control of such disputes, and in any event, you will cooperate with us in asserting any available defences. You further acknowledge and agree that we reserve the right, in our sole discretion, to reject, move,edit, or remove any User Content that is contributed to the Services for any reason or no reason, but we do not assume the obligation. For example, we may remove a review if we) in our sole discretion, believe it violations our Guidlines. OACExpress has no obligation to retain or provide you copies of the User Content."
          )}
        </p>
        <h1>{t("Feedback")}</h1>
        <p>
          {t(
            "Any and all of your feedback about the Services, such as suggestions for corrections, updates, alterations, changes, or modifications to the Services will be the property of 0ACExpress and you hereby assign any rights in such feedback to 0ACExpress, without payment to you."
          )}
        </p>
        <h1>{t("Service Help")}</h1>
        <p>
          {t("For answers to your questions, call us or email ")}

          <a href="mailto:Luai@orientassets.com" target="_blank">
            Luai@orientassets.com
          </a>
          {t(
            "Or, you can write to us at: OACExpressISaudi Arabia, Riyadh. Alnada dis. Prince Mohammed bin Salman st. Floor 2,office3."
          )}
        </p>
      </div>
      <Footer />
      {/* Luai@orientassets.com  */}
    </div>
  );
}
