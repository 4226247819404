
import React, { usestate, useeffect } from "react";
import "./profile.css";
import { useTranslation } from "react-i18next";
import defaultimg from "../../../assets/user.png";
import { useQuery } from "@apollo/client";
import { GET_DRIVER, GET_TRUCK_WITH_DRIVER_ID } from "../../../graphql/Mutation/driver/LoginDriverMutatoin";

const DriverProfile = () => {
  const uid = JSON.parse(localStorage.getItem("id"));
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(GET_DRIVER, {
    variables: { id: uid },
  });
  const  DriverIDNumber = JSON.parse(localStorage.getItem("idNo"));
  const { loading: truckLoading, error: truckError, data: truckData } = useQuery(GET_TRUCK_WITH_DRIVER_ID, {
    variables: { idNo: DriverIDNumber },
  });
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const driverProfile = data.driver; 
  if (truckLoading) {
    return <div>Loading truck data...</div>;
  }

  if (truckError) {
    return <div>Error loading truck data: {truckError.message}</div>;
  }

  const truckDetails = truckData.truckIdNo;

  return (
    <>
      <div className="driver">
          <div className="driver-profile">
         <div style={{ textAlign: "center" }}>
            <img
             src={
                driverProfile?.ProfileImage
                  ? driverProfile?.ProfileImage
                  : defaultimg
              }
              alt="img"
            />
            <h4 style={{ margin: "1rem 0", textDecoration: "underline" }}>
              {t("driver.driverdetail")}
            </h4>
          </div>
          <div className="driver-detail-1">
            <div className="driver-name">
              <h5>{t("driver.name")}</h5>
              <h6>{driverProfile?.name}</h6>
            </div>
            <div className="driver-name">
              <h5>{t("driver.age")}</h5>
              <h6>{driverProfile?.age}</h6>
            </div>
            <div className="driver-name">
              <h5>{t("driver.address")}</h5>
              <h6>{driverProfile?.address}</h6>
            </div>
          </div>
          <div className="driver-detail-2">
            <div className="driver-name">
              <h5>{t("driver.idnumber")}</h5>
              <h6>{driverProfile?.idNo}</h6>
            </div>
            <div className="driver-name">
              <h5>{t("driver.nationality")}</h5>
              <h6>{driverProfile?.nationality}</h6>
            </div>
          </div>
          <div className="truck-details">
            <h4
              style={{
                margin: "1rem 0",
                textAlign: "center",
                textDecoration: "underline",
              }}
            >
              {t("driver.truckdetail")}
            </h4>
            <div className="driver-detail-1">
              <div className="driver-name">
                <h5> {t("driver.modal")}</h5>
                <h6>{truckDetails?.model}</h6>
              </div>
              <div className="driver-name">
                <h5> {t("driver.plate")}</h5>
                <h6>{truckDetails?.noPlate}</h6>
              </div>
              <div className="driver-name">
                <h5> {t("driver.type")}</h5>
                <h6>{truckDetails?.type}</h6>
              </div>
            </div>
            <div className="driver-detail-2">
              <div className="driver-name">
                <h5> {t("driver.height")}</h5>
                <h6>{truckDetails?.height}</h6>
              </div>
              <div className="driver-name">
                <h5>{t("Type of Service")}</h5>
                <h6>{truckDetails?.capacity}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DriverProfile;

