import React, { useState, useEffect } from "react";
import { Modal, Button, Input, Form, 
  // Upload, 
  Row, Col } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
// import { UpdateUser, uploadImage } from "../API/API";
import useUser from "../Hooks/useUser";
import Loader from "../Loaders/Loader";
import { OWNER_BANK_INFO_UPDATE } from "../../graphql/Mutation/owner/ownerBankMutation";
import { useMutation } from "@apollo/client";
export default function OwnerBankInfo({ bankModal, setBankModal }) {
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const [form] = Form.useForm();

  const handleOk = () => {
    setBankModal(!bankModal);
  };

  const handleCancel = () => {
    setBankModal(!bankModal);
  };
  // const onFinish = async (values) => {
  //   setLoading(true);
  //   console.log("values are ", values);
  //   let data = {
  //     ...user,
  //     bankInfo: values,
  //   };
  //   await UpdateUser(user.uid, data).then(() => {
  //     setLoading(false);
  //     handleCancel();
  //   });
  // };
  const [updateOwner] = useMutation(OWNER_BANK_INFO_UPDATE);
  const onFinish = async (values) => {
    setLoading(true);

    try {
      const ownerId = JSON.parse(localStorage.getItem("id"));
      const { data } = await updateOwner({
        variables: {
          id:ownerId,
          updateOwnerInput: {
            bankInfo: {
              iBanNumber: values.iBanNumber,
              beneficiaryName: values.benificiaryName,
              bankIdCode: values.bankIdCode,
            },
          },
        },
      });

      if (data.updateOwner.success) {
        // Update was successful
        setLoading(false);
        handleCancel();
      } else {
        // Handle errors from the GraphQL response, if any
        console.error(data.updateOwner.message);
        setLoading(false);
      }
    } catch (error) {
      // Handle GraphQL query execution errors
      console.error(error);
      setLoading(false);
    }
  };





  useEffect(() => {
    if (user?.bankInfo) {
      form.setFieldsValue(user.bankInfo);
    }
  }, [user]);
  return (
    <div>
      <Modal
        title="Bank Information"
        visible={bankModal}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form
            form={form}
            name="add_driver_form"
            // initialValues={formData}
            onFinish={onFinish}
          >
            <Input.Group>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    hasFeedback
                    name="benificiaryName"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder="Benificiary Name" />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    hasFeedback
                    name="iBanNumber"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder="Iban Number" type="number" />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={24}>
                  <Form.Item
                    hasFeedback
                    name="bankIdCode"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input placeholder="Bank Id Code" />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Edit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
