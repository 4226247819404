import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { reviewDetails } from "./data";
import { GalleryCard } from "./GalleryCard";
import { HiLocationMarker } from "react-icons/hi";
import { AiTwotoneMail } from "react-icons/ai";
import { BiPhoneCall, BiTime } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import maroof from "../../../assets/home/ii.png";
import { Link } from "react-router-dom";
import "./style.css";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

export const Gallery = () => {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    AOS.init();
  }, [])

  return (
    <div>
      <div className="mini">
        <div className="main-contact">
          <h2>{t("Contact Info")}</h2>
          <div className="sec-div">
            <div className="boox"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration="500">
              <span className="icons">
                <a href="https://maroof.sa/263422" target="_blank">
                  <img src={maroof} alt="" className="marrof-img" />
                </a>
              </span>
              <p className="boox-p">{t("Maroof")}</p>
              <p className="ful">
                {t("which is commonly known or acknowledged")}
              </p>
            </div>
            <div className="boox"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="750">
              <HiLocationMarker className="iconss" />
              <p className="boox-p">{t(" Location")}</p>
              <p className="ful">{t("Saudi,Arabia,Riyadh")}</p>
            </div>
            <div className="boox"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1000">
              <AiTwotoneMail className="iconss" />
              <p className="boox-p">{t("E-mail")}</p>
              <p className="ful">
                <a
                  style={{ color: "white" }}
                  href="mailto:info@orientassets.com"
                  target="_blank"
                >
                  info@orientassets.com
                </a>
              </p>
            </div>
            <div className="boox"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1250">
              <BiPhoneCall className="iconss" />
              <p className="boox-p">{t(" Phone")}</p>
              <a href="https://wa.me/0115063391" style={{color:'white'}}>
              <p className="ful">0115063391</p></a>
            </div>
            <div className="boox"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500">
              <BiTime className="iconss" />
              <p className="boox-p">{t("Opening Hours")}</p>
              <p className="ful">{t("At your service from 8 am to 6 pm")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
