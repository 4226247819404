import React,{useRef} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Descriptions,
  Space,
  Button,
  Image,
  Steps,
  Divider,
  Modal,
} from "antd";
import moment from "moment";
import "jspdf-autotable";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { useTranslation } from "react-i18next";
import ActiveRideMap from "../../Modals/ActiveRideMap";
import logo from "./logo.png";
import "./viewRequest.scss";
import { useEffect } from "react";
import { useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { db } from "../../firebase";
// import { useParams } from 'react-router-dom';
export default function RequestDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { request } = state;
  const { _id } = useParams();
  console.log(_id,"wwwwwwwwwwwwwwwwwwwww")
  const [ownerDetails, setOwnerDetails] = useState({});
  const [truckDetails, setTruckDetails] = useState({});
  const [driverDetails, setDriverDetails] = useState({});
  const { t } = useTranslation();

  //show review invoice modal
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const showModal = () => {
    setIsInvoiceModalOpen(true);
  };
  const handleOk = () => {
    setIsInvoiceModalOpen(false);
  };
  const handleCancel = () => {
    setIsInvoiceModalOpen(false);
  };

  //end invoice modal


  //download as a pdf
  const contentRef = useRef(null);
  const generatePDF = async () => {
    try {
      const contentNode = contentRef.current;
      const { width: contentWidth, height: contentHeight } = contentNode.getBoundingClientRect();
      const margin = 20; // Adjust the margin value as needed
  
      // Create a canvas with the dimensions of the content plus margins
      const canvas = document.createElement('canvas');
      canvas.width = (contentWidth + margin * 3) * 3; // Adjust as needed for desired resolution
      canvas.height = (contentHeight + margin * 3) * 3; // Adjust as needed for desired resolution
      const context = canvas.getContext('2d');
      context.scale(2.7, 2.5); // Scale for higher resolution rendering
  
      // Capture content onto the canvas with margins
      context.translate(margin * 2, margin * 2); // Translate to apply margins
      await html2canvas(contentNode, { canvas });
  
      // Create PDF with the captured canvas
      const pdf = new jsPDF('p', 'mm', 'a4',true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (contentHeight / contentWidth) * pdfWidth + margin * 2;
  
      pdf.addImage(canvas, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save('invoice.pdf');
    } catch (error) {
      console.error('PDF generation error:', error);
    }
  };
  
  //end

  const getDatas = async () => {
    const owner = await getDoc(doc(db, "owners", request.acceptedBy?.ownerId));
    setOwnerDetails(owner.data());
    const drive = await getDoc(
      doc(db, "drivers", request.acceptedBy?.driverId)
    );
    setDriverDetails(drive.data());
    const truck = await getDoc(doc(db, "trucks", drive.data()?.truckId));
    setTruckDetails(truck.data());
  };

  useEffect(() => {
    if (request?.driverStarted) {
      getDatas();
    }
  }, []);
  return (
    <div>
      <div>
        <div>
          <h3 className="car-img">{`${t("tableTabs.requst")} ${t(
            "tableTabs.detail"
          )}`}</h3>
        </div>
        <div>
          <div>
            <div
              className="upper_btn_div car-img"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Space>
                <Button
                  type="light"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  {t("tableTabs.back")}
                </Button>
              </Space>
              <Space>
                <Button
                  style={{
                    color: "white",
                    backgroundImage:
                      "linear-gradient(var(--gradient-one), var(--gradient-two))",
                  }}
                  onClick={showModal}
                >
                  Review Invoice
                </Button>
              </Space>
            </div>
          </div>
          <Modal
            title="Review Invoice"
            open={isInvoiceModalOpen}
            onOk={handleOk}
            footer={null}
            onCancel={handleCancel}

          >
            
            <div ref={contentRef}>
            <div
              style={{
                // backgroundImage:
                //   "linear-gradient(var(--gradient-one), var(--gradient-two))",
                background:'#f2b20de3',
                padding: "4px",
                width: "70%",
                margin: "auto",
                borderRadius: "10px",
              }}
            >
              <h5
                style={{
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                OAC EXPRESS{" "}
              </h5>
            </div>
            <div className="main-view-div">
              <div className="view-request">
                <Row className="mb-3">
                  <Col span={5}>
                    <p className="car-img">
                      <b>{t("Request Id:")}</b>
                    </p>
                  </Col>
                  <Col span={11}>
                    <p className="car-img">{request?.requestId}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={5}>
                    <p className="car-img">
                      <b>{t(" Date:")}</b>
                    </p>
                  </Col>
                  <Col span={10}>
                    <p className="car-img">
                      {moment(request?.iroDate?.seconds * 1000).format("lll")}
                    </p>
                  </Col>
                  <Col span={4}>
                    <p>
                      <b className="car-img1">{t("tableTabs.price")}</b>
                    </p>
                  </Col>
                  <Col span={5}>{request?.price}</Col>
                </Row>
                <Row style={{marginTop:'30px'}}>
                  <Col span={10}>
                    <p className="car-img">
                    <b>{t("placeholders.from")}:</b>
                    </p>
                  </Col>
                  <Col span={10}>
                    <p className="car-img">
                    {request.from}
                    </p>
                  </Col>
                  </Row>
                  <Row style={{marginTop:'30px'}}>
                  <Col span={10}>
                    <p>
                      <b className="car-img1"> <b>{t("tableTabs.to")}:</b></b>
                    </p>
                  </Col>
                  <Col span={10}>{request.to}</Col>
                </Row>

                <Row style={{marginTop:'30px'}}>
                <Col span={4}>
                  <Space direction="vertical" className="car-img">
                    <p>
                      <b>{t("tableTabs.name")}</b>
                    </p>
                    <p>{request?.name}</p>
                  </Space>
                </Col>
                <Col span={5}>
                  <Space direction="vertical" className="car-img">
                    <p>
                      <b>{t("tableTabs.type")}</b>
                    </p>
                    <p>{request?.type}</p>
                  </Space>
                </Col>
                <Col span={5}>
                  <Space direction="vertical" className="car-img">
                    <p>
                      <b>{t("tableTabs.capacity")}</b>
                    </p>
                    <p>{request?.capacity}</p>
                  </Space>
                </Col>
                <Col span={5}>
                  <Space direction="vertical" className="car-img2 ">
                    <p>
                      <b>{t("tableTabs.hight")}</b>
                    </p>
                    <p>{request?.height}</p>
                  </Space>
                </Col>
                <Col span={5}>
                  <Space direction="vertical" className="car-img1">
                    <p>
                      <b>{t("tableTabs.distance")}</b>
                    </p>
                    <p>{request?.distance}Km</p>
                  </Space>
                </Col>
              </Row>

              </div>
              <h3 style={{color:'red'}}>Total Price : 500SR </h3>

              <div style={{display:'flex',float:'right'}}>
                <img src={logo} alt=""  style={{width:'50px'}}/>
              </div>
            </div>
            <div>
            </div>
            </div>
            <Button onClick={generatePDF} > Download Invoice</Button>
          </Modal>

          <div className="main-view-div">
            <div className="view-request">
              <Row className="mb-3">
                <Col span={2}>
                  <p className="car-img">
                    <b>{t("Request Id:")}</b>
                  </p>
                </Col>
                <Col span={22}>
                  <p className="car-img">{request?.requestId}</p>
                </Col>
              </Row>
              <Row>
                <Col span={2}>
                  <p className="car-img">
                    <b>{t(" Date:")}</b>
                  </p>
                </Col>
                <Col span={15}>
                  <p className="car-img">
                    {moment(request?.iroDate?.seconds * 1000).format("lll")}
                  </p>
                </Col>
                <Col span={2}>
                  <p>
                    <b className="car-img1">{t("tableTabs.price")}</b>
                  </p>
                </Col>
                <Col span={5}>{request?.price}</Col>
              </Row>
              {/* <Row type="flex" justify="center" align="middle"> */}
              <Row>
                <div className="car-img" style={{ marginTop: "10px" }}>
                  <Steps
                    direction="vertical"
                    progressDot
                    current={1}
                    items={[
                      {
                        title: (
                          <Space size="large">
                            <p>
                              <b>{t("placeholders.from")}:</b>
                            </p>
                            <p
                              style={{
                                marginLeft: "-30px",
                              }}
                            >
                              {request.from}
                            </p>
                          </Space>
                        ),
                      },
                      {
                        title: (
                          <Space size="large">
                            <p>
                              <b>{t("tableTabs.to")}:</b>
                            </p>
                            <p
                              style={{
                                marginLeft: "-30px",
                              }}
                            >
                              {request.to}
                            </p>
                          </Space>
                        ),
                      },
                    ]}
                  />
                </div>
              </Row>
              <Divider />
              <Row>
                <Col span={3}>
                  <Space direction="vertical" className="car-img">
                    <p>
                      <b>{t("tableTabs.name")}</b>
                    </p>
                    <p>{request?.name}</p>
                  </Space>
                </Col>
                <Col span={3}>
                  <Space direction="vertical" className="car-img">
                    <p>
                      <b>{t("tableTabs.type")}</b>
                    </p>
                    <p>{request?.type}</p>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space direction="vertical" className="car-img">
                    <p>
                      <b>{t("tableTabs.capacity")}</b>
                    </p>
                    <p>{request?.capacity}</p>
                  </Space>
                </Col>
                <Col span={3}>
                  <Space direction="vertical" className="car-img2 ">
                    <p>
                      <b>{t("tableTabs.hight")}</b>
                    </p>
                    <p>{request?.height}</p>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space direction="vertical" className="car-img1">
                    <p>
                      <b>{t("tableTabs.senderContact")}</b>
                    </p>
                    <p>{request?.senderContact}</p>
                  </Space>
                </Col>
                <Col span={4}>
                  <Space direction="vertical" className="car-img1">
                    <p>
                      <b>{t("tableTabs.receiverContact")}</b>
                    </p>
                    <p>{request?.receiverContact}</p>
                  </Space>
                </Col>
                <Col span={3}>
                  <Space direction="vertical" className="car-img1">
                    <p>
                      <b>{t("tableTabs.distance")}</b>
                    </p>
                    <p>{request?.distance}Km</p>
                  </Space>
                </Col>
              </Row>
              <Divider />
              <Descriptions
                bordered
                title={t("Attachments")}
                layout={"vertical"}
              >
                <Descriptions.Item label={t("tableTabs.finalByan")}>
                  {state?.request?.finalByan !== undefined &&
                  state?.request?.finalByan !== null ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.finalByan);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.finalByan}
                    />
                  ) : (
                    <span>{t("Not Upload Yet")}</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={t("tableTabs.ticket")}>
                  {state?.request?.ticket !== undefined &&
                  state?.request?.ticket !== null ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.ticket);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.ticket}
                    />
                  ) : (
                    <span>{t("tableTabs.notUploadYet")}</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item
                  label={`${t("tableTabs.blNumber")} ${t("tableTabs.upload")}`}
                >
                  {state?.request?.blNumberUpload !== undefined &&
                  state?.request?.blNumberUpload !== "" &&
                  state?.request?.blNumberUpload !== null ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.blNumberUpload);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.blNumberUpload}
                    />
                  ) : (
                    <span>{t("tableTabs.notUploadYet")}</span>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label={t("Receipt")}>
                  {state?.request?.receipt !== undefined &&
                  state?.request?.receipt !== null ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.receipt);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.receipt}
                    />
                  ) : (
                    <span>{t("tableTabs.notUploadYet")}</span>
                  )}
                </Descriptions.Item>
                {/* <Descriptions.Item label="Receipt  ">
                  <img
                    className="img"
                    onClick={() => {
                      window.open(state?.request?.receipt);
                    }}
                    alt={"Not Upload Yet"}
                    src={state?.request?.receipt}
                  />
                </Descriptions.Item> */}

                <Descriptions.Item label={`IRO ${t("tableTabs.upload")}`}>
                  {state?.request?.iroUpload !== undefined &&
                  state?.request?.iroUpload !== "" &&
                  state?.request?.iroUpload !== null ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.iroUpload);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.iroUpload}
                    />
                  ) : (
                    <span>{t("tableTabs.notUploadYet")}</span>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label={t("tableTabs.deliveryNote")}>
                  {state?.request?.deliveryNote &&
                  state?.request?.deliveryNote !== null &&
                  state?.request?.deliveryNote !== "" ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.deliveryNote);
                      }}
                      alt={"Click to View"}
                      src={state?.request?.deliveryNote}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label={"EIR"}>
                  {state?.request?.eir &&
                  state?.request?.eir !== null &&
                  state?.request?.eir !== "" ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.eir);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.eir}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>

                <Descriptions.Item label={t("tableTabs.Wrong Invoice")}>
                  {state?.request?.invoice !== undefined &&
                  state?.request?.invoice !== null ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.invoice);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.invoice}
                    />
                  ) : (
                    <span>Not upload yet</span>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="O/D">
                  {state?.request?.odUpload !== undefined &&
                  state?.request?.odUpload !== "" &&
                  state?.request?.odUpload !== null ? (
                    <img
                      className="img"
                      onClick={() => {
                        window.open(state?.request?.odUpload);
                      }}
                      alt={"Click to View Doc"}
                      src={state?.request?.odUpload}
                    />
                  ) : (
                    <span>{t("tableTabs.notUploadYet")}</span>
                  )}
                </Descriptions.Item>
              </Descriptions>
              <Row>
                <Col span={24}>
                  <Descriptions
                    bordered
                    title={t("tableTabs.sendTo")}
                    layout={"vertical"}
                  >
                    {request.isAccept ? (
                      <>
                        <Descriptions.Item label={t("tableTabs.driverName")}>
                          {driverDetails?.driverName}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("Driver ID")}>
                          {driverDetails?.driverIdNumber}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("Driver Address")}>
                          {driverDetails?.driverAddress}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("tableTabs.truck")}>
                          {truckDetails?.truckModel}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("tableTabs.truckCapacity")}>
                          {truckDetails.truckCapacity}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("tableTabs.truckHeight")}>
                          {truckDetails.truckHeight}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("tableTabs.truckNumber")}>
                          {truckDetails?.truckPlate}
                        </Descriptions.Item>

                        <Descriptions.Item label={t("tableTabs.truckType")}>
                          {truckDetails?.truckType}
                        </Descriptions.Item>
                        <Descriptions.Item label={t("driver Id Upload")}>
                          <img
                            className="img"
                            onClick={() => {
                              window.open(driverDetails?.driverIdUpload);
                            }}
                            alt={"Click to View"}
                            src={driverDetails?.driverLicenseUpload}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={t("tableTabs.driverLicenseUpload")}
                        >
                          <img
                            className="img"
                            onClick={() => {
                              window.open(driverDetails?.driverLicenseUpload);
                            }}
                            alt={"Click to View"}
                            src={driverDetails?.driverLicenseUpload}
                          />
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={t("tableTabs.driverPortIdUpload")}
                        >
                          <img
                            className="img"
                            onClick={() => {
                              window.open(driverDetails?.portIdUpload);
                            }}
                            alt={"Click to View"}
                            src={driverDetails?.portIdUpload}
                          />
                        </Descriptions.Item>

                        <Descriptions.Item
                          label={t("tableTabs.truckLicenseUpload")}
                        >
                          <img
                            className="img"
                            onClick={() => {
                              window.open(truckDetails?.truckLicenseUpload);
                            }}
                            alt={"Click to View"}
                            src={truckDetails?.truckLicenseUpload}
                          />
                        </Descriptions.Item>
                      </>
                    ) : (
                      <Descriptions.Item label={t("tableTabs.notSendedYet")} />
                    )}
                  </Descriptions>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <ActiveRideMap
          coordinates={state?.request?.coordinates}
          id={state?.request?.uid}
        />
      </div>
    </div>
  );
}




























// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Input,
//   Form,
//   Row,
//   Col,
//   Select,
//   message,
//   Space,
//   Upload,
//   DatePicker,
// } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
// import Loader from "../../Loaders/Loader";
// import { MakeRequestAction } from "../../../Redux/Actions/DataActions";
// import { useSelector, useDispatch } from "react-redux";
// import { getPreciseDistance } from "geolib";
// //Api
// import { AddRequest, uploadImage } from "../../API/API";
// import CustomAlert from "../../Modals/CustomAlert";
// //hooks
// import { useNavigate } from "react-router-dom";
// import useCapacites from "../../Hooks/useCapacities";
// import useCities from "../../Hooks/useCities";
// import useHeights from "../../Hooks/useHeights";
// import useTypes from "../../Hooks/useTypes";
// import useCombinations from "../../Hooks/useCombinations";
// import useUser from "../../Hooks/useUser";
// import { useTranslation } from "react-i18next";
// import moment from "moment";
// const { TextArea } = Input;
// export default function NewRequest() {
//   const navigate = useNavigate();
//   const { combinations } = useCombinations();
//   const { user } = useUser();
//   const [form] = Form.useForm();
//   const { Option } = Select;
//   const [loading, setLoading] = useState(false);
//   const { cities } = useCities();
//   const { capacities } = useCapacites();
//   const { types } = useTypes();
//   const { heights } = useHeights();
//   const [type, setType] = useState("");
//   const [from, setFrom] = useState("");
//   const [to, setTo] = useState("");
//   const [height, setHeight] = useState("");
//   const [capacity, setCapacity] = useState("");
//   const [time, setTime] = useState("");
//   const [distance, setDistance] = useState("");
//   const [price, setPrice] = useState("");
//   const [commission, setCommission] = useState(1);
//   const { t } = useTranslation();
//   const dispatch = useDispatch();

//   const { makeRequestData } = useSelector((state) => state.DataReducer);
//   const onFinish = async (values) => {
//     if (price !== "" && distance !== "" && time !== "" && commission !== "") {
//       let blNumberUpload = "";
//       let iroUpload = "";

//       setLoading(true);
//       if (values.blNumberUpload !== "") {
//         await uploadImage("request/", values.blNumberUpload).then((res) => {
//           blNumberUpload = res;
//           refreshPage();
//         });
//       }
//       if (values.iroUpload !== "") {
//         await uploadImage("request/", values.iroUpload).then((res) => {
//           iroUpload = res;
//           navigate("/customer/tarnsport_requests");
//         });
//       }

//       let data = {
//         ...values,
//         iroDate: values.iroDate._d,
//         coordinates: {
//           from: from.origin,
//           to: to.origin,
//         },
//         name: user.name,
//         phoneNumber: user.phoneNumber,
//         distance: distance,
//         time: time,
//         price: price,
//         iroUpload: iroUpload ? iroUpload : "",
//         blNumberUpload: blNumberUpload ? blNumberUpload : "",
//         commission: commission,
//         status: "pending",
//       };
//       //
//       AddRequest({ ...data })
//         .then(() => {
//           setLoading(false);
//           form.resetFields();
//           setCapacity("");
//           setHeight("");
//           setType("");
//           setFrom("");
//           setTo("");

//           message.success("Add Successull!");
//         })
//         .catch((e) => {
//           setLoading(false);
//           message.error(e.code);
//         });
//     } else {
//       alert("This combination not available plaese choose another combination");
//     }
//   };

//   const getDistanceTimePrice = () => {
//     let v = combinations.find(
//       (v) =>
//         v.type === type?.type &&
//         v.capacity === capacity?.capacity &&
//         v.height === height?.height &&
//         v.from === from?.city &&
//         v.to === to?.city
//     );

//     if (v !== undefined) {
//       setDistance(v.distance);
//       setTime(v.time);
//       setPrice(v.price);
//       setCommission(v.commission);

//       // dispatch(
//       //   MakeRequestAction({
//       //     ...makeRequestData,
//       //     price: v.price,
//       //     commission: v.commission,
//       //     time: v.time,
//       //     distance: v.distance,\\
//       //   })
//       // );
//     } else {
//       setDistance("");
//       setTime("");
//       setPrice("");
//       setCommission("");
//       alert("This combination not available please choose another combination");
//     }
//   };
//   useEffect(() => {
//     if (
//       type !== "" &&
//       height !== "" &&
//       capacity !== "" &&
//       from !== "" &&
//       to !== ""
//     ) {
//       getDistanceTimePrice();
//     }
//   }, [type, height, capacity, from, to]);
//   useEffect(() => {
//     if (Object.keys(makeRequestData).length > 0) {
//       setFrom(makeRequestData.from);
//       setCapacity(makeRequestData.capacity);
//       setHeight(makeRequestData.height);
//       setTo(makeRequestData.to);
//       // setType(makeRequestData.type);

//       form.setFieldsValue({ from: makeRequestData.from.city });
//       form.setFieldsValue({ to: makeRequestData.to.city });
//       form.setFieldsValue({ capacity: makeRequestData.capacity.capacity });
//       form.setFieldsValue({ height: makeRequestData.height.height });
//       // form.setFieldsValue({ type: makeRequestData.type.type });
//       form.setFieldsValue({
//         senderContact: makeRequestData.senderContact,
//       });
//       form.setFieldsValue({
//         receiverContact: makeRequestData.receiverContact,
//       });
//       form.setFieldsValue({
//         blNumber: makeRequestData.blNumber,
//       });

//       form.setFieldsValue({
//         iroDate: moment(makeRequestData.iroDate),
//       });
//     }
//   }, []);
//   // console.log(form, "iroUpload ");

//   function refreshPage() {
//     setTimeout(function() {
//       window.location.reload(false);
//     }, 6000);
//   }

//   return (
//     <div>
//       {loading ? <Loader /> : ""}
//       <div>
//         <h4>{t("Create a new request")}</h4>
//         <Form form={form} name="make_request_form" onFinish={onFinish}>
//           <Input.Group>
//             <Row gutter={24}>
//               <Col span={12}>
//                 <Form.Item
//                   className="inp-emai"
//                   hasFeedback
//                   name="from"
//                   rules={[
//                     {
//                       required: true,
//                       message: "Requerd Field!",
//                     },
//                   ]}
//                 >
//                   <Select
//                     style={{ border: "2px solid #1890ff" }}
//                     placeholder={t("placeholders.from")}
//                     onChange={(val) => {
//                       let value = cities[val];
//                       form.setFieldsValue({ from: value.city });
//                       setFrom(value);
//                       dispatch(
//                         MakeRequestAction({ ...makeRequestData, from: value })
//                       );
//                     }}
//                   >
//                     {cities &&
//                       cities.map((v, i) => {
//                         return (
//                           <Option key={i} value={i}>
//                             {v.city}
//                           </Option>
//                         );
//                       })}
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col span={12}>
//                 <Form.Item
//                   style={{ border: "2px solid #1890ff" }}
//                   hasFeedback
//                   name="to"
//                   rules={[
//                     {
//                       required: true,
//                       message: "Requerd Field!",
//                     },
//                   ]}
//                 >
//                   <Select
//                     className="sign-ema"
//                     onChange={(val) => {
//                       let value = cities[val];
//                       form.setFieldsValue({ to: value.city });
//                       setTo(value);
//                       dispatch(
//                         MakeRequestAction({ ...makeRequestData, to: value })
//                       );
//                     }}
//                   >
//                     {cities &&
//                       cities.map((v, i) => {
//                         return (
//                           <Option key={i} value={i}>
//                             {v.city}
//                           </Option>
//                         );
//                       })}
//                   </Select>
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Input.Group>
//           <Input.Group>
//             <Row gutter={24}>
//               <Col span={12}>
//                 <Form.Item
//                   className="inp-emai"
//                   hasFeedback
//                   name="senderContact"
//                   style={{ border: "2px solid #1890ff" }}
//                   onChange={(val) => {
//                     // console.log(val.target.value);
//                     // let value = val;
//                     form.setFieldsValue({
//                       senderContact: val.target.value,
//                     });

//                     dispatch(
//                       MakeRequestAction({
//                         ...makeRequestData,
//                         senderContact: val.target.value,
//                       })
//                     );
//                   }}
//                   rules={[
//                     {
//                       required: true,
//                       message: "Requerd Field!",
//                     },
//                   ]}
//                 >
//                   <TextArea placeholder={t("placeholders.senderContact")} />
//                 </Form.Item>
//               </Col>
//               <Col span={12}>
//                 <Form.Item
//                   className="sign-ema"
//                   hasFeedback
//                   style={{ border: "2px solid #1890ff" }}
//                   name="receiverContact"
//                   onChange={(val) => {
//                     form.setFieldsValue({
//                       receiverContact: val.target.value,
//                     });
//                     dispatch(
//                       MakeRequestAction({
//                         ...makeRequestData,
//                         receiverContact: val.target.value,
//                       })
//                     );
//                   }}
//                   rules={[
//                     {
//                       required: true,
//                       message: "Requerd Field!",
//                     },
//                   ]}
//                 >
//                   <TextArea placeholder={t("placeholders.receiverContact")} />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Input.Group>
//           <Input.Group>
//             <Row gutter={24}>
//               <Col span={12}>
//                 <Form.Item
//                   hasFeedback
//                   name="capacity"
//                   rules={[
//                     {
//                       required: true,
//                       message: "Requerd Field!",
//                     },
//                   ]}
//                 >
//                   <Select
//                     style={{ border: "2px solid #1890ff" }}
//                     className="inp-emai"
//                     placeholder={t("placeholders.selectCapacity")}
//                     onChange={(val) => {
//                       let value = capacities[val];
//                       form.setFieldsValue({ capacity: value.capacity });
//                       setCapacity(value);
//                       dispatch(
//                         MakeRequestAction({
//                           ...makeRequestData,
//                           capacity: value,
//                         })
//                       );
//                     }}
//                   >
//                     {capacities &&
//                       capacities.map((v, i) => {
//                         return (
//                           <Option key={i} value={i}>
//                             {v.capacity}
//                           </Option>
//                         );
//                       })}
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col span={12}>
//                 <Form.Item
//                   style={{ border: "2px solid #1890ff" }}
//                   hasFeedback
//                   name="height"
//                   rules={[
//                     {
//                       required: true,
//                       message: "Requerd Field!",
//                     },
//                   ]}
//                 >
//                   <Select
//                     className="sign-ema"
//                     placeholder={t("Select Height")}
//                     onChange={(val) => {
//                       let value = heights[val];
//                       form.setFieldsValue({ height: value.height });
//                       setHeight(value);
//                       dispatch(
//                         MakeRequestAction({
//                           ...makeRequestData,
//                           height: value,
//                         })
//                       );
//                     }}
//                   >
//                     {heights &&
//                       heights.map((v, i) => {
//                         return (
//                           <Option key={i} value={i}>
//                             {v.height}
//                           </Option>
//                         );
//                       })}
//                   </Select>
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Input.Group>
//           <Input.Group>
//             <Row gutter={24}>
//               <Col span={24}>
//                 <Form.Item
//                   hasFeedback
//                   name="type"
//                   rules={[
//                     {
//                       required: true,
//                       message: "Requerd Field!",
//                     },
//                   ]}
//                 >
//                   <Select
//                     className="inp-emai"
//                     style={{ border: "2px solid #1890ff" }}
//                     placeholder={t("placeholders.selectType")}
//                     onChange={(val) => {
//                       let value = types[val];
//                       form.setFieldsValue({ type: value.type });
//                       setType(value);
//                       dispatch(
//                         MakeRequestAction({ ...makeRequestData, type: value })
//                       );
//                     }}
//                   >
//                     {types &&
//                       types.map((v, i) => {
//                         return (
//                           <Option key={i} value={i}>
//                             {v.type}
//                           </Option>
//                         );
//                       })}
//                   </Select>
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Input.Group>
//           <Input.Group>
//             <Row gutter={24}>
//               <Col span={12}>
//                 <Row>
//                   <Col span={12}>
//                     <div className="bi-div">
//                       <Form.Item
//                         style={{ border: "2px solid #1890ff" }}
//                         className="inp-emai"
//                         hasFeedback
//                         name="blNumber"
//                         onChange={(val) => {
//                           // console.log(val.target.value);
//                           form.setFieldsValue({
//                             blNumber: val.target.value,
//                           });

//                           dispatch(
//                             MakeRequestAction({
//                               ...makeRequestData,
//                               blNumber: val.target.value,
//                             })
//                           );
//                         }}
//                         rules={[
//                           {
//                             required: true,
//                             message: "Requerd Field!",
//                           },
//                         ]}
//                       >
//                         <Input placeholder={t("placeholders.blNumber")} />
//                       </Form.Item>
//                     </div>
//                   </Col>
//                   <Col span={12}>
//                     <Form.Item
//                       hasFeedback
//                       className="inp-emai"
//                       name="blNumberUpload"
//                       rules={[
//                         {
//                           required: true,
//                           message: "Requerd Field!",
//                         },
//                       ]}
//                     >
//                       <div
//                         className="upload-img-div"
//                         style={{
//                           border: "2px solid #1890ff",
//                           marginLeft: "10px",
//                         }}
//                       >
//                         <Upload
//                           className="upload-img"
//                           listType="picture"
//                           multiple={false}
//                           maxCount={1}
//                           onChange={(e) => {
//                             if (e.file.status === "removed") {
//                               form.setFieldsValue({
//                                 blNumberUpload: "",
//                               });
//                             } else {
//                               form.setFieldsValue({
//                                 blNumberUpload: e.file.originFileObj,
//                               });
//                             }
//                             dispatch(
//                               MakeRequestAction({
//                                 ...makeRequestData,
//                                 blNumberUpload: e.file.originFileObj,
//                               })
//                             );
//                           }}
//                         >
//                           <Button icon={<UploadOutlined />}>
//                             {t("placeholders.upload")}
//                           </Button>
//                         </Upload>
//                       </div>
//                     </Form.Item>
//                   </Col>
//                 </Row>
//               </Col>
//               <Col span={12}>
//                 <Row>
//                   <Col span={12}>
//                     <Form.Item
//                       className="sign-ema"
//                       hasFeedback
//                       name="iroDate"
//                       onChange={(val) => {
//                         form.setFieldsValue({
//                           iroDate: val.target.value,
//                         });

//                         dispatch(
//                           MakeRequestAction({
//                             ...makeRequestData,
//                             iroDate: val.target.value,
//                           })
//                         );
//                       }}
//                       rules={[
//                         {
//                           required: true,
//                           message: "Requerd Field!",
//                         },
//                       ]}
//                     >
//                       <DatePicker
//                         style={{ border: "2px solid #1890ff" }}
//                         inputReadOnly
//                         placeholder="IRO"
//                         className="dateiro"
//                       />
//                     </Form.Item>
//                   </Col>
//                   <Col span={12}>
//                     <Form.Item
//                       style={{ border: "2px solid #1890ff" }}
//                       hasFeedback
//                       className="sign-ema"
//                       name="iroUpload"
//                       rules={[
//                         {
//                           required: true,
//                           message: "Requerd Field!",
//                         },
//                       ]}
//                     >
//                       <Upload
//                         listType="picture"
//                         multiple={false}
//                         maxCount={1}
//                         onChange={(e) => {
//                           if (e.file.status === "removed") {
//                             form.setFieldsValue({
//                               iroUpload: "",
//                             });
//                           } else {
//                             form.setFieldsValue({
//                               iroUpload: e.file.originFileObj,
//                             });
//                           }
//                           dispatch(
//                             MakeRequestAction({
//                               ...makeRequestData,
//                               iroUpload: e.file.originFileObj,
//                             })
//                           );
//                         }}
//                       >
//                         <Button>{t("placeholders.upload")}</Button>
//                       </Upload>
//                     </Form.Item>
//                   </Col>
//                 </Row>
//               </Col>
//             </Row>
//           </Input.Group>
//           <Input.Group>
//             <Row gutter={24}>
//               <Col span={12}>
//                 <Form.Item
//                   className="inp-emai"
//                   //   label={t("Modal.estimateTime")}
//                   // hasFeedback
//                   // name="time"
//                   // rules={[
//                   //   {
//                   //     required: true,
//                   //     message: "Requerd Field!",
//                   //   },
//                   // ]}
//                 >
//                   <Input
//                     value={time}
//                     disabled
//                     className="inp-emai"
//                     style={{ border: "2px solid #1890ff" }}
//                     placeholder={t("placeholders.estimateTime")}
//                     addonAfter=" Day"
//                   />
//                 </Form.Item>
//               </Col>
//               <Col span={12}>
//                 <Form.Item
//                   className="sign-ema"
//                   //   label={t("Modal.distance")}
//                   // hasFeedback
//                   // name="distance"
//                   // rules={[
//                   //   {
//                   //     required: true,
//                   //     message: "Requerd Field!",
//                   //   },
//                   // ]}
//                 >
//                   <Input
//                     value={distance}
//                     disabled
//                     style={{ border: "2px solid #1890ff" }}
//                     placeholder={t("placeholders.distance")}
//                     addonAfter="KM"
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Input.Group>
//           <Input.Group>
//             <Row gutter={24}>
//               <Col span={12}>
//                 <Form.Item
//                   className="inp-emai"
//                   //   label={t("Modal.price")}
//                   // hasFeedback
//                   // name="price"

//                   // rules={[
//                   //   {
//                   //     required: true,
//                   //     message: "Requerd Field!",
//                   //   },
//                   // ]}
//                 >
//                   <Input
//                     className="inp-emai"
//                     value={price}
//                     disabled
//                     style={{ border: "2px solid #1890ff" }}
//                     placeholder={t("placeholders.price")}
//                     addonAfter="SRA"
//                   />
//                 </Form.Item>
//               </Col>
//             </Row>
//           </Input.Group>
//           <Form.Item>
//             <Space>
//               <Button className="make-btn" type="primary" htmlType="submit">
//                 <span>{t("Modal.make")}</span>
//               </Button>
//               <Button onClick={() => window.history.back()}>{"Cancel"}</Button>
//             </Space>
//           </Form.Item>
//         </Form>
//       </div>
//     </div>
//   );
// }