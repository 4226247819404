import React from "react";
import { Tabs } from "antd";
import FinanceTable from "../../Tables/FinanceTable";
import { useTranslation } from "react-i18next";
export default function Finance() {
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  return (
    <div className="inp-emai">
      <div>
        <h3>{t("routes.finance")}</h3>
      </div>
      <div>
        <FinanceTable />
      </div>
    </div>
  );
}
