import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { BiMessageRoundedAdd } from "react-icons/bi";
import { BsFillXCircleFill, BsArrowLeft } from "react-icons/bs";
import { AiOutlineSend, AiOutlineClose } from "react-icons/ai";
import { Input } from "antd";
import ChatData from "./OwnerData";
import ReactScrollableFeed from "react-scrollable-feed";
import { MDBCardHeader, MDBCardBody } from "mdb-react-ui-kit";
import { BsFillChatDotsFill } from "react-icons/bs";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

function ChatOwner() {
  let token = Math.floor(100000 + Math.random() * 900000);
  const { t } = useTranslation();

  const [form, setform] = useState({
    message: "",
  });
  const [isShown, setIsShown] = useState(false);
  const [chat, setChat] = useState([]);
  // const [adminmessage, setAdminMessage] = useState(
  //   <div>{t("if you need any help Admin alwasy available for you!")}</div>
  // );

  var question = (
    <div>
      {t("Choose your question and reply with question number")} <br></br>
      1){t("What Is Oac?")}
      <br></br>
      2){t("Why choose Oac?")} <br></br>
      3){t("How To Provid Oac?")}
      <br></br>
      4){t("is Our Privacy Save in Oac?")}
      <br></br>
      5){t("What is Onwer mean in Oac?")}
      <br></br>
      6){t("How to be a Part Of Oac?")}
      <br></br>
      7){t("Is Oac Charge For Join?")}
      <br></br>
      8){t("Contact with Admin?")}
    </div>
  );
  var adminmessage = (
    <div>{t("if you need any help Admin alwasy available for you!")}</div>
  );
  const { message } = form;
  function between() {
    Math.floor(Math.random() * 1000000 + 1);

    console.log(Math.floor(100000 + Math.random() * 900000));
  }

  const ChatData = [
    {
      id: 1,
      Question1: "What is Oac?",
      Answer: <div>{t("Oac Means That To Join Any Vehicles")}</div>,
    },
    {
      id: 2,
      Question: "Why choose Oac? ",
      Answer: (
        <div>{t("Because Oac Best Platform To Choose Any Vehicles")}</div>
      ),
    },

    {
      id: 3,
      Question: "How To Provide Oac?",
      Answer: <div>{t("Oac Always Provide you To best Vehicles")}</div>,
    },
    {
      id: 4,
      Question: "is Our Privacy Save in Oac?",
      Answer: (
        <div>
          {t("We use Save Privacy to provide and improve the Services")}
        </div>
      ),
    },
    {
      id: 5,
      Question: "What is Coustomer mean in Oac?",
      Answer: (
        <div>
          {t(
            "Onwer check out how customers get benefited from an online truck booking Service."
          )}
        </div>
      ),
    },

    {
      id: 6,
      Question: "How to be a Part Of Oac?",
      Answer: <div>{t("Oac Manage all your work with just a few clicks")}</div>,
    },
    {
      id: 7,
      Question: "Is Oac Charge For Join?",
      Answer: <div>{t("Yes")}</div>,
    },
    {
      id: 8,
      Question: "Contact with Admin?",
      Answer: <div>{t("Sending your mail to admin...")}</div>,
    },
  ];

  const handleChange = (e) => {
    setform({ ...form, [e.target.name]: e.target.value });
  };
  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      handleSubmit();
    }
  };
  const handleSubmit = () => {
    if (form.message) {
      let arr = chat;
      arr.push({ message: form.message, from: "user", time: new Date() });
      setChat([...arr]);
    } else {
      alert("Required field");
    }
    if (chat?.length === 1) {
      setTimeout(() => {
        let arr = chat;

        arr.push({ message: question, from: "admin", time: new Date() });
        setChat([...arr]);
      }, 1000);
    }
    let answer = ChatData.find((v) => v.id === parseInt(form.message));
    if (answer) {
      setTimeout(() => {
        let arr = chat;
        arr[1].message = question; //dghjk
        arr.push({ message: answer?.Answer, from: "admin", time: new Date() });
        setChat([...arr]);
      }, 1000);
    }

    setform({
      message: "",
    });
    if (answer === undefined) {
      setTimeout(() => {
        let arr = chat;
        arr[1].message = question;
        // arr[0].message = adminmessage;

        arr.push({
          message: adminmessage,
          from: "admin",
          time: new Date(),
        });
        setChat([...arr]);
      }, 1000);
    }
  };
  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  // console.log("question ", question);
  // console.log("chat", chat);
  return (
    <>
      <Box className="chat-Box">
        <Fab className="float_btn" onClick={handleClick}>
          <BsFillChatDotsFill className="bsfill" />
        </Fab>
      </Box>

      {isShown && (
        <div>
          <div className="sh-div ">
            <div>
              <div style={{ borderRadius: "15px" }}>
                <MDBCardHeader
                  className="d-flex justify-content-between align-items-start p-2 bg-info text-white "
                  // style={{
                  //   borderTopLeftRadius: "15px",
                  //   borderTopRightRadius: "15px",
                  // }}
                >
                  <BsArrowLeft
                    style={{ color: "#007aff", fontSize: "0px" }}
                    icon="angle-left"
                    onClick={handleClick}
                  />
                  <p className="mb-0 fw-bold">Live chat</p>
                  <div onClick={handleClick}>
                    <AiOutlineClose className="cancel-chat" />
                  </div>
                </MDBCardHeader>

                <MDBCardBody style={{ textAlign: "start" }}>
                  <div className="box_content">
                    <div className="chat_div">
                      <ReactScrollableFeed>
                        <div className="d-flex flex-column justify-content-start ">
                          <h6 style={{ color: " white" }}>
                            {t("WELCOME TO OAC EXPRESS")}
                          </h6>
                          {/* {t(
                            "Choose your question and reply with question number"
                          )} */}
                          <h6 className="send-any">{t("Modal.SendAny")}</h6>
                          {chat.map((val) => {
                            return (
                              <>
                                {val.from === "user" ? (
                                  <div className="d-flex  justify-content-start mt-2">
                                    <div
                                      className="p-2 me-3"
                                      style={{
                                        borderRadius: "2px",
                                        backgroundColor: "#fbfbfb",
                                      }}
                                    >
                                      <p
                                        className="small mb-0 "
                                        style={{
                                          color: " #173ca0",
                                          fontSize: "13px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {val.message}
                                      </p>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="d-flex flex-row justify-content-start mt-2 ">
                                    <div
                                      className="p-2 me-3 "
                                      style={{
                                        backgroundColor: "#fbfbfb",
                                        marginLeft: "60px",
                                      }}
                                    >
                                      <p
                                        className="small mb-0"
                                        style={{
                                          color: "#173ca0",
                                          fontSize: "13px",
                                          fontWeight: "500",
                                        }}
                                      >
                                        {val.message}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </ReactScrollableFeed>
                    </div>

                    <Input
                      onKeyDown={keyDownHandler}
                      className="outinp mt-2"
                      placeholder="Send Message"
                      name="message"
                      type="text"
                      minLength={8}
                      onChange={handleChange}
                      value={message}
                      autoComplete="off"
                      addonAfter={
                        <AiOutlineSend
                          style={{ color: "#173ca0" }}
                          className="send-icon"
                          onClick={handleSubmit}
                        />
                      }
                    />
                  </div>
                </MDBCardBody>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatOwner;
