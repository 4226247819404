import React, { useState } from "react";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import { BsArrowLeft } from "react-icons/bs";
import { AiOutlineSend, AiOutlineClose } from "react-icons/ai";
import { Input } from "antd";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ReactScrollableFeed from "react-scrollable-feed";
import { MDBCardHeader, MDBCardBody } from "mdb-react-ui-kit";
import ChatData from "./ChatData";

import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import { BsFillChatDotsFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

// import { CloseOutlined } from "antd/icon";
// import CloseIcon from "@mui/icons-material/Close";

function Chatt() {
  const { t } = useTranslation();

  let token = Math.floor(100000 + Math.random() * 900000);
  const [data, setform] = useState({
    message: "",
  });
  const [user, setUser] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [chat, setChat] = useState([]);
  const [userEmail, setUserEmail] = useState();
  // const [adminmessage, setAdminMessage] = useState(
  //   <div>{t("Modal.ifyouneed")}</div>
  // );

  const emailRegex = /\S+@\S+\.\S+/;
  var question = (
    <div>
      {t("Choose your question and reply with question number")} <br></br>
      1){t("What Is Oac?")}
      <br></br>
      2){t("Why choose Oac?")} <br></br>
      3){t("How To Provid Oac?")}
      <br></br>
      4){t("is Our Privacy Save in Oac?")}
      <br></br>
      5){t("How to be a Part Of Oac?")}
      <br></br>
      6){t("Is Oac Charge For Join?")}
      <br></br>
      7){t("Contact with Admin?")}
    </div>
  );

  var adminmessage = (
    <div>{t("if you need any help Admin alwasy available for you!")}</div>
  );
  const { message } = data;

  const emailSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("user_email", userEmail);
    if (isValid) {
      setSubmitting(true);
    }
  };

  function between() {
    Math.floor(Math.random() * 1000000 + 1);
    console.log(Math.floor(100000 + Math.random() * 900000));
  }

  const handleEmail = () => {
    let email = localStorage.getItem("user_email") || "";

    const templateParams = {
      name: "Oac Chatbox",
      notes: `User Email: ${email}
      User Token: ${token}`,
    };
    emailjs
      .send(
        "service_vqrerg9",
        "template_dls2bgm",
        templateParams,
        "4fxxHHL9iBT07-CPG"
      )
      .then(
        (result) => {
          console.log(result.text);
          let arr = chat;
          arr.push({
            message: `
            Thank You for contacting your tracking number is: ${token}
            Admin will contact you soon
          `,
            from: "admin",
            time: new Date(),
          });

          setChat([...arr]);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const validateEmail = (e) => {
    e.preventDefault();
    const email = e.target.value;
    if (emailRegex.test(email)) {
      setIsValid(true);
      setUserEmail(email);
      setUser("This is an excellent password.");
    } else {
      setIsValid(false);
      setUser("Please enter a valid email!");
    }
  };

  const handleChange = (e) => {
    setform({ ...data, [e.target.name]: e.target.value });
  };
  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();

      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (data.message) {
      let arr = chat;
      arr.push({ message: data.message, from: "user", time: new Date() });
      setChat([...arr]);
    } else {
      alert(" Required field");
    }
    if (chat?.length === 1) {
      setTimeout(() => {
        let arr = chat;
        arr.push({ message: question, from: "admin", time: new Date() });
        setChat([...arr]);
      }, 1000);
    }
    let answer = ChatData.find((v) => v.id === parseInt(data.message));
    console.log(answer);
    if (answer) {
      setTimeout(() => {
        let arr = chat;
        arr[1].message = question; //dghjk
        arr.push({ message: answer?.Answer, from: "admin", time: new Date() });
        setChat([...arr]);
      }, 1000);
    }
    if (parseInt(data.message) === 7) {
      handleEmail();
    }
    setform({
      message: "",
    });

    if (answer === undefined) {
      setTimeout(() => {
        let arr = chat;
        arr[1].message = question;
        arr.push({ message: adminmessage, from: "admin", time: new Date() });
        setChat([...arr]);
      }, 1000);
    }
  };
  const handleClick = () => {
    setIsShown((current) => !current);
  };
  console.log(data.message, "chatdata");
  return (
    <>
      <Box className="chat-Box">
        <Fab className="float_btn" onClick={handleClick}>
          <BsFillChatDotsFill className="bsfill" />
        </Fab>
      </Box>

      {isShown && (
        <div>
          <div className="email-div">
            <div className="circle" onClick={handleClick}>
              <AiOutlineClose />
            </div>

            <div>
              <Form.Group className="from-divv">
                <label
                  style={{
                    color: "white",
                    fontSize: "22px",
                    fontWeight: "500",
                  }}
                >
                  Enter Email Address
                </label>
                <div>
                  <input
                    type="email"
                    placeholder=" Enter your email"
                    className="email-input"
                    onChange={validateEmail}
                    style={{
                      borderRadius: "5px",
                    }}
                  />

                  <div
                    style={{
                      color: "white",
                      fontSize: "11px",
                      paddingLeft: "33px",
                    }}
                    className={`message ${isValid ? "success" : "error"}`}
                  >
                    {user}
                  </div>
                </div>
                <Button
                  style={{
                    backgroundColor: "white",
                    color: " #295ef5",
                    width: "110px",
                    borderRadius: "5px",
                  }}
                  onClick={emailSubmit}
                >
                  Submit
                </Button>
              </Form.Group>
            </div>
          </div>

          {submitting && (
            <div>
              <div className="sh-div ">
                <div>
                  <div style={{ borderRadius: "15px" }}>
                    <MDBCardHeader className="d-flex justify-content-between align-items-start p-2 bg-info text-white ">
                      <BsArrowLeft
                        style={{ color: "#007aff", fontSize: "0px" }}
                        icon="angle-left"
                        onClick={handleClick}
                      />
                      <p className="mb-0 fw-bold">Live chat</p>
                      <div onClick={handleClick}>
                        <AiOutlineClose className="cancel-chat" />
                      </div>
                    </MDBCardHeader>

                    <MDBCardBody style={{ textAlign: "start" }}>
                      <div className="box_content">
                        <div className="chat_div">
                          <ReactScrollableFeed>
                            <div className="d-flex flex-column justify-content-start ">
                              <h6 style={{ color: " white" }}>
                                {t("WELCOME TO OAC EXPRESS")}
                              </h6>
                              <h6 className="send-any">{t("Modal.SendAny")}</h6>
                              {chat.map((val) => {
                                return (
                                  <>
                                    {val.from === "user" ? (
                                      <div className="d-flex  justify-content-start mt-2">
                                        <div
                                          className="p-2 me-3"
                                          style={{
                                            backgroundColor: "#fbfbfb",
                                          }}
                                        >
                                          <p
                                            className="small mb-0 "
                                            style={{
                                              color: "#173ca0 ",
                                              fontSize: "13px",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {val.message}
                                          </p>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="d-flex flex-row justify-content-start mt-2 ">
                                        <div
                                          className="p-2 me-3  "
                                          style={{
                                            backgroundColor: "#fbfbfb",
                                            marginLeft: "60px",
                                          }}
                                        >
                                          <p
                                            className="small mb-0"
                                            style={{
                                              color: " #173ca0",
                                              fontSize: "13px",
                                              fontWeight: "500",
                                            }}
                                          >
                                            {val.message}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </ReactScrollableFeed>
                        </div>

                        <Input
                          onKeyDown={keyDownHandler}
                          className="outinp mt-2"
                          placeholder="Send Message"
                          name="message"
                          type="text"
                          minLength={8}
                          onChange={handleChange}
                          value={message}
                          autoComplete="off"
                          addonAfter={
                            <AiOutlineSend
                              onClick={handleSubmit}
                              style={{ color: " #173ca0" }}
                              className="send-icon"
                            />
                          }
                        />
                      </div>
                    </MDBCardBody>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Chatt;
