import React, { useState, useEffect, useRef } from "react";
import "./login.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import Chatt from "../../chat-box/Chat-box";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import {
  LockOutlined,
  UserOutlined,
  MailOutlined,
  UploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { BsTelephone } from "react-icons/bs";
import {
  Button,
  Form,
  Input,
  Radio,
  Divider,
  Row,
  Col,
  Image,
  Alert,
  message as antdMessage,
  Upload,
} from "antd";
import ForgetPasswordModal from "../Modals/ForgetPasswordModal";
import Loader from "../Loaders/Loader";
//Redux
import { Language } from "../../Redux/Actions/userActions";
import { useDispatch, useSelector } from "react-redux";
import { isSupported } from "firebase/messaging";
import logo from "../Landing/assets/home/OAC LOGO2.png";
import { useMutation } from "@apollo/client";
import { CREATE_CUSTOMER } from "../../graphql/Mutation/adminMutation";
// const [loginCustomer] = useMutation(LOGIN_CUSTOMER);

import {
  LOGIN_CUSTOMER,
  CREATE_OWNER,
  LOGIN_OWNER,
} from "../../graphql/Mutation/adminMutation";
import { log } from "@craco/craco/lib/logger";
import { LOGIN_DRIVER } from "../../graphql/Mutation/driver/LoginDriverMutatoin";

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function Login() {
  useEffect(() => {
    const type = localStorage.getItem("type");
    if (type === "Owner") {
      navigate("/owner/fleet_managment");
    } else if (type === "customers") {
      navigate("/customer/tarnsport_requests");
    }
  }, []);

  const [type, setType] = useState("owner");

  const [createCustomer] = useMutation(CREATE_CUSTOMER);
  const [createOwner] = useMutation(CREATE_OWNER);
  const [loginCustomer] = useMutation(LOGIN_CUSTOMER);
  const [loginOwner] = useMutation(LOGIN_OWNER);
  const [loginDriver] = useMutation(LOGIN_DRIVER);
  const [form] = Form.useForm();
  const signupFormRef = useRef();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { lng } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();
  const [login, setLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    crNumberUpload: "",
    idNumberUpload: "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [user, SetUser] = useState("driver");

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleLogin = async (values) => {
    setLoading(true);
    try {
      if (selectedUser === "owners") {
        await onFinishLogin(values);
      } else if (selectedUser === "customers") {
        await onFinishLogin1(values);
      } else if (selectedUser === "driver") {
        await onFinishLogin2(values);
      } else {
      }
    } catch (error) {
      console.error("GraphQL Error:", error);
    }
    setLoading(false);
  };

  const [selectedUser, setSelectedUser] = useState("driver");
  // const onFinishLogin = async (values) => {
  //   setLoading(true);
  //   console.log("handleLogin called owners side");
  //   try {
  //     const res = await loginOwner({
  //       variables: {
  //         loginInput: {
  //           email: values.email,
  //           password: values.password,
  //         },
  //       },
  //     });
  //     const { data } = res;
  //     if (data.loginOwner && data.loginOwner.data) {
  //       localStorage.setItem("currentUserEmail", values.email);
  //       localStorage.setItem("id", JSON.stringify(data.loginOwner.data._id));
  //       localStorage.setItem(
  //         "userType",
  //         JSON.stringify(data.loginOwner.data.__typename)

  //       );
  //     } else {
  //       console.error("Invalid response data for loginOwner");

  //     }
  //     navigate("/owner/fleet_managment");
  //     console.log("API response for loginOwner:", res);
  //   } catch (error) {
  //     console.error("GraphQL Error:", error);

  //   }
  //   setLoading(false);
  // };

  const onFinishLogin = async (values) => {
    setLoading(true);
    console.log("handleLogin called owners side");

    try {
      const res = await loginOwner({
        variables: {
          loginInput: {
            email: values.email,
            password: values.password,
          },
        },
      });

      const { data } = res;

      if (data.loginOwner && data.loginOwner.data) {
        // Login successful, continue with the appropriate action
        localStorage.setItem("currentUserEmail", values.email);
        localStorage.setItem("id", JSON.stringify(data.loginOwner.data._id));
        localStorage.setItem(
          "userType",
          JSON.stringify(data.loginOwner.data.__typename)
        );
        // navigate("/owner/fleet_managment");
        console.log("API response for loginOwner:", res);
        antdMessage.success("Login successfully");
        navigate("/owner/fleet_managment");
      } else {
        // Login failed, handle accordingly
        console.error("Invalid response data for loginOwner");

        if (res.errors && res.errors.length > 0) {
          const errorMessage = res.errors[0]?.antdMessage;
          if (errorMessage.includes("Invalid Credentials")) {
            // Display a specific error message for invalid credentials
            antdMessage.error("Invalid email or password. Please try again.");
          } else {
            // Handle other error messages or cases as needed
            antdMessage.error(errorMessage);
          }
        } else {
          antdMessage.error("Invalid email or password. Please try again.");
        }
      }
    } catch (error) {
      console.error("GraphQL Error:", error);
      antdMessage.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  // const onFinishLogin1 = async (values) => {
  //   setLoading(true);
  //   try {
  //     const res = await loginCustomer({
  //       variables: {
  //         loginInput: {
  //           email: values.email,
  //           password: values.password,
  //         },
  //       },
  //     });
  //     const { message, success, data } = res.data.loginCustomer;
  //     localStorage.setItem("id", JSON.stringify(data._id));
  //     localStorage.setItem("userType", data.__typename);
  //     localStorage.setItem("currentUserEmail", values.email);
  //     navigate("/customer/tarnsport_requests");
  //     if (success === true) {
  //       setLoading(false);
  //       message.success("Login Successfully!");
  //       navigate("/customer/tarnsport_requests");
  //     } else {
  //       setLoading(false);
  //       message.error(message);

  //     }
  //     // }
  //   } catch (error) {
  //     console.error("GraphQL Error:", error);
  //     setLoading(false);
  //   }
  // };

  const onFinishLogin1 = async (values) => {
    setLoading(true);

    try {
      const res = await loginCustomer({
        variables: {
          loginInput: {
            email: values.email,
            password: values.password,
          },
        },
      });

      const { message, success, data } = res.data.loginCustomer;

      if (success === true) {
        // Login successful, continue with the appropriate action
        localStorage.setItem("id", JSON.stringify(data._id));
        localStorage.setItem("userType", data.__typename);
        localStorage.setItem("currentUserEmail", values.email);
        navigate("/customer/tarnsport_requests");
        setLoading(false);
        antdMessage.success("Login Successfully!");
      } else {
        // Login failed, handle accordingly
        console.error("Login Error:", message);

        if (message.includes("Invalid Credentials")) {
          // Display a specific error message for invalid credentials
          antdMessage.error("Invalid email or password. Please try again.");
        } else {
          // Handle other error messages or cases as needed
          antdMessage.error(message);
        }

        setLoading(false);
      }
    } catch (error) {
      console.error("GraphQL Error:", error);
      setLoading(false);
      antdMessage.error("An error occurred. Please try again later.");
    }
  };

  // const onFinishLogin2 = async (values) => {
  //   setLoading(true);
  //   try {
  //     const res = await loginDriver({
  //       variables: {
  //         loginInput: {
  //           idNo: values.idNo,
  //           password: values.password,
  //         },
  //       },
  //     });
  //     const { message, success, data } = res.data.loginDriver;
  //     localStorage.setItem("idNo", JSON.stringify(data.idNo));
  //     localStorage.setItem("id", JSON.stringify(data._id));
  //     localStorage.setItem("userType", data.__typename);
  //     navigate("/driver/driverprofile");
  //     if (success === true) {
  //       setLoading(false);
  //       message.success("Login Successfully!");
  //       navigate("/driver/driverprofile");
  //     } else {
  //       setLoading(false);
  //       message.error(message);
  //     }
  //     // }
  //   } catch (error) {
  //     console.error("GraphQL Error:", error);
  //     setLoading(false);
  //   }
  // };

  const onFinishLogin2 = async (values) => {
    setLoading(true);

    try {
      const res = await loginDriver({
        variables: {
          loginInput: {
            idNo: values.idNo,
            password: values.password,
          },
        },
      });

      const { message, success, data } = res.data.loginDriver;

      if (success === true) {
        // Login successful, continue with the appropriate action
        localStorage.setItem("idNo", JSON.stringify(data.idNo));
        localStorage.setItem("id", JSON.stringify(data._id));
        localStorage.setItem("userType", data.__typename);
        navigate("/driver/driverprofile");
        setLoading(false);
        antdMessage.success("Login Successfully!");
      } else {
        // Login failed, handle accordingly
        console.error("Login Error:", message);

        if (message.includes("Invalid Credentials")) {
          // Display a specific error message for invalid credentials
          antdMessage.error("Invalid ID number or password. Please try again.");
        } else {
          // Handle other error messages or cases as needed
          antdMessage.error(message);
        }

        setLoading(false);
      }
    } catch (error) {
      console.error("GraphQL Error:", error);
      setLoading(false);
      antdMessage.error("An error occurred. Please try again later.");
    }
  };

  const [crNumberImageUrl, setCrNumberImageUrl] = useState(null);
  const [cruploading, setcrUploading] = useState(false);
  // function 1 image upload
  const [idNumberImageUrl, setIdNumberImageUrl] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleImageUpload1 = (file) => {
    if (!file) return;

    const imageRef = ref(storage, `signUp/${file.name}`);
    setUploading(true);
    uploadBytes(imageRef, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((url) => {
        setIdNumberImageUrl(url);
        console.log("Upload successful. URL:", url);
      })
      .catch((error) => {
        console.error("Error during image upload:", error);
      })
      .finally(() => {
        setUploading(false);
      });
  };
  const handleImageDelete = () => {
    setIdNumberImageUrl(null);
  };
  const customRequest = ({ file }) => {
    handleImageUpload1(file);
  };

  const handleChange1 = ({ fileList }) => {
    console.log(fileList);
  };

  const handleImageUpload2 = (file) => {
    if (!file) return;

    const imageRef = ref(storage, `signUp/${file.name}`);
    setcrUploading(true);
    uploadBytes(imageRef, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((url) => {
        setCrNumberImageUrl(url);
        console.log("Upload successful. URL:", url);
      })
      .catch((error) => {
        console.error("Error during image upload:", error);
      })
      .finally(() => {
        setcrUploading(false);
      });
  };
  const handleImageDeleteCr = () => {
    setCrNumberImageUrl(null);
  };
  const customRequestCr = ({ file }) => {
    handleImageUpload2(file);
  };

  const handleChangeCr = ({ fileList }) => {
    console.log(fileList);
  };
  // Function to handle image upload
  // const handleImageUpload1 = (image, setImageUrl) => {
  //   if (!image) return Promise.reject("Image is undefined");
  //   const imageRef = ref(storage, `signUp/${image.name}`);
  //   return uploadBytes(imageRef, image)
  //     .then((snapshot) => {
  //       return getDownloadURL(snapshot.ref);
  //     })
  //     .then((url) => {
  //       setImageUrl(url);
  //       console.log(url);
  //       return url;
  //     })
  //     .catch((error) => {
  //       console.error("Error while uploading or getting the image URL", error);
  //       throw error;
  //     });
  // };
  // const handleImageUpload2 = (image, setImageUrl) => {
  //   if (!image) return Promise.reject("Image is undefined");
  //   const imageRef = ref(storage, `signUp/${image.name}`);
  //   return uploadBytes(imageRef, image)
  //     .then((snapshot) => {
  //       return getDownloadURL(snapshot.ref);
  //     })
  //     .then((url) => {
  //       setCrNumberImageUrl(url);
  //       console.log(url);
  //       return url;
  //     })
  //     .catch((error) => {
  //       console.error("Error while uploading or getting the image URL", error);
  //       throw error;
  //     });
  // };
  // {Original for onFinishSignup}
  // const onFinishSignup1 = async (values) => {
  //   setLoading(true);
  //   console.log(values.name);

  //   try {
  //     const res = await createCustomer({
  //       variables: {
  //         registerCustomerInput: {
  //           profileImage: "active",
  //           phoneNumber: values.phoneNumber,
  //           name: values?.name,
  //           idNumberUpload: idNumberImageUrl,
  //           idNumber: values.idNumber,
  //           email: values.email,
  //           password: values.password,
  //           crNumberUpload: crNumberImageUrl,
  //           crNumber: values.crNumber,
  //         },
  //       },
  //     });

  //     setLogin(true);
  //   } catch (error) {
  //     console.error("Error while uploading images or saving to MongoDB", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  const [errorState, setErrorState] = useState(null);

  const onFinishSignup1 = async (values) => {
    setLoading(true);
    localStorage.setItem("name", values.name);
    try {
      const res = await createCustomer({
        variables: {
          registerCustomerInput: {
            profileImage: "active",
            phoneNumber: values.phoneNumber,
            name: values?.name,
            idNumberUpload: idNumberImageUrl,
            idNumber: values.idNumber,
            email: values.email,
            password: values.password,
            crNumberUpload: crNumberImageUrl,
            crNumber: values.crNumber,
          },
        },
      });

      if (res.data.createCustomer.success) {
        // Registration successful, continue with the appropriate action
        setLogin(true);
        console.log(
          "Customer registered successfully:",
          res.data.createCustomer
        );
      } else {
        // Registration failed, handle accordingly
        //  message.error("Error during registration:",res.data.createCustomer.message);
        antdMessage.error({
          content: `Customer already registered. Please try using a different email`,
          duration: 5, // Duration in seconds
        });

        // message.error("Error during registration:", res.data.createCustomer.message);

        // Check if the error message indicates that the user is already registered
        if (res.data.createCustomer.message.includes("already registered")) {
          // User is already registered, handle accordingly
          // For example, set an error state or display a message to the user
          setErrorState("User already registered. Please use another email.");
        } else {
          // Handle other error messages or cases as needed
          setErrorState(res.data.createCustomer.message);
        }
      }
    } catch (error) {
      console.error("Error while uploading images or saving to MongoDB", error);
      // Handle other errors as needed
    } finally {
      setLoading(false);
    }
  };

  const onFinishSignup = async (values) => {
    try {
      const result = await createOwner({
        variables: {
          registerOwnerInput: {
            profileImage: "imageUrl",
            phoneNumber: values.phoneNumber,
            name: values?.name,
            idNumberUpload: idNumberImageUrl,
            idNumber: values.idNumber,
            email: values.email,
            crNumberUpload: crNumberImageUrl,
            crNumber: values.crNumber,
            password: values.password,
          },
        },
      });
      if (result.data.createOwner.success) {
        // Registration successful, continue with the appropriate action
        setLogin(true);
        console.log(
          "Customer registered successfully:",
          result.data.createOwner
        );
      } else {
        // Registration failed, handle accordingly
        //  message.error("Error during registration:",res.data.createCustomer.message);
        antdMessage.error({
          content: `Owner already registered. Please try using a different email`,
          duration: 5, // Duration in seconds
        });

        // message.error("Error during registration:", res.data.createCustomer.message);

        // Check if the error message indicates that the user is already registered
        if (result.data.createOwner.message.includes("already registered")) {
          // User is already registered, handle accordingly
          // For example, set an error state or display a message to the user
          setErrorState("User already registered. Please use another email.");
        } else {
          // Handle other error messages or cases as needed
          setErrorState(result.data.createOwner.message);
        }
      }
    } catch (error) {
      console.error("Error while uploading images or saving to MongoDB", error);
      // Handle other errors as needed
    } finally {
      setLoading(false);
    }
  };

  //     setLogin(true);

  //     if (result.errors) {
  //       console.error("Error adding category:", result.errors);
  //     } else {
  //       console.log("Category added successfully:", result);
  //     }
  //   } catch (error) {
  //     console.error("Error while uploading images or saving to MongoDB", error);
  //   } finally {
  //   }
  // };

  const handleSignup = async (values) => {
    setLoading(true);
    const { type, ...restValues } = values;
    if (type === "owner") {
      await onFinishSignup(restValues);
    } else if (type === "customer") {
      await onFinishSignup1(restValues);
    }
    setLoading(false);
  };

  useEffect(() => {
    isSupported()
      .then((value) => {
        if (value) {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              console.log("Notification permission granted.");
            } else {
              alert(
                "Notification are not allowed for this site please check setting and allow notifications for this site"
              );
            }
          });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="main_login_div">
      {loading ? <Loader /> : ""}
      <ForgetPasswordModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <div className="login_background_div">
        <Chatt />
        <div
          className="login_content_div"
          data-aos="zoom-in"
          data-aos-duration="3000"
        >
          <div className="login_wlp_div"></div>
          <div className="login_form_div">
            <div className="login_form_content_div">
              <div className="login_form_content_upper_div">
                <div className="login_form_content_upper_logo_div ">
                  <div className="logo_div">
                    <img className="logo_img" src={logo} />
                  </div>
                </div>
                <div className="login_form_content_upper_lng_div">
                  <div className="change-locale">
                    <button
                      className={` ar ${lng == "ar" ? "hello-arabic" : ""}`}
                      onClick={() => {
                        dispatch(Language("ar"));
                        i18n.changeLanguage("ar");
                      }}
                    >
                      العربية
                    </button>
                    <button
                      className={` ar ${lng == "en" ? "hello-english" : ""}`}
                      onClick={() => {
                        dispatch(Language("en"));
                        i18n.changeLanguage("en");
                      }}
                    >
                      English
                    </button>
                  </div>
                </div>
              </div>
              <div className="login_form_content_from_div">
                <div className="form_div">
                  <div>
                    <h2 className="inp-emai">
                      {t("welcome")}

                      {/* {"Welcome to OAC EXPRESS"} */}
                    </h2>
                  </div>
                  {login ? (
                    <>
                      <Form
                        name="normal_login"
                        className="login-form"
                        onFinish={handleLogin}
                      >
                        {selectedUser === "driver" ? (
                          <Form.Item
                            name="idNo"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: t("errorsTxt.requiredField"),
                              },
                            ]}
                          >
                            <Input
                              className="inp-emai"
                              type="number"
                              prefix={
                                <MailOutlined className="site-form-item-icon" />
                              }
                              placeholder="Type Number"
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            name="email"
                            hasFeedback
                            rules={[
                              {
                                type: "email",
                                message: t("errorsTxt.invalidEmail"),
                              },
                              {
                                required: true,
                                message: t("errorsTxt.requiredField"),
                              },
                            ]}
                          >
                            <Input
                              className="inp-emai"
                              type="email"
                              prefix={
                                <MailOutlined className="site-form-item-icon" />
                              }
                              placeholder={t("placeholders.inputEmail")}
                            />
                          </Form.Item>
                        )}

                        <Form.Item
                          name="password"
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: t("errorsTxt.requiredField"),
                            },
                            {
                              min: 8,
                              message: t("errorsTxt.minimumLength"),
                            },
                          ]}
                        >
                          <Input.Password
                            prefix={
                              <LockOutlined className="site-form-item-icon" />
                            }
                            className="inp-emai"
                            type="password"
                            placeholder={t("placeholders.inputPass")}
                            minLength={8}
                          />
                        </Form.Item>
                        <Form.Item>
                          <div className="forget_div">
                            <a className="login-form-forgot">
                              <b
                                className="own-div radio-btn"
                                onClick={showModal}
                              >
                                {t("account.forget_password?")}
                              </b>
                            </a>
                          </div>
                        </Form.Item>

                        <Form.Item>
                          <Button
                            // type="primary"
                            htmlType="submit"
                            className="login-form-button submit_btn inp-emai"
                          >
                            <b>{t("buttonsTxt.login")}</b>
                          </Button>
                        </Form.Item>

                        <div className="own-div">
                          <Divider orientation="center">
                            {t("account.dont_have_account?")}{" "}
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                setLogin(!login);
                              }}
                            >
                              {t("account.register_now")}
                            </a>
                          </Divider>
                        </div>
                      </Form>

                      <div style={{ margin: "1rem 0" }}>
                        <Radio.Group
                          onChange={(e) => setSelectedUser(e.target.value)}
                          defaultValue="driver"
                        >
                          <Radio value={"owners"}>{t("Owner")}</Radio>
                          <Radio value={"customers"}>{t("Customer")}</Radio>
                          <Radio value={"driver"}>{t("Driver")}</Radio>
                        </Radio.Group>
                      </div>
                    </>
                  ) : (
                    <>
                      <Form
                        // ref={signupFormRef}
                        name="signup"
                        className="signup-form"
                        onFinish={handleSignup}
                      >
                        <Row gutter={24}>
                          <Col span={12}>
                            {type === "owner" ? (
                              <Form.Item
                                name="name"
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: t("errorsTxt.requiredField"),
                                  },
                                ]}
                              >
                                <Input
                                  className="inp-emai"
                                  type="text"
                                  prefix={
                                    <UserOutlined className="site-form-item-icon" />
                                  }
                                  placeholder={t("placeholders.inputName")}
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                name="name"
                                hasFeedback
                                rules={[
                                  {
                                    required: true,
                                    message: t("errorsTxt.requiredField"),
                                  },
                                ]}
                              >
                                <Input
                                  type="text"
                                  className="inp-emai"
                                  prefix={
                                    <UserOutlined className="site-form-item-icon" />
                                  }
                                  placeholder={t("placeholders.inputName")}
                                />
                              </Form.Item>
                            )}
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="email"
                              hasFeedback
                              rules={[
                                {
                                  type: "email",
                                  message: t("errorsTxt.invalidEmail"),
                                },
                                {
                                  required: true,
                                  message: t("errorsTxt.requiredField"),
                                },
                              ]}
                            >
                              <Input
                                type="email"
                                className="sign-ema"
                                prefix={
                                  <MailOutlined className="site-form-item-icon" />
                                }
                                placeholder={t("placeholders.inputEmail")}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={24}>
                            <Form.Item
                              name="phoneNumber"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: t("errorsTxt.requiredField"),
                                },
                              ]}
                            >
                              <Input
                                className="inp-emai"
                                prefix={
                                  <BsTelephone className="site-form-item-icon" />
                                }
                                type="tel"
                                placeholder={t("placeholders.inputPhoneNumber")}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={24}>
                          <Col span={12}>
                            <Form.Item
                              name="password"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: t("errorsTxt.requiredField"),
                                },
                                {
                                  min: 8,
                                  message: t("errorsTxt.minimumLength"),
                                },
                                {
                                  pattern: new RegExp(
                                    "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
                                  ),
                                  message: t(
                                    "Password must contain one lowercase,uppercase letter,number&special character"
                                  ),
                                },
                              ]}
                            >
                              <Input.Password
                                className="inp-emai"
                                prefix={
                                  <LockOutlined className="site-form-item-icon" />
                                }
                                type="password"
                                placeholder={t("placeholders.inputPass")}
                                minLength={8}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12}>
                            <Form.Item
                              className="sign-ema"
                              name="confirmPassword"
                              hasFeedback
                              rules={[
                                {
                                  required: true,
                                  message: t("errorsTxt.requiredField"),
                                },
                                {
                                  min: 8,
                                  message: t("errorsTxt.minimumLength"),
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      !value ||
                                      getFieldValue("password") === value
                                    ) {
                                      return Promise.resolve();
                                    }

                                    return Promise.reject(
                                      new Error(t("errorsTxt.passwordMatch"))
                                    );
                                  },
                                }),
                              ]}
                            >
                              <Input.Password
                                prefix={
                                  <LockOutlined className="site-form-item-icon" />
                                }
                                type="password"
                                placeholder={t("placeholders.ConfirmPass")}
                                minLength={8}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={24}>
                          <Col span={12}>
                            <Row>
                              <Col span={12}>
                                <Form.Item
                                  className="inp-emai"
                                  name="idNumber"
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      message: t("errorsTxt.requiredField"),
                                    },
                                  ]}
                                >
                                  <Input
                                    // prefix={
                                    //   <PhoneOutlined className="site-form-item-icon" />
                                    // }
                                    type="text"
                                    placeholder={t("placeholders.idNumber")}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                {/* <Form.Item
                                  name="idNumberUpload"
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      message: t("errorsTxt.requiredField"),
                                    },
                                  ]}
                                >
                                  <Upload
                                    listType="picture"
                                    multiple={false}
                                    maxCount={1}
                                    // accept="image/*"
                                    onChange={(e) => {
                                      if (e.file.status === "removed") {
                                        formData.crNumberUpload = "";
                                        setFormData({
                                          ...formData,
                                        });
                                        form.setFieldsValue({
                                          crNumberUpload: "",
                                        });
                                      } else {
                                        formData.crNumberUpload =
                                          e.file.originFileObj;
                                        setFormData({
                                          ...formData,
                                        });
                                        form.setFieldsValue({
                                          idNumbecrNumberUploadrUpload:
                                            e.file.originFileObj,
                                        });
                                      }
                                    }}
                                  >
                                    <Button
                                      icon={<UploadOutlined />}
                                      className="sign-ema"
                                    >
                                      {t("placeholders.upload")}
                                    </Button>
                                  </Upload>
                                </Form.Item> */}
                                {/* <input
                                  type="file"
                                  onChange={(event) => {
                                    setImageUpload(event.target.files[0]);
                                  }}
                                />
                                <button onClick={uploadImage}>upload</button> */}
                                {/* customer side */}
                                <Form.Item
                                  name="idNumberUpload"
                                  hasFeedback
                                  type="file"
                                  valuePropName="fileList"
                                  getValueFromEvent={(e) =>
                                    e.fileList.slice(10)
                                  }
                                >
                                  <Upload
                                    customRequest={customRequest}
                                    onChange={handleChange1}
                                    fileList={
                                      idNumberImageUrl
                                        ? [
                                            {
                                              uid: "-1",
                                              name: "image.png",
                                              status: "done",
                                              url: idNumberImageUrl,
                                            },
                                          ]
                                        : []
                                    }
                                    showUploadList={{
                                      showPreviewIcon: false,
                                      showRemoveIcon: true,
                                      removeIcon: (
                                        <DeleteOutlined
                                          onClick={handleImageDelete}
                                        />
                                      ),
                                    }}
                                  >
                                    <Button
                                      icon={<UploadOutlined />}
                                      loading={uploading}
                                    >
                                      Upload
                                    </Button>
                                  </Upload>
                                  {idNumberImageUrl && (
                                    <div style={{ marginTop: "10px" }}>
                                      <Image
                                        src={idNumberImageUrl}
                                        width={50}
                                      />
                                    </div>
                                  )}
                                  {/* <Upload
                                    customRequest={({ file }) => {
                                      return new Promise((resolve, reject) => {
                                        handleImageUpload1(
                                          file,
                                          setIdNumberImageUrl
                                        )
                                          .then(() => {
                                            console.log("Upload successful");
                                            resolve();
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Error during customRequest:",
                                              error
                                            );
                                            reject(error);
                                          });
                                      });
                                    }}
                                  >
                                    <Button
                                      icon={<UploadOutlined />}
                                      className="sign-ema"
                                    >
                                      Upload
                                    </Button>
                                  </Upload> */}
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>

                          <Col span={12}>
                            <Row>
                              <Col span={12}>
                                <Form.Item
                                  className="sign-ema"
                                  name="crNumber"
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      message: t("errorsTxt.requiredField"),
                                    },
                                  ]}
                                >
                                  <Input
                                    type="text"
                                    placeholder={t("placeholders.carNumber")}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                {/* <Form.Item
                                  name="crNumberUpload"
                                  hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      message: t("errorsTxt.requiredField"),
                                    },
                                  ]}
                                >
                                  <Upload
                                    listType="picture"
                                    multiple={false}
                                    maxCount={1}
                                    // accept="image/*"
                                    onChange={(e) => {
                                      if (e.file.status === "removed") {
                                        formData.crNumberUpload = "";
                                        setFormData({
                                          ...formData,
                                        });
                                        form.setFieldsValue({
                                          crNumberUpload: "",
                                        });
                                      } else {
                                        formData.crNumberUpload =
                                          e.file.originFileObj;
                                        setFormData({
                                          ...formData,
                                        });
                                        form.setFieldsValue({
                                          idNumbecrNumberUploadrUpload:
                                            e.file.originFileObj,
                                        });
                                      }
                                    }}
                                  >
                                    <Button
                                      icon={<UploadOutlined />}
                                      className="sign-ema"
                                    >
                                      {t("placeholders.upload")}
                                    </Button>
                                  </Upload>
                                </Form.Item> */}
                                <Form.Item
                                  name="crNumberUpload"
                                  hasFeedback
                                  type="file"
                                  valuePropName="fileList"
                                  getValueFromEvent={(e) =>
                                    e.fileList.slice(10)
                                  }
                                >
                                  <Upload
                                    customRequest={customRequestCr}
                                    onChange={handleChangeCr}
                                    fileList={
                                      crNumberImageUrl
                                        ? [
                                            {
                                              uid: "-1",
                                              name: "image.png",
                                              status: "done",
                                              url: crNumberImageUrl,
                                            },
                                          ]
                                        : []
                                    }
                                    showUploadList={{
                                      showPreviewIcon: false,
                                      showRemoveIcon: true,
                                      removeIcon: (
                                        <DeleteOutlined
                                          onClick={handleImageDeleteCr}
                                        />
                                      ),
                                    }}
                                  >
                                    <Button
                                      icon={<UploadOutlined />}
                                      loading={cruploading}
                                    >
                                      Upload
                                    </Button>
                                  </Upload>
                                  {crNumberImageUrl && (
                                    <div style={{ marginTop: "10px" }}>
                                      <Image
                                        src={crNumberImageUrl}
                                        width={50}
                                      />
                                    </div>
                                  )}

                                  {/* <Upload
                                    customRequest={({ file }) => {
                                      return new Promise((resolve, reject) => {
                                        handleImageUpload2(
                                          file,
                                          setCrNumberImageUrl
                                        )
                                          .then(() => {
                                            console.log("Upload successful");
                                            resolve();
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Error during customRequest:",
                                              error
                                            );
                                            reject(error);
                                          });
                                      });
                                    }}
                                  >
                                    <Button
                                      icon={<UploadOutlined />}
                                      className="sign-ema"
                                    >
                                      Upload
                                    </Button>
                                  </Upload> */}
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Form.Item
                          style={{ border: "none" }}
                          className="own-div"
                          name={"type"}
                          rules={[
                            {
                              required: true,
                              message: t("errorsTxt.requiredField"),
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={(e) => setType(e.target.value)}
                            value={type}
                          >
                            <Radio value={"owner"}>{t("Owner")}</Radio>
                            <Radio value={"customer"}>{t("Customer")}</Radio>
                          </Radio.Group>
                        </Form.Item>

                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button submit_btn inp-emai"
                          >
                            <b>
                              <b>{t("buttonsTxt.register")}</b>
                            </b>
                          </Button>
                        </Form.Item>

                        <div className="own-div">
                          <Divider orientation="center">
                            {t("account.already_have_account?")}
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                setLogin(!login);
                              }}
                            >
                              {t("buttonsTxt.login")}
                            </a>
                          </Divider>
                        </div>
                      </Form>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
