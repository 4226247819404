import { getDoc, doc, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { useQuery } from "@apollo/client";
import { single_Customers } from "../../graphql/Mutation/customer/getSingleCustomer";

export default function useUser() {
  const [user, setUser] = useState({});
  let type = localStorage.getItem("userType");
  let uid = JSON.parse(localStorage.getItem("id")) || "";
  const getUser = async () => {
    let userRef = doc(db, type, uid);
    const unsub = onSnapshot(userRef, (u) => {
      if (u.exists()) {
        setUser(u.data());
      }
    });
    return () => unsub();
  };

  useEffect(() => {
    getUser();
  }, [uid]);

  const getSingleCustomer = useQuery(single_Customers, {
    variables: {
      id: uid,
    },
  });

  return { user, getSingleCustomer };
}
