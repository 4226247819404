import { gql } from "@apollo/client";

const OWNER_BANK_INFO_UPDATE = gql`
mutation UpdateOwner($id: ID!, $updateOwnerInput: UpdateOwnerInput) {
    updateOwner(ID: $id, updateOwnerInput: $updateOwnerInput) {
      success
      message
      code
      data {
        type
        token
        rRA
        profileImage
        phoneNumber
        ownerName
        name
        isDeleted
        isApproved
        isAccept
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        bankInfo {
          iBanNumber
          beneficiaryName
          bankIdCode
        }
        _id
      }
    }
  }
`;





export {  OWNER_BANK_INFO_UPDATE };