import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  // Switch,
  Input,
  // Radio,
  // Checkbox,
  Form,
  Upload,
  Image,
  Row,
  Col,
  // DatePicker,
  message,
} from "antd";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  AddDriver,
  // uploadImage
} from "../API/API";
// import SimpleLoader from "../Loaders/simpleLoader";
import Loader from "../Loaders/Loader";
import useDrivers from "../Hooks/useDrivers";
// import { query, collection, where, getDocs } from "firebase/firestore";
// import { db } from "../firebase";
import { useTranslation } from "react-i18next";
import PlacesAutocomplete from // geocodeByAddress,
// getLatLng,
"react-places-autocomplete";
import { CREATE_DRIVER } from "../../graphql/Mutation/owner/addDriverMutation";
import { useMutation } from "@apollo/client";

export default function AddDriverModal({ isModalVisible, setIsModalVisible }) {
  const { drivers } = useDrivers();
  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState("");
  const [createDriver] = useMutation(CREATE_DRIVER);
  // const handleChange = (address) => {
  //   setAddress(address);
  // };

  // const handleSelect = (address) => {
  //   geocodeByAddress(address)
  //     .then((results) => getLatLng(results[0]))
  //     .then((latLng) => console.log("Success", latLng))
  //     .catch((error) => console.error("Error", error));
  // };
  const handleChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleSelect = (newAddress) => {
    setAddress(newAddress);
  };

  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    driverId: "",
    driverLicense: "",
    portId: "",
  });
  const handleOk = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCancel = () => {
    setIsModalVisible(!isModalVisible);
  };

  // const onFinish1 = async (values) => {
  //   let q = query(
  //     collection(db, "drivers"),
  //     where("", "==", values.driverIdNumber)
  //   );
  //   let snap = await getDocs(q);
  //   if (snap.empty) {
  //     setLoading(true);
  //     if (formData.driverId !== "") {
  //       await uploadImage("Drivers/", formData.driverId).then((res) => {
  //         formData.driverId = res;
  //         setFormData({ ...formData });
  //       });
  //     }
  //     if (formData.driverLicense !== "") {
  //       await uploadImage("Drivers/", formData.driverLicense).then((res) => {
  //         formData.driverLicense = res;
  //         setFormData({ ...formData });
  //       });
  //     }
  //     if (formData.portId !== "") {
  //       await uploadImage("Drivers/", formData.portId).then((res) => {
  //         formData.portId = res;
  //         setFormData({ ...formData });
  //       });
  //     }
  //     let data = {
  //       ...values,
  //       driverIdUpload: formData.driverId,
  //       driverLicenseUpload: formData.driverLicense,
  //       portIdUpload: formData.portId,
  //       isTruck: false,
  //       isDeleted: true,
  //     };

  //     await AddDriver(data)
  //       .then(() => {
  //         setLoading(false);
  //         form.resetFields();
  //         handleCancel();
  //         message.success("Add Successull!");
  //       })
  //       .catch((e) => {
  //         setLoading(false);
  //         message.error(JSON.stringify(e));
  //       });
  //   } else {
  //     alert("this id number already exist please change it");
  //   }
  // };
  const [driverIdUpload, setDriverIdUpload] = useState(null);
  const [uploading, setUploading] = useState(false);

  const handleImageUpload1 = (file) => {
    if (!file) return;

    const imageRef = ref(storage, `AddDreiver/${file.name}`);
    setUploading(true);

    uploadBytes(imageRef, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((url) => {
        setDriverIdUpload(url);
        console.log("Upload successful. URL:", url);
      })
      .catch((error) => {
        console.error("Error during image upload:", error);
      })
      .finally(() => {
        setUploading(false);
      });
  };
  const handleImageDelete = () => {
    setDriverIdUpload(null);
  };
  const customRequest = ({ file }) => {
    handleImageUpload1(file);
  };

  const handleChange1 = ({ fileList }) => {
    console.log(fileList);
  };
  const [portIdUpload, setPortIdUpload] = useState(null);
  const [portuploading, setportUploading] = useState(false);
  const handleImageUpload2 = (file) => {
    if (!file) return;

    const imageRef = ref(storage, `AddDreiver/${file.name}`);
    setportUploading(true);

    uploadBytes(imageRef, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((url) => {
        setPortIdUpload(url);
        console.log("Upload successful. URL:", url);
      })
      .catch((error) => {
        console.error("Error during image upload:", error);
      })
      .finally(() => {
        setportUploading(false);
      });
  };
  const handleImageDelete2 = () => {
    setPortIdUpload(null);
  };
  const customRequest2 = ({ file }) => {
    handleImageUpload2(file);
  };

  const handleChange2 = ({ fileList }) => {
    console.log(fileList);
  };
  const [driverLicenseUpload, setDriverLicenseUpload] = useState(null);
  const [cruploading, setcrUploading] = useState(false);
  const handleImageUpload3 = (file) => {
    if (!file) return;

    const imageRef = ref(storage, `AddDreiver/${file.name}`);
    setcrUploading(true);

    uploadBytes(imageRef, file)
      .then((snapshot) => getDownloadURL(snapshot.ref))
      .then((url) => {
        setDriverLicenseUpload(url);
        console.log("Upload successful. URL:", url);
      })
      .catch((error) => {
        console.error("Error during image upload:", error);
      })
      .finally(() => {
        setcrUploading(false);
      });
  };
  const handleImageDelete3 = () => {
    setDriverLicenseUpload(null);
  };
  const customRequest3 = ({ file }) => {
    handleImageUpload3(file);
  };

  const handleChange3 = ({ fileList }) => {
    console.log(fileList);
  };
  // Function to handle image upload
  // const handleImageUpload1 = (image, setImageUrl) => {
  //   if (!image) return Promise.reject("Image is undefined");
  //   const imageRef = ref(storage, `AddDreiver/${image.name}`);
  //   return uploadBytes(imageRef, image)
  //     .then((snapshot) => {
  //       return getDownloadURL(snapshot.ref);
  //     })
  //     .then((url) => {
  //       setDriverIdUpload(url);
  //       console.log(url);
  //       return url;
  //     })
  //     .catch((error) => {
  //       console.error("Error while uploading or getting the image URL", error);
  //       throw error;
  //     });
  // };
  // const handleImageUpload2 = (image, setImageUrl) => {
  //   if (!image) return Promise.reject("Image is undefined");
  //   const imageRef = ref(storage, `AddDreiver/${image.name}`);
  //   return uploadBytes(imageRef, image)
  //     .then((snapshot) => {
  //       return getDownloadURL(snapshot.ref);
  //     })
  //     .then((url) => {
  //       setPortIdUpload(url);
  //       console.log(url);
  //       return url;
  //     })
  //     .catch((error) => {
  //       console.error("Error while uploading or getting the image URL", error);
  //       throw error;
  //     });
  // };
  // const handleImageUpload3 = (image, setImageUrl) => {
  //   if (!image) return Promise.reject("Image is undefined");
  //   const imageRef = ref(storage, `AddDreiver/${image.name}`);
  //   return uploadBytes(imageRef, image)
  //     .then((snapshot) => {
  //       return getDownloadURL(snapshot.ref);
  //     })
  //     .then((url) => {
  //       setDriverLicenseUpload(url);
  //       console.log(url);
  //       return url;
  //     })
  //     .catch((error) => {
  //       console.error("Error while uploading or getting the image URL", error);
  //       throw error;
  //     });
  // };

  const onFinish = async (values) => {
    console.log("values", values);
    try {
      setLoading(true);
      const ownerId = JSON.parse(localStorage.getItem("id"));
      const { data } = await createDriver({
        variables: {
          registerDriverInput: {
            status: "created",
            password: values.password,
            owner: ownerId,
            nationality: values.nationality,
            address: values.address,
            name: values.name,
            imageUrl: "",
            idNo: values.driverIdNumber,
            age: values.age,
            driverId: driverIdUpload,
            idUpload: portIdUpload,
            lincense: driverLicenseUpload,
          },
        },
      });
      const driverData = {
        ...values,
        driverIdUpload: formData.driverId,
        driverLicenseUpload: formData.driverLicense,
        portIdUpload: formData.portId,
        isTruck: false,
        isDeleted: true,
      };

      await AddDriver(driverData)
        .then(() => {
          setLoading(false);
          form.resetFields();
          setIsModalVisible(false);
          handleCancel();
          message.success("Add Successful!");
        })
        .catch((e) => {
          setLoading(false);
          message.error(JSON.stringify(e));
        });
    } catch (e) {
      setLoading(false);
      message.error("An error occurred while processing your request.");
    }
  };

  useEffect(() => {
    form.setFieldsValue({ driverAddress: address });
  }, [address]);

  return (
    <div>
      <Modal
        title={t("Modal.addNewDriver")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={null}
      >
        {loading ? <Loader /> : ""}
        <div>
          <Form
            form={form}
            name="add_driver_form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="name"
                    // label="Name"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("placeholders.name")}
                      className="inp-emai"
                      style={{ border: "2px solid #2d808b" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    name="age"
                    // label="Age"

                    className="sign-ema"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      style={{ border: "2px solid #2d808b" }}
                      type={"number"}
                      placeholder={t("placeholders.age")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    className="inp-emai"
                    name="nationality"
                    // label="Nationality"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                    ]}
                  >
                    <Input
                      style={{ border: "2px solid #2d808b" }}
                      placeholder={t("placeholders.nationality")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    className="sign-ema"
                    name="address"
                    rules={[
                      {
                        required: true,
                        message: "Required Field!",
                      },
                    ]}
                  >
                    <PlacesAutocomplete
                      value={address}
                      name="address"
                      onChange={handleChange}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <Input
                            value={address}
                            name="address"
                            style={{ border: "2px solid #2d808b" }}
                            {...getInputProps({
                              placeholder: "Address",
                              className: "location-search-input",
                            })}
                            // autoComplete="off" // Add this line
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <Input.Group>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    className="inp-emai"
                    hasFeedback
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Requerd Field!",
                      },
                      {
                        min: 8,
                        message: "Minimun length of 8 characters",
                      },
                    ]}
                  >
                    <Input.Password
                      style={{ border: "2px solid #2d808b" }}
                      type={"password"}
                      placeholder={t("placeholders.password")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    hasFeedback
                    className="sign-ema"
                    name="driverIdNumber" // Make sure this name matches your form field
                    rules={[
                      {
                        required: true,
                        message: "Required Field!",
                      },
                      {
                        pattern: /^(?:\d*)$/,
                        message: "Value should contain just numbers",
                      },
                      {
                        max: 10,
                        message: "Value should be less than 10 characters",
                      },
                    ]}
                  >
                    <Input
                      style={{ border: "2px solid #2d808b" }}
                      placeholder={t("placeholders.idNumber")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            {/* <div className="form_row_div"> */}
            <Row gutter={24}>
              <Col span={12}>
                {/* <Form.Item
                  hasFeedback
                  style={{ border: "2px solid #2d808b" }}
                  className="inp-emai"
                  name="driverIdUpload"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <div>
                    <Upload
                      listType="picture"
                      multiple={false}
                      maxCount={1}
                      onChange={(e) => {
                        if (e.file.status === "removed") {
                          formData.driverId = "";
                          setFormData({
                            ...formData,
                          });
                        } else {
                          formData.driverId = e.file.originFileObj;
                          setFormData({
                            ...formData,
                          });
                        }
                      }}
                    >
                      <Button icon={<UploadOutlined />}>
                        <div className="btn-uplo">
                          {t("placeholders.uploadDriverId")}
                        </div>
                      </Button>
                    </Upload>
                  </div>
                </Form.Item> */}
                <Form.Item
                  name="driverIdUpload"
                  hasFeedback
                  type="file"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList.slice(10)}
                >
                  <Upload 
                    customRequest={customRequest}
                    onChange={handleChange1}
                    fileList={
                      driverIdUpload
                        ? [
                            {
                              uid: "-1",
                              name: "image.png",
                              status: "done",
                              url: driverIdUpload,
                            },
                          ]
                        : []
                    }
                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: true,
                      removeIcon: (
                        <DeleteOutlined onClick={handleImageDelete} />
                      ),
                    }}
                  >
                    <Button icon={<UploadOutlined />} loading={uploading}>
                      Upload ID
                    </Button>
                  </Upload>
                  {driverIdUpload && (
                    <div style={{ marginTop: "10px" }}>
                      <Image src={driverIdUpload} width={50} />
                    </div>
                  )}
                  {/* <Upload
                                    customRequest={({ file }) => {
                                      return new Promise((resolve, reject) => {
                                        handleImageUpload1(
                                          file,
                                         setDriverIdUpload
                                        )
                                          .then(() => {
                                            console.log("Upload successful");
                                            resolve();
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Error during customRequest:",
                                              error
                                            );
                                            reject(error);
                                          });
                                      });
                                    }}
                                  >
                                    <Button icon={<UploadOutlined />}>
                        <div className="btn-uplo">
                          {t("placeholders.uploadDriverId")}
                        </div>
                      </Button>
                                  </Upload> */}
                </Form.Item>
              </Col>
              <Col span={12}>
                {/* <Form.Item
                  style={{ border: "2px solid #2d808b" }}
                  className="sign-ema"
                  hasFeedback
                  name="driverLicenseUpload"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <Upload
                    className="signa"
                    listType="picture"
                    multiple={false}
                    maxCount={1}
                    onChange={(e) => {
                      if (e.file.status === "removed") {
                        formData.driverLicense = "";
                        setFormData({
                          ...formData,
                        });
                      } else {
                        formData.driverLicense = e.file.originFileObj;
                        setFormData({
                          ...formData,
                        });
                      }
                    }}
                  >
                    <Button icon={<UploadOutlined />}>
                      <div className="btn-uplo">
                        {t("placeholders.uploadLiense")}
                      </div>
                    </Button>
                  </Upload>
                </Form.Item> */}
                <Form.Item
                  name="driverLicenseUpload"
                  hasFeedback
                  type="file"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList.slice(10)}
                >
                  <Upload
                    customRequest={customRequest2}
                    onChange={handleChange2}
                    fileList={
                      portIdUpload
                        ? [
                            {
                              uid: "-1",
                              name: "image.png",
                              status: "done",
                              url: portIdUpload,
                            },
                          ]
                        : []
                    }
                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: true,
                      removeIcon: (
                        <DeleteOutlined onClick={handleImageDelete2} />
                      ),
                    }}
                  >
                    <Button icon={<UploadOutlined />} loading={portuploading}>
                      Upload Lisence ID
                    </Button>
                  </Upload>
                  {portIdUpload && (
                    <div style={{ marginTop: "10px" }}>
                      <Image src={portIdUpload} width={50} />
                    </div>
                  )}
                  {/* <Upload
                                    customRequest={({ file }) => {
                                      return new Promise((resolve, reject) => {
                                        handleImageUpload2(
                                          file,
                                          setDriverLicenseUpload
                                        )
                                          .then(() => {
                                            console.log("Upload successful");
                                            resolve();
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Error during customRequest:",
                                              error
                                            );
                                            reject(error);
                                          });
                                      });
                                    }}
                                  >
                                    <Button icon={<UploadOutlined />}>
                      <div className="btn-uplo">
                        {t("placeholders.uploadLiense")}
                      </div>
                    </Button>
                                  </Upload> */}
                </Form.Item>
              </Col>
            </Row>
            {/* </div> */}
            {/* <div className="form_row_div"> */}
            <Row gutter={24}>
              <Col span={12}>
                {/* <Form.Item
                  className="inp-emai"
                  hasFeedback
                  name="portIdUpload"
                  rules={[
                    {
                      required: true,
                      message: "Requerd Field!",
                    },
                  ]}
                >
                  <Upload
                    listType="picture"
                    multiple={false}
                    maxCount={1}
                    onChange={(e) => {
                      if (e.file.status === "removed") {
                        formData.portId = "";
                        setFormData({
                          ...formData,
                        });
                      } else {
                        formData.portId = e.file.originFileObj;
                        setFormData({
                          ...formData,
                        });
                      }
                    }}
                  >
                    <Button
                      style={{ border: "2px solid #2d808b" }}
                      icon={<UploadOutlined />}
                    >
                      <div className="btn-uplo">
                        {t("placeholders.uploadPortId")}
                      </div>
                    </Button>
                  </Upload>
                </Form.Item> */}
                <Form.Item
                  name="portIdUpload"
                  hasFeedback
                  type="file"
                  valuePropName="fileList"
                  getValueFromEvent={(e) => e.fileList.slice(10)}
                >
                  <Upload
                    customRequest={customRequest3}
                    onChange={handleChange3}
                    fileList={
                      driverLicenseUpload
                        ? [
                            {
                              uid: "-1",
                              name: "image.png",
                              status: "done",
                              url: driverLicenseUpload,
                            },
                          ]
                        : []
                    }
                    showUploadList={{
                      showPreviewIcon: false,
                      showRemoveIcon: true,
                      removeIcon: (
                        <DeleteOutlined onClick={handleImageDelete3} />
                      ),
                    }}
                  >
                    <Button icon={<UploadOutlined />} loading={cruploading}>
                      Upload Port ID
                    </Button>
                  </Upload>
                  {driverLicenseUpload && (
                    <div style={{ marginTop: "10px" }}>
                      <Image src={driverLicenseUpload} width={50} />
                    </div>
                  )}
                  {/* <Upload
                                    customRequest={({ file }) => {
                                      return new Promise((resolve, reject) => {
                                        handleImageUpload3(
                                          file,
                                          setPortIdUpload
                                        )
                                          .then(() => {
                                            console.log("Upload successful");
                                            resolve();
                                          })
                                          .catch((error) => {
                                            console.error(
                                              "Error during customRequest:",
                                              error
                                            );
                                            reject(error);
                                          });
                                      });
                                    }}
                                  >
                                    <Button
                      style={{ border: "2px solid #2d808b" }}
                      icon={<UploadOutlined />}
                    >
                      <div className="btn-uplo">
                        {t("placeholders.uploadPortId")}
                      </div>
                    </Button>
                                  </Upload> */}
                </Form.Item>
              </Col>
            </Row>
            {/* </div> */}

            <Button
              style={{ border: "2px solid #2d808b" }}
              htmlType="submit"
              className="login-form-button lang-btn"
            >
              {t("Modal.add")}
            </Button>
          </Form>
        </div>
      </Modal>
    </div>
  );
}
