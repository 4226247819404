import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { db } from "../firebase";
export default function useSource() {
  const [sourcce, setSourcce] = useState([]);
  const getData = async () => {
    let q = query(
      collection(db, "services", "data", "source")
      
    );
    const unsub = onSnapshot(q, (querySnapShot) => {
      setSourcce([]);
      if (!querySnapShot.empty) {
        querySnapShot.forEach((val) => {
          setSourcce((prev) => [...prev, val.data()]);
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
      getData();

  }, []);

  return { sourcce };
}


