import { Pagination, Table } from "antd";
import React from "react";
import "./history.css";
import { t } from "i18next";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useCustomerRequests from "../../Hooks/useCustomerRequests";


const columns = [
  // {
  //   title: "RequestId",
  //   dataIndex: "requestId",
  //   key: "requestId",
  // },
  {
    // title: t("tableTabs.id"),
    title: "Id",
    render: (record) => (
      <span title={record?._id}>{record?._id.slice(-4)}</span>
    ),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "From",
    dataIndex: "from",
    key: "From",
  },
  {
    title: "To",
    dataIndex: "to",
    key: "to",
  },
  {
    title: "Distance",
    dataIndex: "distance",
    key: "distance",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Capacity",
    dataIndex: "serviceType",
    key: "capacity",
  },
  {
    title: "Created",
    render: (record) => (
      <span>
        {new Date(record?.createdAt).toLocaleString("en-US", {
          month: "short",
          day: "2-digit",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
      </span>
    ),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
];
const History = () => {
  const { t } = useTranslation();
  const driverID = JSON.parse(localStorage.getItem("id"));
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest, count } = useCustomerRequests(
    updateTrigger,
    paginationFilters
  );

  // Filter requests associated with the logged-in driver
  const driverRequests = allRequest.filter(
    (request) => request.acceptedBy?.driverId === driverID
  );

  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    setUpdateTrigger(!updateTrigger);
  };

  useEffect(() => {
    console.log("Driver Data:", driverRequests);
  }, [driverRequests]);


  return (
    <div className="history">
      <div className="inp-emai">
        <h3>{t("driver.history")}</h3>
      </div>
      <div className="table_search_div"></div>
      <div className="history-table">
        <Table columns={columns}
         dataSource={driverRequests}
         pagination={false} />
        <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
      </div>
    </div>
  );
};

export default History;
