import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SeeFreightNewRequestTable from "../../Tables/SeeFreightNewRequestTable";
import SeaFreightUnderProcessRequest from "../../Tables/SeaFreightUnderProcessRequest";
import SeaFreightFinalizingTable from "../../Tables/SeaFreightFinalizingTable";
import SeaFreightFinanceTable from "../../Tables/SeaFreightFinanceTable";
import SeaFreightCompleteTable from "../../Tables/SeaFreightCompleteTable";
import { Badge, Tabs } from "antd";
import useSeaFreightNewRequst from "../../Hooks/useSeaFreightNewRequst";
import useCustomerRequests from "../../Hooks/useCustomerRequests";

const SeeFreight = () => {
  const { t } = useTranslation();
  const { requestData } = useSeaFreightNewRequst();
  // const{ allRequest } = useCustomerRequests
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest, count } = useCustomerRequests(
    updateTrigger,
    paginationFilters
  );
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  const { TabPane } = Tabs;

  return (
    <>
      <Tabs>
        <TabPane
          tab={
            <Badge
              offset={[10, 0]}
              count={
                <span
                  style={{
                    backgroundColor: "green",
                    marginTop: "-7px",
                    marginRight: "10px",
                  }}
                  className="Span-reqest"
                >
                  {allRequest?.filter((el) => el.status === "created").length}
                </span>
              }
            >
              <span>{t("tableTabs.newRequests")}</span>
            </Badge>
          }
          key="1"
        >
          <SeeFreightNewRequestTable />
        </TabPane>
        <TabPane
          tab={
            <Badge
              offset={[10, 0]}
              count={
                <span
                  style={{
                    backgroundColor: "blue",
                    marginTop: "-7px",
                    marginRight: "10px",
                  }}
                  className="Span-reqest"
                >
                  {
                    allRequest?.filter((el) => el.status === "underprocess")
                      .length
                  }
                </span>
              }
            >
              <span>Under Process</span>
            </Badge>
          }
          key="2"
        >
          <SeaFreightUnderProcessRequest />
        </TabPane>
        <TabPane
          tab={
            <Badge
              offset={[10, 0]}
              count={
                <span
                  style={{
                    backgroundColor: "#001529",
                    marginTop: "-7px",
                    marginRight: "10px",
                  }}
                  className="Span-reqest"
                >
                  {
                    allRequest?.filter((el) => el.status === "finalizing")
                      .length
                  }
                </span>
              }
            >
              <span>Finalizing</span>
            </Badge>
          }
          key="3"
        >
          <SeaFreightFinalizingTable />
        </TabPane>
        <TabPane
          tab={
            <Badge
              offset={[10, 0]}
              count={
                <span
                  style={{
                    backgroundColor: "#d06d2c",
                    marginTop: "-7px",
                    marginRight: "10px",
                  }}
                  className="Span-reqest"
                >
                  {allRequest?.filter((el) => el.status === "finance").length}
                </span>
              }
            >
              <span>Finance</span>
            </Badge>
          }
          key="4"
        >
          <SeaFreightFinanceTable />
        </TabPane>
        <TabPane
          tab={
            <Badge
              offset={[10, 0]}
              count={
                <span
                  style={{
                    backgroundColor: "brown",
                    marginTop: "-7px",
                    marginRight: "10px",
                  }}
                  className="Span-reqest"
                >
                  {allRequest?.filter((el) => el.status === "complete").length}
                </span>
              }
            >
              <span>Complete</span>
            </Badge>
          }
          key="5"
        >
          {/* <SeaFreightCompleteTable /> */}
        </TabPane>
      </Tabs>
    </>
  );
};

export default SeeFreight;
