import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { db } from "../firebase";
export default function useExtraCare() {
  const [extracare, setExtracare] = useState([]);
  const getData = async () => {
    let q = query(
      collection(db, "services", "data", "extracharges")
      
    );
    const unsub = onSnapshot(q, (querySnapShot) => {
      setExtracare([]);
      if (!querySnapShot.empty) {
        querySnapShot.forEach((val) => {
          setExtracare((prev) => [...prev, val.data()]);
        });
      }
    });

    return () => unsub();
  };
  useEffect(() => {
    getData();
  }, []);

  return { extracare };
}
