import React, { useState } from "react";
import { Table, Space, Tag, Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";

// import useOwnerRequests from "../Hooks/useOwnerRequests";
// import { DeleteRequest, updateRequest } from "../API/API";
import useTransports from "../Hooks/useTransport";
import { useTranslation } from "react-i18next";
import useCustomerRequests from "../Hooks/useCustomerRequests";
export default function UnderProcess() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const { ownerRequests } = useOwnerRequests();
  const { transports } = useTransports();
  const [search, setSearch] = useState("");






  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest, count} = useCustomerRequests(updateTrigger, paginationFilters);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };

  const columns = [
    // {
    //   title: t("tableTabs.id"),
    //   render: (record) => (
    //     <span title={record?.requestId}>{record?.requestId}</span>
    //   ),
    // },
    {
      title: t("tableTabs.id"),
      render: (record, i, index) => (
        <span title={record?._id}>{record?._id.slice(-4)}</span>
      ),
    },
    // {
    //   title: t("tableTabs.createdAt"),
    //   render: (record) => (
    //     <span>
    //       {new Date(record?.createdAt?.seconds * 1000).toLocaleString("en-US", {
    //         month: "short",
    //         day: "2-digit",
    //         year: "2-digit",
    //         hour: "numeric",
    //         minute: "numeric",
    //         hour12: true,
    //       })}
    //     </span>
    //   ),
    // },
    {
      title: t("tableTabs.createdAt"),
      render: (record) => (
        <span>
          {new Date(record?.createdAt).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      ),
    },
    {
      title: t("tableTabs.name"),
      dataIndex: "name",
      key: "name",
    },

    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },

    {
      title: t("tableTabs.sended"),
      render: (record) => (
        <Tag
          key={record._id}
          color={
            record?.isAccept !== undefined && record?.isAccept
              ? "green"
              : "pink"
          }
        >
          {record?.isAccept !== undefined && record?.isAccept ? "Yes" : "No"}
        </Tag>
      ),
    },

    {
      title: t("tableTabs.approved"),
      render: (record) => (
        <Tag
          key={record.uid}
          color={
            record?.isStarted !== undefined && record?.isStarted
              ? "green"
              : "pink"
          }
        >
          {record?.isStarted !== undefined && record?.isStarted ? "Yes" : "No"}
        </Tag>
      ),
    },

    {
      title: t("tableTabs.action"),
      render: (record) => (
        <span
          className="eyes-btn"
          onClick={() => {
            let transport = transports?.find(
              (v) => v?._id === record.acceptedBy?.transportId
            );
            let data = {
              request: record,
              transport: transport ? transport : {},
            };
            navigate(`/owner/request_detail/${record?._id}`, {
              state: data,
            });
          }}
        >
          <EyeOutlined />
        </span>
      ),
    },
  ];
  //   {
  //     title: t("tableTabs.action"),
  //     dataIndex: "",
  //     key: "x",
  //     align: "center",
  //     render: (record) => (
  //       <Space>
  //         {record?.isAccept !== undefined &&
  //         record?.isAccept &&
  //         (record.isChangeTransport === undefined ||
  //           !record.isChangeTransport) ? (
  //           <span
  //             className="eyes-btn"
  //             onClick={() => {
  //               let transport = transports?.find(
  //                 (v) => v._id === record.acceptedBy?.transportId
  //               );

  //               let data = {
  //                 request: record,
  //                 transport: transport ? transport : {},
  //               };

  //               navigate(`/owner/request_detail/${record._id}`, {
  //                 state: data,
  //               });
  //             }}
  //           >
  //             <EyeOutlined />
  //           </span>
  //         ) : (
  //           <></>
  //         )}
  //       </Space>
  //     ),
  //   },
  // ];
  //   console.log(ownerRequests, "EYES");

  return (
    <div>
      <div className="table_search_div"></div>
      <Table
        columns={columns}
        pagination={false}
        dataSource={
          allRequest
          //  &&
          // allRequest.length > 0 &&
          // allRequest
          //   .sort((a, b) => b.createdAt - a.createdAt)
          //   .filter(
          //     // (val) => val.status == "underProcessing" && val.isAccept === true
          //     (val) => val.status == "underProcessing" && val.isAccept === true
          //   )
          //   .filter((val) => {
          //     if (search == "") {
          //       return val;
          //     } else if (
          //       val &&
          //       Object.keys(val).some((v) =>
          //         val[v]
          //           .toString()
          //           .toLowerCase()
          //           .includes(search.toString().toLowerCase())
          //       )
          //     ) {
          //       return val;
          //     }
          //   })
        }
      />
        <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </div>
  );
}
