// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "@firebase/auth";
import { getStorage } from "firebase/storage";
import {
  getMessaging,
  getToken,
  // isSupported,
  onMessage,
} from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // new app
  apiKey: "AIzaSyDVy7PgC1ETDS-C914uyDubLNxceaCM0Io",
  authDomain: "oac-express.firebaseapp.com",
  projectId: "oac-express",
  storageBucket: "oac-express.appspot.com",
  messagingSenderId: "1042393102814",
  appId: "1:1042393102814:web:c7de0451c2fca170a0054f",
  measurementId: "G-LLKKDPLZB3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const messaging = getMessaging(app);
const GetToken = (setTokenFound) => {
  // return getToken(messaging, {
  //   vapidKey:
  //     "BPs_gcN7B2sfYiaULT_AiSdvOhbMfJa2X_BJn_ZD4K3r06WF2oFQvfZGFP7PlVWG_svNYR2WHpiyB6p_BV4pUoU",
  // })
  //   .then((currentToken) => {
  //     if (currentToken) {
  //       setTokenFound(true);
  //       // Track the token -> client mapping, by sending to backend server
  //       // show on the UI that permission is secured
  //     } else {
  //       console.log(
  //         "No registration token available. Request permission to generate one."
  //       );
  //       setTokenFound(false);
  //       // shows on the UI that permission is required
  //     }
  //   })
  //   .catch((err) => {
  //     console.log("An error occurred while retrieving token. ", err);
  //     // catch error while creating client token
  //   });
};
const fcmToken = () => {
  return new Promise(async (resolve, reject) => {
    await getToken(messaging, {
      vapidKey:
        "BPs_gcN7B2sfYiaULT_AiSdvOhbMfJa2X_BJn_ZD4K3r06WF2oFQvfZGFP7PlVWG_svNYR2WHpiyB6p_BV4pUoU",
    })
      .then((currentToken) => {
        if (currentToken) {
          resolve(currentToken);
        }
      })
      .catch((e) => {
        console.log(e);
        // reject(e)
      });
  });
};
const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export { db, auth, storage, GetToken, onMessageListener, fcmToken };
