import { Popconfirm, Space, Table, message } from "antd";
import React from "react";
import { doc, deleteDoc } from "firebase/firestore";
import useGetOwnerAllMaintenanceRequest from "../Hooks/useGetOwnerAllMaintenanceRequest";
import { db } from "../firebase";
import { DeleteOutlined } from "@ant-design/icons";

const MaintenanceTable = () => {
  const { ownerMaintenance } = useGetOwnerAllMaintenanceRequest();
  const deleteHandler = async (record) => {
    try {
      await deleteDoc(doc(db, "maintenance-service_request", record.uid));
      message.success("deleted!");
    } catch (error) {
      alert(error.message);
    }
  };

  const columns = [
    {
      title: "Truck",
      dataIndex: "truck",
      key: "truck",
    },
    {
      title: "serviceRadius",
      render: (record) => {
        return (
          <>
            <span>{record.serviceradius + "km"}</span>
          </>
        );
      },
    },
    {
      title: "Fuel",
      render: (record) => {
        return (
          <>
            {record?.services.map((item, id) => (
              <span key={id}>{item.children + ", "}</span>
            ))}
          </>
        );
      },
    },
    {
      title: "Amount",
      render: (record) => {
        return (
          <>
            {record?.services.map((item, id) => (
              <span key={id}>{item.value + ", "}</span>
            ))}
          </>
        );
      },
    },
    {
      title: "Action",
      render: (record) => {
        return (
          <>
            <Space>
              <Popconfirm
                title={"Are you sure?"}
                okText="Ok"
                cancelText="Cancel"
                onConfirm={() => {
                  deleteHandler(record);
                }}
              >
                <span className="ant-btn ant-btn-danger">
                  <DeleteOutlined />
                </span>
              </Popconfirm>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table dataSource={ownerMaintenance} columns={columns} />
    </>
  );
};

export default MaintenanceTable;
