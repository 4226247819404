import { gql } from "@apollo/client";

// const CREATE_CUSTOMER_TRANSPORT_REQUEST = gql`
// mutation CreateRequest($customerRequestInput: CustomerRequestInput!) {
//   createRequest(customerRequestInput: $customerRequestInput) {
//     success
//     code
//     message
//     data {
//       type
//       to
//       status
//       serviceType
//       senderContact
//       sendTo
//       receiverContact
//       receipt
//       _id
//       acceptedBy {
//         transportId
//         ownerId
//         driverId
//       }
//       blNumber
//       blUpload
//       commission
//       coordinates {
//         longitude
//         latitude
//       }
//       quantity
//       price
//       phoneNumber
//       offlinePaid
//       odUpload
//       notificationId
//       name
//       location
//       isStarted
//       isSeen
//       isNewRequest
//       isFinished
//       isAccept
//       iroUpload
//       iroDate {
//         seconds
//         nanoseconds
//       }
//       invoice
//       height
//       from
//       finalByan
//       estimateTime
//       eir
//       driverStarted
//       distance
//       deliveryNote
//       customer {
//         userName
//         type
//         token
//         profileImage
//         proVerified
//         phoneNumber
//         name
//         isHold
//         isDeleted
//         isApproved
//         idNumberUpload
//         idNumber
//         emailVerified
//         email
//         createdAt
//         crNumberUpload
//         crNumber
//         _id
//       }
//     }
//   }
// }
// `;

const CREATE_CUSTOMER_TRANSPORT_REQUEST = gql`
  mutation CreateRequest($customerRequestInput: CustomerRequestInput!) {
    createRequest(customerRequestInput: $customerRequestInput) {
      success
      message
      data {
        type
        to
        status
        serviceType
        senderContact
        sendTo
        receiverContact
        receipt
        quantity
        price
        phoneNumber
        offlinePaid
        odUpload
        notificationId
        name
        location
        isStarted
        isSeen
        isNewRequest
        isFinished
        isAccept
        iroUpload
        iroDate {
          seconds
          nanoseconds
        }
        invoice
        height
        from
        finalByan
        estimateTime
        eir
        driverStarted
        distance
        deliveryNote
        customer {
          userName
          type
          token
          profileImage
          proVerified
          phoneNumber
          name
          isRelation
          isHold
          isDeleted
          isApproved
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          _id
        }
        createdAt
        coordinates {
          longitude
          latitude
        }
        commission
        blUpload
        blNumber
        acceptedBy {
          transportId
          ownerId
          driverId
        }
        _id
      }
      code
    }
  }
`;

const GET_MYCUSTOMER_TRANSPORT_REQUEST = gql`
  query MyRequests($customerId: ID!, $page: Int!) {
    myRequests(customerId: $customerId, page: $page) {
      totalPages
      totalDocs
      currentPage
      requests {
        type
        to
        status
        serviceType
        senderContact
        sendTo
        receiverContact
        receipt
        quantity
        price
        phoneNumber
        offlinePaid
        odUpload
        notificationId
        name
        location
        isStarted
        isSeen
        isNewRequest
        isFinished
        isAccept
        iroUpload
        iroDate {
          seconds
          nanoseconds
        }
        invoice
        height
        from
        finalByan
        estimateTime
        eir
        driverStarted
        distance
        deliveryNote
        customer {
          userName
          type
          token
          profileImage
          phoneNumber
          proVerified
          name
          isHold
          isDeleted
          isApproved
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          _id
        }
        createdAt
        coordinates {
          longitude
          latitude
        }
        commission
        blUpload
        blNumber
        acceptedBy {
          transportId
          ownerId
          driverId
        }
        _id
      }
    }
  }
`;

const GET_REQUESTWITHSTATUS = gql`
  query Query($status: String!) {
    getRequestsWithStatus(status: $status) {
      to
      type
      status
      serviceType
      senderContact
      sendTo
      receiverContact
      receipt
      quantity
      price
      phoneNumber
      offlinePaid
      odUpload
      notificationId
      name
      location
      isSeen
      isStarted
      isNewRequest
      isFinished
      isAccept
      iroUpload
      iroDate {
        seconds
        nanoseconds
      }
      invoice
      height
      from
      finalByan
      estimateTime
      eir
      driverStarted
      distance
      deliveryNote
      customer {
        userName
        type
        token
        profileImage
        proVerified
        phoneNumber
        name
        isHold
        isDeleted
        isApproved
        idNumberUpload
        idNumber
        emailVerified
        email
        createdAt
        crNumber
        _id
        crNumberUpload
      }
      createdAt
      coordinates {
        longitude
        latitude
      }
      commission
      blUpload
      blNumber
      acceptedBy {
        transportId
        ownerId
        driverId
      }
      _id
    }
  }
`;

const DELETE_CUSTOMER_REQUEST = gql`
  mutation DeleteRequest($id: ID!) {
    deleteRequest(ID: $id) {
      success
      message
      data {
        type
        to
        status
        serviceType
        senderContact
        sendTo
        receiverContact
        receipt
        quantity
        price
        phoneNumber
        offlinePaid
        odUpload
        notificationId
        name
        location
        isStarted
        isSeen
        isNewRequest
        isFinished
        isAccept
        iroUpload
        iroDate {
          seconds
          nanoseconds
        }
        invoice
        height
        from
        finalByan
        estimateTime
        eir
        driverStarted
        distance
        deliveryNote
        customer {
          userName
          type
          token
          profileImage
          proVerified
          phoneNumber
          name
          isHold
          isDeleted
          isApproved
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          _id
        }
        createdAt
        coordinates {
          longitude
          latitude
        }
        commission
        blUpload
        blNumber
        acceptedBy {
          transportId
          ownerId
          driverId
        }
        _id
      }
    }
  }
`;

const GET_ALL_CUSTOMER_REQUEST = gql`
query CustomerRequests($page: Int!) {
  customerRequests(page: $page) {
    totalPages
    totalDocs
    requests {
      uploadClearance
      type
      to
      status
      serviceType
      senderContact
      sendTo
      routeToSeaFreight
      routeFromSeaFreight
      receiverContact
      receipt
      quantity
      price
      phoneNumber
      offlinePaid
      odUpload
      notificationId
      name
      location
      isStarted
      isSeen
      isNewRequest
      isFinished
      isAccept
      iroUpload
      iroDate {
        seconds
        nanoseconds
      }
      invoice
      height
      from
      finalByan
      estimateTime
      eir
      driverStarted
      distance
      deliveryNote
      customerPhoneSeaFreight
      customerNameSeaFreight
      customerNameClearance
      customerEmailSeaFreight
      customer {
        userName
        type
        token
        profileImage
        proVerified
        phoneNumber
        name
        isRelation
        isHold
        isDeleted
        idNumberUpload
        isApproved
        idNumber
        emailVerified
        email
        createdAt
        crNumberUpload
        crNumber
        _id
      }
      createdAt
      coordinates {
        longitude
        latitude
      }
      containerWeightSeaFreight
      containerSizeSeaFreight
      containerQuantitySeaFreight
      commission
      blUpload
      blNumber
      billOfLandingClearance
      acceptedBy {
        transportId
        ownerId
        driverId
      }
      _id
    }
  }
}
`;
const GET_ALL_CLEARANCE_REQUEST = gql`
  query Requests($customerId: ID!, $page: Int!) {
    myRequests(customerId: $customerId, page: $page) {
      requests {
        uploadClearance
        customerNameClearance
        customer {
          userName
          type
          token
          profileImage
          proVerified
          phoneNumber
          name
          isRelation
          isHold
          isDeleted
          isApproved
          idNumberUpload
          idNumber
          emailVerified
          email
          createdAt
          crNumberUpload
          crNumber
          _id
        }
        billOfLandingClearance
      }
    }
  }
`;
export {
  CREATE_CUSTOMER_TRANSPORT_REQUEST,
  GET_MYCUSTOMER_TRANSPORT_REQUEST,
  DELETE_CUSTOMER_REQUEST,
  GET_REQUESTWITHSTATUS,
  GET_ALL_CUSTOMER_REQUEST,
  GET_ALL_CLEARANCE_REQUEST,
};
