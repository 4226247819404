import { gql } from "@apollo/client";

const SEND_TRANSPORT_REQUEST = gql`
mutation UpdateRequest($id: ID!, $updateRequestInput: UpdateRequestInput!) {
    updateRequest(ID: $id, updateRequestInput: $updateRequestInput) {
      success
      message
      code
      data {
        type
        to
        status
        serviceType
        senderContact
        receiverContact
        sendTo
        receipt
        quantity
        price
        phoneNumber
        offlinePaid
        odUpload
        notificationId
        name
        location
        isStarted
        isSeen
        isNewRequest
        isFinished
        isAccept
        iroUpload
        iroDate {
          seconds
          nanoseconds
        }
        invoice
        height
        from
        finalByan
        estimateTime
        eir
        driverStarted
        distance
        deliveryNote
        customer {
          email
          emailVerified
          idNumber
          idNumberUpload
          isApproved
          isDeleted
          isHold
          isRelation
          name
          phoneNumber
          proVerified
          profileImage
          userName
          type
          token
          crNumberUpload
          crNumber
          _id
        }
        coordinates {
          longitude
          latitude
        }
        commission
        blUpload
        blNumber
        acceptedBy {
          transportId
          ownerId
          driverId
        }
        _id
      }
    }
  }
`;




export { SEND_TRANSPORT_REQUEST };