import { Badge, Tabs } from 'antd';
import React, { useState } from 'react'
import CustomerRequestTable from '../../Tables/CustomerRequestTable';
import CPendingRequestTable from '../../Tables/CPendingRequestsTable';
import CUnderProccessRequestTable from '../../Tables/CUnderProcessRequestTable';
import { readNotifications } from '../../API/API';
import useNotifications from '../../Hooks/useNotifications';
import { useCustomerRequest } from '../../Hooks/useCustomerRequest';
import { useTranslation } from 'react-i18next';
import useCheckActiveTab from '../../../hooks/useCheckActiveTab';
import useCustomerRequests from '../../Hooks/useCustomerRequests';
export const TransportRequest = () => {
    const { notifications } = useNotifications();
    // console.log("nnnnnnnnnnnnnnnn", notifications);
  // const { customerRequests } = useCustomerRequest();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest, count } = useCustomerRequests(
    updateTrigger,
    paginationFilters
  );
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };





 

  console.log(allRequest,"wertyuwertyuwertyeuwertyuwertyu")


  const { t } = useTranslation();
  const { activeTab, handleTabChange } = useCheckActiveTab();
  const { TabPane } = Tabs;
  const underProcess = 
  notifications &&
  notifications?.filter(
    // (v) => !v.isRead && v.status.includes("approved" || "underProcess")
    (v) => !v.isRead && (v.status.includes("approved") || v.status.includes("underProcess"))
  );
const pending =
  notifications &&
  notifications?.filter((v) => !v.isRead && v.status.includes("pending"));
  console.log('underProcess', underProcess);
  console.log('pending', pending);
  const finished =
    notifications &&
    notifications?.filter((v) => !v.isRead && v.status.includes("finished"));
  return (
    <div className="inp-emai">
      <div>
        <h3>{t("routes.transport_request")}</h3>
      </div>
      <div>
        <Tabs onChange={handleTabChange} activeKey={activeTab}>
          <TabPane
            tab={
              <Badge
                onClick={() => {
                  // readNotifications(underProcess);
                  readNotifications(allRequest.filter((v) => v.status === "created"));
                }}
                offset={[10, 0]}
                count={
                  <span
                    style={{
                      backgroundColor: "green",
                      marginTop: "-7px",
                      marginRight: "10px",
                    }}
                    className="Span-reqest"
                  >
                    {
                      allRequest?.filter((v) => v.status === "created").length
                    }
                  </span>
                }
                dot={underProcess && underProcess.length > 0 ? true : false}
              >
                <span>{t("tableTabs.newRequests")}</span>
              </Badge>
            }
            key="1"
          >
            <CustomerRequestTable />
          </TabPane>

          <TabPane
            tab={
              <Badge
                onClick={() => {
                  readNotifications(pending);
                }}
                offset={[10, 0]}
                count={
                  <span
                    style={{
                      backgroundColor: "brown",
                      marginTop: "-7px",
                      marginRight: "8px",
                    }}
                    className="Span-reqest"
                  >
                    {
                      allRequest?.filter((v) => v.status === "pending")
                        .length
                    }
                  </span>
                }
                dot={pending && pending.length > 0 ? true : false}
              >
                <span>{t("tableTabs.needToPay")}</span>
              </Badge>
            }
            key="2"
          >
            <CPendingRequestTable />
          </TabPane>
          <TabPane
            tab={
              <Badge
                onClick={() => {
                  readNotifications(underProcess);
                }}
                offset={[10, 0]}
                count={
                  <span
                    style={{
                      backgroundColor: "#001529",
                      marginTop: "-7px",
                      marginRight: "10px",
                    }}
                    className="Span-reqest"
                  >
                    {
                      allRequest?.filter(
                        (v) => v.status === "underProcess"
                      ).length
                    }
                  </span>
                }
                dot={underProcess && underProcess.length > 0 ? true : false}
              >
                <span>{t("tableTabs.underProcessRequests")}</span>
              </Badge>
            }
            key="3"
          >
            <CUnderProccessRequestTable />
          </TabPane>
          {/* <TabPane
            tab={
              <Badge
                onClick={() => {
                  readNotifications(finished);
                }}
                offset={[10, 0]}
                count={
                  <span
                    style={{
                      backgroundColor: " #ceb745",
                      marginTop: "-7px",
                      marginRight: "10px",
                    }}
                    className="Span-reqest"
                  >
                    {
                      customerRequests.filter((v) => v.status === "finished")
                        .length
                    }
                  </span>
                }
                dot={finished && finished.length > 0 ? true : false}
              >
                <span>{t("tableTabs.finishedRequests")}</span>
              </Badge>
            }
            key="4"
          >
            <CFinisi />
          </TabPane> */}
        </Tabs>
      </div>
    </div>
  )
}
