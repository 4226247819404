import { collection, doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Button, Col, Form, message, Row } from "antd";
import { useTranslation } from "react-i18next";
import { Select } from "antd";
import useTrucks from "../../Hooks/useTrucks";
import useGetAllMaintenanceServices from "../../Hooks/useGetAllMaintenanceServices";
import { db } from "../../firebase";
import MaintenanceTable from "../../Tables/MaintenanceTable";
import Loader from "../../Loaders/Loader";

const Maintenance = () => {
  let ownerTruck = [];
  let truckId;
  const { Option } = Select;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { trucks } = useTrucks();
  const { maintenaceService } = useGetAllMaintenanceServices();
  const [selectedService, setSelectedService] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const uid = JSON.parse(localStorage.getItem("uid"));

  trucks.forEach((el) => {
    ownerTruck.push(el.truckModel);
    truckId = el.uid;
  });
  const ownerUniqueTruck = [...new Set(ownerTruck)];
  const selectedServices = selectedService.map((item) => item.children);

  const selectHandler = (e, option) => {
    setSelectedService(option);
  };

  const totalAmount = selectedService
    .map((item) => item.value)
    .reduce((pre, next) => {
      return pre + next;
    }, 0);

  const onFinish = async (values) => {
    const { truck, serviceradius } = values;
    setIsLoading(true);
    try {
      const ref = doc(collection(db, "maintenance-service_request"));
      await setDoc(ref, {
        services: selectedService,
        serviceradius,
        truck,
        truckId,
        uid: ref.id,
        ownerId: uid,
      });

      const notiRef = doc(collection(db, "maintenance-notification"));
      await setDoc(notiRef, {
        title: "maintenance request",
        to: "admin",
        desc: `The owner of ${truck} request for ${selectedServices.join(
          ","
        )} services`,
        uid: notiRef.id,
      });

      setSelectedService([]);
      message.success("added successfully");
      // form.setFieldsValue({ truck: "" });
      // form.setFieldsValue({ services: "" });
      // form.setFieldsValue({ serviceradius: "" });
      form.resetFields();
    } catch (error) {
      message.error("something went wrong");
    }
    setIsLoading(false);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  console.log("push code");
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div>
            <h3>{t("routes.maintenance")}</h3>
          </div>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row justify="space-between">
              <Col span={12}>
                <Form.Item
                  name="truck"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Select
                    style={{ width: 500 }}
                    showSearch
                    placeholder="Select Truck"
                    optionFilterProp="children"
                    allowClear={true}
                  >
                    {ownerUniqueTruck?.map((v, id) => {
                      return (
                        <Option
                          key={id}
                          value={v.charAt(0).toUpperCase() + v.slice(1)}
                        >
                          {v.charAt(0).toUpperCase() + v.slice(1)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="services"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    mode="multiple"
                    style={{ width: 500 }}
                    placeholder="Select Services"
                    onChange={selectHandler}
                  >
                    {maintenaceService?.map((v, id) => {
                      return (
                        <Option key={v.uid} value={v.serviceamount}>
                          {v?.service.charAt(0).toUpperCase() +
                            v.service.slice(1)}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="serviceradius"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    style={{ width: 500 }}
                    placeholder="Select Radius"
                  >
                    {maintenaceService?.map((v) => {
                      return (
                        <Option key={v.uid} value={v.serviceradius}>
                          {v.serviceradius + "km"}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <ul
              style={{
                listStyle: "none",
                textAlign: "center",
                marginTop: "1rem",
                // background: "#ddd",
                width: "30%",
                margin: "0 auto",
                padding: ".5rem 0",
              }}
            >
              {selectedService.length !== 0 &&
                selectedService?.map((val) => {
                  return (
                    <li>
                      {val.children}-{val.value}
                    </li>
                  );
                })}
            </ul>
            {selectedService.length !== 0 && (
              <div
                style={{
                  textAlign: "center",
                  background: "#ddd",
                  marginBottom: "1rem",
                  width: "30%",
                  margin: "0 auto",
                  padding: ".5rem 0",
                }}
              >
                total Amount : {totalAmount}
              </div>
            )}
            <Row>
              <Col>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <MaintenanceTable />
        </>
      )}
    </>
  );
};

export default Maintenance;
