import React, { useState } from "react";
import {
  Table,
  Button,
  Input,
  Popconfirm,
  Space,
  Select,
  Pagination,
} from "antd";

import {
  // PlusCircleOutlined,
  SearchOutlined,
  // UploadOutlined,
  DeleteOutlined,
  // EditOutlined,
  EyeOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
// import MakeRequestModal from "../Modals/MakeRequestModal";
import useCustomerRequests from "../Hooks/useCustomerRequests";
// import { DownloadOutlined } from "@ant-design/icons";
import { DeleteRequest } from "../API/API";
import ViewRequestModal from "../Modals/ViewRequestModal";
import InvoiceModal from "../Modals/InvoiceRecieptModal";
import useTransports from "../Hooks/useTransport";
import { useTranslation } from "react-i18next";
import "./table.css";
import useUser from "../Hooks/useUser";
import { useNavigate } from "react-router-dom";
export default function CUnderProccessRequestTable() {
  const navigate = useNavigate();
  const { user } = useUser();
  const { customerRequests } = useCustomerRequests();
  const [search, setSearch] = useState("");
  const [requestData, setRequestData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [isModalInvoiceVisible, setIsModalInvoiceVisible] = useState(false);
  const { t } = useTranslation();
  const { transports } = useTransports();

  const showInvoiveModal = () => {
    setIsModalInvoiceVisible(!isModalVisible);
  };

  const showViewModal = () => {
    setIsViewModalVisible(!isModalVisible);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest, count } = useCustomerRequests(
    updateTrigger,
    paginationFilters
  );
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
  // console.log(allRequest, "data from Cunder");

  const columns = [
    {
      title: t("tableTabs.id"),
      render: (record) => (
        <span title={record?._id}>{record?._id.slice(-4)}</span>
      ),
    },

    {
      title: t("tableTabs.createdAt"),
      render: (record) => (
        <span>
          {new Date(record?.createdAt).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      ),
    },
    {
      title: t("tableTabs.name"),
      dataIndex: "name",
      key: "name",
    },

    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },

    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "type",
    },

    {
      title: t("tableTabs.capacity"),
      dataIndex: "serviceType",
      key: "capacity",
    },

    {
      title: t("tableTabs.payonline"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <Button className={`${record?.isPaid ? "btn-success" : "btn-error"}`}>
            {`${record?.isPaid ? t("tableTabs.paid") : t("tableTabs.unpaid")}`}
          </Button>

          {/* <Button
            disabled={record?.offlinePaid}
            style={{ width: "80px", fontSize: "13px" }}
            className={`${
              record?.isPaid ? "btn-offline" : "btn-offline-products"
            }`}

            // className={`${
            //   record.isPaid ? "btn-offline" : "btn-offline-products"
            // }`}
          >
            {t("tableTabs.paid")}
            {/* {`${record?.isPaid ?  : t("tableTabs.unpaid")}`} */}
          {/* </Button>  */}
        </Space>
      ),
    },
    {
      title: t("tableTabs.OfflinePay"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Button
          disabled={record.isPaid}
          style={{ width: "85px", fontSize: "12.3px" }}
          onClick={() => {
            setRequestData(record);
            showInvoiveModal();
          }}
          className={`${
            record?.offlinePaid ? "btn-offline" : "btn-offline-products"
          }`}
        >
          <span className="inners-div">
            <GlobalOutlined />
            <div style={{ marginLeft: "2px" }}>{t("tableTabs.OfflinePay")}</div>
          </span>
        </Button>
      ),
    },

    // original data of the payoffline here
    // {
    //   title: t("tableTabs.OfflinePay"),
    //   dataIndex: "",
    //   key: "x",
    //   align: "center",
    //   render: (record) => (
    //     <Space>
    //       {user?.proVerified ? (
    //         <Button
    //           disabled={record.isPaid}
    //           style={{ width: "85px", fontSize: "12.3px" }}
    //           onClick={() => {
    //             setRequestData(record);
    //             showInvoiveModal();
    //           }}
    //           className={`${
    //             record?.offlinePaid ? "btn-offline" : "btn-offline-products"
    //           }`}
    //         >
    //           <span className="inners-div">
    //             <GlobalOutlined />
    //             <div style={{ marginLeft: "2px" }}>
    //               {t("tableTabs.OfflinePay")}
    //             </div>
    //           </span>
    //         </Button>
    //       ) : (
    //         ""
    //       )}
    //     </Space>
    //   ),
    // },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            onClick={() => {
              let transport = transports?.find(
                (v) => v?.id === record.acceptedBy?.transportId
              );
              let data = {
                request: record,
                transport: transport ? transport : {},
              };
              navigate(`/customer/request-detail/${record?.id}`, {
                state: data,
              });
            }}
            className="ant-btn  ant-btn-warn submit_btn"
          >
            <EyeOutlined />
          </span>
          <Popconfirm
            title={"Are you sure you want to delete?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => {
              if (record.isAccept === undefined || !record.isAccept) {
                DeleteRequest(record?.id, record);
              } else {
                alert(
                  "Accepted request cannot delete please contact with customer support"
                );
              }
            }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
          {/* {!record?.offlinePaid &&
          <span className="eyes-btn">
          <DownloadOutlined/> 
          </span>
          } */}
        </Space>
      ),
    },
  ];
  return (
    <div>
      <ViewRequestModal
        isModalVisible={isViewModalVisible}
        setIsModalVisible={setIsViewModalVisible}
        state={requestData}
      />
      <InvoiceModal
        isModalVisible={isModalInvoiceVisible}
        setIsModalVisible={setIsModalInvoiceVisible}
        state={requestData}
      />

      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        columns={columns}
        pagination={false}
        dataSource={
          allRequest
          //  &&
          // allRequest?.length > 0 &&
          // allRequest
          //   .sort((a, b) => b.createdAt - a.createdAt)
          //   // .filter((v) => v.status === "created")
          //   .filter((v) => v.status === "created")
          //   .filter((val) => {
          //     if (search == "") {
          //       return val;
          //     } else if (
          //       val &&
          //       Object.keys(val).some((v) =>
          //         val[v]
          //           .toString()
          //           .toLowerCase()
          //           .includes(search.toString().toLowerCase())
          //       )
          //     ) {
          //       return val;
          //     }
          //   })
        }
      />
      <Pagination
        defaultCurrent={1}
        defaultPageSize={paginationFilters.pageSize}
        onChange={handleChange}
        total={count}
        className="Pagination"
      />
    </div>
  );
}
