import { db } from '../firebase';
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect } from 'react';
import { useState } from 'react';

const useSeaFreightNewRequst = () => {
    const [requestData, setRequestData] = useState([])
    const getSeaFreight = () => {
        const q = query(collection(db, "sea-freight"), orderBy("createdAt", "desc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const cities = [];
            if (!querySnapshot.empty) {
                querySnapshot.forEach((doc) => {
                    cities.push(doc.data());
                });
            }
            setRequestData(cities)
        });
        return () => unsubscribe()
    }

    useEffect(() => {
        getSeaFreight()
    }, [])
    return { requestData }
}

export default useSeaFreightNewRequst
