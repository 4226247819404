import React, { useState } from "react";
import {
  Table,
  Button,
  Input,
  Popconfirm,
  // Spin,
  Space,
  // Select,
  Pagination,
} from "antd";
import useTransports from "../Hooks/useTransport";

import {
  PlusCircleOutlined,
  SearchOutlined,
  UploadOutlined,
  DeleteOutlined,
  // EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import MakeRequestModal from "../Modals/MakeRequestModal";
import useCustomerRequests from "../Hooks/useCustomerRequests";
// import { useCustomerRequest } from "../Hooks/useCustomerRequest";
import { DeleteRequest } from "../API/API";
import ViewRequestModal from "../Modals/ViewRequestModal";
import InvoiceModal from "../Modals/InvoiceModal";
import { useTranslation } from "react-i18next";
import "./table.css";
import { useNavigate } from "react-router-dom";
export default function CustomerRequestTable() {
  const navigate = useNavigate();
  // const { customerRequests } = useCustomerRequests();
  // const { customerRequest } = useCustomerRequest();
  const [search, setSearch] = useState("");
  const [requestData, setRequestData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [isModalInvoiceVisible, setIsModalInvoiceVisible] = useState(false);
  const { t } = useTranslation();
  const showInvoiveModal = () => {
    setIsModalInvoiceVisible(!isModalVisible);
  };

  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest, count } = useCustomerRequests(
    updateTrigger,
    paginationFilters
  );
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };
 

  // const showViewModal = () => {
  //   setIsViewModalVisible(!isModalVisible);
  // };
  const showViewModal = () => {
    setIsViewModalVisible(!isViewModalVisible);
  };
  
  const { transports } = useTransports();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const columns = [
    // {
    //   title: t("tableTabs.id"),
    //   render: (record, i, index) => (
    //     <span title={record?._id}>{index + 1}</span>
    //   ),
    // },
    {
      title: t("tableTabs.id"),
      render: (record) => (
        <span title={record?._id}>{record?._id.slice(-4)}</span>
      ),
    },

    {
      title: t("tableTabs.createdAt"),
      render: (record) => (
        <span>
          {new Date(record?.createdAt).toLocaleString("en-US", {
            month: "short",
            day: "2-digit",
            year: "2-digit",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })}
        </span>
      ),
    },
    {
      title: t("tableTabs.name"),
      dataIndex: "name",
      key: "name",
    },

    {
      title: t("tableTabs.from"),
      dataIndex: "from",
      key: "from",
    },
    {
      title: t("tableTabs.to"),
      dataIndex: "to",
      key: "to",
    },
    {
      title: t("tableTabs.distance"),
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: t("tableTabs.price"),
      dataIndex: "price",
      key: "price",
    },

    {
      title: t("tableTabs.type"),
      dataIndex: "type",
      key: "type",
    },

    {
      title: t("tableTabs.capacity"),
      dataIndex: "serviceType",
      key: "capacity",
    },

    {
      title: t("tableTabs.action"),
      dataIndex: "",
      key: "x",
      align: "center",
      render: (record) => (
        <Space>
          <span
            onClick={() => {
              setRequestData(record);
              showInvoiveModal();
            }}
            className="ant-btn  ant-btn-warn"
          >
            <UploadOutlined />
          </span>
          <span
            onClick={() => {
              let transport = transports?.find(
                (v) => v?._id === record.acceptedBy?.transportId
              );
              let data = {
                request: record,
                transport: transport ? transport : {},
              };
              {console.log(record?._id,"record?._id")}
              navigate(`/customer/request_detail/${record?._id}`, {
                state: data,
              });
            }}
            className="ant-btn  ant-btn-warn"
          >
            <EyeOutlined />
          </span>

          <Popconfirm
            title={"Are you sure?"}
            okText="Ok"
            cancelText="Cancel"
            onConfirm={() => {
              if (record.isAccept === undefined || !record?.isAccept) {
                DeleteRequest(record?._id, record);
              } else {
                alert(
                  "Accepted request cannot delete please contact with customer support"
                );
              }
            }}
          >
            <span className="ant-btn ant-btn-danger">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  return (
    <div>
      <MakeRequestModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
      <ViewRequestModal
        isModalVisible={isViewModalVisible}
        setIsModalVisible={setIsViewModalVisible}
        state={requestData}
      />
      <InvoiceModal
        isModalVisible={isModalInvoiceVisible}
        setIsModalVisible={setIsModalInvoiceVisible}
        state={requestData}
      />

      <div className="dd_div">
        <Button
          className="make-btn"
          type="primary"
          icon={<PlusCircleOutlined className="iconsss" />}
          size={"middle"}
          onClick={() => navigate("/customer/tarnsport_requests/new")}
        >
          {t("tableTabs.makeNewRequest")}
        </Button>
      </div>
      <div className="table_search_div">
        <Input
          allowClear
          size="middle"
          placeholder={t("placeholders.search")}
          prefix={<SearchOutlined />}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </div>
      <Table
        columns={columns}
        pagination={false}
        dataSource={
          allRequest &&
          allRequest.length > 0 &&
          allRequest
            .filter((v) => v.status === "created")
            // .filter((v) => v.status === "underprocess")
            .filter((val) => {
              if (search == "") {
                return val;
              } else if (
                val &&
                Object.keys(val).some((v) =>
                  val[v]
                    .toString()
                    .toLowerCase()
                    .includes(search.toString().toLowerCase())
                )
              ) {
                return val;
              }
            })
        }
      />
      <Pagination
        defaultCurrent={1}
        defaultPageSize={paginationFilters.pageSize}
        onChange={handleChange}
        total={count}
        className="Pagination"
      />
      {/* <Table
  columns={columns}
  dataSource={
    customerRequests &&
    customerRequests
      .filter((request) => request.status === "created")
      .filter((request) =>
        Object.values(request).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(search.toString().toLowerCase())
        )
      )
  }
/> */}
    </div>
  );
}
