import { SearchOutlined } from "@ant-design/icons";
import { Input, Pagination, Table } from "antd";
import React, { useState } from "react";
import useClearanceNewRequest from "../Hooks/useClearanceNewRequest";
import useCustomerRequests from "../Hooks/useCustomerRequests";

const ClearanceFinanaceTable = () => {
  const { clearanceRequest } = useClearanceNewRequest();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paginationFilters, setPaginationFilters] = useState({
    pageNo: 1,
    pageSize: 5,
    limitSkip: 0,
    limit: 10,
  });
  const { allRequest, count} = useCustomerRequests(updateTrigger, paginationFilters);
  const handleChange = (value, pageSize) => {
    const limitSkip = value * pageSize;
    const limit = pageSize;
    setPaginationFilters({ pageNo: value, pageSize, limitSkip, limit });
    // setLoading(true);
    setUpdateTrigger(!updateTrigger);
  };

  const columns = [
    {
      title: "Pill Of Lading",
      dataIndex: "billOfLandingClearance",
      key: "pilloflading",
    },
    {
      title: "Customer Name",
      dataIndex: "customerNameClearance",
      key: "cname",
    },
    // {
    //   title: "Invoice",
    //   dataIndex: "invoice",
    //   key: "invoice",
    // },
    // {
    //   title: "Saber",
    //   dataIndex: "saber",
    //   key: "saber",
    // },
    // {
    //   title: "SFDA",
    //   dataIndex: "sfda",
    //   key: "sfda",
    // },
    // {
    //   title: "D/O",
    //   dataIndex: "DO",
    //   key: "DO",
    // },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    // },
  ];

  return (
    <div>
      <div style={{ marginBottom: ".6rem", marginTop: "1rem" }}>
        <Input prefix={<SearchOutlined />} placeholder="search" />
      </div>
      <Table
      pagination={false}
        dataSource={allRequest.filter((el) => el.status === "finance")}
        columns={columns}
      />
        <Pagination
          defaultCurrent={1}
          defaultPageSize={paginationFilters.pageSize}
          onChange={handleChange}
          total={count}
          className="Pagination"
        />
    </div>
  );
};

export default ClearanceFinanaceTable;
