import React
// { useState }
from "react";
import "./customer_sidebar.css";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Badge } from "antd";
import {
  // MenuFoldOutlined,
  // MenuUnfoldOutlined,
  // UploadOutlined,
  // VideoCameraOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { BsTruck } from "react-icons/bs";
import { ImCoinDollar } from "react-icons/im";
//firebase
// import { auth } from "../../../../firebase";
//i18n
import { useTranslation } from "react-i18next";
import { Logout } from "../../../../API/API";
import useNotifications from "../../../../Hooks/useNotifications";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../../../../Landing/assets/home/OAC LOGO2.png";
import { SiLinuxcontainers } from "react-icons/si";
import { BsFillFileEarmarkPostFill } from "react-icons/bs";

const { Sider } = Layout;
export default function CustomerSideBar({ collapsed }) {
  const navigate = useNavigate();
  const { notifications } = useNotifications();

  const { t } = useTranslation();
  // const [collapsed, setCollapsed] = useState(false);
  let requests =
    notifications &&
    notifications.filter(
      (v) => !v.isRead && v.type.toLowerCase().includes("request")
    );
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div data-aos="fade-right" data-aos-duration="3000">
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" style={{ margin: ".5rem 0" }}>
          <div className="logo_div">
            <img className="car-img" src={logo} />
          </div>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          // defaultSelectedKeys={["1"]}
          selectedKeys={[window.location.pathname]}
          items={[
            {
              key: "/customer/tarnsport_requests",
              icon: <BsTruck />,
              label: (
                <Badge dot={requests && requests.length > 0 ? true : false}>
                  <span className="badge_span">
                    {t("routes.transportation_request")}
                    {/* {"T Requests"} */}
                  </span>
                </Badge>
              ),
              onClick: () => {
                navigate("/customer/tarnsport_requests");
              },
            },
            {
              key: "/customer/finance",
              icon: <ImCoinDollar />,
              label: t("routes.finance"),
              onClick: () => {
                navigate("/customer/finance");
              },
            },
            {
              key: "/customer/clearance",
              icon: <BsFillFileEarmarkPostFill />,
              label: t("routes.clearance"),
              onClick: () => {
                navigate("/customer/clearance");
              },
            },
            {
              key: "/customer/see-freight",
              icon: <SiLinuxcontainers />,
              label: "Sea Freight",
              onClick: () => {
                navigate("/customer/see-freight");
              },
            },

            {
              key: "/",
              icon: <LogoutOutlined />,
              label: t("routes.logout"),
              onClick: Logout,
            },
          ]}
        >
          {/* <Menu.Item key={"truck"} icon={<BsTruck />}>
              <NavLink to={"/"}>Truck</NavLink>
            </Menu.Item> */}
        </Menu>
      </Sider>
    </div>
  );
}
