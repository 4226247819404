import { gql } from "@apollo/client";

export const get_Relations = gql`
  query Relations($page: Int!) {
    relations(page: $page) {
      totalDocs
      currentPage
      totalPages
      relations {
        _id
        customer {
          _id
          crNumber
          crNumberUpload
          createdAt
          email
          emailVerified
          idNumber
          idNumberUpload
          isApproved
          isDeleted
          isHold
          name
          isRelation
          phoneNumber
          proVerified
          profileImage
          type
          token
          userName
        }
        owner {
          totalDocs
          currentPage
          totalPages
          owners {
            _id
            bankInfo {
              beneficiaryName
              bankIdCode
              iBanNumber
            }
            crNumber
            crNumberUpload
            createdAt
            email
            emailVerified
            idNumber
            idNumberUpload
            isAccept
            isApproved
            isDeleted
            name
            phoneNumber
            profileImage
            rRA
            type
            token
            isVat
            ownerName
          }
          _id
          name
        }
        createdAt
      }
    }
  }
`;
